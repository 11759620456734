import { ChangeEvent, useDeferredValue, useEffect, useState } from 'react'


import InnerStyle from './inputstyle.module.css'
import { DigitValidate, commafy } from '../../Globals'

type DefaultData = {
    IDS: number[]
}

type TInputCustom = {
    disabled?: boolean
    InputTailwindstyle?: string
    DefaultValue?: string
    inputChangedVal: (val: string | number) => void
    isNumber?: boolean,
    EndActionIcon?: JSX.Element,
    EndActionCallback?: () => void
    StartActionIcon?: JSX.Element,
    StartActionCallback?: () => void
}



const InputCustom = ({ disabled,InputTailwindstyle, DefaultValue, inputChangedVal, isNumber, StartActionIcon, StartActionCallback, EndActionIcon, EndActionCallback }: TInputCustom) => {

    // constants & logic
    if (InputTailwindstyle === undefined) InputTailwindstyle = ''
    if (isNumber === undefined) isNumber = false
    if(disabled === undefined) disabled = false  
    //bar & action height 
    const BarActionHeight = 'h-12'
    const ActionWidth = 'w-12'


    // states
    const [InputVal, setInputVal] = useState<string>(DefaultValue ?? "")
    const deferredVal = useDeferredValue(InputVal);

    //Functions 
    const HandleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value.replace(",", "")
        if (isNumber === true) {
            if (val.match(DigitValidate)) {
                setInputVal(val)
                inputChangedVal(val)
            }
        } else {
            setInputVal(val)
            inputChangedVal(val)
        }
    }


    // useEffect
    useEffect(() => {

        if(DefaultValue !== undefined) setInputVal(DefaultValue)
    }, [DefaultValue])

    return (
        <div className='w-full'>
            {/* DropDown main component */}
            <div className={`bg-white ${BarActionHeight} border-2 overflow-hidden border-gray-300 rounded-md flex  justify-start items-center relative ${InputTailwindstyle}`}>
                {StartActionIcon && (
                    <div className={`${BarActionHeight} ${ActionWidth} bg-slate-100 hover:cursor-pointer p-2`} onClick={StartActionCallback}>
                        {StartActionIcon}
                    </div>
                )}
                <input disabled={disabled} type="text" id="chips-input" onChange={HandleInputChange} value={InputVal} className={`${InnerStyle.clearInputBorder} grow h-full text-sm`} />
                {EndActionIcon && (
                    <div className={`${BarActionHeight} ${ActionWidth} bg-slate-100 hover:cursor-pointer p-2`} onClick={EndActionCallback}>
                        {EndActionIcon}
                    </div>
                )}
            </div>
        </div>
    )
}

export default InputCustom