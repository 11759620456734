import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IUserSlice, UsersState } from '../../../redux/slices/UserSlice';

import { GetErrorAxios, HavePermission, PERMISSIONS } from '../../../Globals';
import { DepositIcon, DetailsIcons, WithdrawIcon } from '../../../components/Icons/Icons';
import DevExtremTable from '../../../components/shared/EGDExtremeTable';
import GModal from '../../../components/GModal';
import FinishDeposit from './Deposit';
import FinishWithdraw from './Withdraw';
import { GetAllFinishingFinanceTransactions } from '../../../Service/Apis';
import { FINISHING_TRANSACTIONS, GetAllFinishingFinanceTransactionsResp } from '../../../Service/models/Finishing';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';


type TransActions_Type = {
    id: any,
    deposit: string,
    withdraw: string,
    Trans_type: string,
    Trans_reason: string
}


const DummyData: TransActions_Type[] = [
    { id: 0, deposit: '0', Trans_reason: "test reason", Trans_type: 'test type', withdraw: '1000' },
    { id: 1, deposit: '1000', Trans_reason: "test reason1", Trans_type: 'test type1', withdraw: '0' },
    { id: 2, deposit: '5000', Trans_reason: "test reason2", Trans_type: 'test type2', withdraw: '1000' },
    { id: 3, deposit: '0', Trans_reason: "test reason3", Trans_type: 'test type3', withdraw: '6500' },
    { id: 4, deposit: '2500', Trans_reason: "test reason4", Trans_type: 'test type4', withdraw: '1000' },
    { id: 5, deposit: '0', Trans_reason: "test reason5", Trans_type: 'test type5', withdraw: '750' },
    { id: 6, deposit: '0', Trans_reason: "test reason6", Trans_type: 'test type6', withdraw: '12000' },
    { id: 7, deposit: '1300', Trans_reason: "test reason7", Trans_type: 'test type7', withdraw: '1000' },
    { id: 8, deposit: '0', Trans_reason: "test reason8", Trans_type: 'test type8', withdraw: '120000' },
    { id: 9, deposit: '600', Trans_reason: "test reason9", Trans_type: 'test type9', withdraw: '1000' },
]

const FinishingSafe = () => {
    const UsersData: IUserSlice = useSelector(UsersState);

    // state for deposit amount 
    const [depositModal, setDepositModal] = useState<boolean>(false);
    // state for withdraw amount
    const [withdrawModal, setWithdrawModal] = useState<boolean>(false);

    const [showWithdraw, setShowWithdraw] = useState<boolean>(false);
    const [showDeposit, setShowDeposit] = useState<boolean>(false);

    const [totalBalance, setTotalBalance] = useState<number>(0);
    const [companyBalance, setCompanyBalance] = useState<number>(0);


    const [Transactions, setTransactions] = useState<FINISHING_TRANSACTIONS[]>([])

    const dispatch = useDispatch<AppDispatch>()








    useEffect(() => {
        GetAllFinishingFinanceTransactions().then(res => {
            let transactions: GetAllFinishingFinanceTransactionsResp = res
            console.log(transactions)
            setTransactions(transactions.TRANSACTIONS)
            setTotalBalance(transactions.USERS_BALANCE)
            setCompanyBalance(transactions.COMPANY_BALANCE)

        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, [])


    return (
        <>
            <div>
                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.Finance, UsersData.currentUser.PERMISSIONS) && (
                    <div className='flex gap-10'>
                        <div className='flex flex-row items-center gap-4 hover:text-[#e7c498] hover:cursor-pointer bg-[#e2edf1] rounded shadow w-32 mb-5 p-1' onClick={() => {
                            setShowDeposit(true)
                            setDepositModal(true)
                        }} >

                            <div className='h-8 w-8' >
                                <DepositIcon />
                            </div>
                            <h3 className='text-xs font-bold my-2'>إيداع</h3>
                        </div>
                        <div className='flex flex-row items-center gap-4 hover:text-[#e7c498] hover:cursor-pointer bg-[#e2edf1] rounded shadow w-32 mb-5 p-1' onClick={() => {
                            setWithdrawModal(true)
                            setShowWithdraw(true)
                        }} >
                            <div className='h-8 w-8' >
                                <WithdrawIcon />
                            </div>

                            <h3 className='text-xs font-bold my-2'>صرف</h3>
                        </div>
                    </div>
                )}
                {/* balance section */}
                <div className='w-full bg-slate-100 rounded shadow mb-5 p-5'>
                    {/* اجمالي رصيد العملاء */}
                    <h6>اجمالي رصيد العملاء</h6>
                    <h3 className='text-lg font-bold'>{totalBalance} جنيه</h3>
                    {/* رصيد الشركه */}
                    <h6 className='mt-10'>رصيد الشركه</h6>
                    <h3 className='text-lg font-bold'>{companyBalance} جنيه</h3>

                </div>
                {Transactions?.length > 0 ?
                    <DevExtremTable ID="ID" tableData={[
                        { Caption: "رقم مرجعي", Type: 'string', Value: 'ID' },
                        { Caption: "رقم الطلب", Type: 'string', Value: 'FINISHING_REQUEST_ID' },
                        { Caption: "اسم العميل", Type: 'string', Value: 'CUSTOMER_NAME' },
                        { Caption: "العنوان", Type: 'string', Value: 'CUSTOMER_ADDRESS' },
                        { Caption: "دائن", Type: 'string', Value: 'DEPOSIT_AMT' },
                        { Caption: "مدين", Type: 'string', Value: 'WITHDRAW_AMT' },

                    ]}
                        dataSource={Transactions}
                        Actions={(data) => {
                            let currentTransaction = data.data as FINISHING_TRANSACTIONS;
                            return (
                                <div className=" flex items-center gap-4 hover:cursor-pointer  p-1">
                                    <div className='flex flex-col items-center hover:text-[#e7c498]' onClick={() => {

                                    }}>
                                        <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' >
                                            <DetailsIcons />
                                        </div>
                                        <p className='text-sm font-bold'>تفاصيل</p>
                                    </div>

                                </div>
                            )
                        }}
                    /> :
                    <div className='w-full h-52 overflow-hidden rounded-lg'>
                        <div className='shimmer'>

                        </div>
                    </div>
                }

            </div>
            <GModal OpenModal={depositModal} onCloseModal={() => {
                setDepositModal(false)

                setTimeout(() => {
                    setShowDeposit(false)
                }, 1000);
            }} >
                {showDeposit && <FinishDeposit />}
            </GModal>
            <GModal OpenModal={withdrawModal} onCloseModal={() => {
                setWithdrawModal(false)
                setTimeout(() => {
                    setShowWithdraw(false)
                }, 1000);
            }} >
                {showWithdraw && <FinishWithdraw />}
            </GModal>
        </>
    )
}

export default FinishingSafe