import DataGrid, { Column, Export, FilterRow, Pager, Paging, SearchPanel } from 'devextreme-react/cjs/data-grid'
import { ComponentProps, useEffect, useState } from 'react'
import { DateRegEx, DaysNames, exportFormats, exportGrid, getDateTimeOnly, getYearMonthDayFromDate } from '../../../Globals'

interface ITableProps<T> extends ComponentProps<'div'> {
    tableData: { Caption: string, Value: Extract<keyof T, string>, Type: "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined }[]
    dataSource: T[],
    ID: Extract<keyof T, string>
    Actions?: (currentRow: any) => JSX.Element,
    AllowPaging?: boolean,
    AllowExport?: boolean,
    AllowSearhTopBar?: boolean,
    Width?:number

}

const CellFormat = (data: any) => {
    // 2024-06-12T00:00:00Z not work
    // 2024-04-25T00:17:00.4736413+02:00
    let newValue = data.value
    let isDate = DateRegEx.test( newValue as string)

    if (isDate) {
        return <div dir='ltr' className='flex w-full h-12 justify-center items-center'>
            <h6 className='text-bold truncate'><span>{DaysNames[new Date(newValue).getDay()]}</span> - <span className='font-bold'> {getYearMonthDayFromDate(new Date(newValue))} </span> - <span className='font-bold'>{getDateTimeOnly(new Date(newValue))}</span></h6>
        </div>
    }

    if (typeof newValue === 'boolean') {
        if (newValue === true) {
            return <div className='w-full h-12 flex justify-center items-center'>
                <h5 className='font-bold text-red-500'>نعم</h5>
            </div>
        } else {
            return <div className=' w-full h-12 flex justify-center items-center'>
                <h5 className='font-bold text-green-500'>لا</h5>
            </div>
        }
    }

    return <div dir='ltr' className='flex w-full h-12 justify-center items-center'>
        <h6 className='text-bold truncate'>{newValue}</h6>
    </div>
}

const DevExtremTable = <T extends object>(Props: ITableProps<T>): JSX.Element => {
    const { ID, tableData, dataSource, Actions, AllowPaging, AllowExport, AllowSearhTopBar } = Props

    const [RID, setRID] = useState<string>("");
    const [RtableData, setRtableData] = useState<{ Caption: string, Value: Extract<keyof T, string>, Type: "string" | "number" | "boolean" | "object" | "date" | "datetime" | undefined }[]>([]);
    const [RdataSource, setRdataSource] = useState<T[]>([]);


    useEffect(() => {
        setRID(ID);
        setRtableData(tableData)
        setRdataSource(dataSource)


    }, [ID, tableData, dataSource, Actions, AllowPaging, AllowExport, AllowSearhTopBar])
    return (
        <div dir='rtl'>
            <DataGrid rtlEnabled={true} id="dataGrid" dataSource={RdataSource} keyExpr={RID} allowColumnReordering={true} rowAlternationEnabled={true}scrolling={{scrollByThumb:true,renderAsync:true}} columnAutoWidth={true} onExporting={exportGrid} >
                {/* <Column alignment='center' dataField="Pic" width={'80px'} dataType='string' caption='الصوره' allowFiltering={false} cellRender={(data) => {
                    return <div className='w-full h-full rounded-2xl overflow-hidden object-scale-down flex items-center justify-center'>
                        <img src={data.value} alt="EmployeePicture" style={{ width: '50px', height: '50px' }} />
                    </div>
                }}>
                </Column>
                <Column alignment='center' dataField="Email" dataType='string' caption='البريد الإلكتروني' cellRender={CellFormat}></Column>
                <Column alignment='center' dataField="Name" dataType='string' caption='الإسم' cellRender={CellFormat}></Column>
                <Column alignment='center' dataField="Job" dataType='string' caption='الوظيفه' cellRender={CellFormat}></Column>
                <Column alignment='center' dataField="Active" dataType='string' caption='الحاله' cellRender={CellFormat}></Column>
                <Column alignment='center' dataField="-" dataType='string' caption='الإجرائات' allowFiltering={false} cellRender={Props.Actions}></Column> */}

                {RtableData.map((item, idx) => {
                    return (
                        <Column alignment='center' dataField={item.Value} dataType={item.Type} caption={item.Caption} cellRender={CellFormat}></Column>
                    )
                })}

                {Actions !== undefined && (
                    <Column alignment='center' dataField="" dataType='string' caption='الإجرائات' allowFiltering={false} type='buttons' allowExporting={false} width={Props.Width} cellRender={Props.Actions}></Column>
                )}

                <FilterRow visible={true} />
                {(AllowSearhTopBar !== undefined || AllowSearhTopBar === true) && (
                    <SearchPanel visible={true} />
                )}
                {(AllowPaging !== undefined || AllowPaging === true) && (
                    <Paging defaultPageSize={50} />
                )}
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20, 50]}
                    showInfo={true}
                />
                {(AllowExport !== undefined || AllowExport === true) && (
                    <Export enabled={true} formats={exportFormats} />
                )}
            </DataGrid>
        </div>
    )
}

export default DevExtremTable