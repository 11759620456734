import React, { useEffect } from 'react'
import { HeaderH1Text } from '../../../../../CustomComps/Customs'
import { useTranslation } from 'react-i18next';
import EGSlider from '../../../../../components/shared/EGCarousel/EGSlider';
import { useSelector } from 'react-redux';
import { ProjectsState } from '../../../../../redux/slices/Projects';

const MostSelling = () => {
    const ProjectsData = useSelector(ProjectsState);

    useEffect(() => {

    }, [])

    const { t } = useTranslation();

    return (
        <div className='w-full h-full'>
            <HeaderH1Text className='mb-2' weight='bold'>
                {t('MostSelling')}
            </HeaderH1Text>
            {ProjectsData && ProjectsData.status && ProjectsData.status.length > 0 && (
                <div className='w-full h-full '>
                    <EGSlider data={ProjectsData.status} speed={20} />
                </div>
            )}
        </div>
    )
}

export default MostSelling