import React, { useEffect, useRef, useState } from 'react'
import DevExtremTable from '../../../components/shared/EGDExtremeTable';
import { DetailsIcons } from '../../../components/Icons/Icons';
import { EgButton } from '../../Shared/Shared';
import GModal from '../../../components/GModal';
import { useDispatch, useSelector } from 'react-redux';
import { UsersState } from '../../../redux/slices/UserSlice';
import { GetErrorAxios, HavePermission, PERMISSIONS } from '../../../Globals';
import { UserSalaries } from '../../../Service/models/Salary_Model';
import { GetAllEvents, GetAllExpTypes } from '../../../Service/Apis';
import { AppDispatch } from '../../../redux/store';
import { EVENTS, EXPENSES_TYPE } from '../../../Service/models/EventsData';
import NewEvent from './NewEvent';

const EventsData = () => {
    // constants 
    const UsersData = useSelector(UsersState);
    const dispatch = useDispatch<AppDispatch>()

    //states 
    const [newEventModal, setNewEventModal] = useState<boolean>(false);
    const [UpdateEventModal, setUpdateEventModal] = useState<boolean>(false);
    const [allEvents, setAllEvents] = useState<EVENTS[]>([])
    const [allExpenseTypes, setAllExpenseTypes] = useState<EXPENSES_TYPE[]>([])
    const [clearDataState, setClearDataState] = useState<boolean>(false);

    //functions 
    const getAllData = async () => {

        // all events
        GetAllEvents().then(res => {
            setAllEvents(res)
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })

        //all expenses types 
        GetAllExpTypes().then((res) => {
            setAllExpenseTypes(res)
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }

    //Effects 
    useEffect(() => {
        getAllData()
    }, [])

    return (
        <>
            <div>
                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_EVENT, UsersData.currentUser.PERMISSIONS!) && (
                    <div className='flex gap-1 items-center' onClick={() => {
                        setNewEventModal(true)
                    }}>
                        <div className='i-material-symbols-add-box-outline-rounded text-2xl text-gray-500 hover:cursor-pointer my-3'>
                        </div>
                        <h4 className='text-xl font-bold'>إضافه فاعليه</h4>
                    </div>
                )}
                <DevExtremTable
                    ID="ID"
                    tableData={[
                        { Caption: "الفاعليه", Type: 'string', Value: 'DESCRIPTION' },
                        { Caption: "تاريخ البدايه", Type: 'string', Value: 'START_DATE' },
                        { Caption: "تاريخ النهايه", Type: 'string', Value: 'END_DATE' },
                    ]}
                    dataSource={allEvents ?? []}
                    Actions={(data) => {
                        let currentSalary = data.data as EVENTS;
                        return (
                            <div className="flex items-center gap-4 hover:cursor-pointer p-1">
                                <div className='flex flex-col items-center hover:text-[#e7c498]' onClick={() => {
                                    setUpdateEventModal(true)
                                }}>
                                    <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer'>
                                        <DetailsIcons />
                                    </div>
                                    <p className='text-sm font-bold'>تعديل</p>
                                </div>
                            </div>
                        );
                    }}
                />
                {/* // مودال اضافه ايفينت */}
                {allExpenseTypes.length > 0 && (
                    <GModal OpenModal={newEventModal} onCloseModal={() => {
                        setNewEventModal(false);
                        setClearDataState(true);
                        setTimeout(() => {
                            setClearDataState(false);
                        }, 1000);
                    }}>
                        <NewEvent clearData={clearDataState} ExpenseTypes={allExpenseTypes} />
                    </GModal>
                )}
                {/* // مودال تعديل ايفينت */}
                <GModal OpenModal={UpdateEventModal} onCloseModal={() => {
                }}>
                    <h1>UpdateEventModal</h1>
                </GModal>
            </div>
        </>
    );
}

export default EventsData