
import { useEffect, useRef, useState } from 'react'
import EGDate from '../../../../components/shared/EGDate'
import EGInput from '../../../../components/shared/EGInput'
import { EgButton } from '../../../Shared/Shared'
import './DealStyle.css'
import EGRADIO from '../../../../components/shared/EGRadioBut'
import { DEAL_MODEL } from '../../../../Service/models/DealApplication'
import { ActionTypes, EmptyObjectData, GetErrorAxios, ObjectValidator } from '../../../../Globals'
import { AddAction } from '../../../../redux/slices/ErrorsSlice'
import { useDispatch } from 'react-redux'
import { type } from 'os'
import { ACTIONS } from '../../../../Service/models/UserModel/Actions/ActionsModel'
import axios from 'axios'
import { EditDeal, axiosConfig, getAllProjects } from '../../../../Service/Apis'
import { ContactsModel } from '../../../../Service/models/ContactsModel/ContactsMOdel'
import { DropDownData, DropTextField } from './AppointMents'
import { CustomTextFieldFullHeight } from '../../../../CustomComps/Customs'
import { useTheme } from '@mui/material'
import { tokens } from '../../../../theme'
import { ProjectModel } from '../../../../Service/models/projects/ProjectModel'
import { setLoadingFalse, setLoadingTrue } from '../../../../redux/slices/LoadingSlice'


//Types 

type ValidatorType = { [key in keyof DEAL_MODEL]: boolean };


// Initializers 
const ValidatorObject: ValidatorType = {
  APPLICANT_NAME: false,
  APPLICATION_DATE: false,
  COMPANY_ACCOUNTANT_NAME: false,
  COMPANY_ACCOUNTANT_PHONE: false,
  COMPANY_COMMISSION: false,
  COMPANY_INCENTIVE: false,
  LEADER_COMMISSIONS: false,
  COMPANY_NAME: false,
  COMPANY_PROJECT: false,
  COMPANY_SALES_NAME: false,
  COMPANY_SALES_PHONE: false,
  CLIENT_CONTRACT_DATE: false,
  CLIENT_NAME: false,
  CLIENT_PHONE: false,
  CLIENT_UNIT_CODE: false,
  CLIENT_UNIT_PRICE: false,
  CLIENT_UNIT_TYPE: false,
  SALES_ACCOUNT_NUMBER: false,
  SALES_BANK_NAME: false,
  SALES_IBAN: false,
  LEADER_NAME: false,
  LEADER_IBAN: false,
  LEADER_ACCOUNT_NUMBER: false,

}


const Deal = ({ selectedContact, deal }: { selectedContact: ContactsModel, deal?: DEAL_MODEL }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
  const [ProjectsdropDownData, setProjectsDropDownData] = useState<DropDownData[]>([])
  const [actions, setActions] = useState<ACTIONS>({ ACTION_TYPE: ActionTypes.Deal, CONTACT_ID: selectedContact.CONTACT_ID!, STAMP_DATE: new Date() });
  const [internalProjs, setInternalProjs] = useState<ProjectModel[]>([]);
  //# states & dispatch
  //dispatch
  const dispatch = useDispatch();


  //#Refs
  const DiscountModal = useRef<HTMLDivElement>(null);
  //Request Ref 
  // const RequestRef = useRef<DEAL_MODEL>({} as DEAL_MODEL);
  const [RequestRef, setRequestRef] = useState<DEAL_MODEL>(deal ?? {} as DEAL_MODEL);
  // const [RequestRef, setRequestRef] = useState<DEAL_MODEL>(deal ?? { LEADER_COMMISSIONS: 0 } as DEAL_MODEL);


  //validate Request Ref 

  const ValidateRequestRef = useRef<ValidatorType>(ValidatorObject);

  //#Functions
  const OpenDiscountModal = () => {
    DiscountModal.current?.classList.add('displayModal')
    DiscountModal.current?.classList.remove('hideModal')
  }

  const CloseDiscountModal = () => {
    DiscountModal.current?.classList.remove('displayModal')
    DiscountModal.current?.classList.add('hideModal')
  }

  // general function to update the deal model ref
  const updateObjectValue = <T extends object>(obj: T, key: keyof T, newValue: any): T => {
    obj[key] = newValue;

    return obj;
  }



  // update the request ref by key and value
  const UpdateModalRefBy = (key: keyof DEAL_MODEL, value: any) => {
    // if (RequestRef.current !== null) {
    //   let newObjectValue = updateObjectValue(RequestRef.current, key, value);
    //   RequestRef.current = updateObjectValue(RequestRef.current, key, value);
    //   
    // }
    setRequestRef(perv => ({ ...perv, [key]: value }));
  }

  // validate the request ref by key and value
  const ValidateModalRefBy = (key: keyof ValidatorType, value: boolean) => {
    if (ValidateRequestRef.current !== null) {
      let newObjectValue = updateObjectValue(ValidateRequestRef.current, key, value);
      ValidateRequestRef.current = updateObjectValue(ValidateRequestRef.current, key, value);

    }
  }


  //displaying Error
  const displayValidRequest = <T extends object>(object: T, key: keyof T, mssg: string): boolean => {

    if (typeof object[key] === 'boolean' && object[key] === false) {
      dispatch(AddAction({ message: mssg, status: "error" }))
      return false
    }

    // if( typeof object[key] === 'string' && object[key] === '') {
    //   dispatch(AddAction({ message: mssg, status: "error" }))
    //   return false
    // }

    // if( typeof object[key] === 'number' && object[key] === 0) {
    //   dispatch(AddAction({ message: mssg, status: "error" }))
    //   return false
    // }

    // if( typeof object[key] === 'object' && object[key] === null) {
    //   dispatch(AddAction({ message: mssg, status: "error" }))
    //   return false
    // }

    return true
  }

  //Custom Display Error 
  const CustomDisplayError = <T extends object>(object: T, key: keyof T, mssg: string, callback: () => boolean): boolean => {
    let result: boolean = callback();
    if (result === false) {
      dispatch(AddAction({ message: mssg, status: "error" }))
      return false
    }
    return true
  }

  //validating function Data 
  const validateData = (): boolean => {

    if (deal !== undefined) {
      return true;
    }

    let isValidated: boolean = true;
    let validRequest = ValidateRequestRef.current;

    if (Object.keys(validRequest).length === 0) {
      dispatch(AddAction({ message: "برجاء عدم ترك الخانات فارغه", status: "error" }))
      return false
    };

    if (RequestRef.COMPANY_PROJECT_ID === undefined) {
      dispatch(AddAction({ message: "برجاء اختيار المشروع", status: "error" }))
      return false
    }


    isValidated = displayValidRequest(validRequest, 'APPLICANT_NAME', 'يجب ادخال اسم مقدم الطلب');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'APPLICATION_DATE', 'يجب ادخال تاريخ التعاقد');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'COMPANY_NAME', 'يجب ادخال اسم الشركه');
    if (isValidated === false) return isValidated;
    // isValidated = displayValidRequest(validRequest, 'COMPANY_PROJECT', 'يجب ادخال اسم المشروع');
    // if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'COMPANY_SALES_NAME', 'يجب ادخال اسم السيلز');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'COMPANY_SALES_PHONE', 'يجب ادخال رقم هاتف المبيعات');
    if (isValidated === false) return isValidated;
    // isValidated = displayValidRequest(validRequest, 'COMPANY_ACCOUNTANT_NAME', 'يجب ادخال اسم مسئول الحسابات');
    // if (isValidated === false) return isValidated;
    // isValidated = displayValidRequest(validRequest, 'COMPANY_ACCOUNTANT_PHONE', 'يجب ادخال رقم هاتف مسئول الحسابات');
    // if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'CLIENT_NAME', 'يجب ادخال اسم العميل');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'CLIENT_PHONE', 'يجب ادخال رقم هاتف العميل');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'CLIENT_UNIT_TYPE', 'يجب ادخال نوع الوحده');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'CLIENT_UNIT_CODE', 'يجب ادخال كود الوحده');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'CLIENT_UNIT_PRICE', 'يجب ادخال سعر الوحده');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'COMPANY_COMMISSION', 'يجب ادخال عموله الشركه');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'COMPANY_INCENTIVE', 'يجب ادخال الحافز');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'LEADER_COMMISSIONS', 'يجب ادخال حافز الليدر');
    if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'CLIENT_CONTRACT_DATE', 'يجب ادخال تاريخ التعاقد');
    if (isValidated === false) return isValidated;
    // isValidated = displayValidRequest(validRequest, 'SALES_BANK_NAME', 'يجب ادخال اسم الـ البنك لـ sales');
    // if (isValidated === false) return isValidated;
    isValidated = displayValidRequest(validRequest, 'LEADER_NAME', 'يجب ادخال اسم الـ leader');
    if (isValidated === false) return isValidated;
    // isValidated = CustomDisplayError(validRequest, 'APPLICATION_DATE', 'يجب ادخال تاريخ التعاقد', () => {
    //   return false
    // });
    return isValidated
  }

  //handling save and pring
  const handleSaveAndPrint = () => {
    let isValidated: boolean = validateData()
    if (isValidated) {
      console.log("قيمة حافز الليدر:", RequestRef.LEADER_COMMISSIONS);

      let ActionObj: ACTIONS = { ...actions, DEAL: RequestRef, STAMP_DATE: new Date() }
      let serializeReq = JSON.stringify(ActionObj);
      console.log(" حافز الليدر:", RequestRef);


      // if((RequestRef.current.SALES_ACCOUNT_NUMBER?.length ?? 0) < 5 && (RequestRef.current.SALES_IBAN?.length ?? 0) < 5){
      //   dispatch(AddAction({ message: "برجاء ادخال رقم الحساب او الايبان لـ sales", status: "error" }))
      //   return
      // } 

      // if((RequestRef.current.LEADER_ACCOUNT_NUMBER?.length ?? 0) < 5 && (RequestRef.current.LEADER_IBAN?.length ?? 0) < 5){
      //   dispatch(AddAction({ message: "برجاء ادخال رقم الحساب او الايبان لـ leader", status: "error" }))
      //   return
      // }

      if (deal !== undefined) {
        dispatch(setLoadingTrue())
        EditDeal(RequestRef).then(res => {
          dispatch(setLoadingFalse());
          dispatch(AddAction({ message: "مبروك تم تعديل الديل بنجاح", status: "success" }))

          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        }).catch(err => {
          dispatch(setLoadingFalse());
          GetErrorAxios(err, dispatch)

        })
        return;
      }

      axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
        res => {
          dispatch(AddAction({ message: "مبروك تم تقفيل الديل بنجاح", status: "success" }))
        }
      ).catch(err => {
        GetErrorAxios(err, dispatch)
      })
    }
  }


  useEffect(() => {

    if (deal !== undefined) {
      console.log(deal)
      setRequestRef(deal);
    }

  }, [deal, selectedContact])


  useEffect(() => {


    UpdateModalRefBy('CLIENT_UNIT_TYPE', 1);
    ValidateModalRefBy('CLIENT_UNIT_TYPE', true);
    if (deal === undefined) {
      getAllProjects().then(res => {
        setInternalProjs(res)
        setProjectsDropDownData(res.map((item, idx) => ({ id: item.ID, desc: item.PROJECT_NAME } as DropDownData)))
      }).catch(err => {
        GetErrorAxios(err, dispatch);
      })
    }
  }, [])


  return (
    <div className="bg-white w-full h-full  rounded-2xl p-10">
      <>
        <div className='gridContainer overflow-y-auto relative'>
          <div className='header mb-3'>
            <h3>إستماره مبيعات</h3>
          </div>
          <div className='box mb-3'>
            <div className='flex flex-wrap h-52'>
              <div className='flex flex-col w-[80%] gap-3 justify-between'>
                <div className=' flex justify-between'>
                  <div className='w-1/2 '>
                    <div>
                      <h5>إسم مقدم الطلب</h5>
                    </div>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput initialValue={RequestRef.APPLICANT_NAME} HTMLFor={'ApplicantNam2e'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                        UpdateModalRefBy('APPLICANT_NAME', data);
                      }}
                        validated={(data) => {
                          ValidateModalRefBy('APPLICANT_NAME', data);
                        }}
                      />
                    </div>
                  </div>
                  <div className='w-1/3 flex flex-col'>
                    <label htmlFor="ProjectID">المشروع</label>
                    <DropTextField classes='w-3/4 bg-white' initialText='المشروع' initalValue={RequestRef.COMPANY_PROJECT_ID} data={[
                      ...ProjectsdropDownData
                    ]} onchange={e => {
                      let projectId = parseFloat(e.target.value);

                      UpdateModalRefBy('COMPANY_PROJECT_ID', projectId);
                    }} />
                  </div>
                </div>
                <div className='flex justify-between items-center w-full md:w-2/4'>
                  <h5>التاريخ</h5>
                  <EGDate DateValue={(dateVal) => {

                    UpdateModalRefBy('APPLICATION_DATE', dateVal);
                    ValidateModalRefBy('APPLICATION_DATE', true);
                  }}
                  />
                </div>
              </div>
              <div className='w-[20%] h-[100%]'>
                <img className=' w-full h-full object-contain' src='https://the-team.co/api/images/img?id=6' alt='logo' />
              </div>
            </div>
          </div>
          <div className='header mb-2'>
            <h3>بيانات شركه التطوير</h3>
          </div>
          <div className='box'>
            {/* اسم الشركه */}
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >إسم الشركه</h5>
              <div className='w-full text-center h-[5em]'>
                <EGInput initialValue={RequestRef.COMPANY_NAME} HTMLFor={'companyName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('COMPANY_NAME', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('COMPANY_NAME', data);
                  }}
                />
              </div>
            </div>
            {/* اسم المشروع */}
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >إسم المشروع</h5>
              <div className='w-full text-center h-[5em]'>
                <EGInput initialValue={RequestRef.COMPANY_ACCOUNTANT_NAME} HTMLFor={'projectName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('COMPANY_PROJECT', data);
                  UpdateModalRefBy('COMPANY_PROJECT_NAME', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('COMPANY_ACCOUNTANT_NAME', true);
                  }}
                />
              </div>
            </div>
            {/* اسم السيلز */}
            <div className='flex'>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >إسم السيلز</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.COMPANY_SALES_NAME} HTMLFor={'salesName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('COMPANY_SALES_NAME', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('COMPANY_SALES_NAME', data);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >رقم الهاتف</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.COMPANY_SALES_PHONE} HTMLFor={'salesNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('COMPANY_SALES_PHONE', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('COMPANY_SALES_PHONE', data);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* مسئول الحسابات */}
            <div className='flex'>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >مسئول الحسابات</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.COMPANY_ACCOUNTANT_NAME} HTMLFor={'AccountantName'} errorText={''} valueChange={(data) => {
                    UpdateModalRefBy('COMPANY_ACCOUNTANT_NAME', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('COMPANY_ACCOUNTANT_NAME', data);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >رقم الهاتف</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.COMPANY_ACCOUNTANT_NAME} HTMLFor={'AccountantNumber'} errorText={''} valueChange={(data) => {
                    UpdateModalRefBy('COMPANY_ACCOUNTANT_PHONE', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('COMPANY_ACCOUNTANT_PHONE', data);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='header my-3'>
            <h3>بيانات العميل و الوحده</h3>
          </div>
          <div className='box'>
            {/* اسم العميل و رقم الهاتف */}
            <div className='flex'>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >إسم العميل</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.CLIENT_NAME} HTMLFor={'ClientName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('CLIENT_NAME', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('CLIENT_NAME', data);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >رقم الهاتف</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.CLIENT_PHONE} HTMLFor={'ClientNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('CLIENT_PHONE', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('CLIENT_PHONE', data);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* نوع الوحده و كود الوحده */}
            <div className='flex'>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >نوع الوحده</h5>
                <div className='w-full text-center h-[5em] flex justify-start'>
                  <DropTextField classes='w-3/4 bg-white' initalValue={deal !== undefined ? deal.CLIENT_UNIT_TYPE : 1} data={[
                    { id: 1, desc: 'شقه' },
                    { id: 2, desc: 'فيلا' },
                    { id: 3, desc: 'توين هاوس' },
                    { id: 4, desc: 'تاون هاوس' },
                    { id: 5, desc: 'دوبلكس' },
                    { id: 6, desc: 'بنت هاوس' },
                    { id: 7, desc: 'شاليه' },
                    { id: 8, desc: 'استوديو' },
                    { id: 9, desc: 'كابينه' },
                    { id: 10, desc: 'عياده' },
                    { id: 11, desc: 'مكتب' },
                    { id: 12, desc: 'متجر' },

                  ]} onchange={e => {
                    UpdateModalRefBy('CLIENT_UNIT_TYPE', e.target.value);
                    ValidateModalRefBy('CLIENT_UNIT_TYPE', e.target.value);
                  }} />
                  {/* <EGInput HTMLFor={'UnitType'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('CLIENT_UNIT_TYPE', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('CLIENT_UNIT_TYPE', data);
                  }}
                /> */}
                </div>
              </div>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5>كود الوحده</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.CLIENT_UNIT_CODE} HTMLFor={'UnitCode'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('CLIENT_UNIT_CODE', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('CLIENT_UNIT_CODE', data);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* سعر الوحده  */}
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >سعر الوحده</h5>
              <div className='w-full text-center h-[5em]'>
                <EGInput initialValue={(RequestRef.CLIENT_UNIT_PRICE ?? "").toString()} HTMLFor={'unitPrice'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  let isNumber = !isNaN(parseFloat(data));
                  if (!isNumber) {
                    dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))

                  }
                  UpdateModalRefBy('CLIENT_UNIT_PRICE', parseFloat(data));
                }}
                  validated={(data) => {
                    ValidateModalRefBy('CLIENT_UNIT_PRICE', data);
                  }}
                />
              </div>
            </div>
            {/* عموله الشركه و الحافز */}
            <div className='flex'>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >عموله الشركه</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={(RequestRef.COMPANY_COMMISSION ?? "0").toString()} HTMLFor={'companyCommission'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    let isNumber = !isNaN(parseFloat(data));
                    if (!isNumber) {
                      dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))

                    }
                    UpdateModalRefBy('COMPANY_COMMISSION', parseFloat(data));
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('COMPANY_COMMISSION', data);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5>الحافز</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={(RequestRef.COMPANY_INCENTIVE ?? "0").toString()} HTMLFor={'COMPANY_INCENTIVE'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    let isNumber = !isNaN(parseFloat(data));
                    if (!isNumber) {
                      dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }))

                    }
                    UpdateModalRefBy('COMPANY_INCENTIVE', parseFloat(data));
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('COMPANY_INCENTIVE', data);
                    }}
                  />
                </div>
              </div>

              <div className='flex flex-col w-full items-start gap-2'>
                <h5>حافز الليدر</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput
                    initialValue={(RequestRef.LEADER_COMMISSIONS ?? "0").toString()}
                    HTMLFor={'LEADER_COMMISSIONS'}
                    errorText={'هذا الحقل اجباري'}
                    valueChange={(data) => {
                      let isNumber = !isNaN(parseFloat(data));
                      if (!isNumber) {
                        dispatch(AddAction({ message: "يجب ادخال رقم فقط", status: "error" }));
                        return;
                      }
                      UpdateModalRefBy('LEADER_COMMISSIONS', parseFloat(data));
                    }}
                    validated={(data) => {
                      ValidateModalRefBy('LEADER_COMMISSIONS', data);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* تاريخ التعاقد */}
            <div className='flex justify-between items-center w-full md:w-2/4'>
              <h5>تاريخ التعاقد</h5>
              <EGDate DateValue={(dateVal) => {
                UpdateModalRefBy('CLIENT_CONTRACT_DATE', dateVal);
                ValidateModalRefBy('CLIENT_CONTRACT_DATE', true);
              }}
              />
            </div>
          </div>
          {/* <div className='header my-3'>
          ملاحظات
        </div> */}
          <div className=''>
            {/* إمضاء العقد من العميل */}
            {/* <div className='flex flex-col w-full items-start gap-2'>
            <h3 >إمضاء العقد من العميل</h3>
            <div className='w-full text-center h-[5em]'>
              <EGRADIO initialValue={{ id: 1, label: 'لا' }} options={[{ id: 0, label: "نعم" }, { id: 1, label: "لا" }]} name={'ClientcontractSigned'} onChange={(value) => {
                UpdateModalRefBy('SIGNATURES_CLIENT', value.id === 0 ? true : false);
              }}
              />
            </div>
          </div> */}
            {/* إمضاء العقد من شركه التطوير */}
            {/* <div className='flex flex-col w-full items-start gap-2'>
            <h3 >إمضاء العقد من شركه التطوير</h3>
            <div className='w-full text-center h-[5em]'>
              <EGRADIO initialValue={{ id: 1, label: 'لا' }} options={[{ id: 0, label: "نعم" }, { id: 1, label: "لا" }]} name={'CompanycontractSigned'} onChange={(value) => {
                UpdateModalRefBy('SIGNATURES_DEVELOP_COMPANY', value.id === 0 ? true : false);
              }}
              />
            </div>
          </div> */}
            {/* دفع المقدم */}
            {/* <div className='flex flex-col w-full items-start gap-2'>
            <h3 >دفع مقدم</h3>
            <div className='w-full text-center h-[5em]'>
              <div className='w-full text-center h-[5em]'>
                <EGRADIO initialValue={{ id: 1, label: 'لا' }} options={[{ id: 0, label: "نعم" }, { id: 1, label: "لا" }]} name={'advancePayment'} onChange={(value) => {
                  UpdateModalRefBy('ADVANCE_PAYMENT', value.id === 0 ? true : false);
                }}
                />
              </div>
            </div>
          </div> */}
            {/* وضع الشيكات */}
            {/* <div className='flex flex-col w-full items-start gap-2'>
            <h3 >وضع الشيكات</h3>
            <div className='w-full text-center h-[5em]'>
              <EGRADIO initialValue={{ id: 1, label: 'لا' }} options={[{ id: 0, label: "نعم" }, { id: 1, label: "لا" }]} name={'ChequeStatus'} onChange={(value) => {
                UpdateModalRefBy('CHEQUE_ISSUED', value.id === 0 ? true : false);
              }}
              />
            </div>
          </div> */}
          </div>
          {/* <div className='header my-3'>
          <h3>بيانات افراد التسويق</h3>
        </div> */}
          <div className=''>
            {/* direct & رقم الهاتف */}
            {/* <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3>Direct</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'DirectName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_DIRECT_NAME', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_DIRECT_NAME', data);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3 >رقم الهاتف</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'DirectNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_DIRECT_PHONE', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_DIRECT_PHONE', data);
                  }}
                />
              </div>
            </div>
          </div> */}
            {/* level 1 & رقم الهاتف */}
            {/* <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3>Level 1</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'LVL1Name'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_LVL1_NAME', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_LVL1_NAME', data);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3 >رقم الهاتف</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'LVL1Number'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_LVL1_PHONE', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_LVL1_PHONE', data);
                  }}
                />
              </div>
            </div>
          </div> */}
            {/* level 2 & رقم الهاتف */}
            {/* <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3>Level 2</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'LVL2Name'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_LVL2_NAME', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_LVL2_NAME', data);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3 >رقم الهاتف</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'LVL2Number'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_LVL2_PHONE', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_LVL2_PHONE', data);
                  }}
                />
              </div>
            </div>
          </div> */}
            {/* level 3 & رقم الهاتف */}
            {/* <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3>Level 3</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'LVL3Name'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_LVL3_NAME', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_LVL3_NAME', data);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3 >رقم الهاتف</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'LVL3Number'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('MARKETING_LVL3_PHONE', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('MARKETING_LVL3_PHONE', data);
                  }}
                />
              </div>
            </div>
          </div> */}
          </div>
          {/* <div className='header my-3'>
          <h3>بيانات شيك الـ sales</h3>
        </div> */}
          <div className=''>
            {/* IPAN & الإسم  */}
            {/* <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3>الإسم</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'salesName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('SALES_NAME', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('SALES_NAME', data);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3 >IBAN</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'salesIBAN'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('SALES_IBAN', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('SALES_IBAN', data);
                  }}
                />
              </div>
            </div>
          </div> */}
            {/* رقم الحساب */}
            {/* <div className='flex flex-col w-full items-start gap-2'>
            <h3>رقم الحساب</h3>
            <div className='w-full text-center h-[5em]'>
              <EGInput HTMLFor={'salesAccountNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                UpdateModalRefBy('SALES_ACCOUNT_NUMBER', data);
              }}
                validated={(data) => {
                  ValidateModalRefBy('SALES_ACCOUNT_NUMBER', data);
                }}
              />
            </div>
          </div> */}
          </div>
          <div className='header my-3'>
            <h3>بيانات حساب الـ SALES</h3>
          </div>
          <div className='box'>
            {/* IPAN & الإسم  */}
            <div className='flex'>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5>إسم البنك</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.SALES_BANK_NAME} HTMLFor={'SALES_BANK_NAME'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('SALES_BANK_NAME', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('SALES_BANK_NAME', data);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >IBAN</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.SALES_IBAN} HTMLFor={'SALES_IBAN'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('SALES_IBAN', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('SALES_IBAN', data);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* رقم الحساب */}
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >رقم الحساب</h5>
              <div className='w-full text-center h-[5em]'>
                <EGInput initialValue={RequestRef.SALES_ACCOUNT_NUMBER} HTMLFor={'SALESAccountNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('SALES_ACCOUNT_NUMBER', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('SALES_BANK_NAME', data);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='header my-3'>
            <h3>بيانات حساب الـ Leader</h3>
          </div>
          <div className='box'>
            {/* IPAN & الإسم  */}
            <div className='flex'>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5>الإسم</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.LEADER_NAME} HTMLFor={'leaderName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('LEADER_NAME', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('LEADER_NAME', data);
                    }}
                  />
                </div>
              </div>
              <div className='flex flex-col w-full items-start gap-2'>
                <h5 >IBAN</h5>
                <div className='w-full text-center h-[5em]'>
                  <EGInput initialValue={RequestRef.LEADER_IBAN} HTMLFor={'LeaderIBAN'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                    UpdateModalRefBy('LEADER_IBAN', data);
                  }}
                    validated={(data) => {
                      ValidateModalRefBy('LEADER_IBAN', data);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* رقم الحساب */}
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >رقم الحساب</h5>
              <div className='w-full text-center h-[5em]'>
                <EGInput initialValue={RequestRef.LEADER_ACCOUNT_NUMBER} HTMLFor={'leaderAccountNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('LEADER_ACCOUNT_NUMBER', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('LEADER_ACCOUNT_NUMBER', data);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='box'>
            {/* الملاحظات */}
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >الملاحظات</h5>
              <div className='w-full text-center h-[5em]'>
                <textarea
                  id="Notes"
                  name="otherNotes"
                  className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-0 focus:shadow-none"
                  placeholder="استفسارك"
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    UpdateModalRefBy('NOTES', e.target.value);
                  }}
                  value={RequestRef.NOTES}
                />

              </div>
            </div>
          </div>
          {/* <div className='header my-3'>
          <h3 className='text-red-600'>تنيبه: لن يتم صرف عمولات دون ملئ جميع البيانات المذكورة في الاإستمارة </h3>
        </div> */}
          <div className=''>
            {/* توقيع المستلم & توقيع مقدم الإستماره */}
            {/* <div className='flex mb-20'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3>مقدم الإستماره</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'APPLICANTpROVIDER'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('APPLICANTS_SIGNATURE', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('APPLICANTS_SIGNATURE', data);
                  }}
                />
              </div>
              <h3>التوقيع</h3>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h3 >المستلم</h3>
              <div className='w-full text-center h-[5em]'>
                <EGInput HTMLFor={'RECIPIENTS_SIGNATURE'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                  UpdateModalRefBy('RECIPIENTS_SIGNATURE', data);
                }}
                  validated={(data) => {
                    ValidateModalRefBy('RECIPIENTS_SIGNATURE', data);
                  }}
                />
              </div>
              <h3>التوقيع</h3>
            </div>
          </div> */}
            {/* حفظ و طباعه & اضافه خسم & إلغاء */}
          </div>
          <div className='fullRow mt-3'>
            <div className='flex gap-2 w-full'>
              <div className='grow'>
                <EgButton HandleClick={() => { }}>
                  <h5>إلغاء</h5>
                </EgButton>
              </div>
              {/* <div className='grow'>
              <EgButton HandleClick={() => {
                OpenDiscountModal()
              }}>
                <h3>اضافه طلب خصم</h3>
              </EgButton>
            </div> */}
              <div className='grow'>
                <EgButton HandleClick={() => {
                  handleSaveAndPrint()
                }}>

                  <h5>حفظ </h5>
                </EgButton>
              </div>
            </div>
          </div>
        </div>
        <div ref={DiscountModal} className='absolute transition-all  ease-in-out duration-700 top-0 hideModal w-full h-full bg-slate-100 rounded-2xl p-5'>
          <div className='w-full h-full relative'>
            <div className='gridContainer overflow-y-auto relative mt-7'>
              <div className='header mb-3'>
                <h3>طلب خصم للعميل</h3>
              </div>
              <div className='box mb-3'>
                <div className='flex flex-wrap h-52'>
                  <div className='flex flex-col w-[80%] gap-3 justify-between'>
                    <div>
                      <h3>إسم مقدم الطلب</h3>
                    </div>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'AppId'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {

                        }}
                      />
                    </div>
                    <div className='flex justify-between items-center w-full md:w-2/4'>
                      <h3>التاريخ</h3>
                      <EGDate DateValue={(dateVal) => {

                      }} />
                    </div>
                  </div>
                  <div className='w-[20%] h-[100%]'>
                    <img className=' w-full h-full object-contain' src='https://the-team.co/api/images/img?id=6' alt='logo' />
                  </div>
                </div>
              </div>
              <div className='header mb-2'>
                <h3>بيانات شركه التطوير</h3>
              </div>
              <div className='box'>
                {/* اسم الشركه */}
                <div className='flex flex-col w-full items-start gap-2'>
                  <h3 >إسم الشركه</h3>
                  <div className='w-full text-center h-[5em]'>
                    <EGInput HTMLFor={'companyName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                    }}
                      validated={(data) => {

                      }}
                    />
                  </div>
                </div>
                {/* اسم المشروع */}
                <div className='flex flex-col w-full items-start gap-2'>
                  <h3 >إسم المشروع</h3>
                  <div className='w-full text-center h-[5em]'>
                    <EGInput HTMLFor={'projectName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                    }}
                      validated={(data) => {

                      }}
                    />
                  </div>
                </div>
                {/* اسم السيلز */}
                <div className='flex'>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >إسم السيلز</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'salesName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {

                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >رقم الهاتف</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'salesNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {

                        }} />
                    </div>
                  </div>
                </div>
                {/* مسئول الحسابات */}
                <div className='flex'>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >مسئول الحسابات</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'AccountantName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {
                          ValidateModalRefBy('COMPANY_NAME', data);
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >رقم الهاتف</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'AccountantNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {
                          ValidateModalRefBy('COMPANY_NAME', data);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='header my-3'>
                <h3>بيانات العميل و الوحده</h3>
              </div>
              <div className='box'>
                {/* اسم العميل و رقم الهاتف */}
                <div className='flex'>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >إسم العميل</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'ClientName'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {
                          ValidateModalRefBy('COMPANY_NAME', data);
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >رقم الهاتف</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'ClientNumber'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {
                          ValidateModalRefBy('COMPANY_NAME', data);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* نوع الوحده و كود الوحده */}
                <div className='flex'>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >نوع الوحده</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'UnitType'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {
                          ValidateModalRefBy('COMPANY_NAME', data);
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3>كود الوحده</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'UnitCode'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {

                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* سعر الوحده  */}
                <div className='flex flex-col w-full items-start gap-2'>
                  <h3 >سعر الوحده</h3>
                  <div className='w-full text-center h-[5em]'>
                    <EGInput HTMLFor={'unitPrice'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                    }}
                      validated={(data) => {

                      }}
                    />
                  </div>
                </div>
                {/* عموله الشركه و الحافز */}
                <div className='flex'>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3 >عموله الشركه</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'companyCommission'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {
                          ValidateModalRefBy('COMPANY_NAME', data);
                        }}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col w-full items-start gap-2'>
                    <h3>الحافز</h3>
                    <div className='w-full text-center h-[5em]'>
                      <EGInput HTMLFor={'COMPANY_INCENTIVE'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {

                      }}
                        validated={(data) => {

                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* تاريخ التعاقد */}
                <div className='flex justify-between items-center w-full md:w-2/4'>
                  <h3>تاريخ التعاقد</h3>
                  <EGDate DateValue={(dateVal) => {

                  }} />
                </div>
              </div>
              <div className='header my-3'>
                ملاحظات
              </div>

            </div>
            <div className='absolute -top-10  text-red-600 left-0 i-material-symbols-close-rounded text-3xl hover:cursor-pointer' onClick={() => {
              CloseDiscountModal()
            }}>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default Deal