import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { GetError, ResponseStatus } from '../../Globals';
import { DeleteContactAPI, GetAllContactsAPI, SaveContactAPI } from '../../Service/Apis';
import { ContactsModel } from '../../Service/models/ContactsModel/ContactsMOdel';
import { RootState } from '../store';
import { AddAction } from './ErrorsSlice';
import { showFeedBack } from './Feedback';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';



export interface IContactsSlice {
    AllContacts: ContactsModel[],
    Error: string,
    Loading: boolean,
    Empty: ContactsModel[],

}

const initialState: IContactsSlice = {
    AllContacts: [],
    Error: "",
    Loading: false,
    Empty: []
}

export const ContactSlice = createSlice({
    name: 'Contact',
    initialState,
    reducers: {
        // GetAllProjects: (state, action: PayloadAction<ProjectModel[]>) => {
        //     state.status = action.payload as ProjectModel[]
        // },

    },
    extraReducers: (builder) => {

        // When we send a request,
        // `fetchTodos.pending` is being fired:
        builder.addCase(GetAllContactsThunk.pending, (state) => {
            // At that moment,
            // we change status to `loading` 
            // and clear all the previous errors:
            // state.status = "loading";
            // state.error = null;
        });

        // When a server responses with the data,
        // `fetchTodos.fulfilled` is fired:
        builder.addCase(GetAllContactsThunk.fulfilled,
            (state, { payload }) => {
                // We add all the new todos into the state
                // and change `status` back to `idle`:
                // state.list.push(...payload);
                // state.status = "idle";
                state.AllContacts = payload as ContactsModel[];
            });

        // When a server responses with an error:
        builder.addCase(GetAllContactsThunk.rejected,
            (state, { payload }) => {
                // We show the error message
                // and change `status` back to `idle` again.
                // if (payload) state.error = payload.message;
                state.Error = payload as string

            });
    }
})

type FetchTodosError = {
    message: string;
};

export const GetAllContactsThunk = createAsyncThunk<ContactsModel[] | string>(
    // The first argument is the action name:
    "Contacts/getAll",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (Contact, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return GetAllContactsAPI().then(res => {
            thunkApi.dispatch(setLoadingFalse());
            return res;
        }).catch(err => {
            return GetError(err, thunkApi)
        });
    }
);
export const SaveContactThunk = createAsyncThunk<ResponseStatus, ContactsModel>(
    // The first argument is the action name:
    "Contacts/SaveContact",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (Contact, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return SaveContactAPI(Contact).then(res => {
            thunkApi.dispatch(setLoadingFalse());
            thunkApi.dispatch(showFeedBack({ show: true, message: "تم الصلاحيات", status: "success" }))
            return { Status: 'Success', Data: res } as ResponseStatus
        }).catch(err => {
            // let ERR = err as AxiosError
            // 
            // 
            // let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.request?.responseText;
            // 
            // thunkApi.dispatch(setLoadingFalse());
            // thunkApi.dispatch(showFeedBack({ show: true, message: ErrMsg, status: 'error' }))
            // return { Status: 'Fail', Error: ErrMsg } as ResponseStatus
            let errMsg = GetError(err, thunkApi); 
            return { Status: 'Fail', Error: errMsg } as ResponseStatus
        });
    }
);

export const DeleteContacThunk = createAsyncThunk<void, { CONTACT_ID: number }>(
    // The first argument is the action name:
    "Contacts/DeleteContact",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (Contact, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return DeleteContactAPI(Contact).then(res => {
            thunkApi.dispatch(setLoadingFalse());
            thunkApi.dispatch(AddAction({ message: "تم حذف العميل  نجاح", status: "success" }))
        }).catch(err => {
            return  GetError(err,thunkApi) 
        });
    }
);

export const ContactState = (state: RootState) => state.ContactStore;

export const { } = ContactSlice.actions

export default ContactSlice.reducer