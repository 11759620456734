import { Box, InputAdornment } from "@mui/material";
import { CustomTextField } from "../../CustomComps/Customs";
import { EmailLogo, THETEAM } from "../../components/Icons/Icons";

function Messageconf() {

    return (
        <>
            <div className='registerBG h-screen w-full flex justify-center items-center'>
                <div className='bg-[#EDF3F4] rounded-3xl w-4/6 md:w-2/6 h-[50%] flex flex-col items-center'>
                    <div className='h-1/3 p-2 mb-10 mt-10'>
                        <THETEAM />
                    </div>
                    <form className='w-4/5 h-1/2 flex flex-col  justify-start items-center'>
                        <h5>
                            "تم إرسال رابط تأكيد إعادة تعيين كلمة المرور إلى عنوان بريدك الإلكتروني المُسجل. يُرجى التحقق من بريدك الإلكتروني واتباع الإرشادات لإكمال عملية إعادة تعيين كلمة المرور. شكراً!"
                        </h5>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Messageconf;
