import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './HeroStyle.css'
import { HeaderH2Text } from '../../../../CustomComps/Customs'
import { useTranslation } from 'react-i18next';
import EgDropDown, { AutoCompleteType } from './comps/EgDropDown';
import { useSelector } from 'react-redux';
import { ProjectsState } from '../../../../redux/slices/Projects';
import { AddLogo } from '../../../../components/Icons/Icons';
// import EgDropDown from './comps/EgDropDown';

function Hero() {

  const { t } = useTranslation();

  const ProjectsData = useSelector(ProjectsState);
  const [projectForAutoComplete, setProjectsForAutoComplete] = useState<AutoCompleteType[]>([]);

  useEffect(() => {
    let projectsHolder: AutoCompleteType[] = [];
    if (ProjectsData && ProjectsData.status && ProjectsData.status.length > 0) {
      ProjectsData.status.map(prj => {
        projectsHolder.push({ name: prj.PROJECT_NAME, url: prj.PROJECT_IMAGES[0].IMAGE_URL ?? "" })
      })
    }
    
    if (projectsHolder.length > 0) {
      setProjectsForAutoComplete(projectsHolder);
    }
  }, [ProjectsData.status])


  return (
    <div className='hero w-full h-[514px] mb-12'>
      <HeaderH2Text className='mb-2 h-full' weight='bold'>
        <div className='w-full h-full flex flex-col justify-center'>
          
{/*           <div className=' hidden mx-24 mt-24 h-[76px] md:flex  bg-[#E0E0E0] bg-opacity-[80%]  rounded-[10px]  flex-row justify-between items-center px-4'>
            <div className='flex flex-row'>
              <div className=' min-w-[300px] h-full flex flex-col justify-center items-start'>
                <EgDropDown data={projectForAutoComplete} placeholderText="المشاريع" />
              </div>
            </div>
            <div>
              <div className='h-12 w-32 hover:cursor-pointer justify-center items-center flex rounded-md secondaryBG hover:primaryBG'>
                <h3 className='primaryText hover:secondaryText text-center'>بحث</h3>
              </div>
            </div>
            <div className=' min-w-[300px] h-full flex flex-col justify-center items-start'>
              <EgDropDown />
            </div>
          </div> */}
        </div>
      </HeaderH2Text>
    </div>
  )
}

export default Hero