import { UserModel } from '../../../Service/models/UserModel/UserModel';
import {
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
  createColumnHelper,
  Row
} from '@tanstack/react-table'

import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils'

import './index.css'

import { makeData, Person } from './makeData'
import { useEffect, useMemo, useReducer, useState } from 'react';
import { IUserTableData } from '../../../screens/setup/SetupPannels/Users/Users';
import { AddLogo, DelteLogo, EditLogo } from '../../Icons/Icons';
import {
  Column,
  DataGrid,
  Export,
  FilterRow,
  Pager,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';
import { exportFormats, exportGrid } from '../../../Globals';
// declare module '@tanstack/table-core' {
//   interface FilterFns {
//     fuzzy: FilterFn<unknown>
//   }
//   interface FilterMeta {
//     itemRank: RankingInfo
//   }
// }

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    )
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
}


const Employees: { key: number, EmpName: string }[] = [
  { key: 1, EmpName: "1" },
  { key: 2, EmpName: "2" },
  { key: 3, EmpName: "3" },
  { key: 4, EmpName: "4" },
  { key: 5, EmpName: "5" },
  { key: 6, EmpName: "6" },
  { key: 7, EmpName: "7" },
  { key: 8, EmpName: "8" },
  { key: 9, EmpName: "9" },
]


const EGTable = ({ Users, openModal, DeleteUser }: { Users: IUserTableData[], openModal: (data: IUserTableData) => void, DeleteUser: (user: IUserTableData) => void }) => {

  const rerender = useReducer(() => ({}), {})[1]

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    []
  )
  const [globalFilter, setGlobalFilter] = useState('')

  const columnHelper = createColumnHelper<IUserTableData>()

  const columns = useMemo<ColumnDef<IUserTableData, string>[]>(
    () => [
      {
        accessorFn: row => <div className='flex justify-center items-center w-full'>
          <div className='w-12 h-12 object-center overflow-hidden'><img className='object-fill' src={row.Pic} /></div>
        </div>,
        id: 'Pic',
        header: 'صوره الحساب',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        enableColumnFilter: false
        // filterFn: 'fuzzy',
        // sortingFn: fuzzySort,
      },
      {
        accessorFn: row => `${row.Email}`,
        id: 'Email',
        header: 'البريد الإلكتروني',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,

      },
      {
        accessorFn: row => `${row.Name}`,
        id: 'FullName',
        header: 'الإسم',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
      },
      {
        accessorFn: row => `${row.Job}`,
        id: 'job',
        header: 'الوظيفه',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
      },
      {
        accessorFn: row => `${row.Active}`,
        id: 'Active',
        header: 'الحاله',
        cell: (info) => info.getValue(),
        footer: props => props.column.id,
        filterFn: 'fuzzy',
        sortingFn: fuzzySort,
      },
      {
        accessorFn: row => <div className='flex flex-row w-full h-full justify-around' >
          <div className='bg-[#e2edf1] rounded shadow h-11 w-11 p-2 hover:cursor-pointer' onClick={() => {
            openModal(row)
          }}>
            <EditLogo />
          </div>
          <div className='bg-[#e2edf1] rounded shadow  h-11 w-11 p-2 hover:cursor-pointer' onClick={() => {
            DeleteUser(row)
          }}>
            <DelteLogo />
          </div>
        </div>,
        id: 'Actions',
        header: 'الإجرائات',
        cell: info => info.getValue(),
        footer: props => props.column.id,
        enableColumnFilter: false,
        enableSorting: false
      }
      ,
    ],
    []
  )



  const [data, setData] = useState<IUserTableData[]>(Users)
  const refreshData = () => { }

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: false,
  })



  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
      if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])

  const CellFormat = (data: any) => {
    return <div className='flex w-full h-12 justify-center items-center'>
      <h6 className='text-bold truncate'>{data.value}</h6>
    </div>
  }
  const ActionsFormat = (data: any) => {
    return <div className='flex w-full h-12 justify-center items-center'>
      <div className='flex flex-row w-full h-full justify-around' >
        <div className='bg-[#e2edf1] rounded shadow h-11 w-11 p-2 hover:cursor-pointer' onClick={() => {
          let selectedUserData: IUserTableData = data.data
          openModal(selectedUserData)
        }}>
          <EditLogo />
        </div>
        <div className='bg-[#e2edf1] rounded shadow  h-11 w-11 p-2 hover:cursor-pointer' onClick={() => {
          let selectedUserData: IUserTableData = data.data
          DeleteUser(selectedUserData)
        }}>
          <DelteLogo />
        </div>
      </div>,
    </div>
  }

  return (
    (
      // <div className="p-2">
      //   <div className='flex flex-row justify-between items-end'>
      //     <div>
      //       <DebouncedInput
      //         value={globalFilter ?? ''}
      //         onChange={value => setGlobalFilter(String(value))}
      //         className="p-2 leading-10 text-xl shadow border border-block"
      //         placeholder="البحث في جميع المحددات"
      //       />
      //     </div>
      //     {/* <div className='h-16 w-16 hover:cursor-pointer'>
      //       <AddLogo />
      //     </div> */}
      //   </div>
      //   <div className="h-2" />
      //   <table className="table w-full" id="table_Container">
      //     <thead>
      //       {table.getHeaderGroups().map(headerGroup => (
      //         <tr key={headerGroup.id}>
      //           {headerGroup.headers.map(header => {
      //             return (
      //               <th key={header.id} colSpan={header.colSpan}>
      //                 {header.isPlaceholder ? null : (
      //                   <>
      //                     <div
      //                       {...{
      //                         className: header.column.getCanSort()
      //                           ? 'cursor-pointer select-none'
      //                           : '',
      //                         onClick: header.column.getToggleSortingHandler(),
      //                       }}
      //                     >
      //                       {flexRender(
      //                         header.column.columnDef.header,
      //                         header.getContext()
      //                       )}
      //                       {{
      //                         asc: '🔼',
      //                         desc: '🔽',
      //                       }[header.column.getIsSorted() as string] ?? null}
      //                     </div>
      //                     {header.column.getCanFilter() ? (
      //                       <div>
      //                         <Filter column={header.column} table={table} />
      //                       </div>
      //                     ) : null}
      //                   </>
      //                 )}
      //               </th>
      //             )
      //           })}
      //         </tr>
      //       ))}
      //     </thead>
      //     <tbody>
      //       {table.getRowModel().rows.map(row => {
      //         return (
      //           <tr key={row.id}>
      //             {row.getVisibleCells().map(cell => {
      //               return (
      //                 <td key={cell.id}>
      //                   {flexRender(
      //                     cell.column.columnDef.cell,
      //                     cell.getContext()
      //                   )}
      //                 </td>
      //               )
      //             })}
      //           </tr>
      //         )
      //       })}
      //     </tbody>
      //   </table>
      //   <div className="h-2" />
      //   <div className="flex items-center gap-2">
      //     <button
      //       className="border rounded p-1"
      //       onClick={() => table.setPageIndex(0)}
      //       disabled={!table.getCanPreviousPage()}
      //     >
      //       {'<<'}
      //     </button>
      //     <button
      //       className="border rounded p-1"
      //       onClick={() => table.previousPage()}
      //       disabled={!table.getCanPreviousPage()}
      //     >
      //       {'<'}
      //     </button>
      //     <button
      //       className="border rounded p-1"
      //       onClick={() => table.nextPage()}
      //       disabled={!table.getCanNextPage()}
      //     >
      //       {'>'}
      //     </button>
      //     <button
      //       className="border rounded p-1"
      //       onClick={() => table.setPageIndex(table.getPageCount() - 1)}
      //       disabled={!table.getCanNextPage()}
      //     >
      //       {'>>'}
      //     </button>
      //     <span className="flex items-center gap-1">
      //       <div>Page</div>
      //       <strong>
      //         {table.getState().pagination.pageIndex + 1} of{' '}
      //         {table.getPageCount()}
      //       </strong>
      //     </span>
      //     <span className="flex items-center gap-1">
      //       | Go to page:
      //       <input
      //         type="number"
      //         defaultValue={table.getState().pagination.pageIndex + 1}
      //         onChange={e => {
      //           const page = e.target.value ? Number(e.target.value) - 1 : 0
      //           table.setPageIndex(page)
      //         }}
      //         className="border p-1 rounded w-16"
      //       />
      //     </span>
      //     <select
      //       value={table.getState().pagination.pageSize}
      //       onChange={e => {
      //         table.setPageSize(Number(e.target.value))
      //       }}
      //     >
      //       {[10, 20, 30, 40, 50].map(pageSize => (
      //         <option key={pageSize} value={pageSize}>
      //           Show {pageSize}
      //         </option>
      //       ))}
      //     </select>
      //   </div>
      //   <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
      //   <div>
      //     <button onClick={() => rerender()}>Force Rerender</button>
      //   </div>
      //   <div>
      //     <button onClick={() => refreshData()}>Refresh Data</button>
      //   </div>
      //   {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
      // </div>



      <div dir='rtl'>
        <DataGrid id="dataGrid" dataSource={Users} keyExpr={"Id"} allowColumnReordering={true} rowAlternationEnabled={true} onExporting={exportGrid} >
          <Column alignment='center' dataField="Pic" width={'80px'} dataType='string' caption='الصوره' allowFiltering={false} cellRender={(data) => {
            return <div className='w-full h-full rounded-2xl overflow-hidden object-scale-down flex items-center justify-center'>
              <img src={data.value} alt="EmployeePicture" style={{ width: '50px', height: '50px' }} />
            </div>
          }}>
          </Column>
          <Column alignment='center' dataField="Email" dataType='string' caption='البريد الإلكتروني' cellRender={CellFormat}></Column>
          <Column alignment='center' dataField="Name" dataType='string' caption='الإسم' cellRender={CellFormat}></Column>
          <Column alignment='center' dataField="Job" dataType='string' caption='الوظيفه' cellRender={CellFormat}></Column>
          <Column alignment='center' dataField="Active" dataType='string' caption='الحاله' cellRender={CellFormat}></Column>
          <Column alignment='center' dataField="-" dataType='string' caption='الإجرائات' allowFiltering={false} cellRender={ActionsFormat}></Column>
          <FilterRow visible={true} />
          <SearchPanel visible={true} />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20, 50]}
            showInfo={true}
          />

          <Export enabled={true} formats={exportFormats} />
        </DataGrid>
      </div>
    )
  )
}

// function Filter({
//   column,
//   table,
// }: {
//   column: Column<any, unknown>
//   table: Table<any>
// }) {
//   const firstValue = table
//     .getPreFilteredRowModel()
//     .flatRows[0]?.getValue(column.id)

//   const columnFilterValue = column.getFilterValue()

//   const sortedUniqueValues = useMemo(
//     () =>
//       typeof firstValue === 'number'
//         ? []
//         : Array.from(column.getFacetedUniqueValues().keys()).sort(),
//     [column.getFacetedUniqueValues()]
//   )

//   return typeof firstValue === 'number' ? (
//     <div>
//       <div className="flex space-x-2">
//         <DebouncedInput
//           type="number"
//           min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
//           max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
//           value={(columnFilterValue as [number, number])?.[0] ?? ''}
//           onChange={value =>
//             column.setFilterValue((old: [number, number]) => [value, old?.[1]])
//           }
//           placeholder={`Min ${column.getFacetedMinMaxValues()?.[0]
//             ? `(${column.getFacetedMinMaxValues()?.[0]})`
//             : ''
//             }`}
//           className="w-24 border shadow rounded"
//         />
//         <DebouncedInput
//           type="number"
//           min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
//           max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
//           value={(columnFilterValue as [number, number])?.[1] ?? ''}
//           onChange={value =>
//             column.setFilterValue((old: [number, number]) => [old?.[0], value])
//           }
//           placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
//             ? `(${column.getFacetedMinMaxValues()?.[1]})`
//             : ''
//             }`}
//           className="w-24 border shadow rounded"
//         />
//       </div>
//       <div className="h-1" />
//     </div>
//   ) : (
//     <>
//       <datalist id={column.id + 'list'}>
//         {sortedUniqueValues.slice(0, 5000).map((value: any) => (
//           <option className='text-blue-700' value={value} key={value} />
//         ))}
//       </datalist>
//       <DebouncedInput
//         type="text"
//         value={(columnFilterValue ?? '') as string}
//         onChange={value => column.setFilterValue(value)}
//         placeholder={`محددات البحث (${column.getFacetedUniqueValues().size})`}
//         className="w-full text-center border shadow rounded"
//         list={column.id + 'list'}
//       />
//       <div className="h-1" />
//     </>
//   )
// }


function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input  {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}


export default EGTable

