import { Box, InputAdornment, Tab, Tabs, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTextField, CustomTextFieldFullHeight } from "../../../../CustomComps/Customs";
import { ActionTypes, CallActionTypes, ConvertDateToLocalTimeZone, FilterCalendarDates, getCallActionTypes, getDateTimeOnly, GetErrorAxios, getYearMonthDayFromDate, ShowError } from "../../../../Globals";
import { GetAllCitiesThunk, citiesState } from "../../../../redux/slices/CitySlice";
import { AddAction } from "../../../../redux/slices/ErrorsSlice";
import { AppDispatch } from "../../../../redux/store";
import { axiosConfig, getAllCalender, getLeaderCalender } from "../../../../Service/Apis";
import { TALL_CALENDER, USER_CALENDER } from "../../../../Service/models/CalenderModel/CalenderModel";
import { ContactsModel } from "../../../../Service/models/ContactsModel/ContactsMOdel";
import { ProjectModel } from "../../../../Service/models/projects/ProjectModel";
import { ACTIONS, CALL_ACTION, REQUEST } from "../../../../Service/models/UserModel/Actions/ActionsModel";
import { USER_RESERVATION } from "../../../../Service/models/UserReservation";
import { tokens } from "../../../../theme";
import { EgButton } from "../../../Shared/Shared";
import { Attendance, DropDownData, DropTextField } from "./AppointMents";
import { GetProjectsAsync } from "../../../../redux/slices/Projects";

const Call: React.FC<{ selectedContact: ContactsModel, projects: ProjectModel[] }> = ({ selectedContact, projects }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 20, width: '100%', backgroundColor: 'white' };

    const dispatch = useDispatch<AppDispatch>()


    // const [mappedLeaderCalender, setMappedLeaderCalender] = useState<USER_CALENDER[]>([]);
    const [mappedLeaderCalender, setMappedLeaderCalender] = useState<TALL_CALENDER>({} as TALL_CALENDER);

    const getCurrentLeaderCalendar = async () => {
        try {
            // let myCalendars = await getLeaderCalender();
            // let AllCalender = await getAllCalender();

            // 
            // if (myCalendars && myCalendars.length > 0) {
            //     setMappedLeaderCalender(AllCalender);
            // }
        } catch (err) {
            GetErrorAxios(err, dispatch)
        }

    }

    useEffect(() => {
        getCurrentLeaderCalendar();
    }, [])



    const [actions, setActions] = useState<ACTIONS>({ ACTION_TYPE: ActionTypes.Call, CONTACT_ID: selectedContact.CONTACT_ID!, STAMP_DATE: new Date(), });
    const [ActionTypeOBJ, setActionTypeOBJ] = useState<CALL_ACTION>({} as CALL_ACTION);

    useEffect(() => {
        setActions(prev => ({ ...prev, CALL_ACTION: ActionTypeOBJ, STAMP_DATE: new Date() }))
    }, [ActionTypeOBJ])


    const sendCallDataToServer = () => {

        let serializeReq = JSON.stringify(actions);

        axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
            res => {

            }
        ).catch(err => {

        })
    }


    useEffect(() => {

    }, [mappedLeaderCalender])


    return (
        <div className="w-full h-full justify-between items-center bg-white p-5 rounded-lg  flex flex-col gap-6">
            <CallBanels actionObj={actions} noCareAction={(val) => {
                setActionTypeOBJ(prev => ({ ...prev, NEVER_CARES: val }))
            }}
                noCareVal={ActionTypeOBJ.NEVER_CARES ?? false}
                callenderData={mappedLeaderCalender}

                UserReservationData={(reservs) => {

                    setActionTypeOBJ(prev => ({ ...prev, USER_RESERVATION: { ...reservs, RESERVATION_TYPE: CallActionTypes.Presentation }, NEED_MEETING: true }))
                }}

                projects={projects}

                Request={(req) => {
                    req = { ...req, NEW_PROJECT: false }


                    setActionTypeOBJ(prev => ({ ...prev, NEED_REQUEST: true, REQUEST: { ...req, NEW_PROJECT: false } }))
                }
                }
            />
            {/* <div className="h-[2em] w-[14em]">
                <EgButton HandleClick={sendCallDataToServer} >
                    <h3>حفظ</h3>
                </EgButton>
            </div> */}
        </div>
    )
}

export default Call



export const CallBanels = ({ noCareAction, noCareVal, callenderData, UserReservationData, projects, Request, actionObj }: { actionObj: ACTIONS, Request: (req: REQUEST) => void, projects: ProjectModel[], UserReservationData: (data: USER_RESERVATION) => void, callenderData: TALL_CALENDER, noCareAction: (val: boolean) => void, noCareVal: boolean }) => {

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,

        };
    }

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const TabStyle: CSSProperties = {
        fontSize: 24,

    }


    useEffect(() => {

    }, [callenderData])

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <div className="w-full border-b border-gray-300">
                <Tabs variant="fullWidth" className="w-full" value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={TabStyle} label="تحديد موعد" {...a11yProps(0)} />
                    <Tab style={TabStyle} label="طلب محدد" {...a11yProps(1)} />
                    <Tab style={TabStyle} label="غير مهتم" {...a11yProps(2)} />
                </Tabs>
            </div>
            <div className="w-full h-[92%]">
                <CustomTabPanel value={value} index={0}>
                    <SetMeetingDate actionOBJ={actionObj} availableDates={callenderData} setUserReservationData={UserReservationData} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <RequestComp projects={projects} Request={Request} actionObj={actionObj} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <NoCareComp NoCareChecked={noCareAction} careVal={noCareVal} actionObj={actionObj} />
                </CustomTabPanel>
            </div>
        </Box >
    );
}

const SetMeetingDate: FC<{ actionOBJ: ACTIONS, availableDates: TALL_CALENDER, setUserReservationData: (data: USER_RESERVATION) => void }> = ({ actionOBJ, setUserReservationData, availableDates }) => {

    const IMutableDates = availableDates;
    let SelectedTypeDates = IMutableDates.LEADER_CALENDAR;


    const [dates, setDates] = useState<USER_CALENDER[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [reservation, setreservation] = useState<USER_RESERVATION>({ CUSTOMER_ENTHUSIASM_LEVEL: 1 } as USER_RESERVATION);
    const reservationRef = useRef<USER_RESERVATION>({ CUSTOMER_ENTHUSIASM_LEVEL: 1 } as USER_RESERVATION);
    const [allDates, setAllDates] = useState<TALL_CALENDER>(availableDates);
    const [typeDates, setTypeDates] = useState<USER_CALENDER[]>([]);

    const dispatch = useDispatch<AppDispatch>()


    const GET_ALLCALENDER_DATA = () => {
        getAllCalender().then(res => {
            setAllDates(res);
            setTypeDates(res.PERSONAL_CALENDAR);
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }


    useEffect(() => {
        // setAllDates(availableDates);
        // setTypeDates(availableDates.LEADER_CALENDAR);
        //    setDates(availableDates.PERSONAL_CALENDAR);
        GET_ALLCALENDER_DATA()

    }, [])



    const SendDataToServer = (data: USER_RESERVATION) => {
        let ActionObj: ACTIONS = { ...actionOBJ, CALL_ACTION: { USER_RESERVATION: { ...data, RESERVATION_TYPE: CallActionTypes.Presentation }, NEED_MEETING: true }, STAMP_DATE: new Date() }
        let serializeReq = JSON.stringify(ActionObj);



        axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
            res => {
                dispatch(AddAction({ message: "تم حجز الموعد بنجاح", status: "success" }))
            }
        ).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }


    const setData = () => {
        let ActionId = FilterCalendarDates(dates, reservation.TIME_FROM as Date, reservation.TIME_TO as Date)

        if (ActionId && ActionId.ID) {
            reservationRef.current = { ...reservation, CALENDER_ID: ActionId.ID };

            SendDataToServer(reservationRef.current)

            // setUserReservationData({ ...reservation, CALENDER_ID: ActionId.ID });
        } else {
            ShowError("برجاء تحديد موعد متوافق مع المواعيد المتاحه من الليدر", dispatch)
        }
    }


    return <div className="flex flex-col justify-between h-[40em] w-full overflow-auto">
        <div className="overflow-auto h-3/4">
            <div dir="rtl">
                <div className="flex gap-5 mb-6">
                    <div className="flex items-center gap-2">
                        <div className="i-ion-warning-outline text-3xl text-yellow-400" ></div>
                        <h3 className="text-xl font-bold">اختر يوم لعرض المواعيد المتاحه</h3>
                    </div>
                    <input lang='ar' type="date" onChange={e => {
                        setSelectedDate(new Date(e.target.value))

                        let NewDates = typeDates.filter(date => {

                            return getYearMonthDayFromDate(new Date(date.TIME_FROM)) === e.target.value
                        });

                        setDates(NewDates)

                    }} />
                    <div className="w-1/5">
                        <DropTextField initalValue={0} classes='w-3/4 h-full bg-white' data={[
                            { id: 0, desc: "شخصي" },
                            { id: 1, desc: "مدير" },
                        ]} onchange={e => {
                            let val = e.target.value;
                            if (val === 0) {
                                setTypeDates(allDates.PERSONAL_CALENDAR);


                                let NewDates = (allDates.PERSONAL_CALENDAR || []).filter(date => {

                                    return getYearMonthDayFromDate(date.TIME_FROM) === selectedDate.toISOString().split('T')[0]
                                });

                                setDates(NewDates || [])




                            }
                            if (val === 1) {
                                setTypeDates(allDates.LEADER_CALENDAR);

                                let NewDates = (allDates.LEADER_CALENDAR || []).filter(date => {
                                    
                                    

                                    return getYearMonthDayFromDate(date.TIME_FROM) === selectedDate.toISOString().split('T')[0]
                                });

                                setDates(NewDates || [])

                            }
                        }} />
                    </div>
                </div>
                {/* <p className="i-svg-spinners-180-ring"></p> */}

                <div className="flex gap-5 items-center mb-6">
                    <h3 className="text-xl font-bold">المواعيد المتاحه</h3>
                    <div className="flex items-center gap-2">
                        <h3>اليوم</h3>
                        <h3 className="text-xl font-bold">{getYearMonthDayFromDate(selectedDate, true)}</h3>
                    </div>
                </div>
                <div className="flex flex-wrap gap-10 mb-6">
                    {dates.filter(date => date.CALENDER_TYPE === CallActionTypes.Presentation).map((emptyDate, idx) => {
                        return <div className="flex items-center gap-2 bg-white shadow rounded-md p-2" key={idx}>
                            <div className="i-mdi-calendar-check  text-3xl text-green-400">
                            </div>
                            <h3>من <span className="font-bold text-lg">{getDateTimeOnly(emptyDate.TIME_FROM)}</span></h3>
                            <h3>الى <span className="font-bold text-lg">{getDateTimeOnly(emptyDate.TIME_TO)}</span></h3>
                            <h3></h3>
                            <h3>النوع <span className="font-bold text-lg">{getCallActionTypes(emptyDate.CALENDER_TYPE)}</span></h3>
                        </div>
                    })}
                </div>

                <div className="flex items-center gap-8 mb-6">
                    <h3 className="text-xl font-bold">حجز الموعد</h3>
                    <Attendance title="من" isDate={false} initialDate={selectedDate} data={(e) => {
                        setreservation(prev => ({ ...prev, TIME_FROM: e }))
                    }} />
                    <Attendance title="الى" initialDate={selectedDate} isDate={false} data={(e) => {
                        setreservation(prev => ({ ...prev, TIME_TO: e }))
                    }} />
                </div>
                <h3 className="mb-2 text-xl font-bold">هدف المقابله</h3>
                <div className="flex flex-wrap items-center gap-10 mb-6">
                    <div className='flex items-center gap-2'>
                        <input type="radio" id="invest" name="CallReason" className='w-[2em] h-[2em]' onChange={(e: any) => {
                            if (e.target.checked === true) {
                                setreservation(prev => ({ ...prev, IS_INVESTMENT: true }))
                            }
                        }} />
                        <label htmlFor='invest' className='text-xl'> استثمار</label>
                    </div>
                    <div className='flex  items-center gap-2'>
                        <input type="radio" id="invest" name="CallReason" className='w-[2em] h-[2em]' onChange={(e: any) => {
                            if (e.target.checked === true) {
                                setreservation(prev => ({ ...prev, IS_PERSONAL_HOME: true }))
                            }
                        }} />
                        <label htmlFor='invest' className='text-xl'> سكني</label>
                    </div>
                    <div className='flex  items-center gap-2'>
                        <input type="radio" id="invest" name="CallReason" className='w-[2em] h-[2em]' onChange={(e: any) => {
                            if (e.target.checked === true) {
                                setreservation(prev => ({ ...prev, IS_COMMERCIAL: true }))
                            }
                        }} />
                        <label htmlFor='invest' className='text-xl'> تجاري</label>
                    </div>
                    <div className='flex  items-center gap-2'>
                        <input type="radio" id="invest" name="CallReason" className='w-[2em] h-[2em]' onChange={(e: any) => {
                            if (e.target.checked === true) {
                                setreservation(prev => ({ ...prev, IS_ADMINISTRATIVE: true }))
                            }
                        }} />
                        <label htmlFor='invest' className='text-xl'> اداري</label>
                    </div>
                    <div className='flex  items-center gap-2'>
                        <input type="radio" id="invest" name="CallReason" className='w-[2em] h-[2em]' onChange={(e: any) => {
                            if (e.target.checked === true) {
                                setreservation(prev => ({ ...prev, IS_MEDICAL: true }))
                            }
                        }} />
                        <label htmlFor='invest' className='text-xl'> طبي</label>
                    </div>
                    <div className='flex  items-center gap-2'>
                        <input type="radio" id="invest" name="CallReason" className='w-[2em] h-[2em]' onChange={(e: any) => {
                            if (e.target.checked === true) {
                                setreservation(prev => ({ ...prev, UNKNOWN: true }))
                            }
                        }} />
                        <label htmlFor='invest' className='text-xl'> غير معلوم</label>
                    </div>
                </div>
                <h3 className="text-xl font-bold" >درجه حماس العميل</h3>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-col gap-3 items-center'>
                        <input type="range" min="1" max="10" id="myRange" value={reservation.CUSTOMER_ENTHUSIASM_LEVEL} className='w-full rangGrad' onChange={(e: any) => {
                            setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: parseInt(e.target.value) }))
                        }} />
                        <div className='flex justify-between w-full  h-2'>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 1 })) }}>1</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 2 })) }}>2</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 3 })) }}>3</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 4 })) }}>4</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 5 })) }}>5</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 6 })) }}>6</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 7 })) }}>7</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 8 })) }}>8</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 9 })) }}>9</h5>
                            <h5 className='text-base hover:cursor-pointer' onClick={() => { setreservation(prev => ({ ...prev, CUSTOMER_ENTHUSIASM_LEVEL: 10 })) }}>10</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
        <div className='w-[10em] h-[3em] self-center '>
            <EgButton HandleClick={() => {
                setData()
            }}>
                <div className='w-1/2'>
                    <h6>حجز موعد</h6>
                </div>
            </EgButton>
        </div>
    </div>
}


export const RequestComp = ({ actionObj, projects, Request }: { actionObj: ACTIONS, projects: ProjectModel[], Request: (req: REQUEST) => void }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle: CSSProperties = { width: '100%', backgroundColor: 'white', };

    const cities = useSelector(citiesState);

    const [SubAction, SetSupAction] = useState<REQUEST>({} as REQUEST);
    const RequestRef = useRef<REQUEST>({} as REQUEST);
    const [DropDownType, setDropDownType] = useState<DropDownData[]>([]);
    const [CitiesDropDownType, setCitiesDropDown] = useState<DropDownData[]>([]);

    const dispatch = useDispatch<AppDispatch>();


    const SendDataToServer = (data: REQUEST) => {
        let ActionObj: ACTIONS = { ...actionObj, CALL_ACTION: { REQUEST: { ...data }, NEED_MEETING: false, NEED_REQUEST: true }, STAMP_DATE: new Date() }
        let serializeReq = JSON.stringify(ActionObj);



        axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
            res => {
                dispatch(AddAction({ message: "تم إرسال الطلب بنجاح", status: "success" }))
            }
        ).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }


    const setData = () => {

        RequestRef.current = { ...SubAction };
        SendDataToServer(RequestRef.current)
    }

    useEffect(() => {
        if (projects.length < 1) {
            dispatch(GetProjectsAsync())
        }
            let project_state_holder: DropDownData[] = []
            projects.map(proj => {
                project_state_holder.push({
                    id: proj.ID, desc: proj.PROJECT_NAME
                })
            })

            setDropDownType(project_state_holder)

        if (cities.length < 1) {
            dispatch(GetAllCitiesThunk())
        }
            let Mapped_Cities_to_DropDownType: DropDownData[] = []

            cities.map(city => {
                Mapped_Cities_to_DropDownType.push({ desc: city.CITY_NAME, id: city.ID })
            });

            setCitiesDropDown(Mapped_Cities_to_DropDownType);


    }, [])

    return <div className="w-full flex flex-col justify-between h-[40em]">
        <div className="w-full h-3/4 overflow-auto">
            <div className="flex gap-3 items-center flex-wrap">
                <div>
                    <h3 className="text-xl">المشروع</h3>
                </div>
                <div className="flex gap-2">
                    <DropTextField initialText="المدينه" data={[
                        ...CitiesDropDownType
                    ]} onchange={(data) => {
                        SetSupAction(prev => ({ ...prev, CITY_ID: data.target.value }))
                        let newDropType: DropDownData[] = [];
                        let New_ProjectsArr = projects.filter(proj => proj.CITY_ID === parseInt(data.target.value))
                        New_ProjectsArr.map(proj => {
                            newDropType.push({ id: proj.ID, desc: proj.PROJECT_NAME })
                        })
                        setDropDownType(newDropType)
                    }} />
                </div>
                <div className="flex gap-2">
                    <DropTextField initialText="المشروع" data={[
                        ...DropDownType
                    ]} onchange={(data) => {

                        SetSupAction(prev => ({ ...prev, PROJECT_ID: data.target.value }))
                    }} />
                </div>
            </div>
            <div className="grid grid-cols-2 w-full h-full">
                <div className="">
                    <h3 className="text-xl my-3">مشروع جديد</h3>
                    <div className='w-3/4 my-2'>
                        <CustomTextFieldFullHeight height={25} placeholder='إسم المشروع' type={''} onChange={(e: any) => {
                            SetSupAction(prev => ({ ...prev, PROJECT_NAME: e.target.value }))
                        }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                    </div>
                    <div className='w-3/4 my-2'>
                        <CustomTextFieldFullHeight height={25} placeholder='إسم الشركه' type={''} onChange={(e: any) => {
                            SetSupAction(prev => ({ ...prev, COMPANY_NAME: e.target.value }))
                        }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                    </div>
                    <div className='w-3/4 my-2'>
                        <CustomTextFieldFullHeight height={25} placeholder='المنطقه' type={''} onChange={(e: any) => {
                            SetSupAction(prev => ({ ...prev, LOCATION: e.target.value }))
                        }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                    </div>
                </div>
                <div className="w-full">
                    <h3 className="text-xl my-3">طلب خارجي</h3>
                    <div className='w-3/4 my-2'>
                        <CustomTextFieldFullHeight height={25} placeholder='ميزانيه المقدم' type={'number'} onChange={(e: any) => {
                            SetSupAction(prev => ({ ...prev, BUDGET: parseFloat(e.target.value) }))
                        }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                    </div>
                    <div className='w-3/4 my-2'>
                        <CustomTextFieldFullHeight height={25} placeholder='الحد الأقصى للأقساط' type={'number'} onChange={(e: any) => {
                            SetSupAction(prev => ({ ...prev, MAX_INSTALLMENT_AMOUNT: parseFloat(e.target.value) }))
                        }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                    </div>
                    <div className='w-full flex flex-row my-5'>
                        <div className="w-1/4">
                            <p className=' text-sm'>نوع الوحده</p>
                        </div>
                        <div className="flex flex-wrap justify-between gap-2">
                            <div className='flex flex-col md:flex-row justify-between'>
                                <div className='flex  items-center gap-2'>
                                    <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        if (e.target.checked === true) {

                                            SetSupAction(prev => ({ ...prev, IS_PERSONAL_HOME: e.target.checked }))
                                        }
                                    }} />
                                    <label htmlFor='PersRelation' className='text-xs'>سكني</label>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row  justify-between'>
                                <div className='flex  items-center gap-2'>
                                    <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        if (e.target.checked === true) {
                                            SetSupAction(prev => ({ ...prev, IS_COMMERCIAL: e.target.checked }))

                                        }
                                    }} />
                                    <label htmlFor='PersRelation' className='text-xs'>تجاري</label>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row justify-between'>
                                <div className='flex  items-center gap-2'>
                                    <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        if (e.target.checked === true) {
                                            SetSupAction(prev => ({ ...prev, IS_ADMINISTRATIVE: e.target.checked }))
                                        }
                                    }} />
                                    <label htmlFor='PersRelation' className='text-xs'>إداري</label>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row  justify-between'>
                                <div className='flex  items-center gap-2'>
                                    <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        if (e.target.checked === true) {
                                            SetSupAction(prev => ({ ...prev, IS_MEDICAL: e.target.checked }))
                                        }
                                    }} />
                                    <label htmlFor='PersRelation' className='text-xs'>طبي</label>
                                </div>
                            </div>
                            <div className='flex flex-col md:flex-row  justify-between'>
                                <div className='flex  items-center gap-2'>
                                    <input type="radio" id="PersRelation" name="UnitType" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        if (e.target.checked === true) {
                                            SetSupAction(prev => ({ ...prev, IS_HOTEL: e.target.checked }))
                                        }
                                    }} />
                                    <label htmlFor='PersRelation' className='text-xs'>فندقي</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-row justify-between gap-4'>
                        <p className='text-sm w-1/3'>الغرض</p>
                        <div className='flex flex-col md:flex-row w-full gap-5'>
                            <div className='flex  items-center gap-2'>
                                <div className='flex gap-3'>
                                    <input type="checkbox" id="explain" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                                        SetSupAction(prev => ({ ...prev, IS_INVESTMENT: e.target.checked }))
                                    }} />
                                    <label htmlFor='explain' className='text-xs'>إستثمار</label>
                                </div>
                            </div>
                            <div className='flex  items-center gap-2'>
                                <div className='flex gap-3'>
                                    <input type="checkbox" id="explain" name="explain" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {

                                    }} />
                                    <label htmlFor='explain' className='text-xs'>إستخدام</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex gap-2">
                            <DropTextField initialText="المنطقه" data={[
                                ...CitiesDropDownType
                            ]} onchange={e => {
                                SetSupAction(prev => ({ ...prev, CITY_ID: e.target.value }))
                            }} />
                        </div>
                    </div>
                </div>
                <textarea id="Notes" name="otherNotes" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-black border focus:outline-none focus:shadow-none " placeholder='ملاحظات اخرى' onChange={(e: any) => {
                    SetSupAction(prev => ({ ...prev, NOTES: e.target.value }))
                }} >

                </textarea>
            </div>
            {/* <div className="" onClick={() => {
                Request(SubAction)
            }}>
                تحديث و عرض البيانات
            </div> */}
        </div>
        <div className='w-[10em] h-[3em] self-center'>
            <EgButton HandleClick={() => {
                setData()
            }}>
                <div className='w-3/4'>
                    <h6>ارسال الطلب</h6>
                </div>
            </EgButton>
        </div>
    </div>
}




const NoCareComp = ({ NoCareChecked, careVal, actionObj }: { actionObj: ACTIONS, NoCareChecked: (val: boolean) => void, careVal: boolean }) => {


    const dispatch = useDispatch<AppDispatch>()

    const [checked, setChecked] = useState<boolean>(false);


    const SendDataToServer = () => {
        let ActionObj: ACTIONS = { ...actionObj, CALL_ACTION: { NEVER_CARES: checked } }
        let serializeReq = JSON.stringify(ActionObj);



        axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
            res => {
                dispatch(AddAction({ message: "تم إرسال الطلب بنجاح", status: "success" }))
            }
        ).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }


    const setData = () => {

        SendDataToServer()
    }


    return <div className="w-full flex flex-col justify-between h-[40em]">
        <div className="w-full h-full">
            <div className="flex gap-5 ">
                <input type="checkbox" checked={careVal} id="ClientNotCaring" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                    setChecked(e.target.checked)
                }} />
                <label htmlFor='classLoc' className='text-xl'>العميل غير مهتم</label>
            </div>
        </div>
        <div className='w-[10em] h-[3em] self-center'>
            <EgButton HandleClick={() => {
                setData()
            }}>
                <div className='w-3/4'>
                    <h6>ارسال الطلب</h6>
                </div>
            </EgButton>
        </div>
    </div>
}