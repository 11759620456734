import { useEffect, useRef, useState } from "react";
import MultiDD from "../../../../../components/MultiDD"; // 
import { GetFinishingItems, SaveFinishingCost } from "../../../../../Service/Apis";
import { FINISHING_COST, FINISHING_ITEMS, SaveFinishingCostReq } from "../../../../../Service/models/Finishing";
import { DropDownData } from "../../../Actions/ActionsTypesScreen/AppointMents";
import { CostEstimationUnits, GetErrorAxios, HavePermission, PERMISSIONS } from "../../../../../Globals";
import MulitDD from "../../../../../components/MultiDD";
import InputCustom from "../../../../../components/InputType";
import { EgButton } from "../../../../Shared/Shared";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { AddAction } from "../../../../../redux/slices/ErrorsSlice";
import { UsersState } from "../../../../../redux/slices/UserSlice";
import { DetailsIcons } from "../../../../../components/Icons/Icons";
import React from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';




export type CompProps = {
    id: number,
    totCost: string,
    costs: FINISHING_COST[]
}

export default function Costestimation(Props: CompProps) {
    const { id, costs } = Props

    const UsersData = useSelector(UsersState);
    const [finishingItems, setFinishingItems] = useState<FINISHING_ITEMS[]>([]);

    const [fitems, setFitems] = useState<DropDownData[]>([]);
    const [units, setUnits] = useState<DropDownData[]>([]);
    const [maxIdNumber, setMaxIdNumber] = useState<number>(0)
    const [savefinish, setSavefinish] = useState<SaveFinishingCostReq>({} as SaveFinishingCostReq)
    const [fininshingcost, setFininshingcost] = useState<FINISHING_COST[]>([])
    console.log("fininshingcost", fininshingcost); 



    const dispatch = useDispatch<AppDispatch>()

    const handleSave = () => {
        savefinish.FINISHING_COST = fininshingcost;
        savefinish.REQUEST_ID = id;


        SaveFinishingCost(savefinish)
            .then((res) => {
                dispatch(AddAction({ message: 'تم الحفظ بنجاح', status: "success" }))
                window.location.reload()
            })
            .catch((error) => {
                GetErrorAxios(error, dispatch)
            });

    }

    const handleAddRow = () => {
        // add new row
        setFininshingcost(prev => [...prev, { ID: maxIdNumber }])
        // increase max id number
        setMaxIdNumber(maxIdNumber + 1)
    }

    useEffect(() => {
        GetFinishingItems()
            .then((res) => {
                setFinishingItems(res);
                let ddData: DropDownData[] = res.map(item => ({ id: item.ID!, desc: item.DESCRIPTION ?? "غير موجود" }));
                setFitems(ddData);
                setUnits([{ id: CostEstimationUnits.cm, desc: 'سم' },
                { id: CostEstimationUnits.cm2, desc: 'سم 2' },
                { id: CostEstimationUnits.cm3, desc: 'سم 3' },
                { id: CostEstimationUnits.letter, desc: 'لتر' },
                { id: CostEstimationUnits.m, desc: 'متر' },
                { id: CostEstimationUnits.m2, desc: 'متر 2' },
                { id: CostEstimationUnits.m3, desc: 'متر 3' },
                { id: CostEstimationUnits.mm, desc: 'ملي' },
                { id: CostEstimationUnits.piece, desc: 'قطعه' },
                { id: CostEstimationUnits.ton, desc: 'طن' },])
            })
            .catch((err) => {

            });
    }, []);

    return (

        <div className='grid bg-white p-3 rounded-md shadow my-10' >
            <div className='w-[20%]  overflow-hidden'>
                <h2 className='truncate'>التكلفة التقديرية</h2>
                <hr className='my-2 border-2 border-[#e7c498]' />
            </div>
            {fininshingcost.map((cost, idx) => (
                <div key={cost.ID}>
                    <div className='grid grid-cols-2 p-3 gap-4 ' >
                        <div className='w-full'>
                            <p className='mb-2'>البند</p>
                            <MultiDD
                                SingleSelection
                                OuterDropDownData={fitems}
                                SelectedIds={(ids) => {
                                    let FinishItem = ids[0]

                                    // get item want to change
                                    let selectedObject = fininshingcost.find(item => item.ID === cost.ID)

                                    if (selectedObject) (
                                        // change item value
                                        selectedObject.ITEM_ID = FinishItem
                                    )

                                    // get filtered array 
                                    let filteredArray = fininshingcost.filter(item => item.ID !== cost.ID)

                                    // add to new item to filtered array
                                    filteredArray.push(selectedObject!)
                                    filteredArray.sort((a, b) => a.ID! - b.ID!)


                                    setFininshingcost(filteredArray)
                                }}
                            />
                        </div>
                        <div className='w-full'>
                            <p className='mb-2'>الوحدة</p>
                            <MulitDD
                                SingleSelection
                                OuterDropDownData={units}
                                SelectedIds={(ids) => {
                                    let FinishItem = ids[0]

                                    // get item want to change
                                    let selectedObject = fininshingcost.find(item => item.ID === cost.ID)

                                    if (selectedObject) (
                                        // change item value
                                        selectedObject.UNIT_TYPE = FinishItem
                                    )

                                    // get filtered array 
                                    let filteredArray = fininshingcost.filter(item => item.ID !== cost.ID)

                                    // add to new item to filtered array
                                    filteredArray.push(selectedObject!)
                                    filteredArray.sort((a, b) => a.ID! - b.ID!)


                                    setFininshingcost(filteredArray)
                                }}
                            />
                        </div>
                        <div className='w-full'>
                            <p className='mb-2'>الكمية</p>
                            <InputCustom
                                disabled={false}
                                isNumber
                                inputChangedVal={(val) => {
                                    // Convert the input value to a number
                                    const numericValue = typeof val === 'string' ? parseFloat(val) : val;
                                    // Ensure that numericValue is a valid number
                                    if (isNaN(numericValue)) {
                                        // Handle invalid number if necessary
                                        return;
                                    }

                                    let FinishItem = numericValue;

                                    // Get the item to change
                                    let selectedObject = fininshingcost.find(item => item.ID === cost.ID);

                                    if (selectedObject) {
                                        // Change the item value
                                        selectedObject.QUANTATY = FinishItem;

                                        // Get a filtered array
                                        let filteredArray = fininshingcost.filter(item => item.ID !== cost.ID);

                                        // Add the updated item to the filtered array
                                        filteredArray.push(selectedObject);
                                        filteredArray.sort((a, b) => a.ID! - b.ID!);

                                        // Update the state with the new array
                                        setFininshingcost(filteredArray);
                                    }
                                }}
                            />

                        </div>
                        <div className='w-full'>
                            <p className='mb-2'>سعر الوحدة</p>
                            <InputCustom
                                disabled={false}
                                isNumber
                                inputChangedVal={(val) => {
                                    // Convert the input value to a number
                                    const numericValue = typeof val === 'string' ? parseFloat(val) : val;
                                    // Ensure that numericValue is a valid number
                                    if (isNaN(numericValue)) {
                                        // Handle invalid number if necessary
                                        return;
                                    }

                                    let FinishItem = numericValue;

                                    // Get the item to change
                                    let selectedObject = fininshingcost.find(item => item.ID === cost.ID);

                                    if (selectedObject) {
                                        // Change the item value
                                        selectedObject.UNIT_PRICE = FinishItem;

                                        // Get a filtered array
                                        let filteredArray = fininshingcost.filter(item => item.ID !== cost.ID);

                                        // Add the updated item to the filtered array
                                        filteredArray.push(selectedObject);
                                        filteredArray.sort((a, b) => a.ID! - b.ID!);

                                        // Update the state with the new array
                                        setFininshingcost(filteredArray);
                                    }
                                }}
                            />
                        </div>
                        <div className='w-full'>
                            <p className='mb-2'>السعر الإجمالي</p>
                            <InputCustom
                                disabled={false}
                                isNumber
                                inputChangedVal={(val) => {
                                    // Convert the input value to a number
                                    const numericValue = typeof val === 'string' ? parseFloat(val) : val;
                                    // Ensure that numericValue is a valid number
                                    if (isNaN(numericValue)) {
                                        // Handle invalid number if necessary
                                        return;
                                    }

                                    let FinishItem = numericValue;

                                    // Get the item to change
                                    let selectedObject = fininshingcost.find(item => item.ID === cost.ID);

                                    if (selectedObject) {
                                        // Change the item value
                                        selectedObject.TOTAL_PRICE = FinishItem;

                                        // Get a filtered array
                                        let filteredArray = fininshingcost.filter(item => item.ID !== cost.ID);

                                        // Add the updated item to the filtered array
                                        filteredArray.push(selectedObject);
                                        filteredArray.sort((a, b) => a.ID! - b.ID!);

                                        // Update the state with the new array
                                        setFininshingcost(filteredArray);
                                    }
                                }}
                            />
                        </div>
                        <div className='w-full'>
                            <p className='mb-2'>ملاحظات</p>
                            <InputCustom
                                disabled={false}

                                inputChangedVal={(val) => {
                                    // Get the item to change
                                    let selectedObject = fininshingcost.find(item => item.ID === cost.ID);

                                    if (selectedObject) {
                                        // Change the item value
                                        selectedObject.NOTES = val as string;

                                        // Get a filtered array
                                        let filteredArray = fininshingcost.filter(item => item.ID !== cost.ID);

                                        // Add the updated item to the filtered array
                                        filteredArray.push(selectedObject);
                                        filteredArray.sort((a, b) => a.ID! - b.ID!);

                                        // Update the state with the new array
                                        setFininshingcost(filteredArray);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <hr className="mb-10" />
                </div>
            ))}
            <>
                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_COST_ESTIM, UsersData.currentUser.PERMISSIONS) && (
                    <div className='w-36 self-end mt-4'>
                        <EgButton HandleClick={handleAddRow}>
                            <h6>إضافه بند</h6>
                        </EgButton>
                    </div>
                )}
                {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_COST_ESTIM, UsersData.currentUser.PERMISSIONS) && (
                    <div className='w-36 self-end mt-4'>
                        <EgButton HandleClick={handleSave}>
                            <h6>حفظ البند</h6>
                        </EgButton>
                    </div>
                )}
            </>
            <div>

            </div>

            <CostEstimationTable costs={costs} />
            <div className='overflow-hidden' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:50}}>
                <h2>الإجمالي : {Props.totCost} جنيه</h2>
            </div>
        </div>



    );
}

type TYPECostEstimationTable = {
    costs: FINISHING_COST[];
};

const CostEstimationTable = ({ costs }: TYPECostEstimationTable) => {
    const [selectedCost, setSelectedCost] = useState<FINISHING_COST | null>(null);
    const UpdateCostRef = useRef<HTMLDivElement>(null);

    const hideUpdateCostScreen = () => {
        UpdateCostRef.current?.classList.add("hideSalary");
        UpdateCostRef.current?.classList.remove("showSalary");
    };

    const showUpdateCostScreen = () => {
        UpdateCostRef.current?.classList.add("showSalary");
        UpdateCostRef.current?.classList.remove("hideSalary");
    };

    return (
        <div className="mt-5">
            <table className="min-w-full overflow-hidden rounded-lg shadow-md border-none">
                <thead className="bg-slate-100 rounded overflow-hidden border-0">
                    <tr className="border-0 shadow">
                        <th className="font-semibold border-none">#</th>
                        <th className="font-semibold border-y-0 border-x-2 border-gray-200">البند</th>
                        <th className="font-semibold border-y-0 border-x-2 border-gray-200">سعر الوحده</th>
                        <th className="font-semibold border-y-0 border-x-2 border-gray-200">الكميه</th>
                        <th className="font-semibold border-y-0 border-x-2 border-gray-200">اجمالي السعر</th>
                        <th className="font-semibold border-none">التعليمات</th>
                    </tr>
                </thead>
                <tbody>
                    {costs.map((cost, idx) => (
                        <React.Fragment key={cost.ID}>
                            <tr className="border-t border-gray-200">
                                <td className="p-3 border-x-2 border-gray-200">{idx + 1}</td>
                                <td className="p-3 border-x-2 border-gray-200">{cost.ITEM_NAME}</td>
                                <td className="p-3 border-x-2 border-gray-200">
                                    <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded">
                                        {cost.UNIT_PRICE} جنيه
                                    </span>
                                </td>
                                <td className="p-3 border-x-2 border-gray-200">{cost.QUANTATY}</td>
                                <td className="p-3 border-x-2 border-gray-200">
                                    <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded">
                                        {cost.TOTAL_PRICE} جنيه
                                    </span>
                                </td>
                                <td className="p-3 border-x-2 border-gray-200">
                                    <div
                                        className="flex items-center gap-4 hover:cursor-pointer p-1"
                                        onClick={() => {
                                            showUpdateCostScreen();
                                            setSelectedCost(cost);
                                        }}
                                    >
                                        <div className="flex flex-col items-center hover:text-[#e7c498]">
                                            <div className="bg-[#e2edf1] rounded shadow h-6 w-6 p-1">
                                                <DetailsIcons />
                                            </div>
                                            <p className="text-sm font-bold">تعديل</p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {cost.NOTES && (
                                <tr>
                                    <td colSpan={5} className="p-3 text-center border-x-2 border-gray-200">
                                        <p className="text-gray-700 font-thin">ملاحظات</p>
                                        <p className="font-bold text-xl">{cost.NOTES}</p>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>

            <div
                ref={UpdateCostRef}
                className="hideSalary z-[100] w-full h-[85vh] p-10 fixed top-12 flex justify-center items-center transition-all ease-in-out duration-[1000ms]"
            >
                <div className="w-full h-full relative bg-slate-50 shadow-md rounded-xl p-5">
                    {selectedCost && <UpdateCost costSelected={selectedCost} />}
                    <div
                        className="absolute top-2 left-2 i-material-symbols-close-rounded text-3xl text-red-500 hover:cursor-pointer"
                        onClick={hideUpdateCostScreen}
                    ></div>
                </div>
            </div>
        </div>
    );
};


const UpdateCost = ({ costSelected }: { costSelected: FINISHING_COST }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [fininshingcost, setFininshingcost] = useState<FINISHING_COST>({ ...costSelected });

    // Get ID 
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id')
    
    // Handle Change
    const handleInputChange = (value: string | number, field: keyof FINISHING_COST) => {
        setFininshingcost(prevCost => ({
            ...prevCost,
            [field]: value,
        }));
    };


    const handleSave = () => {
        const req_id = Number(id)
        const req: SaveFinishingCostReq ={
            REQUEST_ID: req_id,
            FINISHING_COST:Array(fininshingcost)
        }

        SaveFinishingCost(req)
            .then((res) => {
                dispatch(AddAction({ message: 'تم الحفظ بنجاح', status: "success" }))
                window.location.reload()
            })
            .catch((error) => {
                GetErrorAxios(error, dispatch)
            });
        
    };

    return (
        <div className="grid bg-white p-3 rounded-md shadow my-10">
            <div className="w-[20%] overflow-hidden">
                <h2 className="truncate">تفاصيل البند</h2>
                <hr className="my-2 border-2 border-[#e7c498]" />
            </div>

            <div className="grid grid-cols-2 p-3 gap-4">
                <div className="w-full">
                    <p className="mb-2">البند</p>
                    <InputCustom
                        disabled={true}
                        isNumber
                        inputChangedVal={(val) =>{}}
                        DefaultValue={fininshingcost.ITEM_NAME}
                    />
                </div>
                <div className="w-full">
                    <p className="mb-2">الوحدة</p>
                    <InputCustom
                        disabled={true}
                        isNumber
                        inputChangedVal={(val) => { }}
                        DefaultValue={fininshingcost.UNIT_TYPE?.toString()}
                    />
                </div>
                <div className="w-full">
                    <p className="mb-2">الكمية</p>
                    <InputCustom
                        disabled={false}
                        isNumber
                        inputChangedVal={val => handleInputChange(Number(val), "QUANTATY")}
                        DefaultValue={fininshingcost.QUANTATY?.toString()}
                    />
                </div>
                <div className="w-full">
                    <p className="mb-2">سعر الوحدة</p>
                    <InputCustom
                        disabled={false}
                        isNumber
                        inputChangedVal={val => handleInputChange(Number(val), "UNIT_PRICE")}
                        DefaultValue={fininshingcost.UNIT_PRICE?.toString()}
                    />
                </div>
                <div className="w-full">
                    <p className="mb-2">السعر الإجمالي</p>
                    <InputCustom
                        disabled={false}
                        isNumber
                        inputChangedVal={val => handleInputChange(Number(val), "TOTAL_PRICE")}
                        DefaultValue={fininshingcost.TOTAL_PRICE?.toString()}
                    />
                </div>
                <div className="w-full">
                    <p className="mb-2">ملاحظات</p>
                    <InputCustom
                        disabled={false}
                        inputChangedVal={val => handleInputChange(val, "NOTES")}
                        DefaultValue={fininshingcost.NOTES}
                    />
                </div>
            </div>

            <div className="w-36 self-end mt-4">
                <EgButton HandleClick={handleSave}>
                    <h6>حفظ البند</h6>
                </EgButton>
            </div>
        </div>
    );
};
