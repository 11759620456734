import axios, { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ContactsModel } from '../../../Service/models/ContactsModel/ContactsMOdel';
import { FEEDBACK_Model } from '../../../Service/models/FeedBackModel';
import TableFeedBacks from '../../../components/shared/EGFeedBackTable/EGFeedBackTable';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { AppDispatch } from '../../../redux/store';
import { UserModel } from '../../../Service/models/UserModel/UserModel';
import { Box, InputAdornment, MenuItem, Modal, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { PDFLogo, RemoveLogo, THETEAM } from '../../../components/Icons/Icons';
import { EgButton } from '../../Shared/Shared';
import { CustomTextField } from '../../../CustomComps/Customs';
import { tokens } from '../../../theme';
import { Cities, GovAndCities } from '../../Rate/Rate';
import PDFContainer from '../../../components/shared/PDF/PDFContainer';
import { useReactToPrint } from 'react-to-print'
import DevExtremTable from '../../../components/shared/EGDExtremeTable';


export type TContactFeedBack = {
    CONTACT: ContactsModel,
    FEEDBACKS: FEEDBACK_Model[],
    USERS?: UserModel
}


const FeedBack = () => {
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const [Govs, setGovs] = useState<GovAndCities[]>();
    const [cities, setCities] = useState<Cities[]>();
    const [gov, setGov] = useState<string>();
    const [city, setCity] = useState<string>();


    const dispatch = useDispatch<AppDispatch>();

    const [contactFeedBacks, setContactFeedBacks] = useState<TContactFeedBack[]>([]);
    const [selectedContactFeedBack, setSelectedContactFeedBack] = useState<TContactFeedBack>();

    // const [reload, setReload] = useState<boolean>(false);
    // const [outerData, setOuterData] = useState<ContactsModel>({} as ContactsModel);
    // const [ContactsState, setContactsState] = useState<ContactsModel[]>([]);
    // const [feedbackURL, setFeedbackURL] = useState<InvitationResponse>({ INVITATION_URL: "" });

    // const UsersData = useSelector(ContactState);

    // useEffect(() => {
    //     let dataHolderForDD: DropDownType[] = [];
    //     let JobdataHolder: DropDownType[] = [];
    //     let UserTableData: IUserTableData[] = [];

    //     dispatch(GetAllContactsThunk()).then(res => {
    //         
    //     });


    // }, [reload])

    // useEffect(() => {
    //     setContactsState(UsersData.AllContacts);
    //     

    // }, [UsersData.AllContacts])

    // useEffect(() => {
    //   
    // }, [ContactsState])

    const getAllFeedBack = () => {


        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'X-Host': window.location.hostname,
            },
            withCredentials: true,
            credentials: 'include',
        }



        axios.get('/api/feedback/getAllFeedbacks', axiosConfig).then(res => {
            setContactFeedBacks(res.data)        }
        ).catch(err => {
            let ERR = err as AxiosError
            
            
            let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;
            dispatch(AddAction({ message: ErrMsg, status: "error" }))
        })
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80vh',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        getAllFeedBack()

    }, [])

    useEffect(() => {
        axios.get('/api/govs/getAllGovernorates').then(res => {
            setGovs(res.data)
            
        }).catch(err => {
            let ERR = err as AxiosError
            
            
            let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;
            dispatch(AddAction({ message: ErrMsg, status: "error" }))
        })
    }, [])

    return (
        <div className='w-full p-4'>
            <h1 className="text-3xl">آراء العملاء</h1>
            {contactFeedBacks && Govs && (
                <div>
                    <TableFeedBacks openModal={(feedBack) => {

                        let govName = Govs?.filter(gov => gov.ID === feedBack.FEEDBACKS[0].GOV)[0].GOVERNORATE_NAME
                        let govOBJ = Govs?.filter(gov => gov.ID === feedBack.FEEDBACKS[0].GOV)[0]
                        let cityName = govOBJ?.ALL_CITIES.filter(city => city.ID === feedBack.FEEDBACKS[0].CITY)[0].CITY_NAME

                        setCity(cityName);
                        setGov(govName);

                        setSelectedContactFeedBack(feedBack)
                        handleOpen()
                    }} FeedBacks={contactFeedBacks} />
                    {/* <DevExtremTable
                    dataSource={contactFeedBacks2}
                    ID='CONTACT_ID'
                    tableData={[
                        {Caption : "الوكيل", Type : 'string', Value : ''}
                    ]}
                    
                    /> */}
                </div>
            )}

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <FeedBackModal govs={gov!} cities={city!} FeedBackData={selectedContactFeedBack!} closeBody={handleClose} />
                </Modal>
            </div>

        </div>
    )
}

export default FeedBack



const FeedBackModal = ({ closeBody, FeedBackData, govs, cities }: { govs: string, cities: string, closeBody: () => void, FeedBackData: TContactFeedBack }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 20, width: '100%', backgroundColor: 'white' };

    const pageStyle = "@page { size: A4 landscape;}";

    const pdfRef = useRef<any>(null);
    const handlePrint = useReactToPrint({
        content: () => pdfRef.current,
        documentTitle: FeedBackData.FEEDBACKS[0].NAME,
        onAfterPrint: () => { },
        // pageStyle : pageStyle

    })


    



    return <div className="w-[100%] h-[90vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
        <div className='h-full w-full flex justify-center items-center'>
            <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col p-10 justify-between items-center overflow-scroll'>

                <div className='self-end flex gap-5 flex-row-reverse mb-5' >
                    <div className='w-5 h-5 hover:cursor-pointer' onClick={closeBody}>
                        <RemoveLogo />
                    </div>
                    <div className='w-10 h-10 hover:cursor-pointer' onClick={handlePrint}>
                        <PDFLogo />
                    </div>
                </div>
                <div className='bgscreen w-full flex py-5 justify-center items-center'>
                    <div ref={pdfRef} dir='rtl' className='bg-[#EDF3F4] rounded-3xl w-4/6  lg:w-3/6  flex flex-col items-center'>
                        <div className='h-1/3 p-2 mb-10'>
                            <THETEAM />
                        </div>
                        <div>
                            <p className='text-2xl'>نرحب بكم , يسعدنا معرفه رأي حضرتك</p>
                        </div>
                        <form className='w-4/5 h-2/3 flex flex-col gap-8  p-3 justify-start items-center' onSubmit={() => { }}>
                            <div className='w-full flex flex-row gap-4'>
                                <CustomTextField placeholder='الإسم' type={'text'} onChange={(e: any) => {

                                }} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box marginLeft={2}>
                                                {/* <KeyIcon /> */}
                                            </Box>
                                        </InputAdornment>
                                    ),
                                }} style={MainInputStyle}
                                    colors={colors} theme={theme} value={FeedBackData.FEEDBACKS[0].NAME} />
                            </div>
                            <div className='w-full flex flex-row gap-4'>
                                <CustomTextField placeholder='السن' onChange={(e: any) => {


                                }} type={'number'} InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box marginLeft={2}>
                                                {/* <KeyIcon /> */}
                                            </Box>
                                        </InputAdornment>
                                    ),
                                }} style={MainInputStyle} value={FeedBackData.FEEDBACKS[0].AGE}
                                    colors={colors} theme={theme} />
                            </div>
                            <div className='w-full flex flex-row gap-4'>
                                <div className='w-full'>
                                    <div className='w-full flex flex-row gap-4 bg-white'>
                                        <Select
                                            placeholder='اختر محافظه'
                                            className='w-full'
                                            value={FeedBackData.FEEDBACKS[0].GOV}
                                            onChange={(e: SelectChangeEvent<number>) => {

                                            }
                                            }
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={FeedBackData.FEEDBACKS[0].GOV}>{govs}</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className='w-full flex flex-row gap-4 bg-white'>
                                        <Select
                                            className='w-full'
                                            value={FeedBackData.FEEDBACKS[0].CITY}
                                            onChange={(e: SelectChangeEvent<number>) => {

                                            }
                                            }
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={FeedBackData.FEEDBACKS[0].CITY}>{cities}</MenuItem>
                                        </Select>
                                    </div>
                                </div>

                            </div>
                            <div className='w-full flex flex-row justify-between gap-4'>
                                <p className='text-xl w-3/4 md:w-1/3'>ماذا يمثل لك المشروع ؟</p>
                                <div className='flex flex-col gap-1 md:flex-row w-full justify-between'>
                                    <div className='flex items-center gap-2'>
                                        <input type="radio" id="res" disabled name="ProjType" checked={FeedBackData.FEEDBACKS[0].IS_PERSONAL_HOME ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {
                                        }} />
                                        <label htmlFor='res' className='text-xl'>سكن</label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input type="radio" disabled id="invest" name="ProjType" checked={FeedBackData.FEEDBACKS[0].IS_INVESTMENT ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='invest' className='text-xl '>استثمار</label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <input type="radio" id="both" disabled name="ProjType" checked={FeedBackData.FEEDBACKS[0].BOTH_PERSONAL_AND_INVEST ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='both' className='text-xl'>كلاهما</label>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4'>
                                <h3 className='text-xl  w-1/3'>مميزات المشروع</h3>
                                <div className='flex flex-col w-full gap-2'>
                                    <div className='flex gap-3'>
                                        <input type="checkbox" disabled id="uniqueLoc" name="ProjType" checked={FeedBackData.FEEDBACKS[0].FANTASTIC_LOCATION ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='uniqueLoc' className='text-xl'>موقع متميز</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input type="checkbox" disabled id="classLoc" name="ProjType" checked={FeedBackData.FEEDBACKS[0].LUDXRY_LOCATION ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='classLoc' className='text-xl'>سكن راقي</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input type="checkbox" disabled id="area" checked={FeedBackData.FEEDBACKS[0].GOOD_AREA ?? false} name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='area' className='text-xl'>مساحه مناسبه</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input type="checkbox" disabled id="serv" name="ProjType" checked={FeedBackData.FEEDBACKS[0].GOOD_SERVICES ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='serv' className='text-xl'>خدمات</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input type="checkbox" disabled id="des" name="ProjType" checked={FeedBackData.FEEDBACKS[0].GOOD_DESGINE ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='des' className='text-xl'>تصميمات</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input type="checkbox" disabled id="fut" name="ProjType" checked={FeedBackData.FEEDBACKS[0].GOOD_FOR_FUTURE ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='fut' className='text-xl'>مستقبل المشروع</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input type="checkbox" disabled id="pay" name="ProjType" checked={FeedBackData.FEEDBACKS[0].GOOD_PAYMENT_METHODS ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='pay' className='text-xl'>طرق السداد</label>
                                    </div>
                                    <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
                                        <textarea id="txtid" disabled name="otherNotes" value={FeedBackData.FEEDBACKS[0].OTHER_OPTIONS ?? ""} className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='ملاحظات اخرى' onChange={(e: any) => {

                                        }} >

                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4 items-center'>
                                <h3 className='w-1/3 text-xl'>تقيم المشروع</h3>
                                <div className='flex flex-col w-full'>
                                    <div className='flex flex-col gap-3 items-center'>
                                        <input type="range" min="1" max="10" id="myRange" value={FeedBackData.FEEDBACKS[0].PROJECT_RATE} className='w-full rangGrad' onChange={(e: any) => {

                                        }} />
                                        <div className='flex justify-between w-full  h-2'>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>1</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>2</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>3</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>4</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>5</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>6</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>7</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>8</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>9</h3>
                                            <h3 className='text-base hover:cursor-pointer' onClick={() => { }}>10</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4 items-center'>
                                <p className='w-1/3 text-xl'>اسباب التقييم</p>
                                <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
                                    <textarea disabled id="txtid2" name="otherNotes" value={FeedBackData.FEEDBACKS[0].RATE_REASONS ?? ""} className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder=' اذكر سبب التقيم لتساعدنا على تحسين اداء الشركه' onChange={(e: any) => {

                                    }} >
                                    </textarea>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4 items-center'>
                                <p className='w-1/3 text-xl'>رساله الى شركه بناء</p>
                                <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
                                    <textarea disabled id="txtid3" name="otherNotes" value={FeedBackData.FEEDBACKS[0].MESSAGE_TO_BENAA ?? ""} className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='رأيك دايما مهم بالنسبالنا' onChange={(e: any) => {

                                    }} >
                                    </textarea>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4 items-center'>
                                <p className='w-1/3 text-xl'>مقترحات</p>
                                <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
                                    <textarea disabled id="txtid3" name="otherNotes" value={FeedBackData.FEEDBACKS[0].SUGGESTIONS ?? ""} className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='مقترحاتك للشركه' onChange={(e: any) => {

                                    }} >
                                    </textarea>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4 justify-between items-center'>
                                <p className='w-1/2 text-xl'>  تحديد موعد للقاء مع احد وكلائنا ؟</p>

                                <div className="flex gap-2">
                                    <input disabled type="radio" id="yes" checked={FeedBackData.FEEDBACKS[0].NEED_MEETING ?? false} name="meet" className='w-[2em] h-[2em]' />
                                    <label htmlFor='yes' className='text-xl'>نعم</label>
                                </div>
                                <div className="flex gap-2">
                                    <input disabled type="radio" id="no" name="meet" checked={FeedBackData.FEEDBACKS[0].NEED_MEETING ?? false} className='w-[2em] h-[2em]' />
                                    <label htmlFor='no' className='text-xl'>لا</label>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4'>
                                <h3 className='w-1/3 text-xl'>أسباب مبدئيه للقاء</h3>
                                <div className='flex flex-col w-full gap-2'>
                                    <div className='flex gap-3'>
                                        <input disabled type="checkbox" id="explain" checked={FeedBackData.FEEDBACKS[0].NEED_EXPLANATION ?? false} name="explain" className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='explain' className='text-xl'>الحصول على شرح وافي</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input disabled type="checkbox" id="quest" checked={FeedBackData.FEEDBACKS[0].HAVE_QUESTIONS ?? false} name="quest" className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='quest' className='text-xl'>لدي بعض الأسئله</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input disabled type="checkbox" id="paper" name="paper" checked={FeedBackData.FEEDBACKS[0].CHECK_LEGAL_PAPERS ?? false} className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='paper' className='text-xl'>التأكد من الأوراق القانونيه</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input disabled type="checkbox" id="choose" checked={FeedBackData.FEEDBACKS[0].CHOOSE_UNIT ?? false} name="choose" className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='choose' className='text-xl'>إختيار وحده</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input disabled type="checkbox" id="bookUnit" checked={FeedBackData.FEEDBACKS[0].EXPLAIN_RESERVATION ?? false} name="bookUnit" className='w-[2em] h-[2em]' onChange={(e: any) => {

                                        }} />
                                        <label htmlFor='bookUnit' className='text-xl'>معرفه إجرائات الحجز</label>
                                    </div>
                                    {/* <div className='flex gap-3 bg-yellow-300 w-full h-10'>
                <textarea id="txtid" name="otherNotes" className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='ملاحظات اخرى' >

                </textarea>
              </div> */}
                                </div>
                            </div>
                            <div className='self-center flex flex-col w-4/5 justify-start items-start'>
                                <div className="w-full">
                                    <EgButton HandleClick={() => {
                                        closeBody()
                                    }}>
                                        <h5>إغلاق</h5>
                                    </EgButton>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
}