import React, { useEffect, useState } from 'react'
import { ContactsModel } from '../../../../../Service/models/ContactsModel/ContactsMOdel'
import { useTheme } from '@mui/material';
import { tokens } from '../../../../../theme';
import { CustomTextFieldFullHeight } from '../../../../../CustomComps/Customs';
import { GET_ALL_SITES, axiosConfig } from '../../../../../Service/Apis';
import { ActionTypes, GetErrorAxios } from '../../../../../Globals';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../redux/store';
import { SITE_VISIT_MODEL, VISIT_SITE_LOCATIONS } from '../../../../../Service/models/SITEVISITMODEL';
import { DropDownData, DropTextField } from '../AppointMents';
import EGDate from '../../../../../components/shared/EGDate';
import { EgButton } from '../../../../Shared/Shared';
import { ACTIONS } from '../../../../../Service/models/UserModel/Actions/ActionsModel';
import axios from 'axios';
import { AddAction } from '../../../../../redux/slices/ErrorsSlice';

const SITEVISIT = ({ selectedContact }: { selectedContact: ContactsModel }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { width: '100%', backgroundColor: 'white' };
    const dispatch = useDispatch<AppDispatch>()
    const [sites, setSites] = useState<VISIT_SITE_LOCATIONS[]>([]);
    const [dropDownVal, setDropDownVal] = useState<DropDownData[]>([]);
    const [siteReq, setSiteReq] = useState<SITE_VISIT_MODEL>({} as SITE_VISIT_MODEL)
    const [actions, setActions] = useState<ACTIONS>({ ACTION_TYPE: ActionTypes.SiteVisit, CONTACT_ID: selectedContact.CONTACT_ID!, STAMP_DATE: new Date() });

    const getAllLocations = () => {
        GET_ALL_SITES().then((res) => {
            setSites(res)
            let dropDownData: DropDownData[] = res.map((item) => ({ id: item.ID, desc: item.SITE_NAME_AR }))
            setDropDownVal(dropDownData)
        }).catch((err) => {
            GetErrorAxios(err, dispatch)
        })
    }

    const handleSave = () => {
        if (siteReq.VISIT_SITE === undefined) {
            dispatch(AddAction({ message: "برجاء اختيار المدينه", status: "error" }))
            return;
        }

        // check on visit datas
        if (siteReq.VISIT_DATE === undefined) {
            dispatch(AddAction({ message: "برجاء اختيار تاريخ الزياره", status: "error" }))
            return;
        }

        //check visit start location 
        if (siteReq.START_LOCATION === undefined || siteReq.START_LOCATION === "") {
            dispatch(AddAction({ message: "برجاء ادخال مكان التحرك", status: "error" }))
            return;
        }
        let ActionObj: ACTIONS = { ...actions, SITE_VISIT_REQ: { ...siteReq, CONTACT_ID: selectedContact.CONTACT_ID, CUSTOMER_MOBILE_NO: selectedContact.MOBILE_NO, CUSTOMER_NAME: selectedContact.NAME }, STAMP_DATE: new Date() }
        let serializeReq = JSON.stringify(ActionObj);
        axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
            res => {
                dispatch(AddAction({ message: "تم إرسال طلبك للإداره", status: "success" }))
            }
        ).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }

    useEffect(() => {
        getAllLocations()
    }, [])
    return (
        <div className='w-full bg-white rounded-2xl overflow-hidden p-5 flex flex-col gap-5'>
            {/* بيانات العميل */}
            <div className='lg:w-1/2 w-full justify-between flex gap-2 flex-wrap' >
                <div>
                    <label htmlFor="ClientName">إسم العميل</label>
                    <CustomTextFieldFullHeight height={25} id='ClientName' value={selectedContact.NAME} type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        setSiteReq(prev => ({ ...prev, CUSTOMER_NAME: val }))
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
                <div dir='ltr' className='flex flex-col items-end'>
                    <label htmlFor="CLIENT_MOBILE_NO">رقم التليفون</label>
                    <CustomTextFieldFullHeight height={25} id='CLIENT_MOBILE_NO' value={selectedContact.MOBILE_NO} type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        setSiteReq(prev => ({ ...prev, CUSTOMER_MOBILE_NO: val }))

                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
            </div>
            <div className='lg:w-1/2 w-full justify-between flex gap-' >
                <div className='flex flex-col'>
                    <label htmlFor="ClientName">المدينه</label>
                    <DropTextField initialText="المدينه" data={[
                        ...dropDownVal
                    ]} onchange={(data) => {
                        
                        let cityID = parseInt(data.target.value)
                        setSiteReq(prev => ({ ...prev, VISIT_SITE: cityID }))
                    }} />
                </div>
                <div className='justify-between flex flex-col gap-2 flex-wrap' >
                    <h5>تاريخ الزياره</h5>
                    <EGDate DateValue={(dateVal) => {
                        setSiteReq(prev => ({ ...prev, VISIT_DATE: new Date(dateVal) }))
                    }}
                    />
                </div>
            </div>
            <div className='lg:w-1/2 w-full justify-between flex flex-col gap-2 flex-wrap' >
                <div>
                    <label htmlFor="StartLocation">مكان التحرك</label>
                    <CustomTextFieldFullHeight height={25} id='StartLocation' value={siteReq.START_LOCATION} type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        setSiteReq(prev => ({ ...prev, START_LOCATION: val }))
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
            </div>
            <div className='lg:w-1/2 w-full flex  gap-2 flex-wrap' >
                    <input type="checkbox" id="ReceiveCheck" name="ReceiveCheck" className='w-[1.5em] h-[1.5em]' onChange={(e: any) => {
                        
                        setSiteReq(prev => ({ ...prev, PAID_DEPOSIT: e.target.checked }))
                    }} />
                <label htmlFor='ReceiveCheck' className='text-base font-bold mr-4'>دفع مقدم</label>
            </div>
            <div className='w-40 self-end'>
                <EgButton HandleClick={() => {
                    handleSave()
                }}>
                    <h3 className="text-base md:text-lg">حجز</h3>
                </EgButton>
            </div>

        </div>
    )
}

export default SITEVISIT