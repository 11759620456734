import { MenuItem, Modal, TextField, useTheme } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddIcon, AddLogo, RemoveLogo } from "../../../components/Icons/Icons";
import EGCalender from "../../../components/shared/Calender/Calender";
import { CustomTextField } from "../../../CustomComps/Customs";
import { ConverCurrentDateToString, DaysNames, DaysWithTDaysType, ISPSWPattern, LOCALSTORAGE_GET, LOCALSTORAGE_SAVE, localStorageKeys } from "../../../Globals";
import { AddAction } from "../../../redux/slices/ErrorsSlice";
import { SaveUserThunk, UsersState } from "../../../redux/slices/UserSlice";
import { AppDispatch } from "../../../redux/store";
import { JobsModel } from "../../../Service/models/Jobs/Jobs";
import { UserModel } from "../../../Service/models/UserModel/UserModel";
import { tokens } from "../../../theme";
import { convertToBase64, EgButton } from "../../Shared/Shared";
import Profile from "../../Profile";
import './style.css'
import { getUserById } from "../../../Service/Apis";

const EditUsr = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let userJobModel: JobsModel = {} as JobsModel;
    const user = useSelector(UsersState).currentUser;

    if (user.JOBS) {
        userJobModel = Object.entries(user.JOBS)[0][1] as JobsModel;
    }

    const [userName, setUserName] = useState<string>(user.FULL_NAME)
    const [image, setImage] = useState<string>(user.PROFILE_PIC_URL ?? "");
    const [mobile, setMobileNumber] = useState<string>(user.MOBILE_NO ?? "");
    const [imgBase64, setImgBase64] = useState<string>("");
    const [userData, setUserData] = useState<UserModel>(user);
    const [ErrorHelper, setErrorHelper] = useState<string>("");
    const isPSWMatched = useRef<boolean>(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();


    const profileEditRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();

    const openProfileModal = () => {
        profileEditRef.current?.classList.remove('RemoveFromScreen')
        profileEditRef.current?.classList.add('AddToScreen')
    }

    const closeProfileModal = () => {
        profileEditRef.current?.classList.add('RemoveFromScreen')
        profileEditRef.current?.classList.remove('AddToScreen')
    }

    const onImageChange = (e: any) => {
        
        const file = e.target.files[0];


        // convertBlobToByteArray(file).then(data => {
        //     
        //     setSendImageUrl(data);
        // })


        convertToBase64(file).then(
            (data) => {
                setImgBase64(data as string);
                setUserData({ ...userData, PROFILE_PIC_BASE: data as string });
                dispatch(SaveUserThunk({ ...userData, PROFILE_PIC_BASE: data as string })).then(async res => {
                    // navigate('/')

                    window.location.reload()
                }).catch(err => {
                    dispatch(AddAction({ message: err.toString()!, status: "error" }))
                })
                return;
            }
        );
    }

    const GetNewUserData = async () => {
        debugger;
        let newUser = await getUserById();
        LOCALSTORAGE_SAVE(localStorageKeys.LoggedInUser, newUser)
        setUserData(newUser)
        
    }

    useEffect(() => {
        GetNewUserData()

    }, []);


    return (
        <div className="flex relative flex-col w-full h-full justify-center items-center p-5 gap-5">
            {userData !== undefined && userData && (
                <Profile AllowEdit={true} CurrentUser={userData} EditProfileImage={(e) => {
                    onImageChange(e)
                }} EditProfile={() => { openProfileModal() }} />
            )}
            {/* <div className="w-[20em]  h-[20em] overflow-hidden rounded-[50%] relative">
                <img src={imgBase64.length > 0 ? imgBase64 : image} alt={user.FULL_NAME} />
            </div> */}
            {/* <div className="absolute top-[30%] left-[65%] md:top-[30%] md:left-[55%]">
                <div className="w-[3em] h-[3em]">
                    <label htmlFor="filePicker" className="hover:cursor-pointer">
                        <AddLogo />
                    </label>
                    <input id="filePicker" style={{ visibility: 'hidden', background: 'red' }} accept="image/*" onChange={onImageChange} type={"file"}></input>
                </div>
            </div> */}
            {/* <div className="md:w-1/5 w-4/5 mt-5">
                <CustomTextField type={'text'} style={{ width: '100%' }} InputProps={{
                }} value={userData.FULL_NAME.replace("+", " ")}
                    onChange={(e) => setUserData({ ...userData, FULL_NAME: e.target.value })}
                    colors={colors} theme={theme} label="الإسم" />
            </div>

            <div className="md:w-1/5 w-4/5">
                <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                }} value={user.EMAIL}

                    colors={colors} theme={theme} label="البريد الإلكتروني" />
            </div>

            <div className="md:w-1/5 w-4/5">
                <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                }} value={userJobModel.JOB_DESCRIPTION.replace("+", " ")}

                    colors={colors} theme={theme} label="الرتبه" />
            </div>

            <div className="md:w-1/5 w-4/5">
                <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                }} value={userData.MOBILE_NO}
                    onChange={(e) => setUserData({ ...userData, MOBILE_NO: e.target.value })}
                    colors={colors} theme={theme} label="رقم التليفون" />
            </div>
            <div className="md:w-1/5 w-4/5">
                <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                }} value={userData.PASSWORD}
                    onChange={
                        (e) => {
                            isPSWMatched.current = ISPSWPattern(e.target.value)
                            setUserData({ ...userData, PASSWORD: e.target.value })
                            if (isPSWMatched.current) {
                                setErrorHelper("")
                            } else {
                                setErrorHelper("رمز الدخول لايقل عن 8 احرف على ان يحتوي حرف كبير و حرف صغير و رقم ورمز خاص مثل @ ")
                            }
                        }
                    }
                    colors={colors} theme={theme} label="تعديل كلمه المرور" helperText={ErrorHelper} />
            </div>
            <div className="md:w-1/5 w-4/5">
                {user.IS_VERIFIED && (
                    <h3 className="text-green-600 font-bold text-lg">مفعل</h3>
                )}
            </div>
            <div className="md:w-1/5 w-4/5">
                <EgButton HandleClick={() => {
                    if (userData.PASSWORD && userData.PASSWORD.length > 0) {
                        if (isPSWMatched.current) {
                            dispatch(SaveUserThunk(userData)).then(res => {
                            }).catch(err => {
                                dispatch(AddAction({ message: err.toString()!, status: "error" }))
                            })
                            return;
                        } else {
                            dispatch(AddAction({ message: "نمط كلمه المرور غير متطابق", status: "error" }));
                            return;
                        }
                    }
                    dispatch(SaveUserThunk(userData)).then(res => {
                    }).catch(err => {
                        dispatch(AddAction({ message: err.toString()!, status: "error" }))
                    })
                }}>
                    <h3 className="text-base md:text-lg">تعديل</h3>
                </EgButton>
            </div>
            <div className="md:w-1/5 w-4/5">
                <EgButton HandleClick={() => {
                    handleOpen()
                }}>
                    <Typography sx={{ marginLeft: '10px' }} variant="h3">جدول المواعيد</Typography>
                    <h3 className="text-base md:text-lg">جدول المواعيد</h3>
                </EgButton>
            </div>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ModalBody />
                </Modal>
            </div> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalBody />
            </Modal>
            <div ref={profileEditRef} className="w-full lg:w-1/2  h-[85vh] bg-slate-100 shadow-lg absolute rounded-2xl overflow-hidden p-10 top-0 RemoveFromScreen z-[100] transition-all ease-in-out duration-[1000ms]" >
                <div className="relative w-full h-full rounded-2xl overflow-auto">
                    <div className="w-full my-5">
                        <CustomTextField type={'text'} style={{ width: '100%' }} InputProps={{
                        }} value={userData?.FULL_NAME?.includes("+") ? userData.FULL_NAME.replace("+", " ") : userData.FULL_NAME}
                            onChange={(e) => setUserData({ ...userData, FULL_NAME: e.target.value })}
                            colors={colors} theme={theme} label="الإسم" />
                    </div>

                    <div className="w-full mb-5">
                        <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                        }} value={user.EMAIL}

                            colors={colors} theme={theme} label="البريد الإلكتروني" />
                    </div>

                    <div className="w-full mb-5">
                        <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                        }} value={userJobModel?.JOB_DESCRIPTION?.includes("+") ? userJobModel.JOB_DESCRIPTION.replace("+", " ") : userJobModel.JOB_DESCRIPTION}

                            colors={colors} theme={theme} label="الرتبه" />
                    </div>

                    <div className="w-full mb-5">
                        <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                        }} value={userData.MOBILE_NO}
                            onChange={(e) => setUserData({ ...userData, MOBILE_NO: e.target.value })}
                            colors={colors} theme={theme} label="رقم التليفون" />
                    </div>
                    <div className="w-full mb-5">
                        <CustomTextField style={{ width: '100%' }} type={'text'} InputProps={{
                        }} value={userData.PASSWORD}
                            onChange={
                                (e) => {
                                    isPSWMatched.current = ISPSWPattern(e.target.value)
                                    setUserData({ ...userData, PASSWORD: e.target.value })
                                    if (isPSWMatched.current) {
                                        setErrorHelper("")
                                    } else {
                                        setErrorHelper("رمز الدخول لايقل عن 8 احرف على ان يحتوي حرف كبير و حرف صغير و رقم ورمز خاص مثل @ ")
                                    }
                                }
                            }
                            colors={colors} theme={theme} label="تعديل كلمه المرور" helperText={ErrorHelper} />
                    </div>
                    <div className="w-full mb-5">
                        {user.IS_VERIFIED && (
                            <h3 className="text-green-600 font-bold text-lg">مفعل</h3>
                        )}
                    </div>
                    <div className="mb-5">
                        <label htmlFor="Desc" className="mb-10" >
                            <h5>الوصف (من أنا)</h5>
                        </label>
                        <textarea value={userData.USER_BIO?.BIO} style={{ marginTop: ".75rem" }} id="Desc" name="Desc" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-none focus:border-none focus:outline-none focus:ring-0 focus:shadow-none " placeholder='الوصف (من أنا)' onChange={(e: any) => {
                            let val = e.target.value;
                            setUserData({ ...userData, USER_BIO: { BIO: e.target.value } })
                        }} >
                        </textarea>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="Expertise" className="mb-10" >
                            <h5>الخبرات</h5>
                        </label>
                        <textarea value={userData.USER_EXPERIENCE?.EXPERIENCE} style={{ marginTop: ".75rem" }} id="Expertise" name="Expertise" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-none focus:border-none focus:outline-none focus:ring-0 focus:shadow-none " placeholder='الخبرات' onChange={(e: any) => {
                            let val = e.target.value;
                            setUserData({ ...userData, USER_EXPERIENCE: { EXPERIENCE: e.target.value } })
                        }} >
                        </textarea>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="ُEducation" className="mb-10" >
                            <h5>التعليم</h5>
                        </label>
                        <textarea value={userData.USER_EDUCATION?.EDUCATION} style={{ marginTop: ".75rem" }} id="ُEducation" name="ُEducation" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-none focus:border-none focus:outline-none focus:ring-0 focus:shadow-none " placeholder='التعليم' onChange={(e: any) => {
                            let val = e.target.value;
                            setUserData({ ...userData, USER_EDUCATION: { EDUCATION: e.target.value } })
                        }} >
                        </textarea>
                    </div>
                    <div className="flex justify-around w-full">
                        <div className="md:w-1/5 w-4/5">
                            <EgButton HandleClick={() => {
                                if (userData.PASSWORD && userData.PASSWORD.length > 0) {
                                    if (isPSWMatched.current) {
                                        dispatch(SaveUserThunk(userData)).then(res => {
                                        }).catch(err => {
                                            dispatch(AddAction({ message: err.toString()!, status: "error" }))
                                        })
                                        return;
                                    } else {
                                        dispatch(AddAction({ message: "نمط كلمه المرور غير متطابق", status: "error" }));
                                        return;
                                    }
                                }
                                dispatch(SaveUserThunk(userData)).then(res => {
                                }).catch(err => {
                                    dispatch(AddAction({ message: err.toString()!, status: "error" }))
                                })
                            }}>
                                <h5 className="text-base md:text-lg">تعديل</h5>
                            </EgButton>
                        </div>
                        <div className="md:w-1/5 w-4/5">
                            <EgButton HandleClick={() => {
                                handleOpen()
                            }}>
                                {/* <Typography sx={{ marginLeft: '10px' }} variant="h3">جدول المواعيد</Typography> */}
                                <h5 className="text-base md:text-lg">جدول المواعيد</h5>
                            </EgButton>
                        </div>
                    </div>
                </div>
                <div className='i-material-symbols-close-rounded text-3xl text-red-500 absolute top-5 left-5 hover:cursor-pointer' onClick={closeProfileModal}>
                </div>
            </div>
        </div >
    )
}

export const ModalBody = () => {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch<AppDispatch>();

    const MainInputStyle = { marginBottom: 20, width: '100%' };
    const navigate = useNavigate();




    return <div className="w-[70%] lg:w-[70%] h-[80vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
        <div className='h-full w-full flex justify-center items-center'>
            <div className='bg-[#fff] rounded-3xl w-full h-full flex flex-col items-center overflow-hidden p-10'>
                <EGCalender />
                {/* <UserEmptyTimes /> */}
            </div>
        </div>
    </div>
}
export default EditUsr

export type TDays = {
    value: number,
    name: string,
    date: Date,
    ID: number
}

export type TTimes = {
    ID: number
    From: Date
    To: Date
}

const UserEmptyTimes: FC<any> = () => {

    const [days, setDays] = useState<TDays[]>([]);
    const [dayModalState, setDayModalState] = useState<boolean>(false);
    const openDayModal = () => setDayModalState(true);
    const closeDayModal = () => setDayModalState(false);



    return <>
        <div className="w-full h-full p-5 overflow-auto">
            <h1 className="text-black font-bold md:text-3xl">مواعيد الحجز</h1>
            <h2 className="text-gray-700 md:text-base">تحديد ساعات الحجز المتاحه للفريق</h2>
            <hr className="bg-gray-500 my-4"></hr>
            <div className="flex items-center gap-4 mb-8">
                <div className="w-10 h-10">
                    {/* <IconButton openDayModal={openDayModal} /> */}
                    <IconButton openDayModal={() => {
                        setDays(prev => [...prev, { name: DaysNames[new Date().getDay()], date: new Date(), value: 6, ID: new Date().getTime() }])
                    }} />
                </div>
                <h2 className="font-bold text-xl">إضافه يوم</h2>
            </div>
            {days && days.sort((a, b) => a.ID - b.ID).map((day, idx) => {
                return <div key={day.ID} className="flex gap-2 items-start justify-between overflow-hidden rounded-2xl p-2" style={{ backgroundColor: idx % 2 === 0 ? '#F4F4F4' : 'white' }}>
                    <div className="flex flex-col flex-grow gap-8 md:gap-10 items-start">
                        <Day key={day.ID} day={day} changedDay={(day) => {
                            let modifiedDay = days.filter(selectedDay => selectedDay.ID === day.ID)[0];
                            modifiedDay.date = day.date
                            modifiedDay.name = DaysNames[day.date.getDay()]
                            modifiedDay.value = day.date.getDay()


                            let removedDay = days.filter(RMday => RMday.ID !== day.ID);
                            removedDay.push(modifiedDay);

                            setDays(removedDay);
                        }} />
                    </div>
                    <div>
                        <div className="w-5 h-5 hover:cursor-pointer" onClick={() => { setDays(prev => [...days.filter(delday => delday.ID !== day.ID)]) }}>
                            <RemoveLogo />
                        </div>
                    </div>
                </div>

            })}
        </div>
    </>
}


const Day: FC<{ day: TDays, changedDay: (day: TDays) => void }> = ({ day, changedDay }) => {


    const [pickedDay, setPickedDay] = useState<TDays>(day);
    const [times, setTime] = useState<TTimes[]>([]);
    let currentDate = ConverCurrentDateToString(pickedDay.date);

    return <div key={day.ID} className="flex flex-col w-full gap-2">
        {/* <DropForDays data={DaysWithTDaysType} /> */}
        <div key={day.ID} className="flex flex-col flex-grow">
            <div className="flex items-center w-[50%] justify-between">
                <input key={day.ID} lang='ar' value={currentDate} type="date" onChange={e => {
                    let newDate = new Date(e.target.value)
                    let newValue = newDate.getDay()
                    let newName = DaysNames[newDate.getDay()]

                    changedDay({ date: newDate, name: newName, value: newValue, ID: day.ID })

                    setPickedDay({ date: newDate, name: newName, value: newValue, ID: day.ID })


                }} />
                <div className="flex gap-2 items-center">
                    <p className="text-gray-500">اليوم</p>
                    <p className="font-bold text-lg">{day.name}</p>
                </div>
            </div>
            <div className="flex items-center gap-4 my-2">
                <div className="w-7 h-7">
                    {/* <IconButton openDayModal={openDayModal} /> */}
                    <IconButton openDayModal={() => {
                        setTime(prev => [...prev, { From: new Date(), To: new Date(), ID: new Date().getDate() }])
                    }} />
                </div>
                <h2 className="font-bold text-md">إضافه موعد</h2>
            </div>
            {times.length > 0 && times.map((time, idx) => (
                <div className="w-full h-full p-2 rounded-lg my-2" style={{ backgroundColor: idx % 2 === 0 ? '#BDBDBD' : 'white' }}>
                    <TimesComponent />
                </div>
            ))}
        </div>
    </div>

}

export const TimesComponent = () => {
    return (
        <div className="w-full h-full flex items-center justify-between gap-5">
            <div className="flex gap-2 items-center justify-between flex-grow">
                <h3>من</h3>
                <input type="time" onChange={e => {

                }} />
                <h3>الى</h3>
                <input type="time" onChange={e => {

                }} />
                <h3>عدد الساعات</h3>
                <h3>3</h3>
                <h3>النوع</h3>
                <h3>مقابلات</h3>
            </div>
            <h3 className="text-red-500 text-base hover:cursor-pointer">X</h3>
        </div>
    )
}

export const DropForDays = ({ data }: { data: TDays[] }) => {

    const [selectedDay, setSelectedDay] = useState<number>(6);

    return (
        <>
            <TextField select value={selectedDay} required margin="normal" size="medium" onChange={e => {
                setSelectedDay(parseInt(e.target.value))
            }}>
                {data.map((item, idx) => (
                    <MenuItem key={idx} value={item.value}><h3>{item.name}</h3></MenuItem>
                ))}
            </TextField >
        </>
    )
}


const AddDayModal = () => {
    return <div className="w-[20%] h-[80vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
        <div className='h-full w-full flex justify-center items-center'>
            <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col items-center'>
                <h1>This Is Day Modal</h1>
            </div>
        </div>
    </div>
}

const IconButton: FC<{ openDayModal: () => void, iconColor?: string }> = ({ openDayModal, iconColor }) => {
    return <>
        <div onClick={() => {
            openDayModal()
        }}>
            <div className="w-full p-2 h-full rounded-md  border shadow hover:bg-[#dca869] secondaryBG  border-gray-400 overflow-hidden hover:cursor-pointer" >
                <AddIcon FillColor={iconColor ?? "#000"} />
            </div>
        </div>
    </>
}