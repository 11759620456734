import { Children, createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'

type CarouselProps = {
    children: ReactNode;
    isFixed?: boolean;
    isInfinite?: boolean;
    itemWidth?: number;
    visibleItems?: number;
};

type CarouselContextType = {
    next: () => void,
    prev: () => void,
    goTo: (index: number) => void,
    currentIndex: number;
    itemWidth: number;
    visibleItems: number;
    totalItems: number;
    isInfinite: boolean;
}

const CarouselContext = createContext<CarouselContextType | null>(null)

export const Carousel = (Props: CarouselProps) => {
    const {
        children,
        isFixed = false,
        isInfinite = false,
        itemWidth = 13.5,
        visibleItems = 1
    } = Props;

    const [showArrows, setShowArrows] = useState(true);
    const childrenArray = Children.toArray(children);

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const ContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ContainerRef.current) {
            ContainerRef.current.style.transform = `translateX(${currentIndex * itemWidth}rem)`
        }
    }, [currentIndex])

    useEffect(() => {
        if (isFixed && ContainerRef.current) {
            const containerWidth = ContainerRef.current.clientWidth;
            const totalItemsWidth = childrenArray.length * itemWidth;
            setShowArrows(totalItemsWidth > containerWidth);
        }
    }, [isFixed, childrenArray.length, itemWidth]);

    const next = () => {
        setCurrentIndex((prev) => {
            if (isInfinite) {
                return (prev + 1) % childrenArray.length;
            }
            return Math.min(prev + 1, childrenArray.length - visibleItems);
        });
    };

    const prev = () => {
        setCurrentIndex((prev) => {
            if (isInfinite) {
                return (prev - 1 + childrenArray.length) % childrenArray.length;
            }
            return Math.max(prev - 1, 0);
        });
    };

    return (
        <CarouselContext.Provider value={{
            next,
            prev,
            goTo: (index) => setCurrentIndex(index),
            currentIndex,
            itemWidth,
            visibleItems,
            totalItems: childrenArray.length,
            isInfinite
        }}>
            <>
                <div className='relative overflow-hidden min-h-fit rounded-xl p-3  bg-transparent'>
                    <div className='w-full overflow-hidden h-full'>
                        <div
                            ref={ContainerRef}
                            className={`relative flex w-full items-stretch rounded-xl h-full transition-all duration-700 ease-in-out`}
                            style={{
                                // transform: `translateX(${currentIndex * itemWidth  + 0.5}rem)`,
                                // width: `${childrenArray.length * itemWidth+.5}rem`
                            }}
                        >
                            {children}
                        </div>
                    </div>
                    {showArrows && <CarouselNext />}
                    {showArrows && <CarouselPrev />}
                    {/* <CarouselDots /> */}
                </div>
            </>
        </CarouselContext.Provider>
    )
}

type CarouselItemProps = {
    children: ReactNode;
}

export const CarouselItem = (Props: CarouselItemProps) => {

    const CarouseContext = useContext(CarouselContext);
    if (!CarouseContext) throw new Error('CarouselItem must be used within a Carousel');
    const { itemWidth } = CarouseContext;


    const { children } = Props;
    const ItemWidthVal = `${itemWidth}rem`;
    console.log(ItemWidthVal)
    return (
        // <div className={`w-52 h-full shrink-0 overflow-hidden  p-3`}>
        <div style={{width : `${ItemWidthVal}`}} className={`h-full shrink-0 overflow-hidden  p-3`}>
            {children}
        </div>
    )
}

type CarouselButtonProps = {

}

export const CarouselNext = () => {

    const CarouseContext = useContext(CarouselContext);

    if (!CarouseContext) throw new Error('CarouselNext must be used within a Carousel');


    const { next, currentIndex, visibleItems, totalItems, isInfinite } = CarouseContext;
    const showNext = isInfinite || currentIndex < totalItems - visibleItems;

    if (!showNext) return null;

    return (
        <div className='w-10 h-10 flex justify-center items-center rounded-xl shadow-md bg-[#196a99] absolute right-0 top-1/2 transform -translate-y-1/2'>
            <button onClick={next} className=''>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#63baed">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
            </button>
        </div>
    )

}

export const CarouselPrev = () => {

    const CarouseContext = useContext(CarouselContext);
    if (!CarouseContext) throw new Error('CarouselPrev must be used within a Carousel');
    const { prev, currentIndex, isInfinite } = CarouseContext;
    const showPrev = isInfinite || currentIndex > 0;

    if (!showPrev) return null;

    return (
        <div className='absolute left-0 top-1/2 transform -translate-y-1/2 w-10 h-10 flex justify-center items-center rounded-xl shadow-md bg-[#196a99]'>
            <button onClick={prev} className=''>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#63baed">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
            </button>
        </div>
    )


}

const CarouselDots = () => {
    const CarouseContext = useContext(CarouselContext);
    if (!CarouseContext) throw new Error('CarouselDots must be used within a Carousel');

    const { currentIndex, totalItems, visibleItems, goTo } = CarouseContext;

    const dotsCount = Math.ceil(totalItems / visibleItems);

    return (
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
            {Array.from({ length: dotsCount }).map((_, index) => (
                <button
                    key={index}
                    className={`w-2 h-2 rounded-full ${Math.floor(currentIndex / visibleItems) === index ? 'bg-blue-500' : 'bg-gray-300'
                        }`}
                    onClick={() => goTo(index * visibleItems)}
                />
            ))}
        </div>
    );
};