import { UserModel } from './../../Service/models/UserModel/UserModel';
import { createSlice, PayloadAction, createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit'
import { GetAllJobsAPI, GetAllUsers, GetCompany, GetConnectionFromServer1, getUserById, Login, RegisterUser, SaveCompany, SaveJobAPI } from '../../Service/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';
import { hideFeedBack, showFeedBack } from './Feedback';
import { saveCompanyRQ } from '../../Service/models/company/SaveCompanyRQ';
import { GetCompanyRS } from '../../Service/models/company/GetCompanyRS';
import { AppDispatch, RootState } from '../store';
import { JobsModel } from '../../Service/models/Jobs/Jobs';
import { AxiosError } from 'axios';
import { GetError } from '../../Globals';
import { AddAction } from './ErrorsSlice';



export interface IJobSlice {
    Jobs: JobsModel[],
    Error: string,
    Loading: boolean,
    Empty: [],
    currentJob: JobsModel
}

const initialState: IJobSlice = {
    Jobs: [],
    Error: "",
    Loading: false,
    Empty: [],
    currentJob: {} as JobsModel
}

export const JobSlice = createSlice({
    name: 'JOB',
    initialState,
    reducers: {
        GetAllJobs: (state, action: PayloadAction<JobsModel[]>) => {
            state.Jobs = action.payload
        },
        SetCurrentJobReducer: (state, action: PayloadAction<JobsModel>) => {
            state.currentJob = action.payload
        }

    },
    extraReducers: (builder) => {


        // When a server responses with the data,
        // `fetchTodos.fulfilled` is fired:
        builder.addCase(GetAllJobsThunk.fulfilled,
            (state, { payload }) => {
                // We add all the new todos into the state
                // and change `status` back to `idle`:
                // state.list.push(...payload);
                // state.status = "idle";
                 state.Jobs = payload as JobsModel[];
            });

        // When a server responses with an error:
        builder.addCase(GetAllJobsThunk.rejected,
            (state, { payload }) => {
                // We show the error message
                // and change `status` back to `idle` again.
                // if (payload) state.error = payload.message;
                 state.Error = payload as string;

            });





    }
})

type FetchTodosError = {
    message: string;
};

// export const SaveUserThunk = createAsyncThunk<void, UserModel>(
//     // The first argument is the action name:
//     "User/Save",

//     // The second one is a function
//     // called payload creator.
//     // It contains async logic of a side-effect.
//     // We can perform requests here,
//     // work with device API, 
//     // or any other async APIs we need to.

//     // The second argument, `thunkApi`, is an object
//     // that contains all those fields
//     // and the `rejectWithValue` function:

//     // The third type-parameter is an object with:
//     // `{dispatch?, state?, extra?, rejectValue?}`` fields.
//     //
//     // `extra` is useful when we need to pass 
//     // some static data to the request function,
//     // like jwt-token or HTTP-headers.
//     //
//     // `rejectValue` is useful when we need to type 
//     // possible errors.

//     async (User, thunkApi) => {

//         thunkApi.dispatch(setLoadingTrue());

//         return RegisterUser(User).then(res => {
//             thunkApi.dispatch(setLoadingFalse());
//             thunkApi.dispatch(showFeedBack({ show: true, message: "تم الإضافه او التعديل بنجاح", status: "success" }))
//         }).catch(err => {
//             let ErrMsg = err.response.data as string;
//             thunkApi.dispatch(setLoadingFalse());
//             thunkApi.dispatch(showFeedBack({ show: true, message: ErrMsg, status: 'error' }))
//         });
//     }
// );

export const GetAllJobsThunk = createAsyncThunk<JobsModel[] | string>(
    // The first argument is the action name:
    "Job/GetAll",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (User, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return GetAllJobsAPI().then(res => {
            thunkApi.dispatch(setLoadingFalse());
           // thunkApi.dispatch(showFeedBack({ show: true, message: "تم تحديث المستخدمين", status: "success" }))
            return res
        }).catch(err => {
            return  GetError(err,thunkApi) 
        });
    }
);
export const SaveJobThunk = createAsyncThunk<void,JobsModel | any>(
    // The first argument is the action name:
    "Job/save",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (Job, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return SaveJobAPI(Job).then(res => {
            thunkApi.dispatch(setLoadingFalse());
            thunkApi.dispatch(AddAction({ message: "تم اضافه الوظيفه", status: "success" }))
        }).catch(err => {
            return  GetError(err,thunkApi) 
        });
    }
);



export const JobStore = (state: RootState) => state.JobStore;
export const { SetCurrentJobReducer } = JobSlice.actions

export default JobSlice.reducer