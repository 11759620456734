import { Box, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomTextFieldFullHeight, EGNavButton, EGNavButtonRegister, NavBarText } from '../../CustomComps/Customs';
import { GetProjectsAsync, ProjectsState } from '../../redux/slices/Projects';
import { UsersState } from '../../redux/slices/UserSlice';
import { tokens } from '../../theme';
import { DropDownData, DropTextField } from '../DashBoard/Actions/ActionsTypesScreen/AppointMents';
import Footer from '../HomePage/comps/Footer/Footer';
import Header from '../HomePage/comps/Header'
import { EgButton } from '../Shared/Shared';
import { CONTACT_US } from '../../Service/models/ContactUs';
import { EmailREG, FieldValidation, GetErrorAxios, Input_Error_State, SECTORS } from '../../Globals';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { AddAction } from '../../redux/slices/ErrorsSlice';
import { GetAllUserDetail, GetAllUsers, sendContactUsForm } from '../../Service/Apis';
import { USER_Detail, UserModel } from '../../Service/models/UserModel/UserModel';
import { JobsModel } from '../../Service/models/Jobs/Jobs';

const Agents = () => {


  const DrawerRef = useRef<HTMLDivElement>(null);

  const UsersData = useSelector(UsersState);
  const Projects = useSelector(ProjectsState);
  const [dropDownData, setDropDownData] = useState<DropDownData[]>([])
  const [Req, setReq] = useState<CONTACT_US>({ EMAIL: "" } as CONTACT_US)
  const [inputsValidator, setInputsValidators] = useState<{ Name: FieldValidation, MobileNumber: FieldValidation, Message: FieldValidation, CompanyId: FieldValidation }>({ Name: { status: Input_Error_State.Initial, Error_Message: "" }, MobileNumber: { status: Input_Error_State.Initial, Error_Message: "" }, Message: { status: Input_Error_State.Initial, Error_Message: "" }, CompanyId: { status: Input_Error_State.Initial, Error_Message: "" } })
  const [users, setUsers] = useState<USER_Detail[]>([]);

  useEffect(() => {
    if (Projects.status.length > 0) {
      let projects = Projects.status;
      let dropDownArray: DropDownData[] = []

      projects.map((project, idx) => {
        dropDownArray.push({ id: project.ID, desc: project.DESCRIPTION })
      });

      if (dropDownArray.length > 0) {
        setDropDownData(dropDownArray)
      }
    }

    if (Projects.status.length === 0) {
      dispatch(GetProjectsAsync()).then(res => {

      })
    }

  }, [Projects.status])


  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };

  const validateReq = (): boolean => {

    let generalBool: boolean = true;


    if (Req.FULL_NAME && Req.FULL_NAME.length < 5) {
      let ErrorMSg = 'الإسم يجب ان لا يقل عن 5'
      dispatch(AddAction({ message: ErrorMSg, status: "error" }))
      setInputsValidators(prev => ({ ...prev, Name: { Error_Message: ErrorMSg, status: Input_Error_State.Fail } }))
      generalBool = false;
      return false;
    }


    if (Req.MOBILE_NO && isNaN(parseInt(Req.MOBILE_NO))) {
      let ErrorMSg = 'برجاء ادخال ارقام فقط في رقم الموبايل'
      dispatch(AddAction({ message: ErrorMSg, status: "error" }))
      setInputsValidators(prev => ({ ...prev, MobileNumber: { Error_Message: ErrorMSg, status: Input_Error_State.Fail } }))
      generalBool = false;
      return false;
    }

    if (Req.MOBILE_NO && Req.MOBILE_NO.includes("+")) {
      let ErrorMSg = 'رقم الموبايل يجب ان لا يحتوي على علامه +'
      dispatch(AddAction({ message: ErrorMSg, status: "error" }))
      setInputsValidators(prev => ({ ...prev, MobileNumber: { Error_Message: ErrorMSg, status: Input_Error_State.Fail } }))
      generalBool = false;
      return false;
    }

    if (Req.MOBILE_NO && Req.MOBILE_NO.length !== 11) {
      let ErrorMSg = 'رقم الموبايل غير صحيح'
      dispatch(AddAction({ message: ErrorMSg, status: "error" }))
      setInputsValidators(prev => ({ ...prev, MobileNumber: { Error_Message: ErrorMSg, status: Input_Error_State.Fail } }))
      generalBool = false;
      return false;
    }

    if (Req.MOBILE_NO && Req.MOBILE_NO.length < 1) {
      let ErrorMSg = 'لايمكن ترك رقم الموبايل فارغ'
      dispatch(AddAction({ message: ErrorMSg, status: "error" }))
      setInputsValidators(prev => ({ ...prev, MobileNumber: { Error_Message: ErrorMSg, status: Input_Error_State.Fail } }))
      generalBool = false;
      return false;
    }


    if (Req.EMAIL !== undefined && Req.EMAIL.length > 0 && !Req.EMAIL?.match(EmailREG)) {
      dispatch(AddAction({ message: "نمط البريد غير صحيح", status: "error" }))

      generalBool = false;
      return false;
    }

    if (Req.MESSAGE && Req.MESSAGE.length < 20) {
      let ErrorMSg = 'اقل عدد حروف 20 حرف'
      dispatch(AddAction({ message: ErrorMSg, status: "error" }))
      setInputsValidators(prev => ({ ...prev, Message: { Error_Message: ErrorMSg, status: Input_Error_State.Fail } }))
      generalBool = false;
      return false;
    }

    // return generalBool;
    return true;
  }

  const sendDataToServer = () => {
    if (validateReq()) {
      let requestData: CONTACT_US = { ...Req, SECTION: SECTORS.Consult, STAMP_DATE: new Date() }
      sendContactUsForm(requestData).then(res => {
        dispatch(AddAction({ message: "تم إرسال طلبك سيتم التواصل معك في أقرب وقت", status: "success" }))
        setTimeout(() => {
          navigate('/')
        }, 1000);

      }).catch(err => {
        GetErrorAxios(err, dispatch)
      })

    }
  }


  let filterStrings: string[] = ["", "مدير النظام"]

  useEffect(() => {
    GetAllUserDetail({ WithAdmin: false ,WithNewUsers:false }).then(res => {

      let filteredUsers = res.filter(user => !filterStrings.includes(user.JOB_NAME));
      setUsers(filteredUsers)
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }, [])

  return (
    <div className='relative w-full'>
      <Header user={UsersData.currentUser} openDrawerFunc={(flag) => {
        if (flag) {
          DrawerRef.current?.classList.add("hide")
        } else {
          DrawerRef.current?.classList.remove("hide")
        }
      }} />
      <div className='mx-5 md:mx-24 mb-5 flex flex-col'>
        <div className='mt-[70px] w-full bg-white p-5 rounded-lg shadow-md flex flex-col gap-16'>
          <div>
            <h2 className='text-3xl font-bold secondaryText mb-4'>الوكلاء</h2>
            <div className='w-full grid gap-20 grid-cols-[repeat(auto-fill,minmax(200px,1fr))]'>
              {users.length > 0 && users.map(user =>
                <div className='hover:cursor-pointer' onClick={() => {
                  navigate(`/Profile?id=${user.ID}`, { state: user })
                }}>
                  <UserCard user={user} />
                </div>
              )}
            </div>
          </div>
          <div className='w-[40em]  bg-[#f6f6f6] self-center p-3 flex flex-col shadow-md' >
            <div className='i-material-symbols-home-work self-center text-7xl text-blue-900' ></div>
            <h2 className='text-center font-bold text-xl my-2'>محتاج أي مساعده بخصوص العقارات ؟</h2>
            <h2 className='text-center font-bold text-base'>تواصل معنا و هنتواصل معاك ف أقرب وقت</h2>
            <hr className='my-4 bg-gray-400 w-3/4 h-[2px] self-center' />
            <div className='w-full  flex flex-col gap-1 my-2'>
              <label htmlFor="Name">الإسم</label>
              <div>
                <CustomTextFieldFullHeight height={25} id='Name' type={''} onChange={(e: any) => {
                  let val = e.target.value;
                  setReq(prev => ({ ...prev, FULL_NAME: val }))
                  if (val.length > 0) {
                    setInputsValidators(prev => ({ ...prev, Name: { status: Input_Error_State.Success, Error_Message: "" } }))
                  } else {
                    setInputsValidators(prev => ({ ...prev, Name: { status: Input_Error_State.Fail, Error_Message: "هذا الحقل إجباري" } }))
                  }
                }} style={MainInputStyle}
                  colors={colors} theme={theme} />
                {inputsValidator.Name.status === Input_Error_State.Fail && (
                  <h6 className='font-bold text-red-600' >* {inputsValidator.Name.Error_Message}</h6>
                )}
                {(inputsValidator.Name.status === Input_Error_State.Success || inputsValidator.Name.status === Input_Error_State.Initial) && (
                  <h6 className='font-bold text-green-600'>&nbsp;</h6>
                )}
              </div>
            </div>
            <div className='w-full  flex flex-col gap-1 my-2'>
              <label htmlFor="Mobile">رقم الموبايل</label>
              <div>
                <CustomTextFieldFullHeight height={25} id='Mobile' type={''} onChange={(e: any) => {
                  let val = e.target.value;
                  setReq(prev => ({ ...prev, MOBILE_NO: val }))
                  if (val.length > 0) {
                    setInputsValidators(prev => ({ ...prev, MobileNumber: { status: Input_Error_State.Success, Error_Message: "" } }))
                  } else {
                    setInputsValidators(prev => ({ ...prev, Name: { status: Input_Error_State.Fail, Error_Message: "هذا الحقل إجباري" } }))
                  }
                }} style={MainInputStyle}
                  colors={colors} theme={theme} />
                {inputsValidator.MobileNumber.status === Input_Error_State.Fail && (
                  <h6 className='font-bold text-red-600' >* {inputsValidator.MobileNumber.Error_Message}</h6>
                )}
                {(inputsValidator.MobileNumber.status === Input_Error_State.Success || inputsValidator.MobileNumber.status === Input_Error_State.Initial) && (
                  <h6 className='font-bold text-green-600'>&nbsp;</h6>
                )}
              </div>
            </div>
            <div className='w-full  flex flex-col gap-1 my-2'>
              <label htmlFor="Email">البريد الإلكتروني ( اختياري )</label>
              <div>
                <CustomTextFieldFullHeight height={25} id='Email' type={''} onChange={(e: any) => {
                  let val = e.target.value as string;
                  setReq(prev => ({ ...prev, EMAIL: val.trim() }))
                }} style={MainInputStyle}
                  colors={colors} theme={theme} />
              </div>
            </div>
            <div className='w-full'>
              <div className="flex w-full justify-between items-center gap-2">
                <label className='text-base'>المشروع</label>
                {Projects.status.length > 0 && (
                  <DropTextField classes='w-3/4 bg-white' initalValue={Projects.status[0].ID} data={[
                    ...dropDownData
                  ]} onchange={e => {
                    setReq(prev => ({ ...prev, PROJECT_ID: e.target.value }))
                  }} />
                )}

              </div>
            </div>
            <div>
              <textarea id="Notes" name="otherNotes" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-none focus:border-none focus:outline-none focus:ring-0 focus:shadow-none " placeholder='استفسارك' onChange={(e: any) => {
                let val = e.target.value;

                setReq(prev => ({ ...prev, MESSAGE: val }))

                if (val.length > 0) {
                  setInputsValidators(prev => ({ ...prev, Message: { status: Input_Error_State.Success, Error_Message: "" } }))
                } else {
                  setInputsValidators(prev => ({ ...prev, Message: { status: Input_Error_State.Fail, Error_Message: "هذا الحقل إجباري" } }))
                }
              }} >
              </textarea>
              {inputsValidator.Message.status === Input_Error_State.Fail && (
                <h6 className='font-bold text-red-600' >* {inputsValidator.Message.Error_Message}</h6>
              )}
              {(inputsValidator.Message.status === Input_Error_State.Success || inputsValidator.Message.status === Input_Error_State.Initial) && (
                <h6 className='font-bold text-green-600'>&nbsp;</h6>
              )}
            </div>
            <div className='w-40 h-10 self-end mt-5'>
              <EgButton HandleClick={() => {
                sendDataToServer()
              }}>
                <div className='flex justify-between items-center w-full px-2'>
                  <p className='font-bold'>ارسل الإستفسار</p>
                  <div className='i-material-symbols-keyboard-double-arrow-left-rounded font-bold text-2xl'>
                  </div>
                </div>
              </EgButton>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Agents



const UserCard = ({ user }: { user: USER_Detail }) => {

  // let userJob = user.JOBS as any;

  // let jobs: JobsModel[] = [];

  // Object.entries(userJob).map(([key, val]) => {
  //   jobs.push(val as JobsModel)
  // });

  // let firstJob = jobs[0];

  return <div className='flex flex-col h-[30em] shadow-md rounded-lg bg-gray-100 items-center p-2 justify-between px-2'>
    <div className='h-48 w-48 overflow-hidden rounded-[50%]'>
      <img className='w-full h-full object-cover' src={user.PHOTO_URL} alt={user.FULL_NAME} />
    </div>
    <div className='h-1/2  flex flex-col justify-around items-center w-full'>
      <h5 className='font-bold text-xl truncate w-full text-center'>{user.FULL_NAME}</h5>
      <h5 className='font-bold text-xl truncate w-full text-center'>{user.JOB_NAME}</h5>
      <h5 className='font-bold truncate w-full text-center'>{user.MOBILE_NO}</h5>
      <h5 dir='ltr' className='font-bold truncate w-full text-center'>{user.EMAIL}</h5>
    </div>
  </div>
}