import { PropertyType } from "../../../Globals"
import { DevelopmentCompany_Model } from "../DevolpCompanyModel"

export type ProjectModel = {
	ID: number,
	COMAPNY_ID: number,
	PROJECT_NAME: string,
	PROJECT_NAME_EN: string,
	DESCRIPTION: string,
	DESCRIPTION_EN: string,
	CITY_ID: number,
	LICENESE_NO?: number,
	LICENESE_DATE?: Date,
	END_DATE: Date,
	NO_UNITS?: number,
	NO_FLOORS?: number,
	TOTAL_AREA?: number,
	LAT?: number,
	LNG?: number,
	PROJECT_ACTIVITY_ID?: number,
	PROJECT_CONDITION_ID?: number,
	PROJECT_IMAGES: ProjectImages_Model[],
	SEQ_NO?: number,
	PROJECT_CAMPAIGNS: PROJECT_CAMPAIGNS[],
	ATTACHMENTS?: PROJECT_ATTACHMENTS[],
	YOUTUBE_VIDEOS?: PROJECT_VIDEOS[],
	IS_ACTIVE?: boolean,
	PROJECT_UNITS?: PROJECT_UNITS[],
	UPDATED_AT?: Date,
	DEVELOPMENT_COMPANY_ID?: number,
	COMPANY_COMMISSION?: number
	COMPANY_INCENTIVE?: number,
	DEVELOPMENT_COMPANY?: DevelopmentCompany_Model
}

export type PROJECT_UNITS = {
	ID?: number,
	PROJECT_ID?: number,
	UNIT_TYPE?: PropertyType,
	START_PRICE?: number,
	END_PRICE?: number,
	AREA_FROM?: number,
	AREA_TO?: number,
	NOTES?: string,
	ADDITIONAL_DESCRIPTION?: string,
	PAYMENT_STRATIGY?: PAYMENT_STRATIGY[]
}


export type PAYMENT_STRATIGY = {
	ID?: number,
	UNIT_ID?: number,
	DEPOSIT_PRECENTAGE?: number,
	NO_YEAR_INSTALLMENTS?: number,
	NOTES?: string,

}

export type PROJECT_VIDEOS = {
	ID?: number,
	PROJECT_ID?: number,
	LINK: string
}

export type PROJECT_ATTACHMENTS = {
	ID?: number,
	PROJECT_ID?: number,
	FILE_NAME?: string
	DATA?: string
	DATA_BASE?: string
	DATA_TYPE?: number
	URL?: string
	PATH?: string
}


export type ProjectImages_Model = {
	PROJECT_ID?: number,
	IMAGE_URL?: string,
	IMAGE_BASE?: string,
	TYPE_ID: ImageTypeReference,
	SHOW_TO_AGENTS?: boolean,
	DOWNLOAD_LINK?: string
}

export enum ImageTypeReference {
	Thumbnail = 1,
	Layout = 2,
	Gallery = 3,
	Template = 4,
	Files = 5
}


export enum UnitTypeReference {
	Apartment = 1,
	Villa = 2,
	Twinhouse = 3,
	Townhouse = 4,
	Duplex = 5,
	Penthouse = 6,
	Chalet = 7,
	Studio = 8,
	Cabin = 9,
	Clinic = 10,
	Office = 11,
	Retail = 12
}

export type PROJECT_CAMPAIGNS = {
	ID?: number,
	PROJECT_ID?: number,
	TEXT: string,
	STAMP_DATE: Date,
	STAMP_USER?: string,
	UPDATED_USER?: Date,
	UPDATED_AT?: Date,
	SHOW_TO_AGENTS: boolean
}

export type ProjectCompgainReq = {
	PROJECT_ID: number,
	COMPGAIN_IMAGES?: ProjectImages_Model[]
	PROJECT_CAMPAIGNS?: PROJECT_CAMPAIGNS[]
}