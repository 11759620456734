import { useEffect, useState } from 'react'
import { FINISHING_REQUEST, SaveContractReq } from '../../../../Service/models/Finishing'
import ImageUploader from '../../../../components/ImageUploader'
import EGDate from '../../../../components/shared/EGDate'
import { set } from 'react-hook-form'
import ButtonAnimated, { animationType } from '../../../../components/ButtonAnimated'
import { SaveContractFinish } from '../../../../Service/Apis'
import { AppDispatch } from '../../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { GetErrorAxios, HavePermission, PERMISSIONS } from '../../../../Globals'
import { UsersState } from '../../../../redux/slices/UserSlice'
// finishcontract type accept finish request as props
type FinishContractProps = {
    finishRequest: FINISHING_REQUEST
}
type FileType = {
    name: string,
    base64: string
}

const FinishContract = (Props: FinishContractProps) => {
    const { finishRequest } = Props

    const [Req, setReq] = useState<SaveContractReq>({ CONTRACT_DATE: new Date(), REQUEST_ID: finishRequest.ID } as SaveContractReq)
    const [PDFfile, setPDFfile] = useState<FileType>({ name: '', base64: '' })
    const [FinishContract, setFinishContract] = useState<animationType>('IDLE')
    const UsersData = useSelector(UsersState);
    const dispatch = useDispatch<AppDispatch>()

    const handleFinishContract = () => {

        setFinishContract('LOADING')
        SaveContractFinish(Req).then(res => {
            setFinishContract('SUCCESS')

            setTimeout(() => {
                window.location.reload()
            }, 2000);




        }).catch(err => {
            setFinishContract('ERROR')
            GetErrorAxios(err, dispatch)
        })
    }

    useEffect(() => {
        setReq({ ...Req, CONTRACT_DATE: finishRequest.CONTRACT_DATE ?? new Date(), REQUEST_ID: finishRequest.ID })
    }, [finishRequest])


    return (
        <div>
            {finishRequest ?
                <div className='flex flex-col w-full gap-10'>
                    {!finishRequest.CONTRACT_URL && (UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.SAVE_CONTRACT, UsersData.currentUser.PERMISSIONS))? 
                        <div className='flex flex-col w-full gap-10'>
                            <div>
                                <ImageUploader multiImage={false} onImageUpload={(data) => {
                                    let PDFfile = data[0]
                                    setReq({ ...Req, CONTRACT_BASE: PDFfile.base64 })
                                }
                                } Accept="Pdf" />
                            </div>
                            <div className='self-center'>
                                <p className='mb-2'>تاريخ التعاقد</p>
                                <EGDate DateValue={date => {
                                    setReq({ ...Req, CONTRACT_DATE: date })
                                }} initialValue={Req.CONTRACT_DATE} />
                            </div>
                            <div className='self-end'>
                                <ButtonAnimated onClick={() => {
                                    handleFinishContract()
                                }} animate={FinishContract}>
                                    <p className='font-bold'>حفظ العقد</p>
                                </ButtonAnimated>
                            </div>
                        </div>
                        : <>
                            <a href={finishRequest.CONTRACT_URL}>
                                <p className='text-blue-500 underline'>تحميل العقد</p>
                            </a>
                        </>
                    }


                </div>
                :
                <div className='w-1/2 h-28 '>
                    <div className='shimmer bg-red-400'>
                    </div>
                </div>
            }
        </div>
    )
}

export default FinishContract