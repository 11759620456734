import { useTheme } from '@mui/material';
import { RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTextFieldFullHeight } from '../../../CustomComps/Customs';
import { Bool_HavePermission, CopyText, DigitValidate, GetErrorAxios, Input_Error_State, PERMISSIONS, isFloat, sleep } from '../../../Globals';
import { GET_ALL_DevelopmentCompanies, SaveProject, getAllProjects, projectCamp_GetAll, projectCamp_save } from '../../../Service/Apis';
import { ImageTypeReference, PROJECT_ATTACHMENTS, PROJECT_CAMPAIGNS, PROJECT_UNITS, PROJECT_VIDEOS, ProjectCompgainReq, ProjectImages_Model, ProjectModel } from '../../../Service/models/projects/ProjectModel';
import { LogoFiles } from '../../../components/Icons/Icons';
import { GetAllCitiesThunk, citiesState } from '../../../redux/slices/CitySlice';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { setLoadingFalse, setLoadingTrue } from '../../../redux/slices/LoadingSlice';
import { GetProjectsAsync, ProjectsState } from '../../../redux/slices/Projects';
import { GetAllUsersThunk, UsersState } from '../../../redux/slices/UserSlice';
import { AppDispatch } from '../../../redux/store';
import { tokens } from '../../../theme';
import { EgButton, convertToBase64 } from '../../Shared/Shared';
import { DropDownData, DropTextField } from '../Actions/ActionsTypesScreen/AppointMents';
// import './projstyle.css'
import { DevelopmentCompany_Model } from '../../../Service/models/DevolpCompanyModel';
import projStyle from './projstyle.module.css';

export type ImageType = {
  ID: number,
  name?: string,
  type?: string,
  base64?: string,
  file?: any,
  url?: string,
  SHOW_TO_AGENTS: boolean
  DOWNLOAD_LINK: string
  ImageType?: ImageTypeReference
  Path?: string
}


type TextType = {
  ID: number,
  text: string,
  SHOW_TO_AGENTS: boolean
}


const InitialText = `
🏡 عمارة سكنية رائعة للبيع 🏡

🌟 الموقع:
تقع هذه العمارة السكنية الجميلة في منطقة هادئة ومميزة، تتيح لك ولعائلتك العيش بسلام وراحة. بالقرب من المدارس والمتاجر وجميع وسائل الراحة.

🏗️ المميزات:

عمارة سكنية مكونة من عدة شقق سكنية فسيحة ومريحة.
مواقف سيارات خاصة للسكان.
حديقة جميلة ومساحة خضراء للاستمتاع بالهواء الطلق.
نظام أمان عالي المستوى لضمان سلامة السكان.
تصميم داخلي أنيق ومدروس.
إطلالة رائعة على المناطق المحيطة.
📏 المساحة:

المساحة الإجمالية: [أضف المساحة هنا] متر مربع.
💰 السعر:
[أضف السعر هنا]

🔑 التفاصيل الإضافية:
هذه العمارة سكنية هي فرصة استثمارية رائعة أيضًا. إذا كنت تبحث عن دخل استثماري مميز أو منزل لعائلتك، فإن هذه العمارة هي الخيار الأمثل.

للمزيد من المعلومات ولجدولة موعد للعرض، يرجى الاتصال بنا على الرقم: [أضف رقم الاتصال هنا]
`

enum EViewList {
  Project_Edit = 0,
  Project_Camp = 1,
  Project_New = 2
}

const ProjectsList = ({ Granted }: { Granted: boolean }) => {

  const dispatch = useDispatch<AppDispatch>();
  const ProjectsData = useSelector(ProjectsState);
  const user = useSelector(UsersState);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };

  const ProjectRef = useRef<HTMLDivElement>(null);
  const confirmRef = useRef<HTMLDivElement>(null);
  const [projectimgs, setProjectimgs] = useState<ImageType[]>([]);
  const [projectThumpimgs, changeprojectThumpimgs] = useState<ImageType[]>([]);
  const [projectLayoutimgs, changeprojectLayoutimgs] = useState<ImageType[]>([]);
  const [projectGalleryimgs, changeprojectGalleryimgs] = useState<ImageType[]>([]);
  const [projectFiles, changeprojectFiles] = useState<ImageType[]>([]);
  const [projectText, setProjectText] = useState<TextType[]>([]);
  const [Allprojectimgs, setAllProjectimgs] = useState<ImageType[]>([]);
  const [AllprojectText, setAllProjectText] = useState<TextType[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectModel>({} as ProjectModel)
  const [projCamps, setProjCamps] = useState<ProjectModel[]>([]);
  const [inputsValidator, setInputsValidators] = useState<{ PROJECT_NAME: Input_Error_State, PROJECT_NAME_EN: Input_Error_State, DESCRIPTION: Input_Error_State, DESCRIPTION_EN: Input_Error_State, CITY_ID: Input_Error_State }>({ PROJECT_NAME: Input_Error_State.Initial, PROJECT_NAME_EN: Input_Error_State.Initial, DESCRIPTION: Input_Error_State.Initial, DESCRIPTION_EN: Input_Error_State.Initial, CITY_ID: Input_Error_State.Initial })
  const [permissions, setPermissions] = useState<PERMISSIONS[]>([])
  const [citiesDropDown, changeCitiesDropDown] = useState<DropDownData[]>([])
  const [Req, changeReq] = useState<ProjectModel>({ PROJECT_NAME: selectedProject.PROJECT_NAME, PROJECT_NAME_EN: selectedProject.PROJECT_NAME_EN, DESCRIPTION: selectedProject.DESCRIPTION, DESCRIPTION_EN: selectedProject.DESCRIPTION_EN } as ProjectModel);
  const cities = useSelector(citiesState);
  const [ViewId, setViewId] = useState<EViewList>(EViewList.Project_Camp)
  const [Rerender, changeRender] = useState<boolean>(false);
  const [vids, changeVids] = useState<PROJECT_VIDEOS[]>([])
  const PaymentRef = useRef<HTMLDivElement>(null);
  const [clearPaymentPlans, changeClearPaymentPlans] = useState<boolean>(false)

  const [commissionString, setCommissionString] = useState<string>("")
  const [incentiveString, setIncentiveString] = useState<string>("")

  const [Alldevelopers, setAllDevelopers] = useState<DevelopmentCompany_Model[]>([])
  const [developersDD, setDevelopersDD] = useState<DropDownData[]>([])

  const ViewDeterminder = () => {

  }

  const delay = async () => {
    await sleep(1000)
    if (ProjectsData.status.length < 1) {
      dispatch(GetProjectsAsync())
    }
  }

  const UpdateProjecstData = () => {
    getAllProjects().then(res => {
      setProjCamps(res);
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }

  const onImagesChange = async (e: any) => {
    const files = e.target.files;
    let imagesBase64: ImageType[] = []

    //setProjectimgs([]);

    // Object.entries(files).map(async ([key, val]) => {
    //   let convertVal = val as any
    //   let MaxIdNumber: number = 0;
    //   // imagesBase64.push({ name: convertVal.name, type: convertVal.type, base64: "", file: convertVal })
    //   await convertToBase64(convertVal).then(
    //     (data) => {
    //       if (typeof data === 'string') {
    //         // projectimgs.map((proj) => {
    //         //   if (proj.ID ?? 0 >= MaxIdNumber) {
    //         //     MaxIdNumber = proj.ID ?? 0
    //         //   }
    //         // })


    //         for (const project of projectimgs) {
    //           if (project.ID ?? 0 >= MaxIdNumber) {
    //             MaxIdNumber = project.ID ?? 0
    //           }
    //         }

    //         // imagesBase64.push({ name: convertVal.name, type: convertVal.type, base64: data, file: convertVal })
    //         setProjectimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true }])
    //         // imagesBase64.push()
    //       }
    //     }
    //   );
    //   //return 
    // })
    let MaxIdNumber: number = 0;
    for (const [key, val] of Object.entries(files)) {
      let convertVal = val as any
      let data = await convertToBase64(convertVal) as string;

      if (MaxIdNumber === 0) {
        for (const project of projectimgs) {
          if (project.ID ?? 0 >= MaxIdNumber) {
            MaxIdNumber = project.ID ?? 0
          }
        }

        MaxIdNumber++;
      } else {
        MaxIdNumber++;
      }
      setProjectimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "" }])
    }

  }
  const onThumpNailImageChange = async (e: any) => {
    const files = e.target.files;
    let imagesBase64: ImageType[] = []

    let MaxIdNumber: number = 0;
    for (const [key, val] of Object.entries(files)) {
      let convertVal = val as any


      let data = await convertToBase64(convertVal) as string;
      for (const proj of projectThumpimgs) {
        if (proj.ID > MaxIdNumber) {
          MaxIdNumber = proj.ID
        }
      }

      if (MaxIdNumber === 0) {
        for (const project of projectimgs) {
          if (project.ID ?? 0 >= MaxIdNumber) {
            MaxIdNumber = project.ID ?? 0
          }
        }

        MaxIdNumber++;
      } else {
        MaxIdNumber++;
      }
      changeprojectThumpimgs(prev => [{ ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "", ImageType: ImageTypeReference.Thumbnail }])
    }

  }
  const onLayoutImageChange = async (e: any) => {
    const files = e.target.files;
    let imagesBase64: ImageType[] = []
    let MaxIdNumber: number = 0;
    for (const [key, val] of Object.entries(files)) {
      let convertVal = val as any


      let data = await convertToBase64(convertVal) as string;

      for (const proj of projectLayoutimgs) {
        if (proj.ID > MaxIdNumber) {
          MaxIdNumber = proj.ID
        }
      }

      if (MaxIdNumber === 0) {
        for (const project of projectimgs) {
          if (project.ID ?? 0 >= MaxIdNumber) {
            MaxIdNumber = project.ID ?? 0
          }
        }

        MaxIdNumber++;
      } else {
        MaxIdNumber++;
      }
      // setProjectimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "" }])
      // changeReq(prev => ({ ...prev, PROJECT_IMAGES: [...prev.PROJECT_IMAGES, { TYPE_ID: ImageTypeReference.Layout, IMAGE_BASE: data }] }))
      changeprojectLayoutimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "", ImageType: ImageTypeReference.Layout }])
    }

  }
  const onGalleryImageChange = async (e: any) => {
    const files = e.target.files;
    let imagesBase64: ImageType[] = []

    let MaxIdNumber: number = 0;
    for (const [key, val] of Object.entries(files)) {
      let convertVal = val as any


      let data = await convertToBase64(convertVal) as string;
      for (const proj of projectGalleryimgs) {
        if (proj.ID > MaxIdNumber) {
          MaxIdNumber = proj.ID
        }
      }

      if (MaxIdNumber === 0) {
        for (const project of projectimgs) {
          if (project.ID ?? 0 >= MaxIdNumber) {
            MaxIdNumber = project.ID ?? 0
          }
        }

        MaxIdNumber++;
      } else {
        MaxIdNumber++;
      }
      // setProjectimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "" }])
      changeprojectGalleryimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "", ImageType: ImageTypeReference.Gallery }])
    }

  }
  const FilesMaxSize2mb = (input: any): boolean => {
    if (input.files.length > 0) {
      const fileSize = input.files[0].size; // in bytes
      const maxSizeInBytes = 60 * 1024 * 1024; // 2 MB

      if (fileSize > maxSizeInBytes) {
        alert('حجم الملف اكبر من 60 ميجا , برجاء اختيار ملف حجمه اصغر');
        input.value = ''; // Clear the file input
        return false
      }
    }
    return true
  }
  const onFileAdded = async (e: any) => {
    const files = e.target.files;

    if (FilesMaxSize2mb(e.target)) {
      let imagesBase64: ImageType[] = []

      let MaxIdNumber: number = 0;
      for (const [key, val] of Object.entries(files)) {
        let convertVal = val as any


        let data = await convertToBase64(convertVal) as string;


        if (MaxIdNumber === 0) {
          for (const project of projectimgs) {
            if (project.ID ?? 0 >= MaxIdNumber) {
              MaxIdNumber = project.ID ?? 0
            }
          }

          MaxIdNumber++;
        } else {
          MaxIdNumber++;
        }
        // setProjectimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "" }])
        changeprojectFiles(prev => [{ ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "", ImageType: ImageTypeReference.Files }])
      }
    }

  }

  const ValidateRequest = (): boolean => {
    if (projectimgs.length === 0 && projectText.length === 0) {
      dispatch(AddAction({ message: "يجب ادخال صوره او نص على الأقل", status: "error" }))
      return false;
    }

    return true
  }

  const sendToServer = () => {
    if (ValidateRequest()) {

      let projectImgs: ProjectImages_Model[] = []
      let projectCamps: PROJECT_CAMPAIGNS[] = []

      projectimgs.map(img => {
        projectImgs.push({ TYPE_ID: 4, IMAGE_BASE: img.base64, SHOW_TO_AGENTS: img.SHOW_TO_AGENTS, IMAGE_URL: img.url })
      })

      projectText.map(text => {
        projectCamps.push({ STAMP_DATE: new Date(), TEXT: text.text, PROJECT_ID: selectedProject.ID, SHOW_TO_AGENTS: text.SHOW_TO_AGENTS, ID: text.ID })
      })

      let req: ProjectCompgainReq = { PROJECT_ID: selectedProject.ID, COMPGAIN_IMAGES: projectImgs, PROJECT_CAMPAIGNS: projectCamps }

      dispatch(setLoadingTrue());

      projectCamp_save(req).then(res => {
        dispatch(AddAction({ message: "تم الحفظ بنجاح", status: "success" }))
      }).catch(err => {
        GetErrorAxios(err, dispatch)
      })



      dispatch(setLoadingFalse());

    }
  }

  const sendNewProjectDataToServer = async () => {
    let allImages: ImageType[] = [...projectGalleryimgs, ...projectLayoutimgs, ...projectThumpimgs];
    let AllVidsLink: PROJECT_VIDEOS[] = []
    let allProjectFiles: PROJECT_ATTACHMENTS[] = selectedProject.ATTACHMENTS ?? []
    let projectImgs: ProjectImages_Model[] = selectedProject.PROJECT_IMAGES

    vids.map((vid, idx) => {
      AllVidsLink.push({ LINK: vid.LINK, PROJECT_ID: selectedProject.ID })
    })

    projectFiles.map((file, idx) => {
      if (file.base64 !== undefined) {
        allProjectFiles.push({ DATA_BASE: file.base64, PROJECT_ID: selectedProject.ID, FILE_NAME: file.name, DATA_TYPE: 1 })
      }
    })

    allImages.map((img, idx) => {
      if (img.ImageType !== undefined) {
        projectImgs.push({ TYPE_ID: img.ImageType, IMAGE_BASE: img.base64 })
      }
    })

    dispatch(setLoadingTrue());
    SaveProject({
      ...Req, PROJECT_IMAGES: projectImgs, YOUTUBE_VIDEOS: AllVidsLink, ATTACHMENTS: allProjectFiles
    }).then(res => {
      dispatch(setLoadingFalse());
      dispatch(AddAction({ message: "تم", status: "success" }))
      confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
      confirmRef.current?.classList.remove(projStyle.LeftIsZero)
      changeRender(prev => !prev)
      changeClearPaymentPlans(true);
      //return "Done";
    }).catch(err => {
      return GetErrorAxios(err, dispatch)
      //return ErrMsg;
    }).finally(() => {
      dispatch(setLoadingFalse());
    });
  }
  const createNewProject = async () => {
    let allImages: ImageType[] = [...projectGalleryimgs, ...projectLayoutimgs, ...projectThumpimgs];
    let AllVidsLink: PROJECT_VIDEOS[] = []
    let allProjectFiles: PROJECT_ATTACHMENTS[] = selectedProject.ATTACHMENTS ?? []
    let projectImgs: ProjectImages_Model[] = selectedProject.PROJECT_IMAGES ?? []

    if (allImages.length > 0) {
      allImages.map((img, idx) => {
        if (img.ImageType !== undefined) {
          projectImgs.push({ TYPE_ID: img.ImageType, IMAGE_BASE: img.base64 })
        }
      })
    } else {
      dispatch(AddAction({ message: "برجاء إضافه صور للمشروع", status: 'error' }))
      return;
    }

    vids.map((vid, idx) => {
      AllVidsLink.push({ LINK: vid.LINK, PROJECT_ID: selectedProject.ID })
    })

    projectFiles.map((file, idx) => {
      if (file.base64 !== undefined) {
        allProjectFiles.push({ DATA_BASE: file.base64, PROJECT_ID: selectedProject.ID, FILE_NAME: file.name, DATA_TYPE: 1 })
      }
    })

    dispatch(setLoadingTrue());
    SaveProject({
      ...Req, PROJECT_IMAGES: projectImgs, ID: -1, PROJECT_ACTIVITY_ID: 1,
      PROJECT_CONDITION_ID: 1, ATTACHMENTS: allProjectFiles, YOUTUBE_VIDEOS: AllVidsLink
    }).then(res => {
      dispatch(setLoadingFalse());
      dispatch(AddAction({ message: "تم", status: "success" }))
      confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
      confirmRef.current?.classList.remove(projStyle.LeftIsZero)
      changeRender(prev => !prev)
      //return "Done";
    }).catch(err => {
      return GetErrorAxios(err, dispatch)
      //return ErrMsg;
    }).finally(() => {
      dispatch(setLoadingFalse());
    });
  }

  //get all projects
  useEffect(() => {
    if (Granted) {
      // delay();
      getAllProjects().then(res => {
        setProjCamps(res);
      }).catch(err => {
        GetErrorAxios(err, dispatch)
      })

      if (cities.length === 0 || cities === undefined) {
        dispatch(GetAllCitiesThunk())
      } else {
        let citiesHolder: DropDownData[] = []

        cities.map((city, idx) => {
          citiesHolder.push({ id: city.ID, desc: city.CITY_NAME })
        })


        changeCitiesDropDown(citiesHolder)
      }

      // get All Developers
      GET_ALL_DevelopmentCompanies().then(data => {
        setAllDevelopers(data)
        let DevelopersDD: DropDownData[] = data.map((item, idx) => ({ id: item.ID, desc: item.COMPANY_NAME! }))
        setDevelopersDD([{ id: -1, desc: "اختر شركه التطوير" }, ...DevelopersDD])
      }).catch(err => {
        GetErrorAxios(err, dispatch)
      })


    } else {
      projectCamp_GetAll().then(res => {
        setProjCamps(res);
      }).catch(err => {
        GetErrorAxios(err, dispatch)
      })
    }
    ProjectRef.current?.classList.remove(projStyle.LeftIsZero)

    if (user.currentUser === undefined) {
      dispatch(GetAllUsersThunk(null))
    }

    if (user.currentUser !== undefined && user.currentUser.PERMISSIONS !== undefined) {
      let Permissions = Bool_HavePermission(user.currentUser.PERMISSIONS)
      setPermissions(Permissions);
    }

  }, [Granted, cities, Rerender]);

  // useEffect(() => {
  //   getAllProjects().then(res => {
  //     setProjCamps(res);
  //   }).catch(err => {
  //     GetErrorAxios(err, dispatch)
  //   })
  // }, [Rerender])

  // if (ProjectsData.status.length > 1) {
  useEffect(() => {

    if (isFloat(commissionString)) {
      changeReq(prev => ({ ...prev, COMPANY_COMMISSION: parseFloat(commissionString) }))
    }

    if (isFloat(incentiveString)) {
      changeReq(prev => ({ ...prev, COMPANY_INCENTIVE: parseFloat(incentiveString) }))
    }
  }, [commissionString, incentiveString])


  if (projCamps && projCamps.length > 0) {
    return (
      <div className='w-full min-h-[80vh] relative overflow-hidden'>
        <div className='flex flex-wrap items-center gap-5 '>
          <div className='i-material-symbols-add-box-outline-rounded text-6xl text-gray-500 hover:cursor-pointer' onClick={() => {
            if (Granted === true) {


              setViewId(EViewList.Project_New)

              setProjectimgs([])
              changeReq({ PROJECT_NAME: "", PROJECT_NAME_EN: "", CITY_ID: -1, DESCRIPTION: "", DESCRIPTION_EN: "", COMPANY_COMMISSION: 0, COMPANY_INCENTIVE: 0, DEVELOPMENT_COMPANY_ID: developersDD[0].id } as ProjectModel)
              setSelectedProject({ PROJECT_NAME: "", PROJECT_NAME_EN: "", CITY_ID: -1, DESCRIPTION: "", DESCRIPTION_EN: "" } as ProjectModel)
              changeprojectThumpimgs([])
              changeprojectGalleryimgs([])
              changeprojectFiles([])
              changeVids([])
              changeClearPaymentPlans(false);

              PaymentRef.current?.classList.remove(projStyle.PaymentLeftisZero)
              PaymentRef.current?.classList.add(projStyle.RemovePaymentLeftisZero)

              ProjectRef.current?.classList.add(projStyle.LeftIsZero)
              ProjectRef.current?.classList.remove(projStyle.LeftIsZero_Remove)
            }
          }}>
          </div>
          {Granted === true && (
            <h1 className='text-xl font-bold'>إضافه مشروع</h1>
          )}
        </div>
        <div className='relative w-full h-full grid gap-10 grid-cols-[repeat(auto-fill,minmax(250px,250px))]' >
          {projCamps.length > 0 && projCamps.map((proj, idx) => {
            return <ProjectCard key={proj.ID} UserPermissions={permissions} project={proj} projectEdit={(project) => {
              let allCities = cities
              changeClearPaymentPlans(false);
              changeprojectFiles([])
              changeprojectThumpimgs([])
              changeprojectGalleryimgs([])
              changeprojectLayoutimgs([])
              setProjectimgs([])

              setSelectedProject(project)
              changeReq(project)
              setViewId(EViewList.Project_Edit)

              let selectedComp = projCamps?.filter(proj => proj.ID === project.ID)[0];


              let imgs: ImageType[] = []
              let texts: TextType[] = []
              let files: ImageType[] = []

              let filteredImgs = selectedComp?.PROJECT_IMAGES.filter(imgs => imgs.TYPE_ID !== 4);
              let projectCamps: PROJECT_CAMPAIGNS[] = selectedComp?.PROJECT_CAMPAIGNS ?? [];

              filteredImgs?.map((img, idx) => {
                imgs.push({ ID: idx + 1, base64: img.IMAGE_BASE, url: img.IMAGE_URL, name: img.IMAGE_URL, SHOW_TO_AGENTS: img.SHOW_TO_AGENTS ?? true, DOWNLOAD_LINK: img.DOWNLOAD_LINK ?? "" })
              })

              projectCamps.map((text, idx) => {
                texts.push({ ID: text.ID ?? idx + 1, text: text.TEXT, SHOW_TO_AGENTS: text.SHOW_TO_AGENTS ?? true })
              })


              setProjectimgs(imgs);
              changeVids(project.YOUTUBE_VIDEOS ?? [])
              if (project && project.ATTACHMENTS) {
                let AttachmentsTypeConvert: ImageType[] = []
                project.ATTACHMENTS.map((att, idx) => {
                  AttachmentsTypeConvert.push({ ID: att.ID!, base64: att.DATA_BASE!, name: att.FILE_NAME!, type: att.DATA_TYPE!.toString(), SHOW_TO_AGENTS: true, DOWNLOAD_LINK: att.URL! })
                })
                changeprojectFiles(AttachmentsTypeConvert)
              } else {
                changeprojectFiles([])
              }


              ProjectRef.current?.classList.add(projStyle.LeftIsZero)
              ProjectRef.current?.classList.remove(projStyle.LeftIsZero_Remove)

              PaymentRef.current?.classList.remove(projStyle.PaymentLeftisZero)
              PaymentRef.current?.classList.add(projStyle.RemovePaymentLeftisZero)

            }} projectTemplate={(project) => {
              setSelectedProject(project);
              setViewId(EViewList.Project_Camp)
              let selectedComp = projCamps?.filter(proj => proj.ID === project.ID)[0];

              let imgs: ImageType[] = []
              let texts: TextType[] = []

              let filteredImgs = selectedComp?.PROJECT_IMAGES.filter(imgs => imgs.TYPE_ID === 4);
              let projectCamps: PROJECT_CAMPAIGNS[] = selectedComp?.PROJECT_CAMPAIGNS ?? [];

              filteredImgs?.map((img, idx) => {
                imgs.push({ ID: idx + 1, base64: img.IMAGE_BASE, url: img.IMAGE_URL, name: img.IMAGE_URL, SHOW_TO_AGENTS: img.SHOW_TO_AGENTS ?? true, DOWNLOAD_LINK: img.DOWNLOAD_LINK ?? "" })
              })

              projectCamps.map((text, idx) => {
                texts.push({ ID: text.ID ?? idx + 1, text: text.TEXT, SHOW_TO_AGENTS: text.SHOW_TO_AGENTS ?? true })
              })

              setProjectimgs(imgs);
              setProjectText(texts)


              ProjectRef.current?.classList.add(projStyle.LeftIsZero)
              ProjectRef.current?.classList.remove(projStyle.LeftIsZero_Remove)
            }} confirmAction={(project) => {
              setSelectedProject(project);
              confirmRef.current?.classList.add(projStyle.LeftIsZero)
              confirmRef.current?.classList.remove(projStyle.LeftIsZero_Remove)
            }} />
          })}
        </div>
        <div ref={ProjectRef} className={'absolute transition-all ease-in-out duration-[1000ms] top-0 p-2 bg-white bg-opacity-95 rounded-lg w-full h-full overflow-auto ' + projStyle.LeftIsZero_Remove}>
          <div className='w-full h-full relative'>
            {(ViewId === EViewList.Project_Camp) && (
              <div className='w-full h-full relative flex flex-col'>
                {Granted === true && (
                  <div className='w-full h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4'>
                    <label htmlFor="files">
                      <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                      </div>
                    </label>
                    <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="image/*" id="files" multiple onChange={onImagesChange} />
                    <h1>إضافه الى معرض الصور</h1>
                  </div>
                )}
                {Granted === false && (
                  <h1 className='text-2xl font-bold'>معرض الصور</h1>
                )}
                <div className='flex flex-wrap gap-8 mt-10'>
                  {projectimgs.length > 0 && projectimgs.map(img => {
                    return <div className='relative w-32 h-40 bg-white p-1 shadow-md rounded-lg flex flex-col justify-start gap-2 items-center'>
                      <div className='w-4/5 h-4/5 rounded object-cover overflow-hidden bg-gray-100'>
                        {img.base64 && (
                          <div className='bg-center w-full h-full'>
                            <img className='w-full h-full object-cover' src={img.base64} alt={img.name} />
                          </div>
                        )}
                        {img.url && (
                          <div className='bg-center w-full h-full'>
                            <img className='w-full h-full object-cover' src={img.url} alt={img.name} />
                          </div>
                        )}
                      </div>
                      <div dir="rtl" className='w-4/5 text-right'>
                        <a href={img.DOWNLOAD_LINK}>
                          <h6 className='truncate' >تحميل</h6>
                        </a>
                      </div>
                      <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                        if (Granted === true) {
                          let selectedImg = projectimgs.filter(imgs => imgs.ID === img.ID)[0];
                          let allimgsExceptSelected = projectimgs.filter(imgs => imgs.ID !== img.ID);

                          selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                          allimgsExceptSelected.push({ ...selectedImg });

                          let sortedArr = allimgsExceptSelected.sort((a, b) => a.ID - b.ID);
                          // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                          setProjectimgs(sortedArr)
                        }

                      }} >
                        {Granted === true && (
                          <>
                            {!img.SHOW_TO_AGENTS && (
                              <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                              </div>
                            )}
                            {img.SHOW_TO_AGENTS && (
                              <div className='i-material-symbols-check-small-rounded text-xl text-green-500'>
                              </div>
                            )}
                          </>
                        )}
                        {Granted === false && (
                          <>
                            <a href={img.DOWNLOAD_LINK}>
                              <div className='i-material-symbols-download text-xl text-green-500'>
                              </div>
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  })}
                </div>

                <div className='mt-10 flex gap-4 items-center'>
                  {Granted === true && (
                    <div className='i-material-symbols-add-box-outline-rounded text-6xl text-gray-500 hover:cursor-pointer' onClick={() => {
                      if (Granted === true) {
                        let MaxIdNumber: number = 0;
                        projectText.map(text => {
                          if (text.ID ?? 0 >= MaxIdNumber) {
                            MaxIdNumber = text.ID ?? 0
                          }
                        })
                        setProjectText(prev => [...prev, { text: InitialText, ID: MaxIdNumber + 1, SHOW_TO_AGENTS: true }])
                      }
                    }}>
                    </div>
                  )}
                  {Granted === true && (
                    <h1 className='text-xl font-bold'>إضافه نص</h1>
                  )}
                  {Granted === false && (
                    <h1 className='text-xl font-bold'>النصوص المتاحه</h1>
                  )}
                </div>
                <div className='flex flex-wrap gap-8 mt-10'>
                  {projectText.length > 0 && projectText.map((text, idx) => {
                    return <div className='relative w-[95%] h-64 p-1 shadow-md flex flex-col justify-start gap-2 items-start'>
                      <textarea disabled={Granted === false ? true : !text.SHOW_TO_AGENTS} id={idx.toString()} name="otherNotes" className="resize-none w-full h-full shadow-md bg-gray-100 rounded-md border-none focus:border-none focus:outline-none focus:ring-0 focus:shadow-xl " value={text.text} onChange={(e: any) => {
                        let val = e.target.value;
                        let selectedText = projectText.filter(texts => texts.ID === text.ID)[0];
                        let allTextsExceptSelected = projectText.filter(texts => texts.ID !== text.ID);

                        selectedText.text = val;

                        allTextsExceptSelected.push({ ...selectedText });

                        let sortedArr = allTextsExceptSelected.sort((a, b) => a.ID - b.ID);

                        setProjectText(sortedArr)
                      }} >
                      </textarea>
                      <div className='absolute  -top-[1rem] -left-[1rem] w-10 h-10 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                        // let removedText = projectText.filter(texts => texts.ID !== text.ID);
                        // setProjectText(removedText)
                        if (Granted === true) {
                          let selectedText = projectText.filter(texts => texts.ID === text.ID)[0];
                          let allTextsExceptSelected = projectText.filter(texts => texts.ID !== text.ID);

                          selectedText.SHOW_TO_AGENTS = !selectedText.SHOW_TO_AGENTS

                          allTextsExceptSelected.push({ ...selectedText });

                          let sortedArr = allTextsExceptSelected.sort((a, b) => a.ID - b.ID);

                          setProjectText(sortedArr)
                        }
                      }} >
                        {Granted === true && (
                          <>
                            {!text.SHOW_TO_AGENTS && (
                              <div className='i-material-symbols-close-rounded text-3xl text-red-500'>
                              </div>
                            )}
                            {text.SHOW_TO_AGENTS && (
                              <div className='i-material-symbols-check-small-rounded text-3xl text-green-500'>
                              </div>
                            )}
                          </>
                        )}
                        {Granted === false && (
                          <>
                            <div className='i-material-symbols-copy-all-rounded text-3xl text-green-500' onClick={async () => {
                              await CopyText(text.text)
                              dispatch(AddAction({ message: "تم نسخ النص", status: "warning" }))
                            }}>
                            </div>
                          </>
                        )}

                      </div>
                    </div>
                  })}
                </div>

                {Granted === true && (
                  <div className="h-[3em] w-[10em] self-end">
                    <EgButton HandleClick={sendToServer}>
                      <h3>حفظ</h3>
                    </EgButton>
                  </div>
                )}
                <div className='absolute top-0 left-0 i-material-symbols-close-rounded text-3xl hover:cursor-pointer' onClick={() => {
                  ProjectRef.current?.classList.remove(projStyle.LeftIsZero)
                  ProjectRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                }}>
                </div>
              </div>
            )}
            {((ViewId === EViewList.Project_Edit || ViewId === EViewList.Project_New)) && (
              <div className='w-full h-full relative flex flex-col'>
                {Granted === true && (
                  <div className='flex flex-wrap gap-10'>
                    <div className='grow h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4'>
                      <label htmlFor="thumpImgs">
                        <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                        </div>
                      </label>
                      <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="image/*" id="thumpImgs" onChange={onThumpNailImageChange} />
                      <h3>إضافه صور توضيحيه</h3>
                    </div>
                    <div className='grow h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4'>
                      <label htmlFor="galleryImgs">
                        <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                        </div>
                      </label>
                      <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="image/*" id="galleryImgs" multiple onChange={onGalleryImageChange} />
                      <h3>إضافه الى معرض الصور</h3>
                    </div>
                    <div className='grow h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4'>
                      <label htmlFor="LayoutImgs">
                        <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                        </div>
                      </label>
                      <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="image/*" id="LayoutImgs" multiple onChange={onLayoutImageChange} />
                      <h3>إضافه رسومات المخطط العام</h3>
                    </div>
                    <div className='grow h-52 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4'>
                      <label htmlFor="OtherFiles">
                        <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                        </div>
                      </label>
                      <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="*/*" id="OtherFiles" onChange={onFileAdded} />
                      <h3>إضافه ملفات اخرى</h3>
                    </div>
                  </div>
                )}

                {!Granted && (
                  <h1 className='text-2xl font-bold'>معرض الصور</h1>
                )}
                <div className='flex flex-wrap gap-5'>
                  <div className='flex flex-wrap gap-8 mt-10'>
                    {projectimgs.length > 0 && projectimgs.map(img => {
                      return <div key={img.ID} className='relative w-32 h-40 bg-white p-1 shadow-md rounded-lg flex flex-col justify-start gap-2 items-center'>
                        <div className='w-4/5 h-4/5 rounded object-cover overflow-hidden bg-gray-100'>
                          {img.base64 && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.base64} alt={img.name} />
                            </div>
                          )}
                          {img.url && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.url} alt={img.name} />
                            </div>
                          )}
                        </div>
                        <div dir="rtl" className='w-4/5 text-right'>
                          <a href={img.DOWNLOAD_LINK}>
                            <h6 className='truncate' >تحميل</h6>
                          </a>
                        </div>
                        <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                          if (Granted === true) {
                            if (projectimgs.length > 0) {
                              let selectedImg = projectimgs.filter(imgs => imgs.ID === img.ID)[0];
                              let allimgsExceptSelected = projectimgs.filter(imgs => imgs.ID !== img.ID);

                              //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                              //allimgsExceptSelected.push({ ...selectedImg });

                              let sortedArr = allimgsExceptSelected.sort((a, b) => a.ID - b.ID);
                              // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                              setProjectimgs(sortedArr)
                            }
                            if (selectedProject.PROJECT_IMAGES.length > 0) {
                              let selectedImg = selectedProject.PROJECT_IMAGES.filter(imgs => imgs.DOWNLOAD_LINK! === img.DOWNLOAD_LINK)[0];
                              let allimgsExceptSelected = selectedProject.PROJECT_IMAGES.filter(imgs => imgs.DOWNLOAD_LINK! !== img.DOWNLOAD_LINK);

                              //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                              //allimgsExceptSelected.push({ ...selectedImg });

                              let sortedArr = allimgsExceptSelected.sort((a, b) => a.PROJECT_ID! - b.PROJECT_ID!);
                              // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                              setSelectedProject(prev => ({ ...prev, PROJECT_IMAGES: sortedArr }))
                            }


                          }

                        }} >
                          {Granted === true && (
                            <>
                              <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    })}
                  </div>
                  {/* thumpnail Images */}
                  <div className='flex flex-wrap gap-8 mt-10'>
                    {projectThumpimgs.length > 0 && projectThumpimgs.map(img => {
                      return <div key={img.ID} className='relative w-32 h-40 bg-white p-1 shadow-md rounded-lg flex flex-col justify-start gap-2 items-center'>
                        <div className='w-4/5 h-4/5 rounded object-cover overflow-hidden bg-gray-100'>
                          {img.base64 && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.base64} alt={img.name} />
                            </div>
                          )}
                          {img.url && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.url} alt={img.name} />
                            </div>

                          )}
                        </div>
                        <div dir="rtl" className='w-4/5 text-right'>
                          <a href={img.DOWNLOAD_LINK}>
                            <h1 className='truncate' >تحميل</h1>
                          </a>
                        </div>
                        <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-50 hover:bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                          if (Granted === true) {
                            let selectedImg = projectThumpimgs.filter(imgs => imgs.ID === img.ID)[0];
                            let allimgsExceptSelected = projectThumpimgs.filter(imgs => imgs.ID !== img.ID);

                            //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                            //allimgsExceptSelected.push({ ...selectedImg });

                            let sortedArr = allimgsExceptSelected.sort((a, b) => a.ID - b.ID);
                            // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                            changeprojectThumpimgs(sortedArr)
                          }

                        }} >
                          {Granted === true && (
                            <>
                              <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    })}
                  </div>
                  {/* Gallery Images */}
                  <div className='flex flex-wrap gap-8 mt-10'>
                    {projectGalleryimgs.length > 0 && projectGalleryimgs.map(img => {
                      return <div key={img.ID} className='relative w-32 h-40 bg-white p-1 shadow-md rounded-lg flex flex-col justify-start gap-2 items-center'>
                        <div className='w-4/5 h-4/5 rounded object-cover overflow-hidden bg-gray-100'>
                          {img.base64 && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.base64} alt={img.name} />
                            </div>
                          )}
                          {img.url && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.url} alt={img.name} />
                            </div>
                          )}
                        </div>
                        <div dir="rtl" className='w-4/5 text-right'>
                          <a href={img.DOWNLOAD_LINK}>
                            <h1 className='truncate' >تحميل</h1>
                          </a>
                        </div>
                        <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                          if (Granted === true) {
                            let selectedImg = projectGalleryimgs.filter(imgs => imgs.ID === img.ID)[0];
                            let allimgsExceptSelected = projectGalleryimgs.filter(imgs => imgs.ID !== img.ID);

                            //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                            //allimgsExceptSelected.push({ ...selectedImg });

                            let sortedArr = allimgsExceptSelected.sort((a, b) => a.ID - b.ID);
                            // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                            changeprojectGalleryimgs(sortedArr)
                          }

                        }} >
                          {Granted === true && (
                            <>
                              <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                              </div>
                            </>
                          )}

                        </div>
                      </div>
                    })}
                  </div>
                  {/* Layout Images */}
                  <div className='flex flex-wrap gap-8 mt-10'>
                    {projectLayoutimgs.length > 0 && projectLayoutimgs.map(img => {
                      return <div key={img.ID} className='relative w-32 h-40 bg-white p-1 shadow-md rounded-lg flex flex-col justify-start gap-2 items-center'>
                        <div className='w-4/5 h-4/5 rounded object-cover overflow-hidden bg-gray-100'>
                          {img.base64 && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.base64} alt={img.name} />
                            </div>
                          )}
                          {img.url && (
                            <div className='bg-center w-full h-full'>
                              <img className='w-full h-full object-cover' src={img.url} alt={img.name} />
                            </div>
                          )}
                        </div>
                        <div dir="rtl" className='w-4/5 text-right'>
                          <a href={img.DOWNLOAD_LINK}>
                            <h1 className='truncate' >تحميل</h1>
                          </a>
                        </div>
                        <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                          if (Granted === true) {
                            let selectedImg = projectLayoutimgs.filter(imgs => imgs.ID === img.ID)[0];
                            let allimgsExceptSelected = projectLayoutimgs.filter(imgs => imgs.ID !== img.ID);

                            //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                            //allimgsExceptSelected.push({ ...selectedImg });

                            let sortedArr = allimgsExceptSelected.sort((a, b) => a.ID - b.ID);
                            // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                            changeprojectLayoutimgs(sortedArr)
                          }

                        }} >
                          {Granted === true && (
                            <>
                              <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                              </div>
                            </>
                          )}
                          {Granted === false && (
                            <>
                              <a href={img.DOWNLOAD_LINK}>
                                <div className='i-material-symbols-download text-xl text-green-500'>
                                </div>
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    })}
                  </div>
                  {/* Other Files */}
                  <div className='flex flex-wrap gap-8 mt-10'>
                    {projectFiles && projectFiles.length > 0 && projectFiles.map(img => {
                      return <div key={img.ID} className='relative w-32 h-40 bg-white p-1 shadow-md rounded-lg flex flex-col justify-start gap-2 items-center'>
                        <div className='w-4/5 h-4/5 rounded object-cover overflow-hidden bg-gray-100'>
                          {/* {img.base64 && (
                          <img src={img.base64} alt={img.name} />
                        )}
                        {img.url && (
                          <img src={img.url} alt={img.name} />
                        )} */}
                          <LogoFiles />
                        </div>
                        <div className='w-full' dir='ltr'>
                          <h1 className='truncate'>{img.name}</h1>
                        </div>
                        <div dir="rtl" className='w-4/5 text-right'>
                          <a href={img.DOWNLOAD_LINK}>
                            <h1 className='truncate' >تحميل</h1>
                          </a>
                        </div>
                        <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                          if (Granted === true) {
                            if (projectFiles.length > 0) {
                              let allFilesExceptSelected = projectFiles.filter(imgs => imgs.ID !== img.ID);

                              //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                              //allimgsExceptSelected.push({ ...selectedImg });

                              let sortedArr = allFilesExceptSelected.sort((a, b) => a.ID - b.ID);
                              // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                              changeprojectFiles(sortedArr)
                            }

                            if (selectedProject.ATTACHMENTS && selectedProject.ATTACHMENTS?.length > 0) {
                              let allFilesExceptSelected = selectedProject.ATTACHMENTS.filter(files => files.ID !== img.ID);

                              //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                              //allimgsExceptSelected.push({ ...selectedImg });

                              let sortedArr = allFilesExceptSelected.sort((a, b) => a.ID! - b.ID!);
                              // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                              setSelectedProject(prev => ({ ...prev, ATTACHMENTS: sortedArr }))
                            }
                          }

                        }} >
                          {Granted === true && (
                            <>
                              <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                              </div>
                            </>
                          )}
                          {Granted === false && (
                            <>
                              <a href={img.DOWNLOAD_LINK}>
                                <div className='i-material-symbols-download text-xl text-green-500'>
                                </div>
                              </a>
                            </>
                          )}
                        </div>
                      </div>
                    })}
                  </div>
                </div>
                {Granted === true && (
                  <div className='mt-5'>
                    <div className='flex flex-wrap gap-5'>
                      {/* اسم المطور */}
                      <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <label htmlFor="DeveloperName">مطور المشروع</label>
                        <div>
                          <DropTextField data={developersDD} initalValue={Req.DEVELOPMENT_COMPANY?.ID ?? developersDD[0].id} onchange={(e) => {
                            let val = e.target.value
                            let convertedVal = parseInt(val)
                            changeReq(prev => ({ ...prev, DEVELOPMENT_COMPANY_ID: convertedVal }))
                          }} />
                        </div>
                      </div>
                      {/* العموله */}
                      <div className='w-full md:w-[10%] flex flex-col gap-1 my-2'>
                        <label htmlFor="DeveloeprCommision">عموله المطور (نسبه مؤيه)</label>
                        <div>
                          <CustomTextFieldFullHeight height={25} value={commissionString} id='DeveloeprCommision' type={''} onChange={(e: any) => {
                            let val = e.target.value as string;

                            if (val.match(DigitValidate)) {
                              setCommissionString(val)
                            }
                          }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                        </div>
                      </div>
                      {/* الحافز */}
                      <div className='w-full md:w-[10%] flex flex-col gap-1 my-2'>
                        <label htmlFor="DeveloperIncentive">حافز المطور (نسبه مؤيه)</label>
                        <div>
                          <CustomTextFieldFullHeight height={25} value={Req.COMPANY_INCENTIVE?.toString()} id='DeveloperIncentive' type={''} onChange={(e: any) => {
                           let val = e.target.value as string;
                            if (val.match(DigitValidate)) {
                              setIncentiveString(val)
                            }
                          }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-wrap gap-5'>
                      <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <label htmlFor="FullName">اسم المشروع</label>
                        <div>
                          <CustomTextFieldFullHeight height={25} value={Req.PROJECT_NAME} id='PROJECT_NAME' type={''} onChange={(e: any) => {
                            let val = e.target.value as string;
                            changeReq(prev => ({ ...prev, PROJECT_NAME: val }))
                            if (val.length > 0) {
                              setInputsValidators(prev => ({ ...prev, Name: Input_Error_State.Success }))
                            } else {
                              setInputsValidators(prev => ({ ...prev, Name: Input_Error_State.Fail }))
                            }
                          }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                          {inputsValidator.PROJECT_NAME === Input_Error_State.Fail && (
                            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
                          )}
                          {(inputsValidator.PROJECT_NAME === Input_Error_State.Success || inputsValidator.PROJECT_NAME === Input_Error_State.Initial) && (
                            <h6 className='font-bold text-green-600'>&nbsp;</h6>
                          )}
                        </div>
                      </div>
                      <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <label htmlFor="PROJECT_NAME_EN">اسم المشروع بالغه الإنجليزيه</label>
                        <div>
                          <CustomTextFieldFullHeight height={25} value={Req.PROJECT_NAME_EN} id='PROJECT_NAME_EN' type={''} onChange={(e: any) => {
                            let val = e.target.value as string;
                            changeReq(prev => ({ ...prev, PROJECT_NAME_EN: val }))
                            if (val.length > 0) {
                              setInputsValidators(prev => ({ ...prev, PROJECT_NAME_EN: Input_Error_State.Success }))
                            } else {
                              setInputsValidators(prev => ({ ...prev, PROJECT_NAME_EN: Input_Error_State.Fail }))
                            }
                          }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                          {inputsValidator.PROJECT_NAME_EN === Input_Error_State.Fail && (
                            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
                          )}
                          {(inputsValidator.PROJECT_NAME_EN === Input_Error_State.Success || inputsValidator.PROJECT_NAME_EN === Input_Error_State.Initial) && (
                            <h6 className='font-bold text-green-600'>&nbsp;</h6>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-wrap gap-5'>
                      <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <label htmlFor="DESCRIPTION">وصف المشروع</label>
                        <div>
                          <CustomTextFieldFullHeight height={25} value={Req.DESCRIPTION} id='DESCRIPTION' type={''} onChange={(e: any) => {
                            let val = e.target.value as string;
                            changeReq(prev => ({ ...prev, DESCRIPTION: val }))
                            if (val.length > 0) {
                              setInputsValidators(prev => ({ ...prev, DESCRIPTION: Input_Error_State.Success }))
                            } else {
                              setInputsValidators(prev => ({ ...prev, DESCRIPTION: Input_Error_State.Fail }))
                            }
                          }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                          {inputsValidator.DESCRIPTION === Input_Error_State.Fail && (
                            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
                          )}
                          {(inputsValidator.DESCRIPTION === Input_Error_State.Success || inputsValidator.DESCRIPTION === Input_Error_State.Initial) && (
                            <h6 className='font-bold text-green-600'>&nbsp;</h6>
                          )}
                        </div>
                      </div>
                      <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <label htmlFor="DESCRIPTION_EN">وصف المشروع بالغه الإنجليزيه</label>
                        <div>
                          <CustomTextFieldFullHeight height={25} value={Req.DESCRIPTION_EN} id='DESCRIPTION_EN' type={''} onChange={(e: any) => {
                            let val = e.target.value as string;
                            changeReq(prev => ({ ...prev, DESCRIPTION_EN: val }))
                            if (val.length > 0) {
                              setInputsValidators(prev => ({ ...prev, DESCRIPTION_EN: Input_Error_State.Success }))
                            } else {
                              setInputsValidators(prev => ({ ...prev, DESCRIPTION_EN: Input_Error_State.Fail }))
                            }
                          }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                          {inputsValidator.DESCRIPTION_EN === Input_Error_State.Fail && (
                            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
                          )}
                          {(inputsValidator.DESCRIPTION_EN === Input_Error_State.Success || inputsValidator.DESCRIPTION_EN === Input_Error_State.Initial) && (
                            <h6 className='font-bold text-green-600'>&nbsp;</h6>
                          )}
                        </div>
                      </div>
                    </div>
                    {Req && Req.CITY_ID && citiesDropDown && (
                      <div className='flex flex-wrap gap-5'>
                        <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                          <div className="flex w-full items-center gap-2">
                            <label className='text-base'>المدينه</label>
                            <DropTextField initalValue={Req.CITY_ID} data={citiesDropDown} onchange={e => {
                              changeReq(prev => ({ ...prev, CITY_ID: e.target.value }))
                            }} />

                            {inputsValidator.CITY_ID === Input_Error_State.Fail && (
                              <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
                            )}
                            {(inputsValidator.CITY_ID === Input_Error_State.Success || inputsValidator.CITY_ID === Input_Error_State.Initial) && (
                              <h6 className='font-bold text-green-600'>&nbsp;</h6>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='flex flex-wrap items-center gap-2'>
                      <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer' onClick={() => {
                        PaymentRef.current?.classList.add(projStyle.PaymentLeftisZero)
                        PaymentRef.current?.classList.remove(projStyle.RemovePaymentLeftisZero)
                      }}>
                      </div>
                      <h3 className='font-bold text-xl my-2'>طرق السداد</h3>
                    </div>
                    <div className='flex flex-wrap items-center gap-2' >
                      <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer' onClick={() => {
                        let MaxIdNumber: number = 0;
                        vids.map(vid => {
                          if (vid.ID ?? 0 >= MaxIdNumber) {
                            MaxIdNumber = vid.ID ?? 0
                          }
                        })
                        changeVids(prev => [...prev, { PROJECT_ID: selectedProject.ID, LINK: "", ID: MaxIdNumber + 1 }])
                      }}>
                      </div>
                      <h3 className='font-bold text-xl my-2'>الفيديوهات</h3>
                    </div>
                    <div>
                      {vids && vids.length > 0 && vids.map((vid, idx) => {
                        return <div key={vid.ID} className='w-3/4 relative my-2'>
                          <CustomTextFieldFullHeight height={25} value={vid.LINK} id={vid.ID!.toString()} type={''} onChange={(e: any) => {
                            let value = e.target.value;
                            let selectedVid = vids.filter(video => video.ID === vid.ID)[0];
                            let allVidsExceptSelected = vids.filter(video => video.ID !== vid.ID);

                            selectedVid.LINK = value;

                            allVidsExceptSelected.push({ ...selectedVid });

                            let sortedArr = allVidsExceptSelected.sort((a, b) => a.ID! - b.ID!);

                            changeVids(sortedArr)
                          }} style={MainInputStyle}
                            colors={colors} theme={theme} />


                          <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                            if (Granted === true) {
                              if (vids.length > 0) {
                                let allVideosExceptSelected = vids.filter(vids => vids.ID !== vid.ID);

                                //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                                //allimgsExceptSelected.push({ ...selectedImg });

                                let sortedArr = allVideosExceptSelected.sort((a, b) => a.ID! - b.ID!);
                                // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                                changeVids(sortedArr)
                              }

                              if (selectedProject.YOUTUBE_VIDEOS && selectedProject.YOUTUBE_VIDEOS.length > 0) {
                                let allVideosExceptSelected = selectedProject.YOUTUBE_VIDEOS.filter(vids => vids.ID !== vid.ID);

                                //selectedImg.SHOW_TO_AGENTS = !selectedImg.SHOW_TO_AGENTS

                                //allimgsExceptSelected.push({ ...selectedImg });

                                let sortedArr = allVideosExceptSelected.sort((a, b) => a.ID! - b.ID!);
                                // let removedImg = projectimgs.filter(imgs => imgs.ID !== img.ID);
                                changeReq(prev => ({ ...prev, YOUTUBE_VIDEOS: sortedArr }))
                              }
                            }

                          }} >
                            <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                            </div>
                          </div>

                        </div>
                      })}
                    </div>
                    {/* {selectedProject.CITY_ID && citiesDropDown && (
                    <div className='flex flex-wrap gap-5'>
                      <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <div className="flex w-full items-center gap-2">
                          <label className='text-base'>المدينه</label>
                          <DropTextField initalValue={selectedProject.CITY_ID} data={[
                            ...citiesDropDown
                          ]} onchange={e => {

                            // changeReq(prev => ({ ...prev, CITY_ID: e.target.value }))
                          }} />

                          {inputsValidator.CITY_ID === Input_Error_State.Fail && (
                            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
                          )}
                          {(inputsValidator.CITY_ID === Input_Error_State.Success || inputsValidator.CITY_ID === Input_Error_State.Initial) && (
                            <h6 className='font-bold text-green-600'>&nbsp;</h6>
                          )}
                        </div>
                      </div>
                    </div>
                  )} */}
                  </div>
                )}


                {Granted === true && ViewId === EViewList.Project_Edit && (
                  <div className="h-[3em] w-[10em] self-end">
                    <EgButton HandleClick={sendNewProjectDataToServer}>
                      <h3>حفظ</h3>
                    </EgButton>
                  </div>
                )}
                {Granted === true && ViewId === EViewList.Project_New && (
                  <div className="h-[3em] w-[10em] self-end">
                    <EgButton HandleClick={createNewProject}>
                      <h3>إضافه</h3>
                    </EgButton>
                  </div>
                )}
                <div className='absolute top-0 left-0 i-material-symbols-close-rounded text-3xl hover:cursor-pointer' onClick={() => {
                  ProjectRef.current?.classList.remove(projStyle.LeftIsZero)
                  ProjectRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                }}>
                </div>
              </div>
            )}
            <div ref={PaymentRef} className={'absolute md:w-3/4 w-3/4 transition-all ease-in-out duration-[2000ms] top-0 p-2  bg-slate-100 shadow-md rounded-lg h-full overflow-auto' + projStyle.RemovePaymentLeftisZero}>
              <div className='relative w-full h-full overflow-hidden p-2'>
                <AddPaymentWays GetPaymentPlans={(data) => {
                  changeReq(prev => ({ ...prev, PROJECT_UNITS: data }))
                  PaymentRef.current?.classList.remove(projStyle.PaymentLeftisZero)
                  PaymentRef.current?.classList.add(projStyle.RemovePaymentLeftisZero)
                }} clearData={clearPaymentPlans} initialPaymentPlans={Req.PROJECT_UNITS ?? []} refEle={PaymentRef} />
                {/* <div className='absolute top-4 left-4 i-material-symbols-close-rounded text-3xl hover:cursor-pointer' onClick={() => {
                  PaymentRef.current?.classList.remove(projStyle.PaymentLeftisZero)
                  PaymentRef.current?.classList.add(projStyle.RemovePaymentLeftisZero)
                }}>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div ref={confirmRef} className={"fixed transition-all ease-in-out duration-[500ms] top-20 p-2 bg-transparent bg-opacity-95 rounded-lg w-full h-full overflow-auto " + projStyle.LeftIsZero_Remove}>
          <div>
            {selectedProject.IS_ACTIVE && selectedProject.IS_ACTIVE === true && (
              <div className='relative bg-yellow-200 w-[25em] h-[10em] p-2 rounded-md flex flex-col justify-between'>
                <h1 className='text-xl font-bold'>هل انت متأكد من إخفاء المشروع ؟</h1>
                <div className='flex flex-wrap justify-between items-center'>
                  <div className='h-[3em] w-[10em]'>
                    <EgButton HandleClick={() => {
                      confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                      confirmRef.current?.classList.remove(projStyle.LeftIsZero)
                    }}>
                      <h3>إلغاء</h3>
                    </EgButton>
                  </div>
                  <div className='h-[3em] w-[10em]'>
                    <EgButton HandleClick={async () => {
                      dispatch(setLoadingTrue());
                      SaveProject({
                        ...selectedProject, IS_ACTIVE: false
                      }).then(res => {
                        dispatch(setLoadingFalse());
                        dispatch(AddAction({ message: "تم", status: "success" }))
                        confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                        confirmRef.current?.classList.remove(projStyle.LeftIsZero)
                        changeRender(prev => !prev)
                        //return "Done";
                      }).catch(err => {
                        return GetErrorAxios(err, dispatch)
                        //return ErrMsg;
                      }).finally(() => {
                        dispatch(setLoadingFalse());
                      });
                    }}>
                      <h3>حذف</h3>
                    </EgButton>
                  </div>
                </div>
                <div className='absolute top-0 text-red-600 left-0 i-material-symbols-close-rounded text-3xl hover:cursor-pointer' onClick={() => {
                  confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                  confirmRef.current?.classList.remove(projStyle.LeftIsZero)
                }}>
                </div>
              </div>
            )}

            {selectedProject.IS_ACTIVE !== undefined && selectedProject.IS_ACTIVE === false && (
              <div className='relative bg-yellow-200 w-[25em] h-[10em] p-2 rounded-md flex flex-col justify-between'>
                <h1 className='text-xl font-bold'>هل انت متأكد من تفعيل المشروع ؟</h1>
                <div className='flex flex-wrap justify-between items-center'>
                  <div className='h-[3em] w-[10em]'>
                    <EgButton HandleClick={() => {
                      confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                      confirmRef.current?.classList.remove(projStyle.LeftIsZero)
                    }}>
                      <h3>إلغاء</h3>
                    </EgButton>
                  </div>
                  <div className='h-[3em] w-[10em]'>
                    <EgButton HandleClick={async () => {
                      dispatch(setLoadingTrue());
                      SaveProject({
                        ...selectedProject, IS_ACTIVE: true
                      }).then(res => {
                        dispatch(setLoadingFalse());
                        dispatch(AddAction({ message: "تم", status: "success" }))
                        confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                        confirmRef.current?.classList.remove(projStyle.LeftIsZero)
                        changeRender(prev => !prev)
                        //return "Done";
                      }).catch(err => {
                        return GetErrorAxios(err, dispatch)
                        //return ErrMsg;
                      }).finally(() => {
                        dispatch(setLoadingFalse());
                      });
                    }}>
                      <h3>تفعيل</h3>
                    </EgButton>
                  </div>
                </div>
                <div className='absolute top-0 text-red-600 left-0 i-material-symbols-close-rounded text-3xl hover:cursor-pointer' onClick={() => {
                  confirmRef.current?.classList.add(projStyle.LeftIsZero_Remove)
                  confirmRef.current?.classList.remove(projStyle.LeftIsZero)
                }}>
                </div>
              </div>
            )}
          </div>

        </div>
      </div>
    )
  }


  return <div className='w-full h-full flex justify-center items-center'>
    <h1 className='text-3xl font-bold'>لا يوجد حملات دعائيه</h1>
  </div>



}

export default ProjectsList


const ProjectCard = ({ confirmAction, UserPermissions, project, projectEdit, projectTemplate }: { confirmAction: (project: ProjectModel) => void, UserPermissions: PERMISSIONS[], project: ProjectModel, projectTemplate: (proj: ProjectModel) => void, projectEdit: (proj: ProjectModel) => void }) => {


  const overLayerRef = useRef<HTMLDivElement>({} as HTMLDivElement);

  useEffect(() => {
    overLayerRef.current.classList.add(projStyle.TopIsZero_Remove);
  }, [])


  return <div key={project.ID} className='relative  shadow-md h-52 rounded-md bg-no-repeat bg-cover bg-center hover:cursor-pointer' style={{ backgroundImage: "url(" + project.PROJECT_IMAGES[0].IMAGE_URL + ")" }} onMouseEnter={() => {
    overLayerRef.current.classList.add(projStyle.TopIsZero);
    overLayerRef.current.classList.remove(projStyle.TopIsZero_Remove);
  }} onMouseLeave={() => {
    overLayerRef.current.classList.remove(projStyle.TopIsZero);
    overLayerRef.current.classList.add(projStyle.TopIsZero_Remove);
  }} >
    <div className='relative overflow-hidden w-full h-full rounded-md'>
      <div ref={overLayerRef} className='transition-all roun ease-in-out duration-500 absolute left-0 w-full h-full bg-[#e7c498] bg-opacity-90 p-2'>
        <div className='w-full h-full flex flex-col gap-6'>
          <h3 className='font-bold'>{project.PROJECT_NAME}</h3>
          <div className='flex justify-center items-center gap-4'>
            <div className='flex flex-col justify-between items-center' onClick={() => {
              projectTemplate(project)
            }}>
              <h5>الحمله الدعائيه</h5>
              <div className='w-10 h-10 i-material-symbols-campaign-sharp text-[#002F3F]'>
              </div>
            </div>
            {UserPermissions.length > 0 && UserPermissions.includes(PERMISSIONS.EDIT_PROJECTS)}
            <div className='flex flex-col justify-between items-center' onClick={() => {
              projectEdit(project)
            }}>
              <h5>تعديل المشروع</h5>
              <div className='w-10 h-10 i-material-symbols-edit text-[#002F3F]'>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='absolute -top-[1rem] -left-[1rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
      if (project.IS_ACTIVE !== undefined) {
        confirmAction(project)
      }
    }} >
      {project.IS_ACTIVE && project.IS_ACTIVE === true && (
        <div className='i-material-symbols-check-small-rounded text-3xl text-green-500'>
        </div>
      )}
      {project.IS_ACTIVE !== undefined && project.IS_ACTIVE === false && (
        <div className='i-material-symbols-close-rounded text-3xl text-red-500'>
        </div>
      )}
    </div>
  </div>
}



const AddPaymentWays = ({ refEle, initialPaymentPlans, GetPaymentPlans, clearData }: { refEle: RefObject<HTMLDivElement>, initialPaymentPlans: PROJECT_UNITS[], GetPaymentPlans: (data: PROJECT_UNITS[]) => void, clearData: boolean }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };

  const PaymentData: DropDownData[] = [
    { id: 0, desc: "سكني" },
    { id: 1, desc: "تجاري" },
    { id: 2, desc: "اداري" },
    { id: 3, desc: "طبي" }
  ]


  const getPaymentTypeDesc = (id: number): string => {
    let paymentType = PaymentData.filter(pay => pay.id === id)[0];
    return paymentType.desc;
  }

  const [paymentTypes, changePaymentTypes] = useState<DropDownData[]>(PaymentData);

  // const [payments, changePayments] = useState<PROJECT_UNITS[]>([]);

  const [selectedPaymMethod, changeSelectedPaymMethod] = useState<number>(0);

  const [reqPaymentMethods, changeReqPaymentMethods] = useState<PROJECT_UNITS[]>([]);

  const dispatch = useDispatch();


  const validatePaymentInput = (): boolean => {
    let LoopPayments: boolean = true;

    if (reqPaymentMethods.length === 0) {
      dispatch(AddAction({ message: "يجب ان تضيف طريقه سداد واحده على الاقل", status: "error" }));
      return false;
    }



    for (const pay of reqPaymentMethods) {
      if (pay.AREA_FROM && pay.AREA_FROM < 10) {
        dispatch(AddAction({ message: "يجب ان تكون المساحه اكبر من 10", status: "error" }));
        LoopPayments = false;
        break;
      }

      if (pay.AREA_TO && pay.AREA_TO < pay.AREA_FROM!) {
        dispatch(AddAction({ message: "يجب ان تكون المساحه الى اكبر من المساحه من", status: "error" }));
        LoopPayments = false;
        break;
      }

      if (pay.START_PRICE && pay.START_PRICE < 1000) {
        dispatch(AddAction({ message: "يجب ان يكون سعر البدايه اكبر من 1000", status: "error" }));
        LoopPayments = false;
        break;
      }

      if (pay.END_PRICE && pay.END_PRICE < pay.START_PRICE!) {
        dispatch(AddAction({ message: "يجب ان يكون سعر النهايه اكبر من سعر البدايه", status: "error" }));
        LoopPayments = false;
        break;
      }
    }

    return LoopPayments;
  }

  useEffect(() => {
    changeReqPaymentMethods(initialPaymentPlans)
  }, [initialPaymentPlans])

  // if( clearData === true ){
  //   changeReqPaymentMethods([]);
  // }

  return <div className='w-full h-full overflow-auto'>
    <h1 className='text-2xl font-bold mb-4'>طرق السداد</h1>

    {reqPaymentMethods && reqPaymentMethods.length > 0 && reqPaymentMethods.map((payment, idx) => {

      return <div key={payment.ID} className={'relative w-full flex flex-col gap-2 my-2' + (payment.ID! % 2 === 0 ? 'bg-slate-400' : 'bg-slate-600')}>
        <h5 className='text-2xl font-bold mb-2'>{getPaymentTypeDesc(payment.UNIT_TYPE ?? 0)}</h5>
        <h4>الوصف</h4>
        <div className='w-3/4'>
          <CustomTextFieldFullHeight height={25} value={payment.ADDITIONAL_DESCRIPTION} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
            let value = e.target.value as string;
            let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
            let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
            selectedPayment.ADDITIONAL_DESCRIPTION = value;
            allPaymentsExceptSelected.push({ ...selectedPayment });
            let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
            changeReqPaymentMethods(sortedArr)
          }} style={MainInputStyle}
            colors={colors} theme={theme} />
        </div>
        <h4>ملاحظات</h4>
        <div className='w-3/4'>
          <CustomTextFieldFullHeight height={25} value={payment.NOTES} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
            let value = e.target.value as string;
            let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
            let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
            selectedPayment.NOTES = value;
            allPaymentsExceptSelected.push({ ...selectedPayment });
            let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
            changeReqPaymentMethods(sortedArr)
          }} style={MainInputStyle}
            colors={colors} theme={theme} />
        </div>
        <h4>سعر المتر</h4>
        <div className='flex flex-wrap gap-5'>
          <div className='flex flex-wrap'>
            <h5>من</h5>
            <CustomTextFieldFullHeight height={25} value={payment.START_PRICE} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
              let value: number = 0

              if (e.target.value) {
                if (!isNaN(e.target.value)) {
                  value = parseInt(e.target.value)
                } else {
                  value = payment.START_PRICE ?? 0
                }
              } else {
                value = 0
              }

              let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
              let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
              selectedPayment.START_PRICE = value;
              allPaymentsExceptSelected.push({ ...selectedPayment });
              let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
              changeReqPaymentMethods(sortedArr)
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
          <div className='flex flex-wrap'>
            <h5>الى</h5>
            <CustomTextFieldFullHeight height={25} value={payment.END_PRICE} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
              let value: number = 0

              if (e.target.value) {
                if (!isNaN(e.target.value)) {
                  value = parseInt(e.target.value)
                } else {
                  value = payment.END_PRICE ?? 0
                }
              } else {
                value = 0
              }

              let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
              let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
              selectedPayment.END_PRICE = value;
              allPaymentsExceptSelected.push({ ...selectedPayment });
              let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
              changeReqPaymentMethods(sortedArr)
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>

        </div>
        <h3>المساحات</h3>
        <div className='flex flex-wrap gap-5'>
          <div className='flex flex-wrap'>
            <h5>من</h5>
            <CustomTextFieldFullHeight height={25} value={payment.AREA_FROM} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
              let value: number = 0

              if (e.target.value) {
                if (!isNaN(e.target.value)) {
                  value = parseInt(e.target.value)
                } else {
                  value = payment.AREA_FROM ?? 0
                }
              } else {
                value = 0
              }
              let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
              let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
              selectedPayment.AREA_FROM = value;
              allPaymentsExceptSelected.push({ ...selectedPayment });
              let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
              changeReqPaymentMethods(sortedArr)
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
          <div className='flex flex-wrap'>
            <h5>الى</h5>
            <CustomTextFieldFullHeight height={25} value={payment.AREA_TO} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
              let value: number = 0

              if (e.target.value) {
                if (!isNaN(e.target.value)) {
                  value = parseInt(e.target.value)
                } else {
                  value = payment.AREA_TO ?? 0
                }
              } else {
                value = 0
              }

              let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
              let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
              selectedPayment.AREA_TO = value;
              allPaymentsExceptSelected.push({ ...selectedPayment });
              let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
              changeReqPaymentMethods(sortedArr)
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>

        </div>

        {payment.PAYMENT_STRATIGY && payment.PAYMENT_STRATIGY.length > 0 && payment.PAYMENT_STRATIGY.map((paymentData, idx) => {
          return <div key={paymentData.ID} className='w-full flex flex-col gap-2 my-2'>
            <div className='flex flex-wrap gap-4 relative'>
              <div className='flex flex-wrap'>
                <h5 className='font-bold'>المقدم (نسبه مؤيه)</h5>
                <CustomTextFieldFullHeight height={25} value={paymentData.DEPOSIT_PRECENTAGE} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
                  let value = parseInt(e.target.value);

                  if (isNaN(value)) {
                    dispatch(AddAction({ message: "يجب ان تكون النسبه المؤيه للمقدم رقم", status: "error" }));
                    return;
                  }

                  let selectedPaymetPlan = payment.PAYMENT_STRATIGY?.filter(pay => pay.ID === paymentData.ID)[0];
                  let allPaymentPlansExceptSelected = payment.PAYMENT_STRATIGY?.filter(pay => pay.ID !== paymentData.ID);
                  if (selectedPaymetPlan) selectedPaymetPlan.DEPOSIT_PRECENTAGE = value;
                  allPaymentPlansExceptSelected?.push({ ...selectedPaymetPlan });
                  let sortedArr = allPaymentPlansExceptSelected?.sort((a, b) => a.ID! - b.ID!);

                  let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
                  let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
                  selectedPayment.PAYMENT_STRATIGY = sortedArr;
                  allPaymentsExceptSelected.push({ ...selectedPayment });
                  let sortedArr2 = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
                  changeReqPaymentMethods(sortedArr2)
                }} style={MainInputStyle}
                  colors={colors} theme={theme} />
              </div>
              <div className='flex flex-wrap'>
                <h5 className='font-bold'>الأقساط (عدد سنين)</h5>
                <CustomTextFieldFullHeight height={25} value={paymentData.NO_YEAR_INSTALLMENTS} id={payment.ID!.toString()} type={''} onChange={(e: any) => {
                  let value = parseInt(e.target.value);

                  if (isNaN(value)) {
                    dispatch(AddAction({ message: "يجب ان يكون عدد السنين رقم", status: "error" }));
                    return;
                  }

                  let selectedPaymetPlan = payment.PAYMENT_STRATIGY?.filter(pay => pay.ID === paymentData.ID)[0];
                  let allPaymentPlansExceptSelected = payment.PAYMENT_STRATIGY?.filter(pay => pay.ID !== paymentData.ID);
                  if (selectedPaymetPlan) selectedPaymetPlan.NO_YEAR_INSTALLMENTS = value;
                  allPaymentPlansExceptSelected?.push({ ...selectedPaymetPlan });
                  let sortedArr = allPaymentPlansExceptSelected?.sort((a, b) => a.ID! - b.ID!);

                  let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
                  let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
                  selectedPayment.PAYMENT_STRATIGY = sortedArr;
                  allPaymentsExceptSelected.push({ ...selectedPayment });
                  let sortedArr2 = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
                  changeReqPaymentMethods(sortedArr2)
                }} style={MainInputStyle}
                  colors={colors} theme={theme} />
              </div>

              <div className='absolute top-[1.5rem] left-[20%] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
                if (payment.PAYMENT_STRATIGY && payment.PAYMENT_STRATIGY.length > 0) {
                  let allPaymentDataExceptSelected = payment.PAYMENT_STRATIGY.filter(pay => pay.ID !== paymentData.ID);
                  let sortedArr = allPaymentDataExceptSelected.sort((a, b) => a.ID! - b.ID!);

                  let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
                  let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);

                  selectedPayment.PAYMENT_STRATIGY = sortedArr;

                  allPaymentsExceptSelected.push({ ...selectedPayment });

                  let sortedArr2 = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);

                  changeReqPaymentMethods(sortedArr2);
                }
              }} >
                <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                </div>
              </div>

            </div>
          </div>
        })}
        <div className="flex w-full items-center gap-2">
          <h2>إضافه خطه دفع</h2>
          <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer' onClick={() => {
            let MaxIdNumber: number = 0;
            (payment.PAYMENT_STRATIGY ?? []).map(pay => {
              if (pay.ID ?? 0 >= MaxIdNumber) {
                MaxIdNumber = pay.ID ?? 0
              }
            })

            let selectedPayment = reqPaymentMethods.filter(pay => pay.ID === payment.ID)[0];
            let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID!);
            if (selectedPayment.PAYMENT_STRATIGY === undefined) selectedPayment.PAYMENT_STRATIGY = [];
            selectedPayment.PAYMENT_STRATIGY.push({ ID: MaxIdNumber + 1, DEPOSIT_PRECENTAGE: 0, NO_YEAR_INSTALLMENTS: 0 });
            allPaymentsExceptSelected.push({ ...selectedPayment });

            let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);

            changeReqPaymentMethods(sortedArr)

          }}>
          </div>
        </div>
        <hr className='w-[80%] h-1 mx-auto my-4 bg-gray-800 border-0 rounded md:my-10 dark:bg-gray-700' />
        <div className='flex flex-wrap'>
          <div className='absolute -top-[1rem] left-[3rem] w-8 h-8 rounded-[50%] bg-gray-200 flex items-center justify-center hover:cursor-pointer' onClick={() => {
            if (reqPaymentMethods.length > 0) {
              let allPaymentsExceptSelected = reqPaymentMethods.filter(pay => pay.ID !== payment.ID);
              let sortedArr = allPaymentsExceptSelected.sort((a, b) => a.ID! - b.ID!);
              changeReqPaymentMethods(sortedArr)
            }
          }}>
            <div className='i-material-symbols-close-rounded text-xl text-red-500'>
            </div>
          </div>
        </div>
      </div>
    })
    }

    <div className="flex flex-col flex-wrap w-full justify-around gap-4">
      <div className="flex  items-center gap-2">
        <DropTextField initalValue={PaymentData[0].id} data={paymentTypes} onchange={e => {
          changeSelectedPaymMethod(e.target.value)

        }} />
        <div className='i-material-symbols-add-box-outline-rounded text-4xl text-gray-500 hover:cursor-pointer' onClick={() => {
          let MaxIdNumber: number = 0;
          reqPaymentMethods.map(payment => {
            if (payment.ID ?? 0 >= MaxIdNumber) {
              MaxIdNumber = payment.ID ?? 0
            }
          })
          changeReqPaymentMethods(prev => [...prev, { ID: MaxIdNumber + 1, UNIT_TYPE: selectedPaymMethod, ADDITIONAL_DESCRIPTION: "", NOTES: "", START_PRICE: 0, END_PRICE: 0, AREA_FROM: 0, AREA_TO: 0, PAYMENT_STRATIGY: [] }])
        }}>
        </div>
      </div>
      <div className='flex flex-wrap justify-between px-4'>
        <div className='md:w-1/4 w-1/3 h-10'>
          <EgButton HandleClick={() => {
            refEle.current?.classList.remove(projStyle.PaymentLeftisZero)
            refEle.current?.classList.add(projStyle.RemovePaymentLeftisZero)
          }}>
            <h5>إلغاء</h5>
          </EgButton>
        </div>
        <div className='md:w-1/4 w-1/2 h-10'>
          <EgButton HandleClick={() => {
            if (validatePaymentInput()) {
              GetPaymentPlans(reqPaymentMethods);
            }
          }}>
            <h5>حفظ طرق الدفع للمشروع</h5>
          </EgButton>
        </div>
      </div>
    </div>
  </div >
}