import { configureStore } from '@reduxjs/toolkit'
import ConnectionReduce from './slices/CheckConnection'
import CitySlice from './slices/CitySlice'
import Company from './slices/Company'
import ContactReducer from './slices/ContactsSlice'
import ActionStore from './slices/ErrorsSlice'
import FeedbackSlice from './slices/Feedback'
import FounderReducer from './slices/FoundersSlice'
import JobsSlice from './slices/JobsSlice'
import language from './slices/Lang'
import LoadingReducer from './slices/LoadingSlice'
import OwnerReducer from './slices/OwnersSlice'
import permission from './slices/PermissionsSlice'
import ProjectSlice from './slices/Projects'
import TeamSlice from './slices/TeamSlice'
import UserSlice from './slices/UserSlice'
import { default as CalenderStore, default as counterReducer } from './slices/counterSlice'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    connection : ConnectionReduce, 
    loading : LoadingReducer, 
    feedback : FeedbackSlice,
    company : Company,
    Language : language,
    ProjectStore : ProjectSlice,
    CityStore : CitySlice, 
    UserStore : UserSlice, 
    PermissionStore : permission, 
    JobStore : JobsSlice, 
    ContactStore : ContactReducer, 
    FounderStore : FounderReducer, 
    OwnerStore : OwnerReducer, 
    TeamStore : TeamSlice, 
    ActionStore : ActionStore,
    CalenderStore : CalenderStore
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() 

export const useTypedSelector: TypedUseSelectorHook<
  RootState
> = useSelector;