import { UserModel } from "../UserModel/UserModel";


export type SITE_VISIT_MODEL = {
    CONTACT_ID?: number; // Assuming int64 translates to number in TypeScript
    ACTION_ID?: number;
    CUSTOMER_NAME?: string;
    CUSTOMER_MOBILE_NO?: string;
    VISIT_SITE?: number;
    SITE?: VISIT_SITE_LOCATIONS;
    VISIT_DATE?: Date; // Assuming time.Time translates to Date in TypeScript
    START_LOCATION?: string;
    STATUS?: STATUS;
    STAMP_USER?: string;
    ACTION_USER?: string;
    ACTION_DATE?: Date;
    PAID_DEPOSIT?: boolean;
    CONTACT_USER?: UserModel;
    AMOUNT?: number;
}

export enum STATUS {
    STATUS_TYPE_PENDING = 0,
    STATUS_TYPE_ACCEPTED = 1,
    STATUS_TYPE_REJECTED = 2
}

export type VISIT_SITE_LOCATIONS = {
    ID: number;
    SITE_NAME_AR: string;
    SITE_NAME_EN: string;
}