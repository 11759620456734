import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetErrorAxios } from '../../../Globals';
import { GET_ALL_DEALS_HAS_COMMISSION } from '../../../Service/Apis';
import { DEAL_MODEL } from '../../../Service/models/DealApplication';
import DevExtremTable from '../../../components/shared/EGDExtremeTable';
import { AppDispatch } from '../../../redux/store';
import './style.css';



const DealsFounders = () => {

    const dispatch = useDispatch<AppDispatch>();

    const StoredAPI = useCallback(() => {
        // getAllProjects().then(res => {
        //     setProjects(res)
        // }).catch(err => {
        //     GetErrorAxios(err, dispatch);
        // })
    }, [])

    const GETAllDealsFromAPI = () => {
        GET_ALL_DEALS_HAS_COMMISSION().then((res) => {
            // setDeals([res[0],res[1]])
            setDeals(res)
            // 
        }).catch((err) => {
            GetErrorAxios(err, dispatch)
        })
    }



    useEffect(() => {
        // hideDealModel()
        GETAllDealsFromAPI()
        StoredAPI()

    }, [])


    const [deals, setDeals] = useState<Partial<DEAL_MODEL>[]>([])


    // useEffect(() => {

    // }, [selectedID]);



    return (
        <>

            <div>
                <DevExtremTable ID="ACTION_ID" tableData={[
                    { Caption: "الفرع", Type: 'string', Value: 'STATION_NAME' },
                    { Caption: "كود الاستمارة", Type: 'string', Value: 'ACTION_ID' },
                    { Caption: "شركة التطوير", Type: 'string', Value: 'COMPANY_NAME' },
                    { Caption: "المشروع", Type: 'string', Value: 'COMPANY_PROJECT_NAME' },
                    { Caption: "تاريخ الاستمارة", Type: 'string', Value: 'APPLICATION_DATE' },
                    { Caption: "سعر الوحده", Type: 'string', Value: 'CLIENT_UNIT_PRICE' },
                    { Caption: "عمولة صندوق الشركة", Type: 'number', Value: 'COMPANY_BOX_COMMISSION_VALUE' },
                    { Caption: "عمولة صندوق المؤسسين", Type: 'number', Value: 'FOUNDER_BOX_COMMISSION_VALUE' },
                ]}
                    dataSource={deals}
                    Width={350}
                    AllowPaging={true} />
            </div>
        </>
    )
}

export default DealsFounders