import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BoxName, GetErrorAxios, HavePermission, PERMISSIONS, commafy } from '../../Globals';
import { Get_Safe_Balance, trans_all } from '../../Service/Apis';
import { CashBoxBalance, TRANSACTIONS } from '../../Service/models/TransActions_model';
import { DepositIcon, DetailsIcons, WithdrawIcon } from '../../components/Icons/Icons';
import DevExtremTable from '../../components/shared/EGDExtremeTable';
import { setLoadingFalse, setLoadingTrue } from '../../redux/slices/LoadingSlice';
import { IUserSlice, UsersState } from '../../redux/slices/UserSlice';
import { AppDispatch } from '../../redux/store';
import Deposit from './Deposit';
import TransDetails from './TransDetails';
import Withdraw from './Withdraw';
import './style.css';


type TransActions_Type = {
    id: any,
    deposit: string,
    withdraw: string,
    Trans_type: string,
    Trans_reason: string
}


const DummyData: TransActions_Type[] = [
    { id: 0, deposit: '0', Trans_reason: "test reason", Trans_type: 'test type', withdraw: '1000' },
    { id: 1, deposit: '1000', Trans_reason: "test reason1", Trans_type: 'test type1', withdraw: '0' },
    { id: 2, deposit: '5000', Trans_reason: "test reason2", Trans_type: 'test type2', withdraw: '1000' },
    { id: 3, deposit: '0', Trans_reason: "test reason3", Trans_type: 'test type3', withdraw: '6500' },
    { id: 4, deposit: '2500', Trans_reason: "test reason4", Trans_type: 'test type4', withdraw: '1000' },
    { id: 5, deposit: '0', Trans_reason: "test reason5", Trans_type: 'test type5', withdraw: '750' },
    { id: 6, deposit: '0', Trans_reason: "test reason6", Trans_type: 'test type6', withdraw: '12000' },
    { id: 7, deposit: '1300', Trans_reason: "test reason7", Trans_type: 'test type7', withdraw: '1000' },
    { id: 8, deposit: '0', Trans_reason: "test reason8", Trans_type: 'test type8', withdraw: '120000' },
    { id: 9, deposit: '600', Trans_reason: "test reason9", Trans_type: 'test type9', withdraw: '1000' },
]

const TransActions = () => {
    const UsersData : IUserSlice = useSelector(UsersState);

    const [AllTrans, setAllTrans] = useState<TRANSACTIONS[]>([]);
    const dispatch = useDispatch<AppDispatch>()
    const [Comp, setComp] = useState<JSX.Element>(() => <h1>Test</h1>)
    const [balance, setBalance] = useState<CashBoxBalance>({} as CashBoxBalance)
    const [selectedTrans, setSelectedTrans] = useState<TRANSACTIONS>({} as TRANSACTIONS)


    async function getAllTransData() {
        
        dispatch(setLoadingTrue())
        let AllTransFromApi = await trans_all()
        let SafeBalance = await Get_Safe_Balance().catch(err => {
            GetErrorAxios(err, dispatch)
        })


        if (SafeBalance !== undefined) {
            setBalance(SafeBalance)
        }

        if (AllTransFromApi !== null && AllTransFromApi.length > 0) {
            setAllTrans(AllTransFromApi)
        }


        dispatch(setLoadingFalse())
    }


    //Refs

    // Trans operations
    const TransferOperations = useRef<HTMLDivElement>(null);

    // trans details
    const TransActionsDetails = useRef<HTMLDivElement>(null);


    const hideOnStart = () => {
        TransferOperations.current?.classList.add('hideTransModal')
        TransferOperations.current?.classList.remove('showTransModal')
    }

    const showDevelopAddPopUp = () => {
        TransferOperations.current?.classList.add('showTransModal')
        TransferOperations.current?.classList.remove('hideTransModal')
    }

    const hideOnStartTransDetails = () => {
        TransActionsDetails.current?.classList.add('hideTransModal')
        TransActionsDetails.current?.classList.remove('showTransModal')
    }

    const showDevelopAddPopUpTransDetails = () => {
        TransActionsDetails.current?.classList.add('showTransModal')
        TransActionsDetails.current?.classList.remove('hideTransModal')
    }




    useEffect(() => {
        getAllTransData()
        hideOnStart()
        hideOnStartTransDetails()
    }, [])


    return (
        <>
            <div>
            {UsersData.currentUser.PERMISSIONS && HavePermission(PERMISSIONS.Finance, UsersData.currentUser.PERMISSIONS) && (
                <div className='flex gap-10'>
                    <div className='flex flex-row items-center gap-4 hover:text-[#e7c498] hover:cursor-pointer bg-[#e2edf1] rounded shadow w-32 mb-5 p-1' onClick={() => {
                        //modelFN(row);
                        // showDealModel()
                        // setCurrentAction(currentAction)
                        setComp(<Deposit />)
                        showDevelopAddPopUp()

                    }} >

                        <div className='h-8 w-8' >
                            <DepositIcon />
                        </div>
                        <h3 className='text-xs font-bold my-2'>إيداع</h3>
                    </div>
                    <div className='flex flex-row items-center gap-4 hover:text-[#e7c498] hover:cursor-pointer bg-[#e2edf1] rounded shadow w-32 mb-5 p-1' onClick={() => {
                        //modelFN(row);
                        // showDealModel()
                        // setCurrentAction(currentAction)
                        setComp(<Withdraw />)
                        showDevelopAddPopUp()

                    }} >
                        <div className='h-8 w-8' >
                            <WithdrawIcon />
                        </div>

                        <h3 className='text-xs font-bold my-2'>صرف</h3>
                    </div>
                    {/* <div className='flex flex-row items-center gap-4 hover:text-[#e7c498] hover:cursor-pointer bg-[#e2edf1] rounded shadow w-32 mb-5 p-1' onClick={() => {
                        //modelFN(row);
                        // showDealModel()
                        // setCurrentAction(currentAction)
                        setComp(<Transfer />)
                        showDevelopAddPopUp()

                    }} >
                        <div className='h-8 w-8' >
                            <MoveBoxMove />
                        </div>
                        <h3 className='text-xs font-bold my-2'>تحويل</h3>
                    </div> */}
                </div>
            )}
                {/* balance section */}
                <div className='w-full bg-slate-100 rounded shadow mb-5 p-5'>
                    {/* رصيد الخزينه */}
                    <h3>رصيد الخزنه</h3>
                    {balance.AMOUNT && (
                        <div className='flex gap-9 mt-5'>
                            {/* فئه الجنيه */}
                            <div>
                                <p>جنيه</p>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{balance.COUNT_1}</p>
                            </div>
                            {/* فئه الـ 5 جنيه */}
                            <div>
                                <p>فئه الـ 5 جنيهات</p>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{balance.COUNT_5}</p>
                            </div>
                            {/* فئه الـ 10 الجنيه */}
                            <div>
                                <p>فئه الـ 10 جنيهات</p>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{balance.COUNT_10}</p>
                            </div>
                            {/* فئه  الـ 20 الجنيه */}
                            <div>
                                <p>فئه الـ 20 جنيه</p>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{balance.COUNT_20}</p>
                            </div>
                            {/* فئه  الـ 50 الجنيه */}
                            <div>
                                <p>فئه الـ 50 جنيه</p>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{balance.COUNT_50}</p>
                            </div>
                            {/* فئه  الـ 100 الجنيه */}
                            <div>
                                <p>فئه الـ 100 جنيه</p>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{balance.COUNT_100}</p>
                            </div>
                            {/* فئه  الـ 200 الجنيه */}
                            <div>
                                <p>فئه الـ 200 جنيه</p>
                                <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{balance.COUNT_200}</p>
                            </div>

                            {/* الإجمالي */}
                            <div>
                                <p>الإجمالي</p>
                                {balance && balance.AMOUNT && (<p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }}  >  {commafy(balance.AMOUNT.toString() ?? "-99999")}</p>)}
                            </div>
                        </div>
                    )}
                    {/* رصيد الصناديق */}
                    <h3 className='mt-10'>رصيد الصناديق</h3>
                    {balance.AMOUNT && (
                        <div className='flex gap-9 mt-5'>
                            {Object.entries(balance.BoxesBalance).map((item, idx) => {
                                let boxNumber = parseInt(item[0]) ?? 0
                                let boxValue = item[1].toString() ?? "-99999"
                                return <div>
                                    <p>{BoxName(boxNumber)}</p>
                                    <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, marginTop: 3 }} >{commafy(boxValue)}</p>
                                </div>
                            })}
                        </div>
                    )}
                </div>
                <DevExtremTable ID="ID" tableData={[
                    { Caption: "رقم مرجعي", Type: 'string', Value: 'ID' },
                    { Caption: "دائن", Type: 'string', Value: 'DEPOSIT_AMT' },
                    { Caption: "مدين", Type: 'string', Value: 'WITHDRAW_AMT' },
                    { Caption: "نوع الحركه", Type: 'string', Value: 'TRANSACTION_TYPE_DESC' },
                    { Caption: "السبب", Type: 'string', Value: 'NOTES' },
                ]}
                    dataSource={AllTrans}
                    Actions={(data) => {
                        let currentContact = data.data as TRANSACTIONS;
                        return (
                            <div className=" flex items-center gap-4 hover:cursor-pointer  p-1">
                                <div className='flex flex-col items-center hover:text-[#e7c498]' onClick={() => {
                                    showDevelopAddPopUpTransDetails()
                                    setSelectedTrans(currentContact)
                                }}>
                                    <div className='bg-[#e2edf1] rounded shadow h-6 w-6 p-1 hover:cursor-pointer' >
                                        <DetailsIcons />
                                    </div>
                                    <p className='text-sm font-bold'>تفاصيل</p>
                                </div>

                            </div>
                        )
                    }}
                />


                <div ref={TransferOperations} className={"absolute  w-3/4 transition-all ease-in-out duration-[1000ms] top-0 p-2 bg-slate-100 shadow-md rounded-lg z-[100] overflow-auto"}>
                    <div className='absolute top-5 left-5 i-material-symbols-close-rounded text-3xl hover:cursor-pointer bg-red-400' onClick={() => {
                        hideOnStart()
                    }}>
                    </div>
                    <div className='p-10'>
                        {Comp}
                    </div>
                </div>
                <div ref={TransActionsDetails} className={"absolute  w-3/4 transition-all ease-in-out duration-[1000ms] top-0 p-2  bg-slate-100 shadow-md rounded-lg  z-[100] overflow-auto"}>
                    <div className='absolute top-5 left-5 i-material-symbols-close-rounded text-3xl hover:cursor-pointer bg-red-400' onClick={() => {
                        hideOnStartTransDetails()
                    }}>
                    </div>

                    <div className='p-10'>
                        <TransDetails Trans={selectedTrans} />
                    </div>

                </div>
            </div>
        </>
    )
}

export default TransActions