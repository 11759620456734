import React, { useEffect } from 'react'
import { commafy } from '../../../Globals'

function Equality({ depositAmount, dealAmount, RejectFunction, AcceptFunction }: { AcceptFunction: () => void, RejectFunction: () => void, depositAmount: string, dealAmount: string }) {



    let ConvertedDealAmount = parseFloat(dealAmount)
    let ConvertedDepositAmount = parseFloat(depositAmount)

    let DealAmountSeperated = commafy(ConvertedDealAmount.toString())
    let DepositAmountSeperated = commafy(ConvertedDepositAmount.toString())

    useEffect(() => {
        if (isNaN(parseFloat(dealAmount))) {
            dealAmount = "0"
        }

        if (isNaN(parseFloat(depositAmount))) {
            depositAmount = "0"
        }

        ConvertedDealAmount = parseFloat(dealAmount)
        ConvertedDepositAmount = parseFloat(depositAmount)

         DealAmountSeperated = commafy(ConvertedDealAmount.toString())
         DepositAmountSeperated = commafy(ConvertedDepositAmount.toString())
    

    }, [depositAmount, dealAmount])

    return (
        <div className='w-4/6 bg-slate-100 rounded-md shadow flex flex-col overflow-hidden p-5'>
            <div className='grow'>
                <h4 className='font-bold'><span className='text-red-500'>هل انت متأكد من انك تريد الإيداع ؟</span>  قيمه الصفقه غير مطابقه لقيمه الإيداع , قيمه الصفقه {DealAmountSeperated} و قيمه الإيداع {DepositAmountSeperated} </h4>
            </div>
            <div className='h-2/5'>
                <div className='flex justify-around'>
                    <div>
                        <button className='bg-red-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                            RejectFunction()
                        }}>لا</button>
                    </div>
                    <div>
                        <button className='bg-green-500 text-white px-4 py-2 rounded-lg hover:cursor-pointer w-28' onClick={() => {
                            AcceptFunction()
                        }}>إيداع</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Equality