import React, { useEffect, useRef } from 'react'
import Project from '../Projects/Projects';
import './EGSlider.css'
import nileView from './../../../Assets/images/Hero/NileViewImage.png'
import { ProjectModel } from '../../../Service/models/projects/ProjectModel';
import { Box } from '@mui/material';
import { EGNavButtonRegister, NavBarText } from '../../../CustomComps/Customs';
import { LeftArrow,RightArrow } from '../../Icons/Icons';



const EGSlider = ({ data, speed }: { data: ProjectModel[], speed: number }) => {

  const sliderRef = useRef<HTMLDivElement>(null);
  const childRefs = useRef<any[]>([]);
  const lefPos = useRef<number>(0);
  const miniSlider = useRef<HTMLDivElement>(null);
  const mainSliderContainer = useRef<HTMLDivElement>(null);
  const arraYmodifier = useRef<any[]>(data);



  useEffect(() => {

    if (miniSlider.current && mainSliderContainer.current) {
      let allContainerWidth = mainSliderContainer.current.offsetWidth;
      let leftValue = miniSlider.current.offsetLeft / 16;
      let currentDistance = allContainerWidth - miniSlider.current.offsetLeft
    }
    // 


    // childRefs.current.map((ele, idx) => {
    //   let newEle = ele as HTMLDivElement;
    //   
    // });

    if (sliderRef.current) {

      sliderRef.current.onmouseenter = () => {
        sliderRef.current?.classList.add('pauseAnimate')
      }
      sliderRef.current.onmouseleave = () => {
        sliderRef.current?.classList.remove('pauseAnimate')
      }
    }
  }, [])

  if(!data){
    return <h1>No data in data</h1>
  }

  return (
    <div className='w-full h-52 relative' dir='ltr'>
      <div className='w-full h-full absolute left-0 top-0 z-50 flex flex-row justify-between'>
        <div className='w-10 bg-[#000] bg-opacity-70 rounded h-full hover:cursor-pointer flex justify-center items-center' onClick={() => {
              let totalEleWidt = 0
              childRefs.current.map((ele, idx) => {
                let element = ele as HTMLDivElement;
                totalEleWidt += element.offsetWidth;
              })

              totalEleWidt += childRefs.current.length * 16
              if (miniSlider.current && mainSliderContainer.current) {
                if (miniSlider.current.offsetLeft >= 0) {
                  miniSlider.current.style.left = "-" + (totalEleWidt - mainSliderContainer.current.offsetWidth) + 'px';
                  return;
                }
                let leftValue = miniSlider.current.offsetLeft / 16;
                miniSlider.current.style.left = leftValue + 10 + 'rem';
              }
            }}>
              <div className='w-10 p-2 h-10'>
                <LeftArrow/>
              </div>
        </div>
        <div>
          <div className='w-10 bg-[#000] bg-opacity-70 rounded h-full hover:cursor-pointer flex justify-center items-center' onClick={() => {
              let totalEleWidt = 0
              childRefs.current.map((ele, idx) => {
                let element = ele as HTMLDivElement;
                totalEleWidt += element.offsetWidth;
              })

              totalEleWidt += childRefs.current.length * 16
              if (miniSlider.current && mainSliderContainer.current) {
                if (Math.abs(miniSlider.current.offsetLeft) > (totalEleWidt - mainSliderContainer.current.offsetWidth)) {
                  miniSlider.current.style.left = 0 + 'px';
                  return;
                }
                let leftValue = miniSlider.current.offsetLeft / 16;
                miniSlider.current.style.left = leftValue - 10 + 'rem';
              }
            }}>
              <div className='w-10 p-2 h-10'>
                <RightArrow />
              </div>
          </div>
        </div>
      </div>
      <div ref={mainSliderContainer} className="w-full h-full grow  absolute left-0 top-0 flex flex-row-reverse gap-2">
        <div className='w-full h-52 bg-white rounded overflow-hidden relative'>
          <div ref={miniSlider} className='w-full h-full flex flex-row gap-2 p-2 absolute left-0 clickabelSLider items-center'>
            {data && data.length > 0 && data.map((project, idx) => {
              return (
                <div ref={el => childRefs.current[idx] = el} className="flex justify-center shrink-0 items-start h-[10rem] w-[10rem]">
                  <Project projectData={project} />
                </div>
              );
            })}

          </div>
        </div>
      </div>
    </div>
  )
}

export default  EGSlider


{/* <Box sx={{ float: 'right' }}>
<EGNavButtonRegister onClick={() => {
  if (miniSlider.current) {
    let leftValue = miniSlider.current.offsetLeft;
    miniSlider.current.style.left = leftValue + 1 + 'rem';
  }
}}>
  <NavBarText>حرك يمين</NavBarText>
</EGNavButtonRegister>
</Box> */}