import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { GetError } from '../../Globals';
import { GetCitiesAPI, SaveCity } from '../../Service/Apis';
import { CityModel } from '../../Service/models/CityModel/CIty';
import { RootState } from '../store';
import { AddAction } from './ErrorsSlice';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';



export interface ICity {
    status: CityModel[],
    Error: string,
    Loading: boolean,
    Empty: string
}

const initialState: ICity = {
    status: [],
    Error: "",
    Loading: false,
    Empty: ''
}

export const CitySlice = createSlice({
    name: 'CitySliceId',
    initialState,
    reducers: {
        cities: (state, action: PayloadAction<CityModel[]>) => {
            state.status = action.payload as CityModel[]
        },

    },
    extraReducers: (builder) => {

        // When we send a request,
        // `fetchTodos.pending` is being fired:
        builder.addCase(SafeCityRemotely.pending, (state) => {
            // At that moment,
            // we change status to `loading` 
            // and clear all the previous errors:
            // state.status = "loading";
            // state.error = null;
        });

        // When a server responses with the data,
        // `fetchTodos.fulfilled` is fired:
        builder.addCase(SafeCityRemotely.fulfilled,
            (state, { payload }) => {
                // We add all the new todos into the state
                // and change `status` back to `idle`:
                // state.list.push(...payload);
                // state.status = "idle";
                //state.status = payload as CityModel;
            });

        // When a server responses with an error:
        builder.addCase(SafeCityRemotely.rejected,
            (state, { payload }) => {
                // We show the error message
                // and change `status` back to `idle` again.
                // if (payload) state.error = payload.message;
                // state.status = "idle";

            });
        builder.addCase(GetAllCitiesThunk.fulfilled,
            (state, { payload }) => {
                // We show the error message
                // and change `status` back to `idle` again.
                // if (payload) state.error = payload.message;
                state.status = payload as CityModel[] ;

            });

    }
})

type FetchTodosError = {
    message: string;
};

export const SafeCityRemotely = createAsyncThunk<string, CityModel>(
    // The first argument is the action name:
    "City/Save",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (city, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return SaveCity(city).then(res => {
            thunkApi.dispatch(setLoadingFalse());
            thunkApi.dispatch(AddAction({ message: "تم بنجاح", status: "success" }))
            // thunkApi.dispatch(AddAction({ message: "تم بنجاح", status: "success" }))
            return "Done";
        }).catch(err => {
            return  GetError(err,thunkApi) 
        });
    }
);

export const GetAllCitiesThunk = createAsyncThunk<CityModel[] | string>(
    // The first argument is the action name:
    "City/GetAllCities",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (city, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return GetCitiesAPI().then(res => {
            thunkApi.dispatch(setLoadingFalse());
            return res
        }).catch(err => {
           return  GetError(err,thunkApi) 
        });
    }
);


export const citiesState = (state: RootState) => state.CityStore.status;
export const { cities } = CitySlice.actions

export default CitySlice.reducer