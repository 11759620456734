import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react'
import Building from './comps/Building/Building';
import City from './comps/City/City';
import ProjectComp from './comps/ProjectComp/ProjectComp';
import Unit from './comps/Unit/Unit';

const ProjectScreen = () => {
    const [projectType, setProjectType] = React.useState<string>("0");

    const handleChange = (event: SelectChangeEvent) => {
        
        setProjectType(event.target.value);
    };


    return (
        <div className='w-full'>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                    value={projectType}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={"0"}>
                        <em>نوع المشروع</em>
                    </MenuItem>
                    <MenuItem value={"city"}>مدن</MenuItem>
                    <MenuItem value={"project"}>مشاريع</MenuItem>
                    <MenuItem value={"building"}>مباني</MenuItem>
                    <MenuItem value={"unit"}>وحدات</MenuItem>
                </Select>
                {/* <FormHelperText>Without label</FormHelperText> */}
            </FormControl>
            <div className='w-full h-full p-5'>
                {projectType === "0" && (
                    <div className='w-full flex flex-col justify-center items-center'>
                        <h1 className='text-7xl text-gray-400'>Please Select Project Type</h1>
                    </div>
                )}
                {projectType === "city" && (
                    <div className='w-full'>
                        <City/>
                    </div>
                )}
                {projectType === "project" && (
                    <div className='w-ful'>
                        <ProjectComp/>
                    </div>
                )}
                {projectType === "building" && (
                    <div className='w-full h-full'>
                        <Building/>
                    </div>
                )}
                {projectType === "unit" && (
                    <div className='w-full h-full'>
                        <Unit/>
                    </div>
                )}
            </div>
        </div>

    )
}

export default ProjectScreen