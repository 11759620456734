import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import Modal from '@mui/material/Modal/Modal';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography/Typography';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Tree, { Point } from 'react-d3-tree';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { THETEAM } from '../../../components/Icons/Icons';
import { DropDownType } from '../../../components/shared/EGDropDown/EGDropDOwn';
import { CustomTextField } from '../../../CustomComps/Customs';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { showFeedBack } from '../../../redux/slices/Feedback';
import { GetAllJobsThunk } from '../../../redux/slices/JobsSlice';
import { GetAllUserChildrenThunk } from '../../../redux/slices/TeamSlice';
import { UsersState } from '../../../redux/slices/UserSlice';
import { AppDispatch } from '../../../redux/store';
import { GET_ALL_STATIONS, GetAllChildsAPI, InvitePerson, SaveUserAPI } from '../../../Service/Apis';
import { JobsModel } from '../../../Service/models/Jobs/Jobs';
import { TeamModel } from '../../../Service/models/TeamModel/TeamModel';
import { UserModel } from '../../../Service/models/UserModel/UserModel';
import { tokens } from '../../../theme';
import { EgButton } from '../../Shared/Shared';
import './Team.css'
import { DropDownData, DropTextField } from '../Actions/ActionsTypesScreen/AppointMents';
import { GetErrorAxios } from '../../../Globals';

type ITeamInvite = {
    Email: string,
    MobileNumber?: string,
    JobId: number
}

interface RawNodeDatum {
    name: string;
    attributes?: Record<string, string | number | boolean>;
    children?: RawNodeDatum[];
    NO_CHILDS?: number
}



const Team = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [treeData, setTreeData] = useState<RawNodeDatum>();
    const [allTreeBranches, setallTreeBranches] = useState<TeamModel[]>([]);
    const [Stations, setStations] = useState<DropDownData[]>([]);

    const dispatch = useDispatch<AppDispatch>();

    const [dimensions, translate, containerRef] = useCenteredTree();
    const [JobsDropDownData, seJobstDropDOwnData] = useState<DropDownType[]>([]);

    const nodeSize = { x: 160, y: 350 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -nodeSize.x / 2, y: -nodeSize.y / 2 };



    const ConvertAttribute = (Data: any): Record<string, string | number | boolean> => {
        let newRecord: Record<string, string | number | boolean> = {};
        Object.entries(Data).map(field => {
            if (field[1] !== null && typeof field[1] == "string" || typeof field[1] == "number" || typeof field[1] == 'boolean') {
                newRecord[field[0]] = field[1];
            }
        })
        return newRecord;
    }


    const getAllTreeData = () => {
        GetAllChildsAPI().then(res => {
            let data = res.sort((a, b) => a.STATION_ID! - b.STATION_ID!);
            setallTreeBranches(data);
            let dropDownData: DropDownData[] = data.map((stat) => ({ id: stat.STATION_ID!, desc: stat.STATION_NAME! }));
            setStations(dropDownData)

            let treeData = data[0];
            setTreeData({ name: treeData.name, attributes: treeData.attributes, children: treeData.children as RawNodeDatum[], NO_CHILDS: treeData.NO_CHILDS })
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }

    useEffect(() => {

        getAllTreeData();

        dispatch(GetAllUserChildrenThunk()).then(async res => {
            if (res.meta.requestStatus === 'fulfilled') {
                let teamTreeData = res.payload as TeamModel;
                //let dataTree = ConvertAllTree(teamTreeData);
                //
                
                // setTreeData({ name: teamTreeData.name, attributes: teamTreeData.attributes, children: teamTreeData.children as RawNodeDatum[], NO_CHILDS: teamTreeData.NO_CHILDS });
            }
        });
    }, [])

    useEffect(() => {
        // 
    }, [treeData])


    const NodeClickFunc = (nodeDatum: any) => {
        window.alert(
            nodeDatum.children ? "Clicked a branch node" : "Clicked a leaf node."
        );


    };


    return (
        <div className='shadow-md flex flex-col' ref={containerRef as any} id="treeWrapper" >
            <div className='w-full gap-2 flex justify-between items-end mb-4'>
                <div className='w-1/4'>
                    <h5 className='mb-2'>فرع الشركه</h5>
                    {Stations && Stations.length > 0 && (
                        <DropTextField initalValue={Stations[0].id} classes='w-3/4 h-full bg-white' data={[
                            ...Stations
                        ]} onchange={e => {
                            
                            let selectedBranch = allTreeBranches.find(branch => branch.STATION_ID === e.target.value);
                            setTreeData({ name: selectedBranch?.name ?? "", attributes: selectedBranch?.attributes, children: selectedBranch?.children as RawNodeDatum[], NO_CHILDS: selectedBranch?.NO_CHILDS })

                        }} />
                    )}
                </div>
                <div className='w-2/6 h-[3em] self-end mb-5'>
                    <EgButton HandleClick={() => {
                        handleOpen()
                    }}>
                        <Stack direction={'row'} justifyContent='center'>
                            <Typography sx={{ marginLeft: '10px' }} variant="h4">دعوه عضو الى الفريق</Typography>
                        </Stack>
                    </EgButton>
                </div>
            </div>
            <div style={{ width: '100%', height: '70vh', backgroundColor: '#EDF3F4' }}>
                {treeData && (

                    <Tree
                        data={treeData}
                        translate={translate as Point}
                        nodeSize={nodeSize}
                        dimensions={dimensions}
                        renderCustomNodeElement={(rd3tProps) =>
                            renderForeignObjectNode({ ...rd3tProps, foreignObjectProps, nodeClick: NodeClickFunc })
                        }
                        orientation="vertical"
                        pathFunc='diagonal'
                    />
                )}
            </div>
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <TeamModalBody />
                </Modal>
            </div>
        </div>
    )
}

export default Team



const TeamModalBody = () => {


    const [isCopied, setIsCopied] = useState<boolean>(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch<AppDispatch>();
    const [JobsDropDownData, seJobstDropDOwnData] = useState<DropDownType[]>();
    const [inviteLink, setInviteLink] = useState<string>();
    const [stations, setStations] = useState<DropDownData[]>([]);
    const [selectedStation, setSelectedStation] = useState<number>();

    const RefLoader = useRef<HTMLDivElement>(null);

    const User = useSelector(UsersState);
    const [jobId, setJobId] = useState<number>(-1);

    const MainInputStyle = { marginBottom: 20, width: '100%' };
    const navigate = useNavigate();

    const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<ITeamInvite>({
        reValidateMode: 'onBlur',
        mode: 'onBlur',

    });

    const { ref: EmailRef, ...EmailProps } = register("Email", {
        required: "البريد الإلكتروني للعضو لا يمكن ان يكون فارغ",
    });


    useEffect(() => {
        let JobdataHolder: DropDownType[] = [];
        dispatch(GetAllJobsThunk()).then(res => {


            if (User.currentUser.JOBS) {

                let currentUserJob = Object.entries(User.currentUser.JOBS)[0][1] as JobsModel;
                let currentUserJobId = currentUserJob.SYSTEM_SEQ ?? 0


                if (currentUserJobId) {

                    if (res.meta.requestStatus === 'fulfilled') {

                        let data = res.payload as JobsModel[];

                        data.filter(job => job.SYSTEM_JOB === true).filter(job => job.SYSTEM_SEQ ? job.SYSTEM_SEQ < currentUserJobId : 0).map(job => {
                            JobdataHolder.push({ id: job.ID, text: job.JOB_DESCRIPTION })
                        })


                        seJobstDropDOwnData(JobdataHolder);

                    }
                    if (res.meta.requestStatus === 'rejected') {
                        dispatch(AddAction({ message: res.payload as string, status: "error" }))
                        return;
                    }
                }

            }
        }).catch(err => {
            dispatch(AddAction({ message: err.payload as string, status: "error" }))
        })
    }, [])

    useEffect(() => {
        GET_ALL_STATIONS().then(res => {
            let DDD: DropDownData[] = res.map((stat) => ({ id: stat.STATION_ID, desc: stat.STATION_NAME }));
            setStations(DDD)
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, [])


    const onSubmit2: SubmitHandler<ITeamInvite> = async (data: ITeamInvite) => {


        if (jobId < 0) {
            return
        }


        try {
            if (RefLoader.current && RefLoader.current.style) {
                RefLoader.current.classList.add('show')
            }

            if (selectedStation !== undefined) {
                
                let URL_INVITATION_LINK = await InvitePerson({ EMAIL: data.Email, JOB_ID: jobId, STATION_ID: selectedStation }).then(res => {
                    setInviteLink(res.INVITATION_URL);
                    dispatch(AddAction({ message: "تم إرسال الدعوه بنجاح", status: "success" }));
                }).catch(err => {
                    GetErrorAxios(err, dispatch)
                });
                // if (URL_INVITATION_LINK) {
                //     dispatch(AddAction({ message: "تم إرسال الدعوه بنجاح", status: "success" }))

                //     if (RefLoader.current && RefLoader.current.style) {
                //         RefLoader.current.classList.remove('show')
                //     }

                //     setInviteLink(URL_INVITATION_LINK.INVITATION_URL);


                //     return;
                // } else {
                //     if (RefLoader.current && RefLoader.current.style) {
                //         RefLoader.current.classList.remove('show')
                //     }
                //     dispatch(AddAction({ message: "حدث خطأ اثناء الدعوه", status: "error" }))
                // }
            } else {
                dispatch(AddAction({ message: 'برجاء تحديد الفرع', status: "error" }))
                return;
            }
        } catch (err) {
            if (RefLoader.current && RefLoader.current.style) {
                RefLoader.current.classList.remove('show')
            }
            dispatch(AddAction({ message: err, status: "error" }))
        }


    };

    async function copyTextToClipboard(text: string) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }


    return <div className='w-[20%] h-[80vh] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2'>
        <div className='bg-[#EDF3F4] rounded-3xl w-full h-full flex flex-col items-center'>
            <div className='h-1/3 p-2 mb-10'>
                <THETEAM />
            </div>
            <form className='w-4/5 h-2/3 flex flex-col justify-start items-center' onSubmit={handleSubmit(onSubmit2)}>
                <div className='w-full flex flex-row gap-4'>
                    <CustomTextField inputRef={EmailRef} {...EmailProps} type={'email'} InputProps={{
                    }} style={MainInputStyle} error={!!errors.Email}
                        helperText={errors?.Email?.message}
                        placeholder='البريد الإلكتروني للعضو' colors={colors} theme={theme} />
                </div>
                {JobsDropDownData && (
                    <div className='w-full flex flex-row gap-4'>
                        <Select
                            className='w-full'
                            value={jobId}
                            onChange={(e: SelectChangeEvent<number>) => {
                                setJobId(e.target.value as number)
                            }
                            }
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={-1}>{"اختار رتبه"}</MenuItem>
                            {JobsDropDownData.map(rank => (
                                <MenuItem value={rank.id}>{rank.text}</MenuItem>
                            ))}

                        </Select>
                    </div>
                )}
                <div className='w-full mt-5'>
                    <h5 className='mb-2'>فرع الشركه</h5>
                    {stations?.length > 0 && (
                        <DropTextField classes='w-3/4' data={[
                            ...stations
                        ]} onchange={e => {
                            
                            setSelectedStation(e.target.value as number)
                        }} />
                    )}
                </div>
                <div className='w-full grow flex flex-col justify-around'>
                    <div className='w-2/3 self-center'>
                        <EgButton Action={'submit'} HandleClick={() => {

                            // if (RefLoader.current && RefLoader.current.style) {
                            //     
                            //     RefLoader.current.classList.add('show')
                            // }
                            //dispatch(fetchTodos({ password: 'ahmed', userName: 'ahmed' }));
                        }}>
                            <Stack direction={'row'} justifyContent='center'>
                                <Typography sx={{ marginLeft: '10px' }} variant="h5">إضافه</Typography>
                            </Stack>
                        </EgButton>
                    </div>
                </div>
            </form>
            {inviteLink && (
                <div className='self-center flex flex-col w-4/5 justify-start items-start'>
                    <h5 className='font-bold'>لينك الدعوه</h5>
                    <div className='flex flex-row w-full'>
                        <input className='w-4/5' disabled value={inviteLink} />
                        <button
                            className='w-1/5 text-black'
                            onClick={() => {
                                copyTextToClipboard(inviteLink)
                                setIsCopied(true);
                            }
                            }>
                            {isCopied && (

                                <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                            )}
                            {!isCopied && (

                                <span>{isCopied ? 'Copied!' : 'Copy'}</span>
                            )}
                        </button>
                    </div>
                </div>
            )}
            {/* <div className='self-center flex h-[5em] justify-start items-center'>
                <div className='BigLoader' ref={RefLoader}>
                </div>
            </div> */}
        </div>
    </div>
}



const handleNodeClick = (nodeDatum: any) => {
    window.alert(
        nodeDatum.name
    );
};


export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const [dimensions, setDimensions] = useState<any>();
    const containerRef = useCallback((containerElem: any) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setDimensions({ width, height });
            setTranslate({ x: width / 2, y: height / 4 });
        }
    }, []);
    return [dimensions, translate, containerRef];
};


const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    nodeClick,
    foreignObjectProps
}: { nodeDatum: any, toggleNode: any, foreignObjectProps: any, nodeClick: (nodeELE: any) => void }) => {

    let User = nodeDatum.attributes as UserModel;
    let childs_count = nodeDatum.NO_CHILDS?.toString() ?? "0";
    // const activateUser = () => {
    //     let Confirm = "نعم"
    //     let message = prompt("برجاء كتابه نعم اذا كنت تريد تفعيل الحساب");
    //     if (message === Confirm) {
    //         let modifiedUserObjREQ: UserModel = { ...User, IS_ACTIVE: true }

    //         SaveUserAPI(modifiedUserObjREQ).then(res => {
    //             window.location.reload();
    //         }).catch(err => {
    //             window.alert(err.toString())
    //         })
    //     }else {
    //         window.alert("تم الإلغاء")
    //     }
    // }

    // const DeactivateUser = () => {
    //     let Confirm = "نعم"
    //     let message = prompt("برجاء كتابه نعم اذا كنت تريد إيقاف الحساب");
    //     if (message === Confirm) {
    //         let modifiedUserObjREQ: UserModel = { ...User, IS_ACTIVE: false }

    //         SaveUserAPI(modifiedUserObjREQ).then(res => {
    //             window.location.reload();
    //         }).catch(err => {
    //             window.alert(err.toString())
    //         })
    //     } else {
    //         window.alert("تم الإلغاء")
    //     }
    // }
    //bg-[#EDF3F4]
    return (
        <g>
            <circle r={5}></circle>
            {/* `foreignObject` requires width & height to be explicitly set. */}
            <foreignObject  {...foreignObjectProps}>
                <div className='  bg-[#EDF3F4] mx-3 h-3/4 flex flex-col overflow-clip'>
                    <div className=' flex justify-center'>
                        <div className='w-[10em] h-[10em] rounded-[50%]  overflow-hidden' onClick={() => { nodeClick(nodeDatum) }} >
                            <img src={User.PROFILE_PIC_URL} alt={User.FULL_NAME} />
                        </div>
                    </div>
                    <h5 className='font-bold text-sm my-2 text-center'>{User.FULL_NAME?.replace("+", " ") ?? ""}</h5>
                    {/* <h3  dir='ltr' className='font-bold text-sm my-2 truncate  text-start px-1'>{User.EMAIL}</h3> */}
                    <h5 className='font-bold text-sm my-2 text-center'>{User.USER_JOB_NAME ?? ""}</h5>
                    <h5 className='font-bold text-sm my-2 text-center'>{childs_count} عدد الأعضاء</h5>
                    {nodeDatum.children && (
                        <div className='w-[70%] self-center'>
                            <EgButton HandleClick={() => {
                                toggleNode()
                            }}>
                                <Stack direction={'row'} justifyContent='center'>
                                    <Typography textAlign='center' variant="h6">{nodeDatum.__rd3t.collapsed ? "عرض الفريق" : "فريقي"}</Typography>
                                </Stack>
                            </EgButton>
                        </div>
                    )}
                    {/* {User.PARENT_USER_ID !== (null || undefined) && (
                        <div className='absolute top-0 left-0 w-10 h-10 bg-white rounded-md flex items-start justify-center'>
                            {!User.IS_ACTIVE && (
                                <div className='i-material-symbols-close-rounded text-5xl text-red-500 hover:cursor-pointer' onClick={activateUser}>
                                </div>
                            )}
                            {User.IS_ACTIVE && (
                                <div className='i-material-symbols-check-small-rounded text-5xl text-green-500 hover:cursor-pointer' onClick={DeactivateUser}>
                                </div>
                            )}
                        </div>
                    )} */}
                </div>
            </foreignObject>
        </g>
    );
}


