import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckedPure, Loading, Unchecked } from '../../../../components/Icons/Icons';
import { CustomTextFieldFullHeight } from '../../../../CustomComps/Customs';
import { ActionTypes, GetErrorAxios, ShowError } from '../../../../Globals';
import { AddAction } from '../../../../redux/slices/ErrorsSlice';
import { AppDispatch } from '../../../../redux/store';
import { axiosConfig } from '../../../../Service/Apis';
import { ContactsModel } from '../../../../Service/models/ContactsModel/ContactsMOdel';
import { ACTIONS, CUSTOMER_DETAILS } from '../../../../Service/models/UserModel/Actions/ActionsModel';
import { tokens } from '../../../../theme';
import { Cities, GovAndCities } from '../../../Rate/Rate';
import { EgButton } from '../../../Shared/Shared';


export const checkActionTypeContactChannel = (channelType: number, ActionTypeOBJ_Holder: CUSTOMER_DETAILS) => {
  return ActionTypeOBJ_Holder.CONTACT_RELATIONSHIP === channelType ? true : false
}

const ClientData = ({ selectedContact }: { selectedContact: ContactsModel }) => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { width: '100%', backgroundColor: 'white' };

  const [tab_idx, set_tab_idx] = useState<number>(0);

  const dispatch = useDispatch<AppDispatch>();


  const [actions, setActions] = useState<ACTIONS>({ ACTION_TYPE: ActionTypes.ClientData, CONTACT_ID: selectedContact.CONTACT_ID!, STAMP_DATE: new Date() });
  const [ActionTypeOBJ, setActionTypeOBJ] = useState<CUSTOMER_DETAILS>({ SURNAME: selectedContact.NAME, MOBILE_NO: selectedContact.MOBILE_NO, MOBILE_NO_2: selectedContact.MOBILE_NO_2!, CONTACT_ID: selectedContact.CONTACT_ID!, CONTACT_RELATIONSHIP: 0, GENDER: 0, CUSTOMER_JOB_ID: 0, CUSTOMER_ADDRESS_LOCATION: 1 });


  const setRefDataForJob = (clientJob: number) => {
    setActionTypeOBJ(prev => ({ ...prev, CUSTOMER_JOB_ID: clientJob }))
  }

  const setCheckForJob = (clientJob: number) => {
    return ActionTypeOBJ.CUSTOMER_JOB_ID === clientJob ? true : false
  }

  const sendClientDataToServer = () => {
    setActions(prev => ({ ...prev, CUSTOMER_DETAILS: ActionTypeOBJ }));
  }


  useEffect(() => {
    if (actions.CUSTOMER_DETAILS) {
      let serializeReq = JSON.stringify(actions);
      axios.post(`/api/contacts/saveAction`, serializeReq, axiosConfig).then(
        res => {
          dispatch(AddAction({ message: "تم أضافه بيانات العميل", status: "success" }))
        }
      ).catch(err => {
        
        GetErrorAxios(err, dispatch)
      })
    }
  }, [actions])




  const IconsStepp: IconStepperType[] = [
    {
      Icon: <CheckedPure />,
      Title: "البيانات الرئيسيه"
    },
    {
      Icon: <CheckedPure />,
      Title: "طريقه الوصول للعميل"
    },
    {
      Icon: <Loading FillColor='#d69e1c' />,
      Title: "العنوان"
    },
    {
      Icon: <Unchecked />,
      Title: "الوظيفه"
    },

  ]

  return (
    <>

      <div className='w-full h-full flex flex-col justify-between bg-[#f8f8f8] p-3 rounded-md'>
        <div className='w-full h-full p-5'>
          <GeneralStepper
            idx_val={tab_idx}
            set_idx_val={(val) => set_tab_idx(val)}
            ItemsComps={[
              <MainClientData action_job={ActionTypeOBJ} set_action_job={(data) => {
                
                setActionTypeOBJ((prev) => ({ ...prev, MOBILE_NO: data.MOBILE_NO, SURNAME: data.SURNAME, MOBILE_NO_2: data.MOBILE_NO_2, GENDER: data.GENDER }))
              }
              } tab_idx={tab_idx} set_tab_idx={(val) => set_tab_idx(val)} />,

              <ClientReachMethod action_job={ActionTypeOBJ} set_action_job={(data) => {
                
                setActionTypeOBJ((prev) => ({ ...prev, CONTACT_RELATIONSHIP: data.CONTACT_RELATIONSHIP }))
              }} tab_idx={tab_idx} set_tab_idx={(val) => set_tab_idx(val)} />,

              <ClientLocation action_job={ActionTypeOBJ} set_action_job={(data) => {
                
                setActionTypeOBJ((prev) => ({ ...prev, CUSTOMER_ADDRESS_LOCATION: data.CUSTOMER_ADDRESS_LOCATION, CITY: data.CITY, GOV: data.GOV, ADDRESS_DETAILS: data.ADDRESS_DETAILS }))
              }} tab_idx={tab_idx} set_tab_idx={(val) => set_tab_idx(val)} />,

              <ClientJob action_job={ActionTypeOBJ} set_action_job={(data) => {
                
                setActionTypeOBJ((prev) => ({ ...prev, CUSTOMER_JOB_ID: data.CUSTOMER_JOB_ID }))
              }} tab_idx={tab_idx} set_tab_idx={(val) => set_tab_idx(val)} sendData={() => {
                sendClientDataToServer();
              }} />
            ]}
            ItemsIcons={IconsStepp}
          />
        </div>
        {/* <div className='w-[10em] h-[3em] self-center'>
          <EgButton HandleClick={sendClientDataToServer}>
            <div className='w-1/2'>
              <h6>حفظ</h6>
            </div>
          </EgButton>
        </div> */}
      </div >
    </>
  )
}

export default ClientData

type TClientData = Pick<CUSTOMER_DETAILS, 'SURNAME' | 'MOBILE_NO' | 'MOBILE_NO_2' | 'GENDER'>;

const MainClientData = ({ action_job, set_action_job, tab_idx, set_tab_idx }: { tab_idx: number, set_tab_idx: (val: number) => void, action_job: TClientData, set_action_job: (data: TClientData) => void }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { width: '100%', backgroundColor: 'white' };
  // let comp_data: TClientData = {} as TClientData;
  // const [data_view, set_data_view] = useState<TClientData>({ surname: action_job.SURNAME, mobile: action_job.MOBILE_NO, mobile2: action_job.MOBILE_NO_2 } as TClientData)

  const dispatch = useDispatch<AppDispatch>();

  const [inputsValidator, setInputsValidators] = useState<{ name: boolean, mobile: boolean }>({ name: false, mobile: false })

  const ValidateComp = (data: { name: boolean, mobile: boolean }): boolean => {
    if (data.name && data.mobile) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    set_action_job({ ...action_job, GENDER: 0 });
    if (action_job.SURNAME.length > 0) {
      setInputsValidators(prev => ({ ...prev, name: true }))
    }
    if (action_job.MOBILE_NO.length > 0) {
      setInputsValidators(prev => ({ ...prev, mobile: true }))
    }
  }, [])



  return <>
    <div className='flex flex-col justify-between h-[90%] bg-white p-3 w-full rounded-lg shadow-md'>
      <div className='flex flex-col gap-5'>
        <div className='w-1/2 lg:w-1/4 flex flex-col gap-4 my-2'>
          <label htmlFor="SurName">الإسم</label>
          <div>
            <CustomTextFieldFullHeight height={25} value={action_job.SURNAME} id='SurName' type={'text'} onChange={(e: any) => {
              let val = e.target.value as string;
              
              set_action_job({ ...action_job, SURNAME: e.target.value })
              if (val.length > 0) {
                setInputsValidators(prev => ({ ...prev, name: true }))
              } else {
                setInputsValidators(prev => ({ ...prev, name: false }))
              }
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
          {!inputsValidator.name && (
            <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
          )}
          {inputsValidator.name && (
            <h6 className='font-bold text-green-600'>&nbsp;</h6>
          )}

        </div>
        <div className='w-1/2 lg:w-1/4 flex flex-col gap-4 my-2'>
          <label htmlFor="mobileNumber">رقم التليفون</label>
          <div>
            <CustomTextFieldFullHeight height={25} id="mobileNumber" value={action_job.MOBILE_NO} placeholder='رقم التليفون الأول' onChange={(e: any) => {
              set_action_job({ ...action_job, MOBILE_NO: e.target.value })
              let val = e.target.value as string;
              if (val.length > 0) {
                setInputsValidators(prev => ({ ...prev, mobile: true }))
              } else {
                setInputsValidators(prev => ({ ...prev, mobile: false }))
              }
            }} type={''} style={MainInputStyle}
              colors={colors} theme={theme} />
            {!inputsValidator.mobile && (
              <h6 className='font-bold text-red-600' >* هذا الحقل إجباري</h6>
            )}
            {inputsValidator.mobile && (
              <h6 className='font-bold text-green-600'>&nbsp;</h6>
            )}
          </div>
        </div>
        <div className='w-1/2 lg:w-1/4 flex flex-col gap-4'>
          <label htmlFor="SecondMobNumber"> رقم التليفون الثاني (اختياري) </label>
          <CustomTextFieldFullHeight height={25} id="SecondMobNumber" value={action_job.MOBILE_NO_2} placeholder='رقم التليفون الثاني' onChange={(e: any) => {
            set_action_job({ ...action_job, MOBILE_NO_2: e.target.value })
          }} type={''} style={MainInputStyle}
            colors={colors} theme={theme} />
        </div>
        <div className='w-full flex flex-col  gap-4 my-2'>
          <p className='text-xl w-1/3'>النوع</p>
          <div className='flex flex-col md:flex-col w-full gap-4 justify-between'>
            <div className='flex  items-center gap-2'>
              <input type="radio" id="male" name="Gender" checked={action_job.GENDER === 0 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
                if (e.target.checked === true) {
                  set_action_job({ ...action_job, GENDER: 0 })

                }
              }} />
              <label htmlFor='male' className='text-xl'>ذكر</label>
            </div>
            <div className="flex items-center gap-2">
              <input type="radio" id="female" name="Gender" checked={action_job.GENDER === 1 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
                if (e.target.checked === true) {
                  set_action_job({ ...action_job, GENDER: 1 })

                }
              }} />
              <label htmlFor='female' className='text-xl '>انثى</label>
            </div>
          </div>
        </div>
      </div>
      <div className='flex gap-4 self-end'>
        <div className='w-[10em] h-[3em] self-center'>
          <EgButton HandleClick={() => {
            if (tab_idx != 0) {
              set_tab_idx(tab_idx - 1);
            }
          }}>
            <div className='w-1/2'>
              <h6>السابق</h6>
            </div>
          </EgButton>
        </div>
        <div className='w-[10em] h-[3em] self-center'>
          <EgButton HandleClick={() => {
            if (ValidateComp(inputsValidator)) {
              set_tab_idx(tab_idx + 1);
            } else {
              ShowError("برجاء التحقق من الإسم و رقم الموبايل و النوع", dispatch)
            }
            //set_action_job(data_view)
          }}>
            <div className='w-1/2'>
              <h6>التالي</h6>
            </div>
          </EgButton>
        </div>
      </div>
    </div>
  </>
}
type TClien_Reach_Methods = Pick<CUSTOMER_DETAILS, 'CONTACT_RELATIONSHIP'>

const ClientReachMethod = ({ action_job, set_action_job, tab_idx, set_tab_idx }: { tab_idx: number, set_tab_idx: (val: number) => void, action_job: TClien_Reach_Methods, set_action_job: (data: TClien_Reach_Methods) => void }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { width: '100%', backgroundColor: 'white' };

  let comp_data: TClien_Reach_Methods = {} as TClien_Reach_Methods;

  useEffect(() => {
    set_action_job({ ...action_job, CONTACT_RELATIONSHIP: 0 });
  }, [])

  return <div className='flex flex-col justify-between h-[90%] bg-white p-3 w-full rounded-lg shadow-md'>
    <div className='flex flex-col md:flex-col w-full justify-between self-start gap-4'>
      <div className='flex  items-center gap-2'>
        <input type="radio" id="PersRelation" checked={action_job.CONTACT_RELATIONSHIP === 0 ? true : false} name="ReachMethod" className='w-[2em] h-[2em]' onChange={(e: any) => {
          if (e.target.checked === true) {
            set_action_job({ ...comp_data, CONTACT_RELATIONSHIP: 0 })
            // setActionTypeOBJ(prev => ({ ...prev, CONTACT_RELATIONSHIP: 0 }))
          }
        }} />
        <label htmlFor='PersRelation' className='text-xl'> قائمه علاقات شخصيه</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="Social" name="ReachMethod" checked={action_job.CONTACT_RELATIONSHIP === 1 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
          if (e.target.checked === true) {
            set_action_job({ ...comp_data, CONTACT_RELATIONSHIP: 1 })
          }
        }} />
        <label htmlFor='Social' className='text-xl '>سوشيال ميديا</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="Sugg" name="ReachMethod" checked={action_job.CONTACT_RELATIONSHIP === 2 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
          if (e.target.checked === true) {
            set_action_job({ ...comp_data, CONTACT_RELATIONSHIP: 2 })
          }
        }} />
        <label htmlFor='Sugg' className='text-xl'>ترشيحات</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="PrevClient" name="ReachMethod" checked={action_job.CONTACT_RELATIONSHIP === 3 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
          if (e.target.checked === true) {
            set_action_job({ ...comp_data, CONTACT_RELATIONSHIP: 3 })
          }
        }} />
        <label htmlFor='PrevClient' className='text-xl'>عميل سابق</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="other" name="ReachMethod" checked={action_job.CONTACT_RELATIONSHIP === 4 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
          if (e.target.checked === true) {
            set_action_job({ ...comp_data, CONTACT_RELATIONSHIP: 4 })
          }
        }} />
        <label htmlFor='other' className='text-xl'>أخرى</label>
      </div>
    </div>
    <div className='flex gap-4 self-end'>
      <div className='w-[10em] h-[3em] self-center'>
        <EgButton HandleClick={() => {
          if (tab_idx != 0) {
            set_tab_idx(tab_idx - 1);
          }
        }}>
          <div className='w-1/2'>
            <h6>السابق</h6>
          </div>
        </EgButton>
      </div>
      <div className='w-[10em] h-[3em] self-center'>
        <EgButton HandleClick={() => {
          set_tab_idx(tab_idx + 1);
          //set_action_job(data_view)
        }}>
          <div className='w-1/2'>
            <h6>التالي</h6>
          </div>
        </EgButton>
      </div>
    </div>
  </div>
}
type TClien_Location = Pick<CUSTOMER_DETAILS, 'CUSTOMER_ADDRESS_LOCATION' | 'GOV' | 'CITY' | 'ADDRESS_DETAILS'>

const ClientLocation = ({ action_job, set_action_job, tab_idx, set_tab_idx }: { tab_idx: number, set_tab_idx: (val: number) => void, action_job: TClien_Location, set_action_job: (data: TClien_Location) => void }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { width: '100%', backgroundColor: 'white' };
  const [Govs, setGovs] = useState<GovAndCities[]>();
  const [selectedGov, setSelectedGov] = useState<number>(-1);
  const [cities, setCities] = useState<Cities[]>();
  const [city, setSelectedCity] = useState<number>(-1);

  const dispatch = useDispatch<AppDispatch>();


  useEffect(() => {
    axios.get('/api/govs/getAllGovernorates').then(res =>
      setGovs(res.data)
    ).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }, [])



  useEffect(() => {
    let newCitiesArr = Govs?.filter(gov => gov.ID === selectedGov)[0];
    setCities(newCitiesArr?.ALL_CITIES)
  }, [selectedGov])




  return <div className='flex flex-col justify-between h-[90%] bg-white p-3 w-full rounded-lg shadow-md'>
    <div className='flex flex-col gap-5'>
      <div className='flex flex-col md:flex-col w-full justify-between gap-5'>
        <div className='flex  items-center gap-2'>
          <input type="radio" id="inEgy" name="Location" checked={action_job.CUSTOMER_ADDRESS_LOCATION === 0 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
            if (e.target.checked === true) {
              set_action_job({ ...action_job, CUSTOMER_ADDRESS_LOCATION: 0 })
            }
          }} />
          <label htmlFor='inEgy' className='text-xl'>داخل مصر</label>
        </div>
        <div className="flex items-center gap-2">
          <input type="radio" id="outEgy" name="Location" checked={action_job.CUSTOMER_ADDRESS_LOCATION === 1 ? true : false} className='w-[2em] h-[2em]' onChange={(e: any) => {
            if (e.target.checked === true) {
              set_action_job({ ...action_job, CUSTOMER_ADDRESS_LOCATION: 1 })
            }
          }} />
          <label htmlFor='outEgy' className='text-xl '>خارج مصر</label>
        </div>
      </div>
      {action_job.CUSTOMER_ADDRESS_LOCATION === 0 && (
        <div className='w-full flex flex-col gap-5'>
          <div className='w-full'>
            <div className='w-1/2 flex flex-row gap-4 bg-white'>
              <Select
                placeholder='اختر محافظه'
                className='w-full'
                value={selectedGov}
                onChange={(e: SelectChangeEvent<number>) => {
                  let _selectedGov = Govs?.filter(gov => gov.ID === e.target.value)[0];
                  if (_selectedGov) {
                    setSelectedGov(_selectedGov?.ID)
                    set_action_job({ ...action_job, GOV: selectedGov })
                  }
                }
                }
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={-1}>{"اختر محافظه"}</MenuItem>
                {Govs && Govs.map((gov, idx) => (
                  <MenuItem value={gov.ID}>{gov.GOVERNORATE_NAME}</MenuItem>
                ))}

              </Select>
            </div >
          </div >
          <div className='w-full'>
            <div className='w-1/2 flex flex-row gap-4 bg-white'>
              <Select
                className='w-full'
                value={city}
                onChange={(e: SelectChangeEvent<number>) => {
                  let _selectedCity = cities?.filter(city => city.ID === e.target.value)[0];
                  if (_selectedCity) {
                    setSelectedCity(_selectedCity?.ID)
                    set_action_job({ ...action_job, CITY: _selectedCity.ID })
                  }
                }
                }
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={-1}>{"إختر مدينه / مركز"}</MenuItem>
                {cities && cities.map((city, idx) => (
                  <MenuItem value={city.ID}>{city.CITY_NAME}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </div >
      )}
      <textarea id="Notes" name="otherNotes" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-black border focus:outline-none focus:shadow-none " placeholder='ملاحظات اخرى' onChange={(e: any) => {
        set_action_job({ ...action_job, ADDRESS_DETAILS: e.target.value })
      }} >

      </textarea>
    </div>
    <div className='flex gap-4 self-end'>
      <div className='w-[10em] h-[3em] self-center'>
        <EgButton HandleClick={() => {
          if (tab_idx != 0) {
            set_tab_idx(tab_idx - 1);
          }
        }}>
          <div className='w-1/2'>
            <h6>السابق</h6>
          </div>
        </EgButton>
      </div>
      <div className='w-[10em] h-[3em] self-center'>
        <EgButton HandleClick={() => {
          set_tab_idx(tab_idx + 1);
          //set_action_job(data_view)
        }}>
          <div className='w-1/2'>
            <h6>التالي</h6>
          </div>
        </EgButton>
      </div>
    </div>


  </div >
}
type TClien_Jobs = Pick<CUSTOMER_DETAILS, 'CUSTOMER_JOB_ID'>

const ClientJob = ({ action_job, set_action_job, tab_idx, set_tab_idx, sendData }: { sendData: () => void, tab_idx: number, set_tab_idx: (val: number) => void, action_job: TClien_Jobs, set_action_job: (data: TClien_Jobs) => void }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { width: '100%', backgroundColor: 'white' };

  let comp_data: TClien_Jobs = {} as TClien_Jobs;

  const setCheckForJob = (clientJob: number) => {
    return action_job.CUSTOMER_JOB_ID === clientJob ? true : false
  }

  const setRefDataForJob = (clientJob: number) => {
    // setActionTypeOBJ(prev => ({ ...prev, CUSTOMER_JOB_ID: clientJob }))
    set_action_job({ ...comp_data, CUSTOMER_JOB_ID: clientJob })
  }


  return <div className='flex flex-col justify-between h-[90%] bg-white p-3 w-full rounded-lg shadow-md'>
    <div className='flex flex-col md:flex-col w-full justify-between self-start gap-4'>
      <div className='flex  items-center gap-2'>
        <input type="radio" id="Eng" name="Job" checked={setCheckForJob(0)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(0)
        }} />
        <label htmlFor='Eng' className='text-xl'>مهندس</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="Doc" name="Job" checked={setCheckForJob(1)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(1)
        }} />
        <label htmlFor='Doc' className='text-xl '>دكتور</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="ph" name="Job" checked={setCheckForJob(2)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(2)
        }} />
        <label htmlFor='ph' className='text-xl'>صيدلي</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="tech" name="Job" checked={setCheckForJob(3)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(3)
        }} />
        <label htmlFor='tech' className='text-xl'>مدرس</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="law" name="Job" checked={setCheckForJob(4)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(4)
        }} />
        <label htmlFor='law' className='text-xl'>محامي</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="emp" name="Job" checked={setCheckForJob(5)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(5)
        }} />
        <label htmlFor='emp' className='text-xl'>موظف</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="bus" name="Job" checked={setCheckForJob(6)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(6)
        }} />
        <label htmlFor='bus' className='text-xl'>رجل اعمال</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="merch" name="Job" checked={setCheckForJob(7)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(7)
        }} />
        <label htmlFor='merch' className='text-xl'>تاجر</label>
      </div>
      <div className="flex items-center gap-2">
        <input type="radio" id="other" name="Job" checked={setCheckForJob(8)} className='w-[2em] h-[2em]' onChange={(e: any) => {
          setRefDataForJob(8)
        }} />
        <label htmlFor='other' className='text-xl'>أخرى</label>
      </div>
    </div>

    <div className='flex gap-4 self-end'>
      <div className='w-[10em] h-[3em] self-center'>
        <EgButton HandleClick={() => {
          if (tab_idx != 0) {
            set_tab_idx(tab_idx - 1);
          }
        }}>
          <div className='w-1/2'>
            <h6>السابق</h6>
          </div>
        </EgButton>
      </div>
      <div className='w-[18em] h-[3em] self-center'>
        <EgButton HandleClick={() => {
          //set_tab_idx(tab_idx + 1);
          //set_action_job(data_view)
          sendData();
        }}>
          <div className='w-3/4'>
            <h6 className='font-bold'>حفظ بيانات العميل</h6>
          </div>
        </EgButton>
      </div>
    </div>
  </div >
}

type IconStepperType = {
  Icon: JSX.Element,
  Title: string
}

type Props = {
  ItemsIcons: IconStepperType[],
  ItemsComps: JSX.Element[],
  idx_val: number,
  set_idx_val: (val: number) => void
}


export const GeneralStepper: FC<Props> = ({ ItemsIcons, ItemsComps, idx_val, set_idx_val }) => {
  const [comp_idx, set_comp_idx] = useState<number>(0);

  if (ItemsComps.length !== ItemsIcons.length) {
    return <>
    </>;
  }


  let classesString = 'w-[3em] h-[3em] p-1 rou shadow m-1 rounded-full';

  return <>
    {/* comp headers */}
    <div className='flex mb-8'>
      {ItemsIcons.map((icon, idx) => {
        return <>
          {idx < ItemsIcons.length - 1 && (
            <div key={idx} className='flex items-center grow'>
              <div className='flex flex-col items-center relative  hover:cursor-pointer' onClick={() => {
                set_idx_val(idx)
              }}>
                <div className={idx > idx_val ? classesString + ' bg-slate-200' : idx === idx_val ? classesString + ' ' : classesString + ' bg-green-100'}>
                  {idx < idx_val && (
                    <CheckedPure />
                  )}
                  {idx === idx_val && (
                    <Loading FillColor='#d69e1c' />
                  )}
                </div>
                <div className='absolute top-[100%] right-[0%]'>
                  <h6 className={idx_val === idx ? 'w-[10em] truncate font-bold' : 'w-[10em] truncate'}>{icon.Title}</h6>
                </div>
              </div>
              <div className={idx < idx_val ? "grow bg-green-400 rounded-full h-[.125em]" : 'grow bg-gray-300 rounded-full h-[.125em]'}>

              </div>
            </div>
          )}
          {idx === ItemsIcons.length - 1 && (
            <div key={idx} className='flex items-center'>
              <div className='flex flex-col items-center relative hover:cursor-pointer' onClick={() => {
                set_idx_val(idx)
              }}>
                <div className={idx > idx_val ? classesString + ' bg-slate-200' : idx === idx_val ? classesString + ' ' : classesString + ' bg-green-100'}>
                  {idx < idx_val && (
                    <CheckedPure />
                  )}
                  {idx === idx_val && (
                    <Loading FillColor='#d69e1c' />
                  )}
                </div>
                <div className='absolute top-[100%] right-[0%]'>
                  <h6 className={idx_val === idx ? 'w-[10em] truncate font-bold' : 'w-[10em] truncate'}>{icon.Title}</h6>
                </div>
              </div>
            </div>
          )}
        </>
      })}
    </div>
    {/* comp Components */}
    {ItemsComps.length > 0 && (
      <>
        <div className='w-full h-full'>
          {ItemsComps[idx_val]}
        </div>
      </>
    )}
  </>
}


type TIDHOC = {
  value: number,
  set_value: (val: number) => void
}

export function IDPanelHOC<P>(WrappedComp: React.ComponentType<P>, PassedVal: number, set_PassedVal: (val: number) => void) {


  const AddedIDController = (props: P & TIDHOC) => {
    return <WrappedComp {...props} val={PassedVal} setVal={set_PassedVal} />
  }

  return AddedIDController
}

const HTest = ({ text }: { text: string }) => {
  return <>
    <h6>{text}</h6>
  </>
}