import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetError, ResponseStatus } from '../../Globals';
import { GetAllFoundersAPI, SaveFounderAPI } from '../../Service/Apis';
import { FoundersModel } from '../../Service/models/FoundersModel/Founders';
import { RootState } from '../store';
import { showFeedBack } from './Feedback';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';



export interface IFoundersSlice {
    AllFounders: FoundersModel[],
    Error: string,
    Loading: boolean,
    Empty: FoundersModel[],

}

const initialState: IFoundersSlice = {
    AllFounders: [],
    Error: "",
    Loading: false,
    Empty: []
}

export const Founderslice = createSlice({
    name: 'Founder',
    initialState,
    reducers: {
        // GetAllProjects: (state, action: PayloadAction<ProjectModel[]>) => {
        //     state.status = action.payload as ProjectModel[]
        // },

    },
    extraReducers: (builder) => {

        // When we send a request,
        // `fetchTodos.pending` is being fired:
        builder.addCase(GetAllFoundersThunk.pending, (state) => {
            // At that moment,
            // we change status to `loading` 
            // and clear all the previous errors:
            // state.status = "loading";
            // state.error = null;
        });

        // When a server responses with the data,
        // `fetchTodos.fulfilled` is fired:
        builder.addCase(GetAllFoundersThunk.fulfilled,
            (state, { payload }) => {
                // We add all the new todos into the state
                // and change `status` back to `idle`:
                // state.list.push(...payload);
                // state.status = "idle";
                state.AllFounders = payload as FoundersModel[];
            });

        // When a server responses with an error:
        builder.addCase(GetAllFoundersThunk.rejected,
            (state, { payload }) => {
                // We show the error message
                // and change `status` back to `idle` again.
                // if (payload) state.error = payload.message;
                state.Error = payload as string

            });
    }
})

type FetchTodosError = {
    message: string;
};

export const GetAllFoundersThunk = createAsyncThunk<FoundersModel[] | string>(
    // The first argument is the action name:
    "Founders/getAll",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (Founders, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return GetAllFoundersAPI().then(res => {
            thunkApi.dispatch(setLoadingFalse());
            return res;
        }).catch(err => {
            return GetError(err, thunkApi)
        });
    }
);
export const SaveFoundersThunk = createAsyncThunk<ResponseStatus, FoundersModel>(
    // The first argument is the action name:
    "Founders/SaveFounders",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (Founders, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return SaveFounderAPI(Founders).then(res => {
            thunkApi.dispatch(setLoadingFalse());
            thunkApi.dispatch(showFeedBack({ show: true, message: "تم الصلاحيات", status: "success" }))
            return { Status: 'Success', Data: res } as ResponseStatus
        }).catch(err => {
            // let ERR = err as AxiosError
            // 
            // 
            // let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.request?.responseText;
            // 
            // thunkApi.dispatch(setLoadingFalse());
            // thunkApi.dispatch(showFeedBack({ show: true, message: ErrMsg, status: 'error' }))
            // return { Status: 'Fail', Error: ErrMsg } as ResponseStatus
            let errMsg = GetError(err, thunkApi); 
            return { Status: 'Fail', Error: errMsg } as ResponseStatus
        });
    }
);


export const { } = Founderslice.actions

export default Founderslice.reducer
export const FounderState = (state: RootState) => state.FounderStore;