import React, { useEffect, useRef, useState } from 'react'
import { DEAL_MODEL } from '../../Service/models/DealApplication'
import { getNameOfUnitType, getYearMonthDayFromDate } from '../../Globals'
import { EgButton } from '../Shared/Shared'
import './style.css'
import DealPrint from '../PrintScreens/DealAndCommission'
import { useNavigate } from 'react-router-dom'
import Deal from '../DashBoard/Actions/ActionsTypesScreen/Deal'
import { ContactsModel } from '../../Service/models/ContactsModel/ContactsMOdel'

const DDeal = ({ deal }: { deal: DEAL_MODEL }) => {



    
    const printRef = useRef<HTMLDivElement>(null)


    const navigate = useNavigate();
    const DisplayPrintModal = () => {
        if (printRef.current !== null) {
            printRef.current.classList.remove('hide')
            printRef.current.classList.add('show')
        }
    }

    //hide function
    const HidePrintModal = () => {
        if (printRef.current !== null) {
            printRef.current.classList.remove('show')
            printRef.current.classList.add('hide')
        }
    }

    return (
        <div className='p-2'>
            <div className='flex flex-col items-center p-1 gap-2 md:p-5 md:gap-2' >
                <div className=' w-4/5 flex justify-between gap-2  md:gap-2'>
                    <div className='bg-white rounded-3xl w-3/6 shadow-xl p-3'>
                        <div className='grid grid-cols-2'>
                            <div className='flex flex-col gap-2'>
                                <div>
                                    <p>اسم العميل</p>
                                    <h5>{deal.CLIENT_NAME}</h5>
                                </div>
                                <div>
                                    <p>رقم العميل</p>
                                    <h5>{deal.CLIENT_PHONE}</h5>
                                </div>
                                <div>
                                    <p>نوع الوحده</p>
                                    <h5>{getNameOfUnitType(deal.CLIENT_UNIT_TYPE)}</h5>
                                </div>
                                <div>
                                    <p>كود الوحده</p>
                                    <h5>{deal.CLIENT_UNIT_CODE}</h5>
                                </div>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <div>
                                    <p>سعر الوحده</p>
                                    <h5>{deal.CLIENT_UNIT_PRICE}</h5>
                                </div>
                                <div>
                                    <p>اسم مقدم الطلب</p>
                                    <h5>{deal.APPLICANT_NAME}</h5>
                                </div>
                                <div>
                                    <p>اسم المشروع</p>
                                    <h5>{deal.COMPANY_PROJECT_NAME}</h5>
                                </div>
                                <div>
                                    <p>تاريخ تقديم الطلب</p>
                                    <h5>{getYearMonthDayFromDate(deal.APPLICATION_DATE)}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white rounded-3xl w-3/6 shadow-xl p-3'>
                        <div className='grid grid-cols-2 gap-2'>
                            <div className='flex flex-col gap-2'>
                                <div>
                                    <p>اسم الشركه</p>
                                    <h5>{deal.COMPANY_NAME}</h5>
                                </div>
                                <div>
                                    <p>اسم المشروع</p>
                                    <h5>{deal.COMPANY_PROJECT_NAME}</h5>
                                </div>
                                <div>
                                    <p>اسم السيلز</p>
                                    <h5>{deal.COMPANY_SALES_NAME ?? "لا يوجد رقم"}</h5>
                                </div>
                                <div>
                                    <p>رقم الهاتف للسيلز</p>
                                    <h5>{deal.COMPANY_SALES_PHONE ?? "لا يوجد رقم"}</h5>
                                </div>
                                <div>
                                    <p>مسئول الحسابات</p>
                                    <h5>{deal.COMPANY_ACCOUNTANT_NAME ?? "لايوجد رقم لمسئول الحسابات"}</h5>
                                </div>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <div>
                                    <p>رقم الهاتف لمسئول الحسابات</p>
                                    <h5>{deal.COMPANY_ACCOUNTANT_PHONE ?? "لايوجد رقم لمسئول الحسابات"}</h5>
                                </div>
                                <div>
                                    <p>عموله الشركه</p>
                                    <h5>{deal.COMPANY_COMMISSION}</h5>
                                </div>
                                <div>
                                    <p>حافز الشركه</p>
                                    <h5>{deal.COMPANY_INCENTIVE}</h5>
                                </div>
                                <div>
                                    <p>حافز الليدر</p>
                                    <h5>{deal.LEADER_COMMISSIONS}</h5>

                                </div>

                                <div>
                                    <p>تاريخ التعاقد</p>
                                    <h5>{getYearMonthDayFromDate(deal.CLIENT_CONTRACT_DATE)}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-4/5 bg-white rounded-3xl shadow-xl p-3'>
                    <div className='grid grid-cols-3 gap-4'>
                        <div className='flex flex-col gap-4'>
                            <div>
                                <p>إسم بنك الـ sales</p>
                                <h5>{deal.SALES_BANK_NAME ?? "لا يوجد بنك للسيلز"}</h5>
                            </div>
                            <div>
                                <p>رقم الحساب sales</p>
                                <h5>{deal.SALES_BANK_NAME ?? "لا يوجد رقم حساب للسيلز"}</h5>
                            </div>
                            <div>
                                <p>رقم الـ  sales IBAN</p>
                                <h5>{deal.SALES_BANK_NAME ?? "لا يوجد رقم IBAN للسيلز"}</h5>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div>
                                <p>إسم الـ Leader</p>
                                <h5>{deal.LEADER_NAME ?? "لا يوجد بنك لليدر"}</h5>
                            </div>
                            <div>
                                <p>رقم الحساب Leader</p>
                                <h5>{deal.LEADER_ACCOUNT_NUMBER ?? "لا يوجد رقم حساب لليدر"}</h5>
                            </div>
                            <div>
                                <p>رقم الـ  Leader IBAN</p>
                                <h5>{deal.LEADER_IBAN ?? "لا يوجد رقم IBAN لليدر"}</h5>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <div>
                                <p>تم توزيع النسب</p>
                                <h5>{deal.DEAL_COMMISION !== undefined ? "نعم" : "لا"}</h5>
                            </div>
                            <div>
                                <p>تقديم مطالبه</p>
                                <h5>{(deal.SENT_REQUEST_TO_DEVELOPER === undefined || !deal.SENT_REQUEST_TO_DEVELOPER) ? "لا" : "نعم"}</h5>
                            </div>
                            <div>
                                <p>استلام الشيك</p>
                                <h5>{(deal.GOT_CHECK === undefined || !deal.GOT_CHECK) ? "لا" : "نعم"}</h5>
                            </div>
                            <div>
                                <p>تم صرف الشيك</p>
                                <h5>{(deal.CHECK_HAS_DEPOSITED === undefined || !deal.CHECK_HAS_DEPOSITED) ? "لا" : "نعم"}</h5>
                            </div>
                            <div>
                                <p>صرف العمولات</p>
                                <h5>{(deal.MONEY_TRANSFEERED === undefined || !deal.MONEY_TRANSFEERED) ? "لا" : "نعم"}</h5>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='w-1/2 flex gap-5'>
                <div className='w-52'>
                    <EgButton HandleClick={() => {
                        navigate('/DealPrint', { state: { dealOBJ: deal } });
                    }}>
                        <p>طباعه</p>
                    </EgButton>
                </div>
                <div className='w-52'>
                    <EgButton HandleClick={() => {
                        DisplayPrintModal()
                    }}>
                        <p>تعديل</p>
                    </EgButton>
                </div>
            </div>
            {/* print ref modal */}
            <div ref={printRef} className='transition-all duration-1000 ease-in-out hide fixed top-[12.5%] rounded-2xl shadow-xl  w-3/4 bg-slate-100 z-[100] overflow-hidden'>
                <div className='w-full h-full relative overflow-scroll'>
                    <Deal selectedContact={{ CONTACT_ID: deal.CONTACT_ID } as ContactsModel} deal={deal} />
                    {/* absolute div to close modal */}
                    <div className='absolute top-4 left-4 i-material-symbols-close-rounded text-xl text-red-500 hover:cursor-pointer' onClick={() => {
                        HidePrintModal()
                    }
                    }>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DDeal