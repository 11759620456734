import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { locale, loadMessages } from 'devextreme/localization';
const customMessages = {
  "en": {
    "dxDataGrid-filterRowOperationEquals": "Is equal to",
    "dxDataGrid-filterRowOperationNotEquals": "Is not equal to",
    "dxDataGrid-filterRowOperationContains": "Contains",
    "dxDataGrid-filterRowOperationNotContains": "Does not contain",
    "dxDataGrid-filterRowOperationStartsWith": "Starts with",
    "dxDataGrid-filterRowOperationEndsWith": "Ends with",
    "dxDataGrid-filterRowOperationBetween": "Between",
    "dxDataGrid-filterRowResetOperationText": "Reset",
    "dxDataGrid-exportToExcel": "Export all data to Excel",
    "dxDataGrid-exportToPdf": "Export all data to PDF",
    // Add other customizations here
    "dxDataGrid-trueText": "True", // For true
    "dxDataGrid-falseText": "False", // For false
    "dxDataGrid-allText": "All",
    "dxDataGrid-headerFilterText": "(All)"
  },
  "ar": {
    // Provide the Arabic translations here
    "dxDataGrid-filterRowOperationEquals": "يساوي",
    "dxDataGrid-filterRowOperationNotEquals": "لا يساوي",
    "dxDataGrid-filterRowOperationContains": "يحتوي",
    "dxDataGrid-filterRowOperationNotContains": "لا يحتوي",
    "dxDataGrid-filterRowOperationStartsWith": "يبدأ بـ",
    "dxDataGrid-filterRowOperationEndsWith": "ينتهي بـ",
    "dxDataGrid-filterRowResetOperationText": "إعادة تعيين", 
    "dxDataGrid-exportToExcel": "تصدير كل البيانات إلى Excel",
    "dxDataGrid-exportToPdf": "تصدير كل البيانات إلى PDF",
    // Continue adding Arabic translations

    "dxDataGrid-trueText": "نعم", // For true
    "dxDataGrid-falseText": "لا", // For false
    "dxDataGrid-allText": "الكل", // Assuming 'allText' is the key for "All"
    "dxDataGrid-headerFilterText": "(الكل)"
  }
};
// import  './i18n'

// function App() {
//   const { t,i18n } = useTranslation();

//   useEffect(()=>{
//     i18n.changeLanguage('ar')
//     document.body.dir =  i18n.dir('ar')
//   },[]);

//   return <h2>{t('welcome_message')}</h2>;
// }

//   const loadingMarkup = (
//     <div className="py-4 text-center">
//       <h3>Loading..</h3>
//     </div>
//   )


// Load your custom messages
loadMessages(customMessages);

// Set locale to Arabic
locale("ar");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <Suspense fallback='loadingMarkup'>
  //<React.StrictMode>
  <GoogleOAuthProvider clientId={process.env.REACT_APP_SECRET_NAME!}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Provider store={store}>
        <App />
      </Provider>
    </LocalizationProvider>
  </GoogleOAuthProvider>
  //</React.StrictMode>

  // </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
