import React, { FC, useDeferredValue, useEffect, useState } from 'react'
import { CustomTextField, CustomTextFieldFullHeight } from '../../../CustomComps/Customs'
import { Box, InputAdornment, useTheme } from '@mui/material'
import { tokens } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import { Input_Error_State } from '../../../Globals';

interface EGInputProps {
    labelName?: string
    HTMLFor: string,
    errorText: string,
    valueChange: (data: string) => void,
    initialValue?: string, 
    validated : (data : boolean) => void
}

const EGInput: FC<EGInputProps> = ({ labelName, HTMLFor, errorText, valueChange, initialValue,validated }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const MainInputStyle = {  width: '100%', backgroundColor: 'white' };
    const [inputsValidator, setInputsValidators] = useState<{ ValidateValue: Input_Error_State }>({ ValidateValue: Input_Error_State.Initial })
    const [Req, changeReq] = useState<{ Value: string }>({ Value: initialValue ?? '' })
    const deferredValue = useDeferredValue(Req)

    useEffect(() => {
        changeReq(prev => ({ ...prev, Value: initialValue ?? '' }))
    }, [initialValue])

    return (
        <div className='w-full'>
            <div className='w-full md:w-[50%] flex flex-col gap-1'>
                {labelName !== undefined && (
                    <label htmlFor={HTMLFor}>{labelName}</label>
                )}
                <div>
                    <CustomTextFieldFullHeight height={30} value={Req.Value} id={HTMLFor} type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        changeReq(prev => ({ ...prev, Value: val }))
                        valueChange(val)
                        if (val.length > 0) {
                            setInputsValidators(prev => ({ ...prev, ValidateValue: Input_Error_State.Success }))
                            validated(true)
                        } else {
                            setInputsValidators(prev => ({ ...prev, ValidateValue: Input_Error_State.Fail }))
                            validated(false)
                        }
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                    {inputsValidator.ValidateValue === Input_Error_State.Fail && (
                        <h6 className='font-bold text-red-600 text-left' >* {errorText}</h6>
                    )}
                    {(inputsValidator.ValidateValue === Input_Error_State.Success || inputsValidator.ValidateValue === Input_Error_State.Initial) && (
                        <h6 className='font-bold text-green-600 text-left'>&nbsp;</h6>
                    )}
                </div>
            </div>
        </div>
    )
}

export default EGInput