import { MenuItem, Modal, Select, SelectChangeEvent } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes, GetActionTypeString, GetErrorAxios, getDateTimeOnly, getYearMonthDayFromDate } from "../../../Globals";
import { GetAllUserDetail } from "../../../Service/Apis";
import { ContactsModel } from "../../../Service/models/ContactsModel/ContactsMOdel";
import { ACTIONS } from "../../../Service/models/UserModel/Actions/ActionsModel";
import { USER_Detail } from "../../../Service/models/UserModel/UserModel";
import { AddIconCustomized } from "../../../components/Icons/Icons";
import { ProjectsState } from "../../../redux/slices/Projects";
import { AppDispatch } from "../../../redux/store";
import './Actions.css';
import AppointmentWindow from "./ActionsDetailsWindows/AppointmentWindow";
import CallWindow from "./ActionsDetailsWindows/CallWindow";
import ClientWindow from "./ActionsDetailsWindows/ClientWindow";
import DealWindow from "./ActionsDetailsWindows/DealWindow";
import AppointMents from "./ActionsTypesScreen/AppointMents";
import Call from "./ActionsTypesScreen/Call";
import ClientData from "./ActionsTypesScreen/ClientData";
import Deal from "./ActionsTypesScreen/Deal";
import Percentage from "./ActionsTypesScreen/Percentage";
import SITEVISIT from "./ActionsTypesScreen/SiteVisit";


export const ModalAction = ({ contact_Current, closeFeed, ActionData }: { contact_Current: ContactsModel, closeFeed: () => void, ActionData: ACTIONS[] }) => {


    const [contactid, setContactID] = useState<number>(-1);
    const [ActionType, setActionType] = useState<ActionTypes>(0);
    const [selectedContact, setSelectedContact] = useState<ContactsModel>(contact_Current);



    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);


    const [open_client_history_flag, set_open_client_history_flag] = useState<boolean>(false);
    const false_client_history_flag = () => set_open_client_history_flag(false);
    const true_client_history_flag = () => set_open_client_history_flag(true);

    const projectsData = useSelector(ProjectsState);




    const ChangeComponent = (): JSX.Element => {
        switch (ActionType) {
            case (ActionTypes.ClientData): return <ClientData selectedContact={selectedContact} />
            case (ActionTypes.Call): return <Call projects={projectsData.status} selectedContact={selectedContact} />
            case (ActionTypes.Deal): return <Deal selectedContact={selectedContact} />
            case (ActionTypes.Appointment): return <AppointMents projects={projectsData.status} selectedContact={selectedContact} />

            default: return <></>
        }
    }

    useEffect(() => {
        ChangeComponent();
    }, [ActionType]);


    return <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-70 backdrop-blur"  >
        <div className="w-[100%] absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-1/2">
            <div className="w-full h-full flex flex-col justify-between">
                <div className='h-full w-full flex justify-center items-center'>
                    <ClientHistory closeModal={closeFeed} History={selectedContact.ACTIONS} selectedContact={contact_Current} />
                </div>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <ActionModal closeModal={handleClose} Comp={ChangeComponent()} />
                    </Modal>
                </div>
            </div>
        </div>
    </div>
}

export default ModalAction


const ActionModal = ({ closeModal, Comp }: { closeModal: () => void, Comp: JSX.Element }) => {
    return <div className="w-[90%] h-[90vh] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className='h-full w-full flex justify-center items-center'>
            <div className='bg-transparent backdrop-blur rounded-3xl w-full h-full flex flex-col p-5 justify-between items-center'>
                <div className='w-full h-full  flex flex-col justify-between  items-center relative'>
                    {Comp}
                    <div className='absolute top-3 left-3 i-material-symbols-close-rounded text-3xl text-red-500 hover:cursor-pointer' onClick={closeModal}>
                    </div>
                    {/* <div className="flex gap-2">
                        <div className='bg-[#fff] rounded-md hover:cursor-pointer  flex flex-col items-center' onClick={() => {
                            closeModal()
                        }}>
                            <h1 className="text-2xl p-2">إغلاق</h1>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
}

const ClientHistory = ({ closeModal, History, selectedContact }: { closeModal: () => void, History: ACTIONS[], selectedContact: ContactsModel }) => {

    const dispatch = useDispatch<AppDispatch>()
    const projectsData = useSelector(ProjectsState);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const [ActionType, setActionType] = useState<ActionTypes>(0);
    const [users, changeUsers] = useState<USER_Detail[]>([]);
    const [selectedAction, changeSelectedAction] = useState<ACTIONS>({} as ACTIONS);

    const DetailsWindow = useRef<HTMLDivElement>(null);

    const ChangeComponent = (): JSX.Element => {

        switch (ActionType) {
            case (ActionTypes.ClientData): return <ClientData selectedContact={selectedContact} />
            case (ActionTypes.Call): return <Call projects={projectsData.status} selectedContact={selectedContact} />
            case (ActionTypes.Deal): return <Deal selectedContact={selectedContact} />
            case (ActionTypes.Appointment): return <AppointMents projects={projectsData.status} selectedContact={selectedContact} />
            case (ActionTypes.SiteVisit): return <SITEVISIT selectedContact={selectedContact} />

            default:
                const _exhaustiveCheck: never = ActionType;
                return <></>;
        }
    }

    const ChangeDetailsWindowComponent = (): JSX.Element => {
        switch (selectedAction.ACTION_TYPE) {
            case (ActionTypes.ClientData): return <ClientWindow History={selectedAction} />
            case (ActionTypes.Call): return <CallWindow History={selectedAction} />
            case (ActionTypes.Deal): return <DealWindow History={selectedAction} />
            case (ActionTypes.Appointment): return <AppointmentWindow History={selectedAction} />



            default: return <></>
        }
    }

    useEffect(() => {
        GetAllUserDetail({ WithAdmin: true, WithNewUsers: false }).then(res => {
            changeUsers(res);
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
        HideModal();
    }, [])

    const ShowModal = () => {
        if (DetailsWindow.current) {
            DetailsWindow.current.classList.add("Show");
            DetailsWindow.current.classList.remove("Hide");
        }
    }

    const HideModal = () => {
        if (DetailsWindow.current) {
            DetailsWindow.current.classList.add("Hide");
            DetailsWindow.current.classList.remove("Show");
        }
    }


    return <div className="md:w-[70%] w-[100%] h-[90vh] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" >
        <div className='h-full w-full flex justify-center items-center'>
            <div className='bg-white backdrop-blur rounded-3xl w-full h-full flex flex-col overflow-hidden justify-between items-center' >
                <div className='w-full h-full  flex flex-col justify-start items-center relative overflow-auto'>
                    <div className="w-full">
                        <div className="w-full bg-[#e7c498] mb-5 flex justify-center">
                            <h3 className="text-3xl font-bold my-5">تاريخ العميل</h3>
                        </div>
                    </div>
                    <div className="w-full h-[70%] flex flex-col gap-4 items-center overflow-auto p-1 relative">
                        {History && History.map(history => {
                            let currentUser: USER_Detail = {} as USER_Detail;

                            if (users && Array.isArray(users) && users.length > 0) {
                                currentUser = users.filter(user => user.ID === history.USER_ID)[0];
                            }

                            return <div className="w-[80%]" >
                                {users.length > 0 && (
                                    <div dir="ltr" className="grid grid-cols-[auto_50px] gap-x-2 grid-rows-[25px_25px] bg-[#fCfCfC] shadow-md p-2 rounded-lg">
                                        <div dir="rtl" className=" col-start-1 col-span-1 row-start-1 row-span-1 flex items-center gap-2 border-b-2">
                                            <div className="i-material-symbols-connect-without-contact-outline-rounded text-2xl text-green-400" ></div>
                                            <p className="text-sm">قام <span className="font-bold text-md"> {currentUser.FULL_NAME.replace("+", " ")}</span> بإضافه إجراء <span className="font-bold text-md">{GetActionTypeString(history.ACTION_TYPE)}</span></p>
                                        </div>
                                        <div className="col-start-1 col-span-1 row-start-2 row-span-1 flex items-center justify-between gap-4" dir="rtl">
                                            <div className="flex items-center gap-1">
                                                <div className="i-material-symbols-calendar-month-outline-rounded text-2xl text-green-400" ></div>
                                                {getYearMonthDayFromDate(history.STAMP_DATE, true)}
                                            </div>
                                            <div className="flex items-center gap-1">
                                                <div className="i-material-symbols-nest-clock-farsight-analog-outline text-2xl text-green-400" ></div>
                                                {getDateTimeOnly(history.STAMP_DATE)}
                                            </div>
                                            <div className=" flex items-center gap-1 hover:cursor-pointer hover:text-[#e7c498] p-1" onClick={() => {
                                                changeSelectedAction(history);
                                                ShowModal();
                                            }}>
                                                <p> التفاصيل</p>
                                                <div className="i-material-symbols-keyboard-double-arrow-left-rounded text-2xl text-[#e7c498]" ></div>
                                            </div>

                                        </div>
                                        <div className="col-start-2 col-end-3 row-start-1 row-span-2 flex items-center justify-center">
                                            <div className=" w-full h-full object-cover flex items-center overflow-hidden rounded-lg">
                                                <img src={currentUser.PHOTO_URL} alt="User" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        })}
                        <div ref={DetailsWindow} className={"absolute transition-all duration-1000 ease-in-out top-0 overflow-hidden rounded-lg w-4/5 h-full bg-blue-50 p-2"}>
                            <div className="w-full overflow-auto relative p-4 h-full">
                                <h1 className="text-2xl font-bold mb-5">{GetActionTypeString(selectedAction.ACTION_TYPE)}</h1>
                                {ChangeDetailsWindowComponent()}
                            </div>
                            <div className='i-material-symbols-close-rounded text-3xl text-red-700 absolute top-5 left-5 hover:cursor-pointer mb-2' onClick={() => {
                                HideModal()
                            }} >
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full flex-wrap px-7 items-center gap-10 mt-20 h-[10%]">
                        <h3 className="text-2xl">الإجراء</h3>
                        <div className="w-1/2">
                            <div className='w-full flex flex-row gap-4'>
                                <Select
                                    className='w-full'
                                    value={ActionType}
                                    onChange={(e: SelectChangeEvent<number>) => {
                                        setActionType(e.target.value as number)
                                    }
                                    }
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={-1}>{"نوع الإجراء"}</MenuItem>
                                    <MenuItem value={ActionTypes.ClientData}>{"بيانات العميل"}</MenuItem>
                                    <MenuItem value={ActionTypes.Call}>{"مكالمه"}</MenuItem>
                                    <MenuItem value={ActionTypes.Appointment}>{"نتيجه اللقاء"}</MenuItem>
                                    <MenuItem value={ActionTypes.Deal}>{"تقفيل الإجراء"}</MenuItem>
                                    <MenuItem value={ActionTypes.SiteVisit}>{"زياره موقع"}</MenuItem>

                                </Select>
                            </div>
                        </div>
                        <div className="">
                            {(selectedContact.IS_BLOCKED !== undefined && selectedContact.IS_BLOCKED === false) ?
                                <div className="hover:cursor-pointer w-full" onClick={() => {
                                    if (selectedContact.IS_BLOCKED !== undefined && selectedContact.IS_BLOCKED === false) {
                                        handleOpen()
                                    }
                                }}>
                                    <div className="flex w-full gap-10 h-auto p-2 secondaryBG rounded-md items-center justify-between">
                                        <div className="h-7">
                                            {(selectedContact.IS_BLOCKED !== undefined && selectedContact.IS_BLOCKED === false) ? (
                                                <AddIconCustomized color="#000" />
                                            ) : (
                                                <h3 className="text-red-500">لايمكنك إضافه إجراء على هذا العميل</h3>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                :
                                <h3 className="text-red-500">لايمكنك إضافه إجراء على هذا العميل</h3>
                            }
                        </div>
                    </div>
                    <div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <ActionModal closeModal={handleClose} Comp={ChangeComponent()} />
                        </Modal>
                    </div>
                    <div className='i-material-symbols-close-rounded text-3xl text-red-700 absolute top-5 left-5 hover:cursor-pointer mb-2' onClick={() => {
                        closeModal()
                    }} >
                    </div>
                </div>
            </div>
        </div>
    </div>
}