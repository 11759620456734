import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut} from 'react-chartjs-2';
import { STA_DRAW_TYPE } from '../../../logic';
ChartJS.register(ArcElement, Tooltip, Legend);

const DOComp = ({data}:{data: STA_DRAW_TYPE}) => {
    return <>
        <Doughnut data={data} />;
    </>
}

export default DOComp