import { Box, List, ListItemIcon, ListItemText, MenuItem, useTheme, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NewDrawer } from '../../CustomComps/Customs';
import { HavePermission, PERMISSIONS } from '../../Globals';
import { BrushIcon, BrushIcon1, CARLOGO, CVICON, ContactLogo, DealLogo, DocumentIcon, EventIcon, MeetingIcon, MoneyTransfer, PollLogo, SVGComponent, SafeIcon, StatisticsLogo, TeamWorkLogo, WalletLogo } from '../../components/Icons/Icons';
import { UsersState } from '../../redux/slices/UserSlice';
import { tokens } from '../../theme';
import { dashBoardUrl } from '../../App';

const ConvertToH3 = ({ children }: { children: React.ReactNode }) => {
  return <h3 className='text-black'>
    {children}
  </h3>
}

interface AppDrawerProps {
  OpenVal: boolean;
}

function AppDrawer({ OpenVal }: AppDrawerProps) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const UsersData = useSelector(UsersState);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
  const [mainOpen, setmainOpen] = useState(true);
  const [marketingOpen, setMarketingOpen] = useState(false);
  const [projectPlansOpen, setProjectPlansOpen] = useState(false);
  const [accountingOpen, setAccountingOpen] = useState(false);
  const [FinishingOpen, setFinishingOpen] = useState(false);

  const handleToggle = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setState(prev => !prev);
  };

  const handleMenuItemClick = (id: string) => {
    setSelectedMenu(id);
  };

  const MenuItemSx = {
    '&.Mui-selected': {
      backgroundColor: "#DAD5D0",
      color: "#000"
    }
  };

  useEffect(() => {
    // Perform any side-effects related to UsersData.currentUser.PERMISSIONS
  }, [UsersData.currentUser.PERMISSIONS]);

  const menuItems = [
    {
      category: 'لوحة التحكم الرئيسية', state: mainOpen, setState: setmainOpen, items: [
        { path: `${dashBoardUrl}/welcome`, icon: <TeamWorkLogo />, text: 'شاشة الترحيب', permission: undefined },
        { path: `${dashBoardUrl}/Founders`, icon: <ContactLogo />, text: 'المؤسسين', permission: PERMISSIONS.Founders },
        { path: `${dashBoardUrl}/Owners`, icon: <ContactLogo />, text: 'الملاك', permission: PERMISSIONS.Owners },
        { path: `${dashBoardUrl}/JoinRequests`, icon: <CVICON />, text: 'تقديمات الوظائف', permission: PERMISSIONS.Career },
      ]
    },
    {
      category: 'التسويق', state: marketingOpen, setState: setMarketingOpen, items: [
        { path: `${dashBoardUrl}/Contacts`, icon: <ContactLogo />, text: 'جهات الإتصال', permission: PERMISSIONS.SAVE_CONTACT },
        { path: `${dashBoardUrl}/team`, icon: <TeamWorkLogo />, text: 'فريقي', permission: PERMISSIONS.TEAMS_SCREEN },
        { path: `${dashBoardUrl}/Statistics`, icon: <StatisticsLogo />, text: 'الإحصائيات', permission: PERMISSIONS.GET_STATISTICS },
        { path: `${dashBoardUrl}/TeamStatistics`, icon: <StatisticsLogo />, text: 'إحصائيات الفريق', permission: PERMISSIONS.GET_STATISTICS },
        { path: `${dashBoardUrl}/Actions`, icon: <DocumentIcon />, text: 'الإجرائات', permission: PERMISSIONS.Save_Action },
        { path: `${dashBoardUrl}/FeedBack`, icon: <PollLogo />, text: 'آراء العملاء', permission: PERMISSIONS.SAVE_CONTACT },
        { path: `${dashBoardUrl}/Meetings`, icon: <MeetingIcon />, text: 'طلبات المقابله', permission: PERMISSIONS.TEAMS_SCREEN },
        { path: `${dashBoardUrl}/DealsActions`, icon: <DealLogo />, text: 'الصفقات', permission: PERMISSIONS.getDeals },
      ]
    },
    {
      category: 'إدارة المشروعات', state: projectPlansOpen, setState: setProjectPlansOpen, items: [
        { path: `${dashBoardUrl}/Cities`, icon: <div className='i-material-symbols-home-work-outline-rounded text-4xl text-black'></div>, text: 'المدن', permission: PERMISSIONS.ADD_CITIES },
        { path: `${dashBoardUrl}/DevelopCompanies`, icon: <div className='i-material-symbols-home-work-outline-rounded text-4xl text-black'></div>, text: 'شركات التطوير', permission: PERMISSIONS.SAVE_DEVELOPMENT_COMPANIES },
        { path: `${dashBoardUrl}/ProjectsList`, icon: <div className='i-material-symbols-home-work-outline-rounded text-4xl text-black'></div>, text: 'المشاريع', permission: PERMISSIONS.SaveCompgains || PERMISSIONS.EDIT_PROJECTS },
        { path: `${dashBoardUrl}/AgentProjectsList`, icon: <div className='i-material-symbols-campaign-outline-rounded text-4xl text-black'></div>, text: 'الحملات الدعائيه', permission: PERMISSIONS.SAVE_CONTACT },
      ]
    },
    {
      category: 'الماليات', state: accountingOpen, setState: setAccountingOpen, items: [
        { path: `${dashBoardUrl}/Wallet`, icon: <WalletLogo />, text: 'المحفظة', permission: PERMISSIONS.GetWallet },
        { path: `${dashBoardUrl}/DealFounders`, icon: <DealLogo />, text: 'صفقات المحفظة', permission: PERMISSIONS.IS_FOUNDER || PERMISSIONS.SYS_ADMIN },
        { path: `${dashBoardUrl}/TransActions`, icon: <MoneyTransfer />, text: 'الحسابات', permission: PERMISSIONS.GetFinanceTransactions },
        { path: `${dashBoardUrl}/EventsData`, icon: <EventIcon />, text: 'الفعاليات', permission: PERMISSIONS.GET_EVENT },
        { path: `${dashBoardUrl}/Salary`, icon: <SVGComponent />, text: 'المرتبات', permission: PERMISSIONS.GET_SALARY },
        { path: `${dashBoardUrl}/Boxes`, icon: <MoneyTransfer />, text: 'الصناديق', permission: PERMISSIONS.Finance },
        { path: `${dashBoardUrl}/TransReqs`, icon: <CARLOGO />, text: 'طلبات التنقل', permission: PERMISSIONS.GetSiteReq },
      ]
    },
    {
      category: 'التشطيبات', state: FinishingOpen, setState: setFinishingOpen, items: [
        { path: `${dashBoardUrl}/NewFinishApplication`, icon: <BrushIcon />, text: 'طلب جديد', permission: undefined },
        { path: `${dashBoardUrl}/FinishingApplications`, icon: <BrushIcon1 />, text: 'طلبات التشطيبات', permission: undefined },
        { path: `${dashBoardUrl}/Finishingitems`, icon: <BrushIcon1 />, text: 'بنود التشطيب', permission: PERMISSIONS.SAVE_FINISH_ITEMS },
        { path: `${dashBoardUrl}/FinishingSafe`, icon: <SafeIcon />, text: 'الخزينه', permission: PERMISSIONS.Finance },

      ]
    },
  ];

  const currentPermissions = UsersData.currentUser.PERMISSIONS || new Map<string, boolean>();

  const filteredMenuItems = menuItems.filter(category =>
    category.items.some(item => !item.permission || HavePermission(item.permission, currentPermissions))
  );

  return (
    <NewDrawer transitionDuration={{ enter: 2000, exit: 2000 }} sx={{ zIndex: 0 }} anchor='right' variant="permanent" open={OpenVal} openval={OpenVal.toString()} drawercolor={colors.primary[400]}>
      <List>
        {filteredMenuItems.map((category, catIdx) => (
          <div key={catIdx}>
            <MenuItem onClick={() => handleToggle(category.setState)}>
              <ListItemText primary={category.category} />
              {category.state ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={category.state} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {category.items.map((item, itemIdx) => {
                  const itemId = `${catIdx}-${itemIdx}`;
                  return (
                    (!item.permission || HavePermission(item.permission, currentPermissions)) && (
                      <Link key={itemId} to={item.path}>
                        <MenuItem sx={MenuItemSx} selected={selectedMenu === itemId} onClick={() => handleMenuItemClick(itemId)}>
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: OpenVal ? 1 : 'auto',
                              ml: OpenVal ? 1 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <Box width='20px' height='20px'>
                              {item.icon}
                            </Box>
                          </ListItemIcon>
                          <ConvertToH3>
                            <ListItemText primary={item.text} sx={{ textAlign: 'start', opacity: OpenVal ? 1 : 0, display: OpenVal ? 'block' : 'none' }} />
                          </ConvertToH3>
                        </MenuItem>
                      </Link>
                    )
                  );
                })}
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </NewDrawer>
  );
}

export default AppDrawer;
