import React, { useEffect, useRef, useState } from 'react'
import { GET_ALL_TRANSPORT_REQUEST, SAVE_SITE_VISIT_STATUS, SaveVisitExpenses } from '../../Service/Apis'
import { GetErrorAxios } from '../../Globals'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { SITE_VISIT_MODEL, STATUS } from '../../Service/models/SITEVISITMODEL';
import { EditLogo, RateIcon, UserActionLogo, ViewLogo } from '../../components/Icons/Icons';
import DevExtremTable from '../../components/shared/EGDExtremeTable';
import './style.css'
import { AddAction } from '../../redux/slices/ErrorsSlice';
import GModal from '../../components/GModal';
import { CustomTextField } from '../../CustomComps/Customs';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';

const TRANSRequest = () => {

  const [MovReq, setMovReq] = useState<SITE_VISIT_MODEL[]>([])
  const MoveReq = useRef<HTMLDivElement>(null);
  const [currentAction, setCurrentAction] = useState<SITE_VISIT_MODEL>({} as SITE_VISIT_MODEL);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setModalOpen(false)
  }
  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };


  const dispatch = useDispatch<AppDispatch>();

  const hideDealModel = () => {
    if (MoveReq.current) {
      MoveReq.current.classList.add('hide')
      MoveReq.current.classList.remove('show')
    }
  }

  const showDealModel = () => {
    if (MoveReq.current) {
      MoveReq.current.classList.add('show')
      MoveReq.current.classList.remove('hide')
    }
  }

  const getAllTranReqData = () => {
    GET_ALL_TRANSPORT_REQUEST().then((res) => {
      setMovReq(res)

    }).catch((err) => {
      GetErrorAxios(err, dispatch)
    })
  }

  const reflectStatus = (status: STATUS) => {
    switch (status) {
      case STATUS.STATUS_TYPE_PENDING:
        return <h3 className=' font-bold'></h3>
      case STATUS.STATUS_TYPE_ACCEPTED:
        return <h3 className='text-green-500 font-bold'>مقبول</h3>
      case STATUS.STATUS_TYPE_REJECTED:
        return <h3 className='text-red-500 font-bold'>مرفوض</h3>
      default:
        return ''
    }

  }

  const sendSiteVisitAction = (status: STATUS) => {
    SAVE_SITE_VISIT_STATUS({ ...currentAction, STATUS: status }).then((res) => {
      if (status === STATUS.STATUS_TYPE_ACCEPTED) {
        dispatch(AddAction({ message: "تم قبول الطلب", status: "success" }))
      } else {
        dispatch(AddAction({ message: "تم رفض الطلب", status: "success" }))
      }

      hideDealModel()
    }).catch((err) => {
      GetErrorAxios(err, dispatch)
    })
  }

  const handleAmount = (e: any) => {
    const newAmount = parseFloat(e.target.value);
    setCurrentAction(prev => ({
      ...prev,
      AMOUNT: newAmount,
    }));
  };

  const handleSave = () => {
    SaveVisitExpenses(currentAction)
      .then(res => {
        
        dispatch(AddAction({ message: "تم تأكيد الطلب", status: "success" }))
        // handleCloseModal()


      })
      .catch((err) => {
        dispatch(AddAction({ message: "تم رفض الطلب", status: "success" }))
        GetErrorAxios(err, dispatch)
      })

  };

  useEffect(() => {
    getAllTranReqData()
  }, [])
  useEffect(() => {
    
  }, [modalOpen])



  return (
    <div>
      <DevExtremTable ID="CONTACT_ID" tableData={[
        { Caption: "مقدم الطلب", Type: 'string', Value: 'STAMP_USER' },
        { Caption: "اسم العميل", Type: 'string', Value: 'CUSTOMER_NAME' },
        { Caption: "رقم التليفون", Type: 'string', Value: 'CUSTOMER_MOBILE_NO' },
        { Caption: "المدينيه", Type: 'string', Value: 'VISIT_SITE' },
      ]}
        dataSource={MovReq}
        Actions={(data: { data: SITE_VISIT_MODEL }) => {
          let currentAction = data.data as SITE_VISIT_MODEL
          return (
            <div className=" flex items-center gap-4 hover:cursor-pointer  p-1">
              <div className='flex flex-col items-center hover:text-[#e7c498]'>
                <div className='bg-[#e2edf1] rounded shadow h-6 w-6 hover:cursor-pointer' onClick={() => {
                  //modelFN(row);
                  showDealModel()
                  setCurrentAction(currentAction)

                }}>
                  <ViewLogo />
                </div>
                <p className='text-sm font-bold'>عرض</p>
              </div>
            </div>
          )
        }}
      />
      {/* <div ref={MoveReq} className='transition-all duration-1000 ease-in-out hide fixed top-[12.5%] rounded-2xl shadow-xl  w-3/4 bg-slate-100 z-[100] overflow-hidden'>
        <div className='w-full h-full relative'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-between items-center p-4 bg-slate-100'>
              <div className='text-xl font-bold'>
                تفاصيل الطلب
              </div>
            </div>
            <div className='bg-white w-2/3 rounded-3xl shadow-xl self-center p-5'>
              <div className='grid grid-cols-2 gap-1 w-full'>
                <div className='flex flex-col  gap-5 '>
                  <div>
                    <div className='text-lg font-bold'>
                      مقدم الطلب
                    </div>
                    <div>
                      {currentAction.STAMP_USER}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      رقم تليفون مقدم الطلب
                    </div>
                    <div>
                      {currentAction.CONTACT_USER?.MOBILE_NO}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      تاريخ الزياره
                    </div>
                    <div>
                      {currentAction.VISIT_DATE?.toString().split('T')[0]}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      مكان التحرك
                    </div>
                    <div>
                      {currentAction.START_LOCATION}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-5 '>
                  <div>
                    <div className='text-lg font-bold'>
                      اسم العميل
                    </div>
                    <div>
                      {currentAction.CUSTOMER_NAME}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      رقم التليفون
                    </div>
                    <div>
                      {currentAction.CUSTOMER_MOBILE_NO}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      المدينيه
                    </div>
                    <div>
                      {currentAction.SITE?.SITE_NAME_AR}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-4'>
              <div className='flex flex-row gap-4'>


              </div>
              <div className='flex flex-row gap-4'>
                <div>
                  <div className='text-lg font-bold'>
                    الحاله
                  </div>
                  <div>
                    {reflectStatus(currentAction.STATUS!)}
                  </div>
                </div>

                <div>
                  <div className='text-lg font-bold'>
                  </div>
                  <div className='absolute top-4 left-4 i-material-symbols-close-rounded text-xl text-red-500 hover:cursor-pointer' onClick={() => hideDealModel()}>
                  </div>
                </div>
              </div>
              {currentAction.STATUS === STATUS.STATUS_TYPE_PENDING && (
                <div className='flex flex-row gap-4 mt-9'>
                  <div className="bg-green-500 text-white px-6 py-2 rounded-2xl inline-flex items-center justify-center hover:cursor-pointer" onClick={() => {
                    sendSiteVisitAction(STATUS.STATUS_TYPE_ACCEPTED)
                  }}>
                    موافق
                  </div>
                  <div className="bg-red-500 text-white px-6 py-2 rounded-2xl inline-flex items-center justify-center hover:cursor-pointer" onClick={() => {
                    sendSiteVisitAction(STATUS.STATUS_TYPE_REJECTED)
                  }}>
                    رفض
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}
      <div ref={MoveReq} className='transition-all duration-1000 ease-in-out hide fixed top-[12.5%] rounded-2xl shadow-xl  w-3/4 bg-slate-100 z-[100] overflow-hidden'>
        <div className='w-full h-full relative'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-between items-center p-4 bg-slate-100'>
              <div className='text-xl font-bold'>
                تفاصيل الطلب
              </div>
            </div>
            <div className='bg-white w-2/3 rounded-3xl shadow-xl self-center p-5'>
              <div className='grid grid-cols-2 gap-1 w-full'>
                <div className='flex flex-col  gap-5 '>
                  <div>
                    <div className='text-lg font-bold'>
                      مقدم الطلب
                    </div>
                    <div>
                      {currentAction.STAMP_USER}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      رقم تليفون مقدم الطلب
                    </div>
                    <div>
                      {currentAction.CONTACT_USER?.MOBILE_NO}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      تاريخ الزياره
                    </div>
                    <div>
                      {currentAction.VISIT_DATE?.toString().split('T')[0]}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      مكان التحرك
                    </div>
                    <div>
                      {currentAction.START_LOCATION}
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-5 '>
                  <div>
                    <div className='text-lg font-bold'>
                      اسم العميل
                    </div>
                    <div>
                      {currentAction.CUSTOMER_NAME}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      رقم التليفون
                    </div>
                    <div>
                      {currentAction.CUSTOMER_MOBILE_NO}
                    </div>
                  </div>
                  <div>
                    <div className='text-lg font-bold'>
                      المدينيه
                    </div>
                    <div>
                      {currentAction.SITE?.SITE_NAME_AR}
                    </div>
                    {currentAction.STATUS === STATUS.STATUS_TYPE_ACCEPTED && (
                      <div className='flex flex-row gap-4 mt-9'>
                        <button className="bg-green-500 text-white px-6 py-2 rounded-2xl inline-flex items-center justify-center hover:cursor-pointer"
                          onClick={() => {
                            setModalOpen(true)

                            
                          }} >
                          تحديد رسوم الإنتقال
                        </button>
                      </div>
                    )}


                  </div>
                </div>
              </div>
            </div>
            <div className='p-4'>
              <div className='flex flex-row gap-4'>


              </div>
              <div className='flex flex-row gap-4'>
                <div>
                  <div className='text-lg font-bold'>
                    الحاله
                  </div>
                  <div>
                    {reflectStatus(currentAction.STATUS!)}
                  </div>
                </div>

                <div>
                  <div className='text-lg font-bold'>
                  </div>
                  <div className='absolute top-4 left-4 i-material-symbols-close-rounded text-xl text-red-500 hover:cursor-pointer' onClick={() => hideDealModel()}>
                  </div>
                </div>
              </div>
              {currentAction.STATUS === STATUS.STATUS_TYPE_PENDING && (
                <div className='flex flex-row gap-4 mt-9'>
                  <div className="bg-green-500 text-white px-6 py-2 rounded-2xl inline-flex items-center justify-center hover:cursor-pointer" onClick={() => {
                    sendSiteVisitAction(STATUS.STATUS_TYPE_ACCEPTED)
                  }}>
                    موافق
                  </div>
                  <div className="bg-red-500 text-white px-6 py-2 rounded-2xl inline-flex items-center justify-center hover:cursor-pointer" onClick={() => {
                    sendSiteVisitAction(STATUS.STATUS_TYPE_REJECTED)
                  }}>
                    رفض
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <GModal onCloseModal={() => {
        
        setModalOpen(false)
      }} OpenModal={modalOpen} >
        <div className='flex flex-col  gap-5  mt-8'>
          <div className='text-lg font-bold'>
            <CustomTextField type={'number'}
              style={MainInputStyle}
              colors={colors}
              theme={theme}
              onChange={handleAmount}
              value={currentAction.AMOUNT}

            />
          </div>
          <div className='text-lg font-bold'>
            <button className="bg-green-500 text-white px-6 py-2 rounded-2xl inline-flex items-center justify-center hover:cursor-pointer" onClick={handleSave} >
              تأكيد قيمة المصروفات
            </button>
          </div>

        </div>
      </GModal>
    </div>
  )
}

export default TRANSRequest