import moment from "moment";
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import { Calendar, EventProps, momentLocalizer, View } from "react-big-calendar";
import './Calender.css';

// import Dialog from "material-ui/Dialog";
// import FlatButton from "material-ui/FlatButton";
// import TextField from "material-ui/TextField";
// import TimePicker from "material-ui/TimePicker";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { DaysNames, getCallActionTypes, getDateTimeOnly, GetErrorAxios, getYearMonthDayFromDate } from '../../../Globals';
import { ContactState } from '../../../redux/slices/ContactsSlice';
import { AppDispatch } from '../../../redux/store';
import { getUserCalender } from '../../../Service/Apis';
import { USER_CALENDER } from '../../../Service/models/CalenderModel/CalenderModel';
import { UserModel } from '../../../Service/models/UserModel/UserModel';
import { AddIcon } from '../../Icons/Icons';
import AlertDialogSlide from "./Dialog";
import { TCalender } from "./events";
import { ContactsModel } from "../../../Service/models/ContactsModel/ContactsMOdel";
require("react-big-calendar/lib/css/react-big-calendar.css");

moment.locale('ar');
const localizer = momentLocalizer(moment);

type DateRangeType = {
  From: Date,
  To: Date
}

enum ViewTypes {
  Agenda = 'agenda',
  Day = 'day',
  WorkWeek = 'work_week',
  Week = 'week',
  Month = 'month'
}


const EGCalender = ({ appointments }: { appointments?: TCalender[] }) => {

  const dispatch = useDispatch<AppDispatch>();


  const [dateRange, setDateRange] = useState<DateRangeType>({ To: new Date(), From: new Date() })
  const [currentView, setCurrentView] = useState<View>(ViewTypes.Month);

  const [calenderAppointments, setCalenderAppointments] = useState<USER_CALENDER[]>([]);
  const [selectedCalender, setselectedCalender] = useState<USER_CALENDER>({} as USER_CALENDER);
  const [mappedCalenderAppointments, setMappedCalenderAppointments] = useState<TCalender[]>([]);
  const [date_initial, set_date_initial] = useState<Date>(new Date());
  const ReservsModalRef = useRef<HTMLDivElement>(null);
  const [render, setRender] = useState<number>(0);
  const constactsData = useSelector(ContactState);

  const getMyCalendar = async () => {
    try {
      let myCalendars = await getUserCalender();
      if (myCalendars.length > 0) {
        setCalenderAppointments(myCalendars)
        let calenderTypeHolder: TCalender[] = [];
        myCalendars.map(cal => {
          calenderTypeHolder.push({ id: cal.ID!, title: cal.TITLE, start: new Date(cal.TIME_FROM), end: new Date(cal.TIME_TO), type: cal.CALENDER_TYPE, NumberOfReservs: cal.USER_RESERVATION?.length })
        })
        setMappedCalenderAppointments(calenderTypeHolder);
      }
    } catch (err) {
      GetErrorAxios(err, dispatch)
    }

  }

  useEffect(() => {
    ReservsModalRef.current?.classList.add('RemoveModalWidth');
    getMyCalendar();
    calender_names_update()
  }, [])

  const calender_names_update = () => {

    const button_groups = document.getElementsByClassName('rbc-btn-group');
    if (button_groups) {
      for (let groub of button_groups) {
        for (let element of groub.children) {


          switch (element.innerHTML) {
            case 'Today':
              element.innerHTML = 'اليوم'
              break;
            case 'Back':
              element.innerHTML = 'السابق'
              break;
            case 'Next':
              element.innerHTML = 'التالي'
              break;
            case 'Agenda':
              element.innerHTML = 'الأعمال'
              break;
            case 'Day':
              element.innerHTML = 'اليوم'
              break;
            case 'Work Week':
              element.innerHTML = 'اعمال الإسبوع'
              break;
            case 'Week':
              element.innerHTML = 'اسبوع'
              break;
            case 'Month':
              element.innerHTML = 'شهر'
              break;

          }


          // if(element.innerHTML === 'Today'){
          //   element.innerHTML = 'اليوم'
          // }
        }
      }
    }
  }

  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <div className='secondaryBG justify-center rounded-lg mb-5' >
        <div className='h-10 px-4 w-full gap-2 flex items-center rounded-md z-[100]  overflow-hidden hover:cursor-pointer' onClick={() => {
          setOpen(true)
        }}>
          <div className="w-5  h-full " >
            <AddIcon FillColor={"#dd9133"} />
          </div>
          <p className='font-bold text-lg '>إضافه موعد</p>
        </div>
      </div>
      <div className="h-[80vh] w-full">
        <Calendar
          events={mappedCalenderAppointments ?? []}
          localizer={localizer}
          views={["agenda", "day", "work_week", "week", "month"]}
          components={{ event: TestIt }}
          //defaultDate={date_initial}
          date={date_initial}
          selectable={true}
          //min={new Date()}
          step={15}
          culture={'ar'}
          rtl
          className={"calendar"}
          onView={(view: View) => {
            setCurrentView(view)
          }}
          view={currentView}
          onNavigate={date => {

            set_date_initial(new Date(date));
            setDateRange({ From: date, To: date })
          }}
          onSelecting={slot => false}
          onSelectEvent={(event) => {
            let CalFilter = calenderAppointments.filter(cal => cal.ID === event.id)[0];
            if (ReservsModalRef.current) {
              ReservsModalRef.current?.classList.remove('RemoveModalWidth');
              ReservsModalRef.current.classList.add('ModalWidth');
              setRender(prev => prev + 1)
            }
            if (CalFilter) {
              setselectedCalender(CalFilter)
            }
          }}
          onSelectSlot={(event) => {
            if (currentView === ViewTypes.Month) {
              set_date_initial(new Date(event.start));
              setDateRange({ From: event.start, To: event.end })
              setCurrentView(ViewTypes.Day);
              return;
            }


            //alert(event.start + " --/-- " + event.end)
            setOpen(true)
            setDateRange({ From: event.start, To: event.start })
          }
          }
        />
        <AlertDialogSlide openFlagStatus={open} From={new Date(dateRange.From)} To={new Date(dateRange.To)} openFlag={() => setOpen(true)} closeFlag={() => setOpen(false)} success_data={(reserv_data) => {
          if (reserv_data) {
            getMyCalendar();
          }
        }} />
        <div ref={ReservsModalRef} className='fixed top-0 right-0 bg-[#fcfcfc] shadow-md transition-all duration-1000 p-5 h-full z-[9999] rounded-3xl RemoveModalWidth'>
          {ReservsModalRef.current?.classList.contains('ModalWidth') && (
            <div className='absolute top-2 left-2 i-material-symbols-close-rounded text-3xl text-red-700 font-bold hover:cursor-pointer' onClick={() => {
              ReservsModalRef.current?.classList.add('RemoveModalWidth');
              ReservsModalRef.current?.classList.remove('ModalWidth');
              setRender(prev => prev + 1)
            }}>
              tttttt
            </div>
          )}
          <div className='flex flex-col gap-4'>
            <div className='flex flex-wrap gap-2 items-center'>
              <h5>نوع المقابله</h5>
              <h5 className='font-bold text-xl'>{getCallActionTypes(selectedCalender.CALENDER_TYPE)}</h5>
            </div>
            <hr className='bg-gray-200 w-full h-[1px] self-center' />
            <div className='flex flex-wrap gap-2 items-center'>
              <h5>غرض المقابله</h5>
              <h5 className='font-bold text-xl'>{selectedCalender.TITLE}</h5>
            </div>
            <hr className='bg-gray-200 w-full h-[1px] self-center' />
            <div className='flex flex-wrap gap-2 items-center'>
              <h5>موعد المقابله الرئيسي</h5>
              <div className="flex flex-wrap items-center gap-2 w-[auto] bg-white shadow-md rounded-md p-2">
                <div className="i-mdi-calendar-check  text-3xl text-green-400">
                </div>
                <div className="flex gap-2">
                  <h5 className="font-bold text-xl">{DaysNames[new Date(selectedCalender.TIME_FROM).getDay()]}</h5>
                  <h5 className="font-bold text-xl">{getYearMonthDayFromDate(new Date(selectedCalender.TIME_FROM))}</h5>
                </div>
                <h5>من <span className="font-bold text-lg">{getDateTimeOnly(selectedCalender.TIME_FROM as Date)}</span></h5>
                <h5>الى <span className="font-bold text-lg">{getDateTimeOnly(selectedCalender.TIME_TO as Date)}</span></h5>
              </div>
            </div>
            <hr className='bg-gray-200 w-full h-[1px] self-center' />
            <div className='flex flex-col flex-wrap gap-2 items-start justify-start'>
              <h5>الحجوزات</h5>
              <div className="flex flex-wrap w-full gap-4">
                {selectedCalender && selectedCalender.USER_RESERVATION && selectedCalender.USER_RESERVATION?.length > 0 && selectedCalender.USER_RESERVATION.map((reserv, idx) => {
                  let currentUser: ContactsModel = {} as ContactsModel
                  if (constactsData.AllContacts.length > 0) {
                    currentUser = constactsData.AllContacts.filter(contact => contact.CONTACT_ID === reserv.CONTACT_ID)[0];
                  }


                  return <div key={reserv.ACTION_ID} className="flex flex-wrap items-center gap-2 w-[30%] bg-white shadow-md rounded-md p-2">
                    <div className="i-mdi-calendar-check  text-3xl text-green-400">
                    </div>
                    <div className="flex gap-2">
                      <h5 className="font-bold text-xl">{DaysNames[new Date(reserv.TIME_FROM).getDay()]}</h5>
                      <h5 className="font-bold text-xl">{getYearMonthDayFromDate(new Date(reserv.TIME_FROM))}</h5>
                    </div>
                    <h5>من <span className="font-bold text-lg">{getDateTimeOnly(reserv.TIME_FROM as Date)}</span></h5>
                    <h5>الى <span className="font-bold text-lg">{getDateTimeOnly(reserv.TIME_TO as Date)}</span></h5>
                    <h5>المشروع <span className="font-bold text-lg">{reserv.TITLE}</span></h5>
                    <h5>العميل <span className="font-bold text-lg">{currentUser.NAME}</span></h5>
                  </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EGCalender


const TestIt = (props: EventProps<TCalender>) => {
  let colorTest = "#e7c498"
  return (
    <div style={{ backgroundColor: colorTest, borderRadius: '.5em', paddingLeft: '.25rem', paddingRight: '.25rem' }}>
      <div className={`w-full h-[2em] rounded-lg px-1 flex flex-col justify-center items-center relative`}>
        <h5 className={`truncate`}>{props.event.title}</h5>
        {props.event.NumberOfReservs !== undefined && props.event.NumberOfReservs > 0 && (
          <div className="absolute left-1 top-1 bg-[#002f3f] p-1 h-5 w-5 flex items-center justify-center rounded-[50%]">
            <h5 className={`truncate text-white text-center`}>{props.event.NumberOfReservs}</h5>
          </div>
        )}
      </div>
    </div>
  )
}

// calendar: {
//   '& > .rbc-calendar': {
//     width: '100%',
//     minHeight: '500px',
//   },
//   '& .rbc-month-view': {
//     border: 'none',
//     '& .rbc-event': {
//       paddingLeft: '2px',
//       borderRadius: 3,
//       backgroundColor: theme.palette.secondary.main,
//     },
//     '& .rbc-now': {
//       color: theme.palette.secondary.main,
//     },
//     '& .rbc-selected-cell': {
//       backgroundColor: '#fff',
//     },
//     '& .rbc-today.rbc-selected-cell': {
//       backgroundColor: '#eaf6ff',
//     },
//     '& .rbc-off-range-bg.rbc-selected-cell': {
//       background: '#e6e6e6',
//     },
//     '& .rbc-month-row': {
//       cursor: 'pointer',
//     },
//   },
// },