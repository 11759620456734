import React, { useEffect, useState } from 'react'
import { HeaderH1Text } from '../../../../../CustomComps/Customs'
import { GetRankedUser } from '../../../../../Service/Apis';
import { UserModel } from '../../../../../Service/models/UserModel/UserModel';

const TopMarketers = () => {

    const [RankedUser, setRankedUsers] = useState<UserModel[]>([]);


    useEffect(() => {
        setTimeout(async () => {
            let rankedUsers = await GetRankedUser();
            
            
            if ( rankedUsers && rankedUsers.length > 0) {
                setRankedUsers(rankedUsers);
            }
        }, 100);

    }, []);


    return (
        <div className='flex flex-col flex-wrap'>
            <HeaderH1Text className='mb-20' weight='bold'>
                أفضل المسوقين
            </HeaderH1Text>

            <div className='self-center items-center md:items-center flex flex-col md:flex-row gap-2 overflow-auto p-1'>
                {RankedUser.length > 0 && (
                    RankedUser.map(user => (
                        <div>
                            <div className='w-[15em] h-[25em] flex flex-col justify-around rounded-lg p-3 bg-white shadow-md'>
                                <div className='w-full h-3/6  self-center rounded-[10%] overflow-hidden bg-no-repeat bg-cover bg-center'>
                                    <img className='object-cover' height='100%' width='100%' src={user.PROFILE_PIC_URL} alt={user.FULL_NAME} />
                                </div>
                                <div className='flex flex-col justify-center  items-center'>
                                    <h3 className='text-xl mb-4 text-[#000] font-bold'>{user.FULL_NAME}</h3>
                                    <h3 className='text-xl mb-4 text-[#000] font-bold'>{user.USER_JOB_NAME}</h3>
                                    {/* <div className='flex flex-row gap-2'>
                                        <h3 className='text-base mb-4 text-[#000] font-bold'>الترتيب</h3>
                                        <h3 className='text-xl mb-4 text-[#000] font-bold'>{user.RANK}</h3>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>


        </div>
    )
}

export default TopMarketers