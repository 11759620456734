import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

const HLSVideoPlayer = ({ src }) => {
    const videoRef = useRef(null);
    const hlsRef = useRef(null);
    const [levels, setLevels] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        function setupHls() {
            const hls = new Hls({
                autoStartLoad: true,
                maxBufferLength: 2,
                maxBufferSize: 10 * 1000 * 1000, // 10 MB
                maxBufferHole: 0.5, // 0.5 seconds
                lowLatencyMode: true,
                fastSwitching: true,
                abrEwmaFastLive: 5.0,
                abrEwmaSlowLive: 9.0,
                abrBandWidthFactor: 0.95,
                abrBandWidthUpFactor: 1.05,
                maxStarvationDelay: 4,
                maxLoadingDelay: 3
            });
            hlsRef.current = hls;

            hls.loadSource(src);
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                // 
            });
            hls.on(Hls.Events.MANIFEST_PARSED, function () {
                setLevels(hls.levels);
                // videoRef.current.play().catch(error => console.error('Error attempting to play', error));
            });

            hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
                
            });

            hls.on(Hls.Events.FRAG_CHANGED, function (event, data) {
                
            });

            hls.on(Hls.Events.ERROR, function (event, data) {
                if (data.fatal) {
                    switch(data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            
                            hls.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            
                            hls.recoverMediaError();
                            break;
                        default:
                            hls.destroy();
                            break;
                    }
                }
            });
        }

        if (Hls.isSupported()) {
            setupHls();
        } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = src;
            videoRef.current.play().catch(error => console.error('Error attempting to play', error));
        }

        return () => {
            if (hlsRef.current) {
                hlsRef.current.destroy();
            }
        };
    }, [src]);

    const handleQualityChange = (event) => {
        const selectedLevel = parseInt(event.target.value, 10);
        if (hlsRef.current) {
            const currentTime = videoRef.current.currentTime;
            hlsRef.current.nextLevel = selectedLevel;  // Request the switch to the next level
            hlsRef.current.once(Hls.Events.LEVEL_SWITCHED, () => {
                videoRef.current.currentTime = currentTime; // Seek to the current time after the level has switched
            });
        }
    };
    

    const handlePlay = () => {
        setIsPlaying(true);
    };

    return (
        <div>
            <video ref={videoRef} controls style={{ width: "100%" }} onPlay={handlePlay} />
            {isPlaying && levels.length > 0 && (
                <div>
                    <label htmlFor="quality">Quality:</label>
                    <select id="quality" onChange={handleQualityChange} defaultValue="auto">
                        <option value="-1">Auto</option>
                        {levels.map((level, index) => (
                            <option value={index} key={index}>
                                {level.height}p ({Math.round(level.bitrate / 1000)} kbps)
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};

export default HLSVideoPlayer;
