
import React, { useState, useRef, useEffect } from 'react';

interface ImageUploaderProps {
  // onImageUpload: (base64: string, fileName: string) => void;
  onImageUpload: (data: { base64: string, fileName: string }[]) => void
  multiImage?: boolean,
  Accept?: "Img" | "Pdf" | "ImgPdf"
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageUpload, multiImage, Accept }) => {

  // if accept prop is undefined, set it to image/jpeg,image/png
  if (Accept === undefined) {
    Accept = 'Img'
  }


  const [isDragging, setIsDragging] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState<string | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [images, setImages] = useState<{ fileName: string, base64: string }[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [Ext, setExt] = useState<string>("")

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFile = (file: File) => {
    setPreview(null);
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadstart = () => {
      setUploadProgress(0);
    };

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded / event.total) * 100);
        setUploadProgress(progress);
      }
    };

    reader.onloadend = () => {
      setUploadProgress(100);
      if (typeof reader.result === 'string') {
        setPreview(reader.result);
        setImages(prev => ([...prev, { fileName: file.name, base64: reader.result?.toString() ?? "" }]))
        // onImageUpload(reader.result, file.name);
        onImageUpload([...images, { fileName: file.name, base64: reader.result?.toString() ?? "" }]);
      }
    };

    reader.onerror = () => {
      console.error('Error reading file');
      setUploadProgress(0);
    };

    reader.readAsDataURL(file);
  };

  const handleRemoveImage = (item: { fileName: string, base64: string }) => {
    let removeElement = images.filter(imgs => imgs.fileName !== item.fileName)

    setImages(removeElement)
    onImageUpload(removeElement)

  }

  useEffect(() => {
    if(Accept === 'Img'){
      setExt('image/jpeg,image/png')
    }else if(Accept === 'Pdf'){
      setExt('application/pdf')
    } else if(Accept === 'ImgPdf'){
      setExt('image/jpeg,image/png,application/pdf')
    }

  }, [Accept])

  return (
    <div className="w-full max-w-md mx-auto">
      <div
        className={`flex flex-col border-4 border-dashed rounded-lg p-4 text-center cursor-pointer ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
          }`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
      >
        <>
          <div className="text-gray-600 mb-2">
            <svg className="w-12 h-12 mx-auto mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            اسحب  {Accept === 'Pdf' ? "ملف" : "صور"} هنا او اضغط للتصفح
          </div>
          <div className="text-sm text-gray-500">{Accept === 'Pdf' ? " الصيغ المدعومه PDF" : Accept === 'Img' ? "الصيغه المدعومه: JPG, JPEG2000, PNG":"الصيغ المدعومه  الصور و ملفات الـ ؛PDF"}</div>
        </>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileInput}
        accept={Ext}
      />
      <div className='flex flex-wrap items-start gap-1'>
        {preview && images.map((item, idx) => {
          return <div key={item.fileName}>
            <div className='flex flex-col justify-center items-center mt-10 w-12 h-12 relative'>
              <img src={item.base64} alt="Preview" width={multiImage ? 50 : 250} height={multiImage ? 50 : 250} className="max-w-full h-auto mb-2 rounded object-cover" />
              {/* <p className="text-sm text-gray-600 w-full">{item.fileName}</p> */}
              <div className='absolute -top-2 left-0 w-full h-full hover:cursor-pointer z-[1000]' onClick={() => {
                handleRemoveImage(item)
              }}>
                <div className='w-1/2 h-1/2 rounded-lg bg-red-100 flex justify-center items-center'>
                  <div className='i-material-symbols-close-rounded text-xl text-red-500'>
                  </div>
                </div>
              </div>
            </div>
          </div>
        })}
      </div>
      {uploadProgress > 0 && uploadProgress <= 100 && (
        <div className="mt-4 flex flex-col">
          <div className="bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
          <div className="text-right text-sm text-gray-500 mt-1 self-center">
            تم تحميل... {uploadProgress}%
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;