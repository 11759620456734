import { ChangeEvent, useEffect, useState } from 'react'

import ApartmentIcon from '@mui/icons-material/Apartment'
import EmailIcon from '@mui/icons-material/Email'
import { Box, InputAdornment, Stack, Typography, useTheme } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { CustomTextField } from '../../../../CustomComps/Customs'
import { GetErrorAxios } from '../../../../Globals'
import { AddAction } from '../../../../redux/slices/ErrorsSlice'
import { SaveJobThunk } from '../../../../redux/slices/JobsSlice'
import { GetAllPermissionThunk, PermissionsState } from '../../../../redux/slices/PermissionsSlice'
import { AppDispatch } from '../../../../redux/store'
import { GetAllJobsAPI, SaveJobAPI } from '../../../../Service/Apis'
import { JobsModel } from '../../../../Service/models/Jobs/Jobs'
import { PermissionModel, PermissionModel_item } from '../../../../Service/models/Permission/Permission'
import { tokens } from '../../../../theme'
import { DropDownData, DropTextField } from '../../../DashBoard/Actions/ActionsTypesScreen/AppointMents'
import { EgButton } from '../../../Shared/Shared'
import GModal from '../../../../components/GModal'


type IUserPermissions = {
  permission: PermissionModel,
  status: boolean;
}


const Jobs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch<AppDispatch>();

  const Permissions = useSelector(PermissionsState);
  const [PermissionMap, setPermissionMap] = useState<IUserPermissions[]>();
  const [userJobs, setUserJobs] = useState<JobsModel[]>();
  const [JobPermission, setJobPermission] = useState<PermissionModel_item[]>([]);
  const [JobModelReq, setJobModelReq] = useState<JobsModel>({} as JobsModel);
  const [jobsDD, setJobsDD] = useState<DropDownData[]>([]);
  const [selectedJobIndex, setSelectedJobIndex] = useState<number>(-1);
  const [selectedJob, setSelectedJob] = useState<JobsModel>({ ID: -1, COMPANY_ID: -1, JOB_DESCRIPTION: '', JOB_DESCRIPTION_EN: '' });
  const [modifiedPermissions, setModifiedPermissions] = useState<PermissionModel[]>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);


  const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<JobsModel>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {}
  });

  const { ref: DescRef, ...DescProps } = register("JOB_DESCRIPTION", {
    required: "هذا الحقل مطلوب",
    // pattern: {
    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    //     message: "نمط البريد غير صحيح"
    // }

  });
  const { ref: DescEngRef, ...DescEngProps } = register("JOB_DESCRIPTION_EN", {
    required: "هذا الحقل مطلوب",
    // pattern: {
    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    //     message: "نمط البريد غير صحيح"
    // }

  });

  useEffect(() => {
    dispatch(GetAllPermissionThunk()).then(res => {

      if (res.meta.requestStatus === 'fulfilled') {


        let data = res.payload as PermissionModel[];

      }
      if (res.meta.requestStatus === 'rejected') {
        dispatch(AddAction({ message: res.payload as string, status: "error" }))
        return;
      }
    }).catch(err => {
      dispatch(AddAction({ message: err.payload as string, status: "error" }))

    })

    GetAllJobsAPI().then(res => {
      setUserJobs(res)
      console.log(res)
      let jobsDD: DropDownData[] = []
      res.map(job => {
        jobsDD.push({ id: job.ID, desc: job.JOB_DESCRIPTION })
      })
      setJobsDD(jobsDD)
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })

  }, [])


  useEffect(() => {

    let ChangedPerms: PermissionModel[] = []

    setJobModelReq(selectedJob)

    Permissions.AllPermissions.map(perm => {
      let currentJob: PermissionModel = { PARENT_NAME: perm.PARENT_NAME, PERMISSION: [] }
      perm.PERMISSION.map(item => {

        let jobNow: PermissionModel_item = (selectedJob.JOB_PERMISSIONS as PermissionModel_item[] ?? []).find(perm => perm.ID === item.ID) ?? { ID: -1, Checked: false, DESCRIPTION: '', DESCRIPTION_EN: '', NAME: '', PARENT_NAME: '', PARENT_NAME_EN: '' }

        let isChecked = false
        if (jobNow.ID !== -1) {
          isChecked = true
        }
        let currentPerm: PermissionModel_item = { ID: item.ID, Checked: isChecked, DESCRIPTION: item.DESCRIPTION, DESCRIPTION_EN: item.DESCRIPTION_EN, NAME: item.NAME, PARENT_NAME: item.PARENT_NAME, PARENT_NAME_EN: item.PARENT_NAME_EN }
        currentJob.PERMISSION.push(currentPerm)
      })
      ChangedPerms.push(currentJob)
    })

    setModifiedPermissions(ChangedPerms)

  }, [Permissions, selectedJob])

  const handleCheckChange = (prem: PermissionModel, item: PermissionModel_item, e: ChangeEvent<HTMLInputElement>) => {

    // debugger
    setModifiedPermissions(modifiedPermissions?.map(perm2 => {
      if (perm2.PARENT_NAME === prem.PARENT_NAME) {
        return {
          PARENT_NAME: prem.PARENT_NAME,
          PERMISSION: prem.PERMISSION.map(permItem => {
            if (permItem.ID === item.ID) {
              return {
                ID: item.ID,
                Checked: e.target.checked ?? false,
                DESCRIPTION: item.DESCRIPTION,
                DESCRIPTION_EN: item.DESCRIPTION_EN,
                NAME: item.NAME,
                PARENT_NAME: item.PARENT_NAME,
                PARENT_NAME_EN: item.PARENT_NAME_EN
              }
            } else {
              return permItem
            }
          })
        }
      } else {
        return perm2
      }
    }))



    if (e.target.checked) {
      setJobPermission([...JobPermission, { ID: parseInt(e.target.name) }])
    } else {
      let newARR = JobPermission.filter(job => job.ID !== parseInt(e.target.name))
      setJobPermission(newARR);
    }
  }


  const onSubmit: SubmitHandler<JobsModel> = async (data: JobsModel) => {

    let jobPermissionsRQ: PermissionModel_item[] = [];

    jobPermissionsRQ.push(...JobPermission)

    let sendObject = {
      COMPANY_ID: 1,
      ID: -1,
      JOB_DESCRIPTION: data.JOB_DESCRIPTION,
      JOB_DESCRIPTION_EN: data.JOB_DESCRIPTION_EN,
      JOB_PERMISSIONS: jobPermissionsRQ
    }


    dispatch(SaveJobThunk(sendObject))

  };

  const MainInputStyle = { marginBottom: 10, width: '40%' }


  const handleEdit = () => {

    if (selectedJob.ID === -1) {
      dispatch(AddAction({ message: 'اختار وظيفه', status: 'error' }))
      return;
    }

    let req = JobModelReq
    let filteredPerms: PermissionModel_item[] = []
    modifiedPermissions?.map(item => {
      item.PERMISSION.map(p => {
        if (p.Checked) {
          filteredPerms.push(p)
        }
      })
    })
    req.JOB_PERMISSIONS = filteredPerms
    // req.JOB_PERMISSIONS = modifiedPermissions?.filter(perm => {   
    //   return perm.PERMISSION.filter(permItem => permItem.Checked === true)
    // }) as PermissionModel[]; 

    req.COMPANY_ID = 1

    req.ID = selectedJob.ID

    SaveJobAPI(req).then(res => {
      dispatch(AddAction({ message: 'تم التعديل بنجاح', status: 'success' }))
      window.location.reload();
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }


  return (
    <div className='w-full p-4'>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='w-full'>
            <div className='w-4/5 flex justify-between mb-5' >
              <CustomTextField InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box marginLeft={2}>
                      <ApartmentIcon />
                    </Box>
                  </InputAdornment>
                ),
              }} style={MainInputStyle} type='text' error={!!errors.JOB_DESCRIPTION}
                helperText={errors?.JOB_DESCRIPTION?.message}
                inputRef={DescRef}
                {...DescProps} label='إسم الرتبه' colors={colors} theme={theme} />
              <CustomTextField InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box marginLeft={2}>
                      <EmailIcon />
                    </Box>
                  </InputAdornment>
                ),
              }} style={MainInputStyle} type='text' error={!!errors.JOB_DESCRIPTION_EN}
                helperText={errors?.JOB_DESCRIPTION_EN?.message}
                inputRef={DescEngRef}
                {...DescEngProps} label='إسم الرتبه بالإنجليزيه' colors={colors} theme={theme} />
            </div>
            <div className='w-full flex gap-2'>
              <EgButton Action='submit' HandleClick={() => { }}>
                <Stack width='50%' direction={'row'} justifyContent='center'>
                  <Typography sx={{ marginLeft: '10px' }} variant="h4">إضافه</Typography>
                </Stack>
              </EgButton>
              <EgButton Action='button' HandleClick={handleEdit}>
                <Stack width='50%' direction={'row'} justifyContent='center'>
                  <Typography sx={{ marginLeft: '10px' }} variant="h4">تعديل</Typography>
                </Stack>
              </EgButton>
            </div>
          </div>
          <h3>صلاحيات الوظيفه</h3>
          <DropTextField
            data={[
              { id: -1, desc: 'اختار وظيفه' },
              ...jobsDD
            ]}
            initalValue={selectedJobIndex}
            onchange={(e) => {
              let Value = e.target.value
              setSelectedJobIndex(Value)
              console.log(Value)
              setSelectedJob(userJobs?.find(job => job.ID === Value) ?? { ID: -1, COMPANY_ID: -1, JOB_DESCRIPTION: '', JOB_DESCRIPTION_EN: '' })
            }}
          />
          <div className='flex flex-col gap-5'>
            {modifiedPermissions && modifiedPermissions.map(permG => {
              return (
                <div>
                  <h5 className='mb-2'>{permG.PARENT_NAME}</h5>
                  <div className='flex flex-row gap-2'>
                    {permG.PERMISSION.map(permL => {
                      return (
                        <div className='flex gap-2'>
                          <input type={'checkbox'} checked={permL.Checked} name={permL.ID.toString()} onChange={(e) => handleCheckChange(permG, permL, e)} />
                          <label htmlFor={permL.NAME}>{permL.DESCRIPTION}</label>
                        </div>
                      )
                    })}
                  </div>

                </div>
              )
            })}
          </div>
        </form>
      </div>
      <GModal OpenModal={openEditModal} onCloseModal={() => {
        setOpenEditModal(false)
      }}>
        <div className='w-full h-full p-5 rounded-lg overflow-hidden'>
          this is edit modal
        </div>
      </GModal>
    </div>
  )
}

export default Jobs