import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import axios, { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckedIcon, THETEAM, Unchecked } from '../../components/Icons/Icons';
import { CustomTextField } from '../../CustomComps/Customs';
import { AddAction } from '../../redux/slices/ErrorsSlice';
import { AppDispatch } from '../../redux/store';
import { FEEDBACK_Model } from '../../Service/models/FeedBackModel';
import { UserModel } from '../../Service/models/UserModel/UserModel';
import { tokens } from '../../theme';
import { EgButton } from '../Shared/Shared';
import './Rate.css';

export type Cities = {
  ID: number,
  GOV_ID: number,
  CITY_NAME: string,
  CITY_NAME_EN: string
}

export type GovAndCities = {
  ID: number,
  GOVERNORATE_NAME: string,
  GOVERNORATE_NAME_EN: string,
  ALL_CITIES: Cities[]
}




export const textFiedlFlag = (Switch: 0 | 1 | -1, initialIcon: JSX.Element) => {
  return {
    endAdornment: Switch === -1 ? (
      <InputAdornment position="start">
        <Box marginX={1} width={25} height={25}>
          {initialIcon}
        </Box>
      </InputAdornment>
    ) : Switch === 1 ? <InputAdornment position="start">
      <Box width={25} height={25} marginX={1}>
        <CheckedIcon />
      </Box>
    </InputAdornment> : <InputAdornment position="start">
      <Box width={25} height={25} marginX={1}>
        <Unchecked />
      </Box>
    </InputAdornment>,
  }
};


export const Rate = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const MainInputStyle = { marginBottom: 20, width: '100%', backgroundColor: 'white' };

  const [feedBackRQ, setFeedBackRQ] = useState<FEEDBACK_Model>({PROJECT_RATE : 2} as FEEDBACK_Model);
  const [Govs, setGovs] = useState<GovAndCities[]>();
  const [cities, setCities] = useState<Cities[]>();

  const dispatch = useDispatch<AppDispatch>();




  const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<UserModel>({
    reValidateMode: 'onBlur',
    mode: 'onBlur'
  });

  // let passData = watch('PASSWORD');

  const {
    ref: EmailInputRef, ...EmailInputProps } = register("EMAIL", {
      required: "البريد الإلكتروني لايمكن ان يكون فارغ",
      // pattern: {
      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      //   message: "نمط البريد غير صحيح"
      // }
    });
  const {
    ref: FullNameInputRef, ...FullNameInputProps } = register("FULL_NAME", {
      required: "الإسم لايمكن ان يكون فارغ",
      // pattern: {
      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      //   message: "نمط البريد غير صحيح"
      // }
    });
  const {
    ref: MobileNumberInputRef, ...MobileNumberInputProps } = register("MOBILE_NO", {
      required: "رقم الموبايل لايمكن ان يكون فارغ",
      pattern: {
        value: /^(?:\+?[0-9]+)$/i,
        message: "رقم الموبايل غير صحيح"
      }
    });
  const {
    ref: PasswordInputRef, ...PasswordInputProps } = register("PASSWORD", {
      required: "رمز المرور لايمكن ان يكون فارغ",
      pattern: {
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
        message: "رمز الدخول لايقل عن 8 احرف على ان يحتوي حرف كبير و حرف صغير و رقم ورمز خاص مثل @ "
      }
      // pattern: {
      //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      //   message: "نمط البريد غير صحيح"
      // }
    });
  // const [passwordConfirm, setPasswordConfirm] = useState('');
  // const [password, setPassword] = useState('');
  const passwordRef = useRef('');
  const passwordConfirmRef = useRef('');

  const onSubmit: SubmitHandler<UserModel> = async (data: UserModel) => {

  };



  const ValidateData = () => {
    
  }

  const sendDatatoServer = (req: FEEDBACK_Model) => {

    


    var reqBodySerialized = JSON.stringify(req);


    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'X-Host': window.location.hostname,
      },
      withCredentials: true,
      credentials: 'include',
    }

    axios.post('/api/feedback/postFeedback', reqBodySerialized, axiosConfig).then(res => {
      dispatch(AddAction({ message: "تم ارسال التقيم", status: "success" }))
    }
    ).catch(err => {
      let ERR = err as AxiosError
      
      
      let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;
      dispatch(AddAction({ message: ErrMsg, status: "error" }))
    })

  }



  const login = useGoogleLogin({
    onSuccess: tokenResponse => {}
  });





  useEffect(() => {
    axios.get('/api/govs/getAllGovernorates').then(res =>
      setGovs(res.data)
    ).catch(err => {
      let ERR = err as AxiosError
      
      
      let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;
      dispatch(AddAction({ message: ErrMsg, status: "error" }))
    })
  }, [])



  useEffect(() => {
    let newCitiesArr = Govs?.filter(gov => gov.ID === feedBackRQ.GOV)[0];
    setCities(newCitiesArr?.ALL_CITIES)
  }, [Govs, feedBackRQ.GOV])



  return (
    <div className='bgscreen w-full flex py-5 justify-center items-center'>
      <div className='bg-[#EDF3F4] rounded-3xl w-4/6  lg:w-3/6  flex flex-col items-center'>
        <div className='h-1/3 p-2 mb-10'>
          <THETEAM />
        </div>
        <div>
          <p className='text-2xl'>نرحب بكم , يسعدنا معرفه رأي حضرتك</p>
        </div>
        <form className='w-4/5 h-2/3 flex flex-col gap-8  p-3 justify-start items-center' onSubmit={handleSubmit(onSubmit)}>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField placeholder='الإسم' type={'text'} onChange={(e: any) => {
              setFeedBackRQ(prev => ({ ...prev, NAME: e.target.value }))
            }} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box marginLeft={2}>
                    {/* <KeyIcon /> */}
                  </Box>
                </InputAdornment>
              ),
            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField placeholder='السن' onChange={(e: any) => {
              setFeedBackRQ(prev => ({ ...prev, AGE: parseInt(e.target.value) }))
            }} type={'number'} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box marginLeft={2}>
                    {/* <KeyIcon /> */}
                  </Box>
                </InputAdornment>
              ),
            }} style={MainInputStyle} error={!!errors.MOBILE_NO}
              helperText={errors?.MOBILE_NO?.message}
              colors={colors} theme={theme} />
          </div>
          <div className='w-full flex flex-row gap-4'>
            <div className='w-full'>
              <div className='w-full flex flex-row gap-4 bg-white'>
                <Select
                  placeholder='اختر محافظه'
                  className='w-full'
                  value={feedBackRQ.GOV}
                  onChange={(e: SelectChangeEvent<number>) => {
                    setFeedBackRQ(prev => ({ ...prev, GOV: e.target.value as number }))
                  }
                  }
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={-1}>{"اختر محافظه"}</MenuItem>
                  {Govs && Govs.map((gov, idx) => (
                    <MenuItem value={gov.ID}>{gov.GOVERNORATE_NAME}</MenuItem>
                  ))}

                </Select>
              </div>
            </div>
            <div className='w-full'>
              <div className='w-full flex flex-row gap-4 bg-white'>
                <Select
                  className='w-full'
                  value={feedBackRQ.CITY}
                  onChange={(e: SelectChangeEvent<number>) => {
                    setFeedBackRQ(prev => ({ ...prev, CITY: e.target.value as number }))
                  }
                  }
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={-1}>{"إختر مدينه / مركز"}</MenuItem>
                  {cities && cities.map((city, idx) => (
                    <MenuItem value={city.ID}>{city.CITY_NAME}</MenuItem>
                  ))}
                </Select>
              </div>
            </div>

          </div>
          <div className='w-full flex flex-row justify-between gap-4'>
            <p className='text-xl w-1/3'>ماذا يمثل لك المشروع ؟</p>
            <div className='flex flex-col md:flex-row w-full justify-between'>
              <div className='flex  items-center gap-2'>
                <input type="radio" id="res" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, IS_PERSONAL_HOME: e.target.checked, IS_INVESTMENT: false, BOTH_PERSONAL_AND_INVEST: false }))
                }} />
                <label htmlFor='res' className='text-xl'>سكن</label>
              </div>
              <div className="flex items-center gap-2">
                <input type="radio" id="invest" name="ProjType" value="استثمار" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, IS_INVESTMENT: e.target.checked, IS_PERSONAL_HOME: false, BOTH_PERSONAL_AND_INVEST: false }))
                }} />
                <label htmlFor='invest' className='text-xl '>استثمار</label>
              </div>
              <div className="flex items-center gap-2">
                <input type="radio" id="both" name="ProjType" value="كلاهما" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, BOTH_PERSONAL_AND_INVEST: e.target.checked, IS_INVESTMENT: false, IS_PERSONAL_HOME: false }))
                }} />
                <label htmlFor='both' className='text-xl'>كلاهما</label>
              </div>
            </div>
          </div>
          <div className='w-full flex flex-row gap-4'>
            <h3 className='text-xl  w-1/3'>مميزات المشروع</h3>
            <div className='flex flex-col w-full gap-2'>
              <div className='flex gap-3'>
                <input type="checkbox" id="uniqueLoc" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, FANTASTIC_LOCATION: e.target.checked }))
                }} />
                <label htmlFor='uniqueLoc' className='text-xl'>موقع متميز</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="classLoc" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, LUDXRY_LOCATION: e.target.checked }))
                }} />
                <label htmlFor='classLoc' className='text-xl'>سكن راقي</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="area" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, GOOD_AREA: e.target.checked }))
                }} />
                <label htmlFor='area' className='text-xl'>مساحه مناسبه</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="serv" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, GOOD_SERVICES: e.target.checked }))
                }} />
                <label htmlFor='serv' className='text-xl'>خدمات</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="des" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, GOOD_DESGINE: e.target.checked }))
                }} />
                <label htmlFor='des' className='text-xl'>تصميمات</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="fut" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, GOOD_FOR_FUTURE: e.target.checked }))
                }} />
                <label htmlFor='fut' className='text-xl'>مستقبل المشروع</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="pay" name="ProjType" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, GOOD_PAYMENT_METHODS: e.target.checked }))
                }} />
                <label htmlFor='pay' className='text-xl'>طرق السداد</label>
              </div>
              <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
                <textarea id="txtid" name="otherNotes" className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='ملاحظات اخرى' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, OTHER_OPTIONS: e.target.value }))
                }} >

                </textarea>
              </div>
            </div>
          </div>
          <div className='w-full flex flex-row gap-4 items-center'>
            <h3 className='w-1/3 text-xl'>تقيم المشروع</h3>
            <div className='flex flex-col w-full'>
              <div className='flex flex-col gap-3 items-center'>
                <input type="range" min="1" max="10" id="myRange" value={feedBackRQ.PROJECT_RATE} className='w-full rangGrad' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: parseInt(e.target.value) }))
                }} />
                <div className='flex justify-between w-full  h-2'>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 1 })) }}>1</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 2 })) }}>2</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 3 })) }}>3</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 4 })) }}>4</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 5 })) }}>5</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 6 })) }}>6</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 7 })) }}>7</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 8 })) }}>8</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 9 })) }}>9</h3>
                  <h3 className='text-base hover:cursor-pointer' onClick={() => { setFeedBackRQ(prev => ({ ...prev, PROJECT_RATE: 10 })) }}>10</h3>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full flex flex-row gap-4 items-center'>
            <p className='w-1/3 text-xl'>اسباب التقييم</p>
            <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
              <textarea id="txtid2" name="otherNotes" className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder=' اذكر سبب التقيم لتساعدنا على تحسين اداء الشركه' onChange={(e: any) => {
                setFeedBackRQ(prev => ({ ...prev, RATE_REASONS: e.target.value }))
              }} >
              </textarea>
            </div>
          </div>
          <div className='w-full flex flex-row gap-4 items-center'>
            <p className='w-1/3 text-xl'>رساله الى شركه بناء</p>
            <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
              <textarea id="txtid3" name="otherNotes" className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='رأيك دايما مهم بالنسبالنا' onChange={(e: any) => {
                setFeedBackRQ(prev => ({ ...prev, MESSAGE_TO_BENAA: e.target.value }))
              }} >
              </textarea>
            </div>
          </div>
          <div className='w-full flex flex-row gap-4 items-center'>
            <p className='w-1/3 text-xl'>مقترحات</p>
            <div className='flex gap-3 bg-yellow-300 w-full h-[4em]'>
              <textarea id="txtid3" name="otherNotes" className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='مقترحاتك للشركه' onChange={(e: any) => {
                setFeedBackRQ(prev => ({ ...prev, SUGGESTIONS: e.target.value }))
              }} >
              </textarea>
            </div>
          </div>
          <div className='w-full flex flex-row gap-4 justify-between items-center'>
            <p className='w-1/2 text-xl'>  تحديد موعد للقاء مع احد وكلائنا ؟</p>

            <div className="flex gap-2">
              <input type="radio" id="yes" name="meet" className='w-[2em] h-[2em]' onChange={(e: any) => {
                setFeedBackRQ(prev => ({ ...prev, NEED_MEETING: e.target.checked }))
              }} />
              <label htmlFor='yes' className='text-xl'>نعم</label>
            </div>
            <div className="flex gap-2">
              <input type="radio" id="no" name="meet" className='w-[2em] h-[2em]' onChange={(e: any) => {
                setFeedBackRQ(prev => ({ ...prev, NEED_MEETING: false }))
              }} />
              <label htmlFor='no' className='text-xl'>لا</label>
            </div>
          </div>
          <div className='w-full flex flex-row gap-4'>
            <h3 className='w-1/3 text-xl'>أسباب مبدئيه للقاء</h3>
            <div className='flex flex-col w-full gap-2'>
              <div className='flex gap-3'>
                <input type="checkbox" id="explain" name="explain" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, NEED_EXPLANATION: e.target.checked }))
                }} />
                <label htmlFor='explain' className='text-xl'>الحصول على شرح وافي</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="quest" name="quest" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, HAVE_QUESTIONS: e.target.checked }))
                }} />
                <label htmlFor='quest' className='text-xl'>لدي بعض الأسئله</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="paper" name="paper" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, CHECK_LEGAL_PAPERS: e.target.checked }))
                }} />
                <label htmlFor='paper' className='text-xl'>التأكد من الأوراق القانونيه</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="choose" name="choose" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, CHOOSE_UNIT: e.target.checked }))
                }} />
                <label htmlFor='choose' className='text-xl'>إختيار وحده</label>
              </div>
              <div className='flex gap-3'>
                <input type="checkbox" id="bookUnit" name="bookUnit" className='w-[2em] h-[2em]' onChange={(e: any) => {
                  setFeedBackRQ(prev => ({ ...prev, EXPLAIN_RESERVATION: e.target.checked }))
                }} />
                <label htmlFor='bookUnit' className='text-xl'>معرفه إجرائات الحجز</label>
              </div>
              {/* <div className='flex gap-3 bg-yellow-300 w-full h-10'>
                <textarea id="txtid" name="otherNotes" className="resize-none w-full h-auto rounded-md border-black border p-1" placeholder='ملاحظات اخرى' >

                </textarea>
              </div> */}
            </div>
          </div>
          <div className='w-2/3 self-center'>
            <EgButton HandleClick={() => {
              //ValidateData();
              sendDatatoServer(feedBackRQ);
              //dispatch(fetchTodos({ password: 'ahmed', userName: 'ahmed' }));
            }}>
              <Stack direction={'row'} justifyContent='center'>
                <Typography sx={{ marginLeft: '10px' }} variant="h5">إرسال</Typography>
              </Stack>
            </EgButton>
          </div>
        </form>
      </div>
    </div>
  )
}
export default Rate