/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { ContactsModel } from '../../Service/models/ContactsModel/ContactsMOdel'
import { useLocation } from 'react-router-dom';
import { USER_Detail, UserModel } from '../../Service/models/UserModel/UserModel';
import { BenaIMGURL, GetErrorAxios, LOCALSTORAGE_GET, ShowSuccess, localStorageKeys } from '../../Globals';
import { SEND_POST, getProfileDataById } from '../../Service/Apis';
import { USER_POSTS } from '../../Service/models/PostsModel';
import { useSelector } from 'react-redux';
import { UsersState } from '../../redux/slices/UserSlice';
import Header from '../HomePage/comps/Header';
import './style.css'
import { CustomTextFieldFullHeight } from '../../CustomComps/Customs';
import { Modal, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { EgButton, convertToBase64 } from '../Shared/Shared';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { ModalBody } from '../DashBoard/EditUser/EditUsr';

type TProfile = {
    AllowEdit?: boolean,
    EditProfile?: () => void,
    EditProfileImage?: (e: any) => void,
    CurrentUser?: UserModel,

}


type TProfileData = {
    ImgURL: string,
    FullName: string,
    JobName: string,
    MobileNumber: string,
    Email: string,
    Expertise?: string,
    Education?: string,
    Description?: string,
    Posts?: USER_POSTS[]
}

const Profile = ({ AllowEdit, EditProfile, EditProfileImage, CurrentUser }: TProfile) => {
    const UsersData = useSelector(UsersState);
    const DrawerRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const itemName = query.get('id');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
    const postRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch<AppDispatch>();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [userPosts, setUserPosts] = useState<USER_POSTS>({} as USER_POSTS);


    const [user, setUser] = useState<UserModel>({} as UserModel);
    const isEditAllowed = (AllowEdit !== undefined && AllowEdit === true) ? true : false
    // const user = location.state as USER_Detail;
    let CurrentUserData: TProfileData = {} as TProfileData;

    if (isEditAllowed === true) {
        CurrentUserData = {
            ImgURL: CurrentUser!.PROFILE_PIC_URL!,
            FullName: CurrentUser!.FULL_NAME!,
            JobName: CurrentUser!.USER_JOB_NAME!,
            Email: CurrentUser!.EMAIL!,
            MobileNumber: CurrentUser!.MOBILE_NO,
            Expertise: CurrentUser!.USER_EXPERIENCE?.EXPERIENCE ?? "لاتوجد خبرات",
            Description: CurrentUser!.USER_BIO?.BIO ?? "لا يوجد وصف",
            Education: CurrentUser!.USER_EDUCATION?.EDUCATION ?? "لم يتم إضافه الوصف التعليمي بعد",
            Posts: CurrentUser!.USER_POSTS ?? []


        }
    } else {
        CurrentUserData = {
            ImgURL: user.PROFILE_PIC_URL ?? "",
            FullName: user.FULL_NAME,
            JobName: user.USER_JOB_NAME ?? "",
            Email: user.EMAIL,
            MobileNumber: user.MOBILE_NO,
            Expertise: user.USER_EXPERIENCE?.EXPERIENCE ?? "لاتوجد خبرات",
            Description: user!.USER_BIO?.BIO ?? "لا يوجد وصف",
            Education: user!.USER_EDUCATION?.EDUCATION ?? "لم يتم إضافه الوصف التعليمي بعد",
            Posts: user!.USER_POSTS ?? []
        }
    }


    const DisplayNewPostModal = () => {
        postRef.current?.classList.add("show")
        postRef.current?.classList.remove("hide")
    }


    const HideNewPostModal = () => {
        postRef.current?.classList.add("hide")
        postRef.current?.classList.remove("show")

    }

    const onGalleryImageChange = async (e: any) => {
        const files = e.target.files;
        // let imagesBase64: ImageType[] = []

        let MaxIdNumber: number = 0;
        for (const [key, val] of Object.entries(files)) {
            let convertVal = val as any


            let data = await convertToBase64(convertVal) as string;
            //   for (const proj of projectGalleryimgs) {
            //     if (proj.ID > MaxIdNumber) {
            //       MaxIdNumber = proj.ID
            //     }
            //   }

            if (MaxIdNumber === 0) {
                // for (const project of projectimgs) {
                //   if (project.ID ?? 0 >= MaxIdNumber) {
                //     MaxIdNumber = project.ID ?? 0
                //   }
                // }

                MaxIdNumber++;
            } else {
                MaxIdNumber++;
            }
            // setProjectimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "" }])
            // changeprojectGalleryimgs(prev => [...prev, { ID: MaxIdNumber + 1, name: convertVal.name, type: convertVal.type, base64: data, file: convertVal, SHOW_TO_AGENTS: true, DOWNLOAD_LINK: "", ImageType: ImageTypeReference.Gallery }])
        }

    }


    const sendPost = async () => {
        await SEND_POST(userPosts).then(res => {
            
            HideNewPostModal(); // hide the modal
            ShowSuccess("تم إضافه المقال بنجاح", dispatch)
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }


    useEffect(() => {
        HideNewPostModal()
        if (itemName !== null) {
            let idNumber: number = parseInt(itemName);
            getProfileDataById({ USERID: idNumber }).then(user => {
                setUser(user)
                
            })

        }
    }, [])



    return (
        <div className='flex flex-col w-full m-auto shadow-md rounded-lg bg-gray-100 items-center p-2 justify-between relative' dir='ltr'>
            <div className='w-full mb-10' dir='rtl'>
                {itemName !== null && (
                    <Header user={UsersData.currentUser} openDrawerFunc={(flag) => {
                        if (flag) {
                            DrawerRef.current?.classList.add("hide")
                        } else {
                            DrawerRef.current?.classList.remove("hide")
                        }
                    }} />
                )}
            </div>
            <div className='w-full h-64 relative bg-slate-50 shadow' style={{ backgroundImage: "url(" + BenaIMGURL + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}>
                <div className='h-32 w-32 md:w-48 md:h-48  rounded-[50%] absolute -bottom-[30%] right-56 p-2 bg-slate-100 overflow-visible'   >
                    <div dir='ltr' className='relative w-full h-full'>
                        <img className='w-full h-full object-cover rounded-[50%]' src={CurrentUserData.ImgURL} alt={CurrentUser?.FULL_NAME} />
                        {isEditAllowed && (
                            <div className='w-10 h-10 rounded-2xl bg-blue-100 hover:cursor-pointer absolute top-0 right-0 z-50 flex justify-center items-center' >
                                <label htmlFor="filePicker2" className="hover:cursor-pointer">
                                    <div className='i-material-symbols-edit text-3xl text-blue-500'>
                                    </div>
                                </label>

                                <input className='absolute top-0 left-0' id="filePicker2" style={{ visibility: 'hidden', backgroundColor: 'red' }} accept="image/*" onChange={EditProfileImage} type={"file"} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-row relative '>
                {/* Posts */}
                <div className='flex-grow p-2 my-5'>
                    <div className='flex w-full justify-around'>
                        {itemName === null && (
                            <div className='flex'>
                                {/* <h1>مقال جديد</h1> */}
                                <div className='i-material-symbols-add-box-outline-rounded text-6xl text-gray-500 hover:cursor-pointer' onClick={DisplayNewPostModal}  >
                                </div>
                            </div>
                        )}
                        <h1 >مقالاتي</h1>
                    </div>
                    <div className='bg-white p-8 rounded-2xl my-5'>
                        {CurrentUserData.Posts !== undefined && CurrentUserData.Posts.length > 0 && CurrentUserData.Posts.map((post, idx) => {
                            return <PostCard post={post} key={post.ID} />
                        })}
                    </div>
                </div>
                <div className='w-1/4 flex flex-col justify-start items-end relative'>
                    <div className='w-full mt-28 pl-9 pr-9 flex flex-col items-center md:items-end' dir=''>
                        {isEditAllowed && (
                            <div dir='' className='hover:cursor-pointer hover:text-blue-500 absolute top-5 right-9 z-50' onClick={EditProfile}>
                                <div className='relative  h-full flex  gap-5 items-center justify-center ' >
                                    <div className='flex justify-center items-center bg-blue-100 w-10 h-10 rounded-2xl'>
                                        <div className='i-material-symbols-edit text-3xl text-blue-500'>
                                        </div>
                                    </div>
                                    <p>تعديل</p>
                                </div>
                            </div>
                        )}
                        <h2 className='font-bold truncate mb-3'>{CurrentUserData?.FullName?.includes("+") ? CurrentUserData.FullName.replace("+", " ") : CurrentUserData.FullName}</h2>

                        <div className='flex gap-5' dir=''>
                            <h4 style={{ fontWeight: 'lighter' }} className='font-light truncate mb-3'>{CurrentUserData?.JobName?.includes("+") ? CurrentUserData.JobName.replace("+", " ") : CurrentUserData.JobName} At Benaa the team</h4>
                            <div className='i-material-symbols-work text-[#e7c498] text-2xl'>
                            </div>
                        </div>
                        <div className='flex gap-5'>
                            <h4 style={{ fontWeight: 'lighter' }} className='font-bold truncate mb-3'>{CurrentUserData.MobileNumber}</h4>
                            <div className='i-material-symbols-phone-android text-[#e7c498] text-2xl'>

                            </div>
                        </div>
                        <div className='flex gap-5'>
                            <h4 style={{ fontWeight: 'lighter' }} dir='ltr' className='font-bold truncate mb-3'>{CurrentUserData.Email}</h4>
                            <div className='i-material-symbols-mail-outline-rounded text-[#e7c498] text-2xl'>

                            </div>
                        </div>
                        {isEditAllowed && (
                            <div className="w-full my-2">
                                <EgButton HandleClick={() => {
                                    handleOpen()
                                }}>
                                    {/* <Typography sx={{ marginLeft: '10px' }} variant="h3">جدول المواعيد</Typography> */}
                                    <h5 className="text-base md:text-lg">جدول المواعيد</h5>
                                </EgButton>
                            </div>
                        )}

                        {/* who i'm section */}
                        <div className='mb-6 w-full'>
                            <h2 className='font-bold truncate mb-3 text-center md:text-right'>من أنا</h2>
                            <p style={{ fontWeight: 'lighter', textAlign: 'right', width: '100%', wordWrap: 'break-word' }}>{CurrentUserData.Description}</p>
                        </div>
                        {/* خبراتي */}
                        <div className='mb-6 w-full'>
                            <h2 className='font-bold truncate mb-3 text-center md:text-right'>خبراتي</h2>
                            <p style={{ fontWeight: 'lighter', textAlign: 'right', width: '100%', wordWrap: 'break-word' }}>{CurrentUserData.Expertise}</p>
                        </div>
                        {/* التعليم */}
                        <div className='mb-6 w-full'>
                            <h2 className='font-bold truncate mb-3 text-center md:text-right'>التعليم</h2>
                            <p style={{ fontWeight: 'lighter', textAlign: 'right', width: '100%', wordWrap: 'break-word' }}>{CurrentUserData.Education}</p>
                        </div>
                        {/* مقالاتي */}
                        {/* <div dir='rtl' className='mb-6'>
                        <h2 className='font-bold truncate mb-3'>مقالاتي</h2>
                        <p style={{ fontWeight: 'lighter' }}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta magni aspernatur praesentium dolorem tempore doloremque consectetur dolore, alias voluptate error saepe ipsam aperiam culpa mollitia voluptatibus fugiat laudantium est quidem.</p>
                    </div> */}

                    </div>
                </div>
            </div>
            <div ref={postRef} className='fixed top-36 w-1/2 h-[70vh] rounded-2xl bg-white shadow-md z-50 transition-all duration-1000 ease-in-out p-5'>
                <div className='w-full h-full relative flex flex-col justify-start items-center gap-10'>
                    <div className='w-full mt-10' dir='rtl'>
                        <h3 className='mb-2'>العنوان</h3>
                        <CustomTextFieldFullHeight height={25} id='PostTitle' type={''} onChange={(e: any) => {
                            let val = e.target.value as string;
                            setUserPosts(prev => ({ ...prev, TITLE: val }))
                        }} style={MainInputStyle}
                            colors={colors} theme={theme} />
                    </div>
                    <div className='w-full h-1/2' dir='rtl'>
                        <h3 className='mb-2'>المقاله</h3>
                        <textarea dir='rtl' id={"POSTAREA"} name="posts" className="resize-none w-full h-3/4 shadow-md bg-gray-100 rounded-md  focus:outline-none focus:ring-0" onChange={(e: any) => {
                            let val = e.target.value;
                            setUserPosts(prev => ({ ...prev, MESSAGE: val }))
                        }} >

                        </textarea>
                    </div>
                    <div className='grow h-36 border-[5px] border-gray-600 border-dashed mt-10 rounded-lg flex justify-center items-center gap-4 p-2'>
                        <label htmlFor="galleryImgs">
                            <div className='i-material-symbols-add-a-photo-outline-rounded text-6xl text-gray-500 hover:cursor-pointer'>
                            </div>
                        </label>
                        <input style={{ visibility: 'hidden', display: 'none' }} type="file" accept="image/*" id="galleryImgs" multiple onChange={onGalleryImageChange} />
                        <h3>إضافه صور تعريفيه</h3>
                    </div>
                    <div className='w-36 h-12 self-start '>
                        <EgButton HandleClick={sendPost}>
                            <h5>حفظ</h5>
                        </EgButton>
                    </div>
                    <div className='absolute top-3 left-3 i-material-symbols-close-rounded text-3xl text-red-500 hover:cursor-pointer' onClick={HideNewPostModal}>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalBody />
            </Modal>
        </div>
    )
}

export default Profile



const PostCard = ({ post }: { post: USER_POSTS }) => {
    return <div className='my-2'>
        <div className='bg-slate-100 p-5 shadow shadow-md'>
            <h3>{post.MESSAGE}</h3>
        </div>
    </div>
}