// data logic
import { ActionTypes, GetActionTypeString, groupBy } from "../../Globals";
import {
    ACTION_STATISTICS, STATION_STATISTICS,
    STATISTICS,
    STATISTICS_PER_STATION,
    USER_STATISTICS
} from "../../Service/models/statistics/indext";
import { DEAL_MODEL } from "../../Service/models/DealApplication";
import { SITE_VISIT_MODEL } from "../../Service/models/SITEVISITMODEL";
import { APPOINTMENT, CALL_ACTION, CUSTOMER_DETAILS } from "../../Service/models/UserModel/Actions/ActionsModel";
import { USER_Detail, UserModel } from "../../Service/models/UserModel/UserModel";
import { STATION } from "../../Service/models/STATION";
import { FOUNDER_STATISTICS, OWNER_STATISTICS, OwnersFounderStats } from "../../Service/models/Wallet";

export const data = {
    labels: ['احمد الأحمر', 'محمود الأزرق', 'طارق الأصفر'],
    datasets: [
        {
            label: 'السلبح',
            data: [12, 19, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1,
        },
    ],
};


export const BGCOLORs = (): string[] => {
    return [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(86,255,207,.2)',
        'rgba(255,85,224,.2)',
    ]
}

export const Border_Color = (): string[] => {
    return [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(86,255,207,1)',
        'rgba(255,85,224,1)',
    ]
}

export type STA_DRAW_TYPE = {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor?: string[];
        borderColor?: string[];
        borderWidth?: number
    }[]

}
export type ActionStatisticsRetunType = {
    ActionTypeName: string;
    Actions: ACTION_STATISTICS[]

}


export type USER_ACTIONS_DATA = {
    DEALS: DEAL_MODEL[],
    SITE_VISIT_REQ: SITE_VISIT_MODEL[],
    Appointment: APPOINTMENT[],
    CUSTOMER_DETAILS: CUSTOMER_DETAILS[],
    CALL_ACTION: CALL_ACTION[],
    user: USER_Detail,
    user_id: number
}

export type USER_DATA_Optimized = {
    DEALS: number,
    SITE_VISIT_REQ: number,
    Appointment: number,
    CUSTOMER_DETAILS: number,
    CALL_ACTION: number,
    Total_price_number: number,
    Total_price_text: string,
    Total_price_Tafqeet: string,
    user: USER_Detail,
    user_id: number,
    station: STATION
}

export type Station_DATA_Optimized = {
    DEALS: number,
    SITE_VISIT_REQ: number,
    Appointment: number,
    CUSTOMER_DETAILS: number,
    CALL_ACTION: number,
    Total_price_number: number,
    Total_price_text: string,
    Total_price_Tafqeet: string,
    station: STATION
}

export type Founder_data_optimized = {
    shares_number: number[],
    names: string[],
    data: FOUNDER_STATISTICS[]
}

export type Partners_data_optimized = {
    shares_number: number[],
    names: string[],
    data: OWNER_STATISTICS[] | FOUNDER_STATISTICS[]
}


type GroupedStatisticsForActionTYpeType = ActionStatisticsRetunType[]

export const getStatisticsByAction = (statistics: STATISTICS, filterUser: "USER" | "TEAM" = 'USER', stationID: number, userID: number): USER_ACTIONS_DATA[] => {

    let AllUStatisticsDataPerPerson: STATISTICS_PER_STATION[] = statistics.ALL_STATISTICS ?? []
    if (stationID != 99) {
        AllUStatisticsDataPerPerson = statistics.ALL_STATISTICS?.filter(allStatistics => allStatistics.STATION_ID === stationID) ?? []
    }


    

    if (AllUStatisticsDataPerPerson !== undefined && AllUStatisticsDataPerPerson.length > 0) {
        if (filterUser === "USER") {
            let allUsersStatistics: ACTION_STATISTICS[] = []
            let Returned_value_type: ActionStatisticsRetunType[] = []
            let ActionsData: USER_ACTIONS_DATA = {} as USER_ACTIONS_DATA
            let all_user_data: USER_ACTIONS_DATA[] = []

            if (userID === 99) {
                AllUStatisticsDataPerPerson.map(data => {
                    data.USER_STATISTICS_PER_USER?.map(user => {
                        ActionsData = {
                            user: user.USER!,
                            user_id: user.USER_ID!,
                            CALL_ACTION: [],
                            DEALS: [],
                            Appointment: [],
                            CUSTOMER_DETAILS: [],
                            SITE_VISIT_REQ: []
                        }
                        user.ACTION_STATISTICS?.map(action => {
                            ActionsData.SITE_VISIT_REQ = [...ActionsData.SITE_VISIT_REQ, ...action.SITE_VISIT_REQ || []]
                            ActionsData.CALL_ACTION = [...ActionsData.CALL_ACTION, ...action.CALL_ACTION || []]
                            ActionsData.DEALS = [...ActionsData.DEALS, ...action.DEAL || []]
                            ActionsData.Appointment = [...ActionsData.Appointment, ...action.APPOINTMENT || []]
                            ActionsData.CUSTOMER_DETAILS = [...ActionsData.CUSTOMER_DETAILS, ...action.CUSTOMER_DETAILS || []]
                        })
                        allUsersStatistics.push(...user.ACTION_STATISTICS ?? [])
                        all_user_data.push(ActionsData)
                        // User_Data_statistics.push(ActionsData)
                    })
                })
            } else {
                AllUStatisticsDataPerPerson.map(data => {
                    data.USER_STATISTICS_PER_USER?.map(user => {
                        ActionsData = {
                            user: user.USER!,
                            user_id: user.USER_ID!,
                            CALL_ACTION: [],
                            DEALS: [],
                            Appointment: [],
                            CUSTOMER_DETAILS: [],
                            SITE_VISIT_REQ: []
                        }
                        if (user.USER_ID === userID) {
                            user.ACTION_STATISTICS?.map(action => {
                                
                                ActionsData.SITE_VISIT_REQ = [...ActionsData.SITE_VISIT_REQ, ...action.SITE_VISIT_REQ || []]
                                ActionsData.CALL_ACTION = [...ActionsData.CALL_ACTION, ...action.CALL_ACTION || []]
                                ActionsData.DEALS = [...ActionsData.DEALS, ...action.DEAL || []]
                                ActionsData.Appointment = [...ActionsData.Appointment, ...action.APPOINTMENT || []]
                                ActionsData.CUSTOMER_DETAILS = [...ActionsData.CUSTOMER_DETAILS, ...action.CUSTOMER_DETAILS || []]
                            })
                            allUsersStatistics.push(...user.ACTION_STATISTICS ?? [])
                            all_user_data.push(ActionsData)
                        }
                    })
                })
            }

            let dataGrouped = groupBy(allUsersStatistics, 'ACTION_TYPE', false)
            // let User_data_group = groupBy(User_Data_statistics, 'user_id', false)

            Object.entries(dataGrouped).map(([key, value]) => {
                Returned_value_type.push({
                    ActionTypeName: GetActionTypeString(parseInt(key)),
                    Actions: value as ACTION_STATISTICS[]
                })
            })

            Object.entries(dataGrouped).map(([key, value]) => {
                Returned_value_type.push({
                    ActionTypeName: GetActionTypeString(parseInt(key)),
                    Actions: value as ACTION_STATISTICS[]
                })
            })

            
            
            
            if (all_user_data.length > 0) {
                // return dataGrouped as  ActionStatisticsRetunType[]
                // return Returned_value_type as ActionStatisticsRetunType[]
                return all_user_data
            }
        }
        if (filterUser === "TEAM") {
            let allTeamStatistics: ACTION_STATISTICS[] = []
            let Team_Returned_value_type: ActionStatisticsRetunType[] = []

            AllUStatisticsDataPerPerson.map(data => {

                data.USER_STATISTICS_PER_TEAM?.map(user => {
                    allTeamStatistics.push(...user.ACTION_STATISTICS ?? [])
                })
            })


            let dataGrouped = groupBy(allTeamStatistics, 'ACTION_TYPE', false)
            Object.entries(dataGrouped).map(([key, value]) => {
                Team_Returned_value_type.push({
                    ActionTypeName: GetActionTypeString(parseInt(key)),
                    Actions: value as ACTION_STATISTICS[]
                })
            })
            
            
            if (Team_Returned_value_type.length > 0) {
                // return dataGrouped as  ActionStatisticsRetunType[]
                // return Team_Returned_value_type as ActionStatisticsRetunType[]
            }
        }
    }


    return []
}

export const Get_founder_owner_Statistics = (stat: OwnersFounderStats, filter: "Owner" | "Founder" = "Founder"): Partners_data_optimized => {
    let statisticsByRole = filter === 'Owner' ? stat.OWNER_STATISTICS as OWNER_STATISTICS[] ?? [] as OWNER_STATISTICS[] : stat.FOUNDER_STATISTICS as FOUNDER_STATISTICS[] ?? [] as FOUNDER_STATISTICS[]
    let partnerDataHolder: Partners_data_optimized = {} as Partners_data_optimized

    if (statisticsByRole.length > 0) {
        statisticsByRole.map(partner => {
            if (filter === 'Owner') {
                let partnerType = partner as OWNER_STATISTICS
                partnerDataHolder.data = [...partnerDataHolder.data, partnerType]
                partnerDataHolder.names = [...partnerDataHolder.names, partnerType.USER?.FULL_NAME ?? ""]
                partnerDataHolder.shares_number = [...partnerDataHolder.shares_number, partnerType.SHARED_PREC ?? 0]
            } else {
                let partnerType = partner as FOUNDER_STATISTICS
                partnerDataHolder.data = [...partnerDataHolder.data, partnerType]
                partnerDataHolder.names = [...partnerDataHolder.names, partnerType.USER?.FULL_NAME ?? ""]
                partnerDataHolder.shares_number = [...partnerDataHolder.shares_number, partnerType.NO_FOUNDERS ?? 0]
            }
        })
    }




    return partnerDataHolder
}


export const getStatisticsByAction_Optimized = (statistics: STATISTICS, filterUser: "USER" | "TEAM" = 'USER', stationID: number, userID: number): USER_DATA_Optimized[] => {

    let AllUStatisticsDataPerPerson: STATISTICS_PER_STATION[] = statistics.ALL_STATISTICS ?? []
    if (stationID != -1) {
        AllUStatisticsDataPerPerson = statistics.ALL_STATISTICS?.filter(allStatistics => allStatistics.STATION_ID === stationID) ?? []
    }

    
    

    if (AllUStatisticsDataPerPerson !== undefined && AllUStatisticsDataPerPerson.length > 0) {
        if (filterUser === "USER") {
            let allUsersStatistics: ACTION_STATISTICS[] = []
            let Returned_value_type: ActionStatisticsRetunType[] = []
            let ActionsData: USER_DATA_Optimized = {} as USER_DATA_Optimized
            let all_user_data: USER_DATA_Optimized[] = []

            if (userID === -1) {
                AllUStatisticsDataPerPerson.map(data => {
                    data.USER_STATISTICS_PER_USER?.map(user => {
                        ActionsData = {
                            user: user.USER!,
                            user_id: user.USER_ID!,
                            CALL_ACTION: 0,
                            DEALS: 0,
                            Appointment: 0,
                            CUSTOMER_DETAILS: 0,
                            SITE_VISIT_REQ: 0,
                            Total_price_number: 0,
                            Total_price_Tafqeet: "",
                            Total_price_text: "",
                            station: data.STATIONS!
                        }

                        user.ACTION_STATISTICS?.map(action => {

                            if (action !== undefined) {
                                switch (action.ACTION_TYPE) {
                                    case ActionTypes.Call:
                                        ActionsData.CALL_ACTION = ActionsData.CALL_ACTION + (action.COUNT_ACTION ?? 0)
                                        break;
                                    case ActionTypes.ClientData:
                                        ActionsData.CUSTOMER_DETAILS = ActionsData.CUSTOMER_DETAILS + (action.COUNT_ACTION ?? 0)
                                        break;
                                    case ActionTypes.Appointment:
                                        ActionsData.Appointment = ActionsData.Appointment + (action.COUNT_ACTION ?? 0)
                                        break;
                                    case ActionTypes.SiteVisit:
                                        ActionsData.SITE_VISIT_REQ = ActionsData.SITE_VISIT_REQ + (action.COUNT_ACTION ?? 0)
                                        break;
                                    case ActionTypes.Deal:
                                        ActionsData.DEALS = ActionsData.DEALS + (action.COUNT_ACTION ?? 0)
                                        break;
                                }

                            }
                        })
                        ActionsData.Total_price_Tafqeet = user.TOTAL_SALES_TAFKIT ?? ""
                        ActionsData.Total_price_text = user.TOTAL_SALES_STRING ?? ""
                        allUsersStatistics.push(...user.ACTION_STATISTICS ?? [])
                        all_user_data.push(ActionsData)
                        // User_Data_statistics.push(ActionsData)
                    })
                })
            } else {
                AllUStatisticsDataPerPerson.map(data => {
                    data.USER_STATISTICS_PER_USER?.map(user => {
                        ActionsData = {
                            user: user.USER!,
                            user_id: user.USER_ID!,
                            CALL_ACTION: 0,
                            DEALS: 0,
                            Appointment: 0,
                            CUSTOMER_DETAILS: 0,
                            SITE_VISIT_REQ: 0,
                            Total_price_number: 0,
                            Total_price_Tafqeet: "",
                            Total_price_text: "",
                            station: data.STATIONS!
                        }
                        if (user.USER_ID === userID) {
                            user.ACTION_STATISTICS?.map(action => {
                                if (action !== undefined) {
                                    switch (action.ACTION_TYPE) {
                                        case ActionTypes.Call:
                                            ActionsData.CALL_ACTION = ActionsData.CALL_ACTION + (action.COUNT_ACTION ?? 0)
                                            break;
                                        case ActionTypes.ClientData:
                                            ActionsData.CUSTOMER_DETAILS = ActionsData.CUSTOMER_DETAILS + (action.COUNT_ACTION ?? 0)
                                            break;
                                        case ActionTypes.Appointment:
                                            ActionsData.Appointment = ActionsData.Appointment + (action.COUNT_ACTION ?? 0)
                                            break;
                                        case ActionTypes.SiteVisit:
                                            ActionsData.SITE_VISIT_REQ = ActionsData.SITE_VISIT_REQ + (action.COUNT_ACTION ?? 0)
                                            break;
                                        case ActionTypes.Deal:
                                            ActionsData.DEALS = ActionsData.DEALS + (action.COUNT_ACTION ?? 0)
                                            break;
                                    }

                                }
                            })
                            ActionsData.Total_price_Tafqeet = user.TOTAL_SALES_TAFKIT ?? ""
                            ActionsData.Total_price_text = user.TOTAL_SALES_STRING ?? ""
                            allUsersStatistics.push(...user.ACTION_STATISTICS ?? [])
                            all_user_data.push(ActionsData)
                        }
                    })
                })
            }

            
            
            
            if (all_user_data.length > 0) {
                return all_user_data
            }
        }
        if (filterUser === "TEAM") {
            let allUsersStatistics: ACTION_STATISTICS[] = []
            let Returned_value_type: ActionStatisticsRetunType[] = []
            let ActionsData: USER_DATA_Optimized = {} as USER_DATA_Optimized
            let all_user_data: USER_DATA_Optimized[] = []

            AllUStatisticsDataPerPerson.map(data => {
                data.USER_STATISTICS_PER_TEAM?.map(user => {
                    ActionsData = {
                        user: user.USER!,
                        user_id: user.USER_ID!,
                        CALL_ACTION: 0,
                        DEALS: 0,
                        Appointment: 0,
                        CUSTOMER_DETAILS: 0,
                        SITE_VISIT_REQ: 0,
                        Total_price_number: 0,
                        Total_price_Tafqeet: "",
                        Total_price_text: "",
                        station: data.STATIONS!
                    }

                    user.ACTION_STATISTICS?.map(action => {

                        if (action !== undefined) {
                            switch (action.ACTION_TYPE) {
                                case ActionTypes.Call:
                                    ActionsData.CALL_ACTION = ActionsData.CALL_ACTION + (action.COUNT_ACTION ?? 0)
                                    break;
                                case ActionTypes.ClientData:
                                    ActionsData.CUSTOMER_DETAILS = ActionsData.CUSTOMER_DETAILS + (action.COUNT_ACTION ?? 0)
                                    break;
                                case ActionTypes.Appointment:
                                    ActionsData.Appointment = ActionsData.Appointment + (action.COUNT_ACTION ?? 0)
                                    break;
                                case ActionTypes.SiteVisit:
                                    ActionsData.SITE_VISIT_REQ = ActionsData.SITE_VISIT_REQ + (action.COUNT_ACTION ?? 0)
                                    break;
                                case ActionTypes.Deal:
                                    ActionsData.DEALS = ActionsData.DEALS + (action.COUNT_ACTION ?? 0)
                                    break;
                            }
                            ActionsData.Total_price_Tafqeet = user.TOTAL_SALES_TAFKIT ?? ""
                            ActionsData.Total_price_text = user.TOTAL_SALES_STRING ?? ""
                        }
                    })

                    allUsersStatistics.push(...user.ACTION_STATISTICS ?? [])
                    all_user_data.push(ActionsData)
                    // User_Data_statistics.push(ActionsData)
                })
            })

            
            
            
            if (all_user_data.length > 0) {
                return all_user_data
            }
        }
    }


    return []
}

export const getStatisticsByBranch = (statistics: STATISTICS, stationID: number): Station_DATA_Optimized[] => {
    let allStationStatistics: STATION_STATISTICS[] = statistics.TOTAL_STATION_STATISTICS ?? [];

    if (stationID !== -1) {
        allStationStatistics = statistics.TOTAL_STATION_STATISTICS?.filter(allStatistics => allStatistics.STATION_ID === stationID) ?? []
    }

    
    

    if (allStationStatistics !== undefined && allStationStatistics.length > 0) {
        let allBranchStat: ACTION_STATISTICS[] = []
        let Returned_value_type_for_view: ActionStatisticsRetunType[] = []
        let All_stations_holder: Station_DATA_Optimized[] = []

        allStationStatistics.map(data => {
            let station_data: Station_DATA_Optimized = { Total_price_Tafqeet: data.TOTAL_SALES_TAFKIT ?? "مفيش", Total_price_text: data.TOTAL_SALES_STRING ?? "مفيش", station: data.STATION!, Total_price_number: 0, DEALS: 0, Appointment: 0, CALL_ACTION: 0, CUSTOMER_DETAILS: 0, SITE_VISIT_REQ: 0 }
            data.ACTION_STATISTICS?.map(action => {
                if (action !== undefined) {
                    switch (action.ACTION_TYPE) {
                        case ActionTypes.Call:
                            station_data.CALL_ACTION = station_data.CALL_ACTION + (action.COUNT_ACTION ?? 0)
                            break;
                        case ActionTypes.ClientData:
                            station_data.CUSTOMER_DETAILS = station_data.CUSTOMER_DETAILS + (action.COUNT_ACTION ?? 0)
                            break;
                        case ActionTypes.Appointment:
                            station_data.Appointment = station_data.Appointment + (action.COUNT_ACTION ?? 0)
                            break;
                        case ActionTypes.SiteVisit:
                            station_data.SITE_VISIT_REQ = station_data.SITE_VISIT_REQ + (action.COUNT_ACTION ?? 0)
                            break;
                        case ActionTypes.Deal:
                            station_data.DEALS = station_data.DEALS + (action.COUNT_ACTION ?? 0)
                            break;
                    }
                }
            })
            All_stations_holder.push(station_data)
        })


        if (All_stations_holder.length > 0) {
            // return dataGrouped as  ActionStatisticsRetunType[]
            return All_stations_holder
        }
    }

    return []
}