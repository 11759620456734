import { LoginReq } from './../../Service/models/LoginRQ';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GetConnectionFromServer1, Login, LogOut } from '../../Service/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';
import { hideFeedBack, showFeedBack } from './Feedback';
import { ClearCookies, GetError } from '../../Globals';
import { AxiosError } from 'axios';
import { AddAction } from './ErrorsSlice';

export interface IConnectionStatus {
  status: string, 
  Error : string , 
  Loading : boolean, 
  Empty : string
}

const initialState: IConnectionStatus = {
  status: "Not Checked", 
  Error : "", 
  Loading : false, 
  Empty : ''
}

export const ConnectionSlice = createSlice({
  name: 'Connection',
  initialState,
  reducers: {
    Connect: (state, action: PayloadAction<string>) => {
      state.status = action.payload
    },

  },
  extraReducers: (builder) => {

    // When we send a request,
    // `fetchTodos.pending` is being fired:
    builder.addCase(fetchTodos.pending, (state) => {
      // At that moment,
      // we change status to `loading` 
      // and clear all the previous errors:
      // state.status = "loading";
      // state.error = null;
    });

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(fetchTodos.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
        state.status = payload;
      });

    // When a server responses with an error:
    builder.addCase(fetchTodos.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        // state.status = "idle";

      });
    builder.addCase(LogOutThunk.pending, (state) => {
      // At that moment,
      // we change status to `loading` 
      // and clear all the previous errors:
      // state.status = "loading";
      // state.error = null;
    });

    // When a server responses with the data,
    // `fetchTodos.fulfilled` is fired:
    builder.addCase(LogOutThunk.fulfilled,
      (state, { payload }) => {
        // We add all the new todos into the state
        // and change `status` back to `idle`:
        // state.list.push(...payload);
        // state.status = "idle";
        state.status = payload as string;
      });

    // When a server responses with an error:
    builder.addCase(LogOutThunk.rejected,
      (state, { payload }) => {
        // We show the error message
        // and change `status` back to `idle` again.
        // if (payload) state.error = payload.message;
        // state.status = "idle";
        state.Error = payload as string
      });

  }
})

type FetchTodosError = {
  message: string;
};

export const fetchTodos = createAsyncThunk<string, LoginReq>(
  // The first argument is the action name:
  "Connection/getToken",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (loginData,thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return Login(loginData).then(res => {
      thunkApi.dispatch(setLoadingFalse());
      //thunkApi.dispatch(showFeedBack({show : true, message : "تمت عمليه الدخول", status : "success"}))
      // thunkApi.dispatch(showFeedBack({status:'success',message : 'التوكن اتاخدت تاني'}));
      return 'Done';
    }).catch(err => {
      return  GetError(err,thunkApi) 
    });
  }
);


export const LogOutThunk = createAsyncThunk<string>(
  // The first argument is the action name:
  "Connection/LogoutUsers",

  // The second one is a function
  // called payload creator.
  // It contains async logic of a side-effect.
  // We can perform requests here,
  // work with device API, 
  // or any other async APIs we need to.

  // The second argument, `thunkApi`, is an object
  // that contains all those fields
  // and the `rejectWithValue` function:

  // The third type-parameter is an object with:
  // `{dispatch?, state?, extra?, rejectValue?}`` fields.
  //
  // `extra` is useful when we need to pass 
  // some static data to the request function,
  // like jwt-token or HTTP-headers.
  //
  // `rejectValue` is useful when we need to type 
  // possible errors.

  async (loginData,thunkApi) => {

    thunkApi.dispatch(setLoadingTrue());

    return LogOut().then(res => {
      ClearCookies();
      thunkApi.dispatch(setLoadingFalse());
      //thunkApi.dispatch(showFeedBack({show : true, message : "", status : "success"}))
      // thunkApi.dispatch(showFeedBack({status:'success',message : 'التوكن اتاخدت تاني'}));
      return 'Done';
    }).catch(err => {
      return  GetError(err,thunkApi) 
    });
  }
);

export const { Connect } = ConnectionSlice.actions

export default ConnectionSlice.reducer