import React, { createContext, ReactElement, ReactNode, useContext, useState } from 'react'
import { DownArrow, UpArrow } from '../Icons/Icons';

type AccordionContextType = {
    openIndex: number | null;
    toggleIndex: (index: number) => void;
};

type AccordionProps = {
    children: ReactNode;
    defaultIndex?: number | null;
};

type AccordionItemProps = {
    children: ReactNode;
    index: number;
};

type AccordionHeaderProps = {
    children: ReactNode;
    index: number;
};

type AccordionPanelProps = {
    children: ReactNode;
    index: number;
};

type AccordionChild = ReactElement<AccordionHeaderProps | AccordionPanelProps>;

const AccordionContext = createContext<AccordionContextType | undefined>(undefined);

export const useAccordionContext = () => {
    const context = useContext(AccordionContext);
    if (!context) {
        throw new Error('useAccordionContext must be used within an Accordion');
    }
    return context;
};

export const Accordion: React.FC<AccordionProps> = ({ children, defaultIndex = null }) => {
    const [openIndex, setOpenIndex] = useState<number | null>(defaultIndex);

    const toggleIndex = (index: number) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <AccordionContext.Provider value={{ openIndex, toggleIndex }}>
            <div className="w-full">{children}</div>
        </AccordionContext.Provider>
    );
};


export const AccordionItem: React.FC<AccordionItemProps> = ({ children, index }) => {
    return (
        <div className="border-b">
            {React.Children.map(children, (child) =>
                React.isValidElement(child) && (child as AccordionChild).type
                    ? React.cloneElement(child as AccordionChild, { index })
                    : child
            )}
        </div>
    );
};


export const AccordionHeader: React.FC<AccordionHeaderProps> = ({ children, index }) => {
    const { openIndex, toggleIndex } = useAccordionContext();
    const isOpen = openIndex === index;

    return (
        <div
            className="p-4 cursor-pointer flex justify-between items-center"
            onClick={() => toggleIndex(index)}
        >
            <p className='font-bold text-xl'>{children}</p>
            <div className='w-10 h-10'>{isOpen ? <UpArrow /> : <DownArrow />}</div>
        </div>
    );
};

export const AccordionPanel: React.FC<AccordionPanelProps> = ({ children, index }) => {
    const { openIndex } = useAccordionContext();
    return (
        <div className={`overflow-hidden transition-all ${openIndex === index ? 'h-fit' : 'max-h-0'}`}>
            <div className="p-4">{children}</div>
        </div>
    );
};


