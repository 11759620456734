import React, { useEffect, useState } from 'react'
import { CustomTextFieldFullHeight } from '../../../CustomComps/Customs';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { DropDownType } from '../../../components/shared/EGDropDown/EGDropDOwn';
import { DropDownData, DropTextField } from '../../DashBoard/Actions/ActionsTypesScreen/AppointMents';
import { EgButton } from '../../Shared/Shared';
import { MONEY_SOURCE, TRANSACTIONS, TRANSACTION_CATG, TRANSACTION_DETALS, TRANSACTION_TYPES, UserDeals } from '../../../Service/models/TransActions_model';
import { GetAllEvents, GetAllUserDetail, GetFounderAvailabeBalance, GetUserSalary, GetVisitExpensesByUser, trans_all, trans_deals, trans_fin_save, trans_types } from '../../../Service/Apis';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { AddAction } from '../../../redux/slices/ErrorsSlice';
import { setLoadingFalse, setLoadingTrue } from '../../../redux/slices/LoadingSlice';
import { USER_Detail } from '../../../Service/models/UserModel/UserModel';
import { Boxes, GetErrorAxios } from '../../../Globals';
import { UserSalaries } from '../../../Service/models/Salary_Model';
import { EVENTS } from '../../../Service/models/EventsData';
import MulitDD from '../../../components/MultiDD';
import { SITE_VISIT_MODEL } from '../../../Service/models/SITEVISITMODEL';
import { UserAmount } from '../../../Service/models/statistics/indext';

const Withdraw = () => {

  //global data
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
  const dispatch = useDispatch<AppDispatch>()
  const [multiDDHolder, setMultiDDholder] = useState<number[]>([6])
  //Request
  const [TransRequest, setTransRequest] = useState<TRANSACTIONS>({} as TRANSACTIONS)

  //change & total number
  const [changeData, setChangeData] = useState<{ EGP: string, FiveEGP: string, TenEgp: string, twentyEGP: string, fiftyEGP: string, hundredEGP: string, TwoHundredEGP: string }>({ EGP: "0", FiveEGP: "0", TenEgp: "0", twentyEGP: "0", fiftyEGP: "0", hundredEGP: "0", TwoHundredEGP: "0" })
  const [total, setTotal] = useState<string>("")

  //Users State
  const [users, setUsers] = useState<USER_Detail[]>([])
  const [selectedUserId, setSelectedUserId] = useState<number>(-1)
  const [userDropDown, setUserDropDown] = useState<DropDownData[]>([])

  //Box State
  const [selectedBoxId, setSelectedBoxId] = useState<number>(0)

  //TransReason
  const [transReason, setTransUser] = useState<TRANSACTION_TYPES[]>([])
  const [selectedTransReason, setSelectedReason] = useState<number>(-1)
  const [TransReasonDropDown, setTransReasonDropDown] = useState<DropDownData[]>([])
  const [isSalary, setIssalary] = useState<boolean>(false);
  const [isEvent, setisEvent] = useState<boolean>(false);
  const [isTransportation, setisTransportation] = useState<boolean>(false);
  const [isProfit, setIsProfit] = useState<boolean>(false);
  const [isDeal, setIsDeal] = useState<boolean>(false)

  // notes 
  const [notes, setNotes] = useState<string>("")


  // DealsPerUser
  const [userDeals, setUserDeals] = useState<UserDeals>({})

  // Salaries for Users 
  const [userSalaries, setUserSalaries] = useState<UserSalaries[]>([])

  // Selected Deals To withdraw from 
  const [selectedDeals, setSelectedDeals] = useState<TRANSACTION_DETALS[]>([])

  // Selected Salary To withdraw from 
  const [selectedSalary, setSelectedSalary] = useState<TRANSACTION_DETALS[]>([])

  // Selected transportation To withdraw from 
  const [transportation, setTransportation] = useState<TRANSACTION_DETALS[]>([])
  const [TransportationDD, setTransportationDD] = useState<SITE_VISIT_MODEL[]>([])

  // Events & selected Event 
  const [Events, setEvents] = useState<EVENTS[]>([])
  const [selectedEvent, setSelectedEvent] = useState<TRANSACTION_DETALS[]>([])

  // Profit & selected Profit
  const [Profits, setProfit] = useState<UserAmount>({} as UserAmount)
  const [selectedProfit, setSelectedProfit] = useState<TRANSACTION_DETALS[]>([])

  // force withdraw 
  const [forceWithdraw, setForceWithdraw] = useState<boolean>(false)

  const [isEventFilter, setIsEvent] = useState<boolean>(false);

  // set salary, events & isTransportation
  const resetEveryThing = (only: "salary" | "Event" | "transportation" | "deal" | "profit", add: boolean, reset?: boolean) => {


    if (reset === true && reset !== undefined) {
      setIssalary(false)
      setisEvent(false)
      setisTransportation(false)
      setIsDeal(false)
      setIsProfit(false)
    } else {
      if (add === false) {
        setIssalary(false)
        setisEvent(false)
        setisTransportation(false)
        setIsDeal(false)
        setIsProfit(false)
      }


      if (only === 'salary') {
        setIssalary(true)
      }
      if (only === 'Event') {
        setisEvent(true)
      }
      if (only === 'transportation') {
        setisTransportation(true)
      }
      if (only === 'deal') {
        setIsDeal(true)
      }
      if (only === 'profit') {
        setIsProfit(true)
      }
    }



  }

  const ResetTransDetails = (only: "deal" | "salary" | "event" | "transportation" | "profit", Data: TRANSACTION_DETALS[]) => {

    setSelectedEvent([])
    setSelectedSalary([])
    setSelectedDeals([])
    setTransportation([])
    setSelectedProfit([])
    //TODO clear transportation

    if (only === 'salary') {
      setSelectedSalary(prev => Data)

    }
    if (only === 'event') {
      setSelectedSalary(prev => Data)
    }
    if (only === 'transportation') {
      //TODO Check for Transportation
      setTransportation(prev => Data)
    }
    if (only === 'deal') {
      setSelectedDeals(prev => Data)
    }
    if (only === 'profit') {
      setSelectedProfit(prev => Data)
    }


  }

  //Final selected Trans Details
  const [finalTrans, setFinalTrans] = useState<TRANSACTION_DETALS[]>([])
  useEffect(() => {
    if (selectedSalary.length > 0) {
      setFinalTrans(selectedSalary)
    }
    if (selectedDeals.length > 0) {
      setFinalTrans(selectedDeals)
    }
    if (selectedEvent.length > 0) {
      setFinalTrans(selectedEvent)
    }
    if (transportation.length > 0) {
      setFinalTrans(transportation)
    }
    if (selectedProfit.length > 0) {
      setFinalTrans(selectedProfit)
    }

  }, [selectedEvent, selectedSalary, selectedDeals, transportation, selectedProfit])


  //functions

  // get all required data for dropdowns in screen
  async function GetAllDropDownData() {
    let transTypes = await trans_types()
    let AllUsers = await GetAllUserDetail({ WithAdmin: false, WithNewUsers: false })


    // AllEvents 


    // users dropdown logic 
    if (AllUsers.length > 0) {
      setUsers(AllUsers)
      let DropDownMap: DropDownData[] = AllUsers.map((item, idx) => ({ id: item.ID, desc: item.FULL_NAME, image: item.PHOTO_URL }))
      setUserDropDown(DropDownMap)
    }

    //trans dropdown logic 
    if (transTypes !== null && transTypes.length > 0) {
      setTransUser(transTypes)
      let TransTypesDropDownMap: DropDownData[] = transTypes.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
      setTransReasonDropDown(TransTypesDropDownMap)
    }
  }

  // calculate the sum of all changes in screens
  function CalculateTotal(Amounts: { EGP: string, FiveEGP: string, TenEgp: string, twentyEGP: string, fiftyEGP: string, hundredEGP: string, TwoHundredEGP: string }) {
    if (Amounts.TwoHundredEGP === "") Amounts.TwoHundredEGP = "0"
    if (Amounts.hundredEGP === "") Amounts.hundredEGP = "0"
    if (Amounts.fiftyEGP === "") Amounts.fiftyEGP = "0"
    if (Amounts.twentyEGP === "") Amounts.twentyEGP = "0"
    if (Amounts.TenEgp === "") Amounts.TenEgp = "0"
    if (Amounts.FiveEGP === "") Amounts.FiveEGP = "0"
    if (Amounts.EGP === "") Amounts.EGP = "0"



    let EGPAmount = parseInt(Amounts.EGP) ?? 0
    let EGPFive_Amount = parseInt(Amounts.FiveEGP) * 5 ?? 0
    let EGPTen_Amount = parseInt(Amounts.TenEgp) * 10 ?? 0
    let EGPTwenty_Amount = parseInt(Amounts.twentyEGP) * 20 ?? 0
    let EGPFifty_Amount = parseInt(Amounts.fiftyEGP) * 50 ?? 0
    let EGPHundred_Amount = parseInt(Amounts.hundredEGP) * 100 ?? 0
    let EGPTwoHundred_Amount = parseInt(Amounts.TwoHundredEGP) * 200 ?? 0

    return EGPAmount + EGPFive_Amount + EGPTen_Amount + EGPTwenty_Amount + EGPFifty_Amount + EGPHundred_Amount + EGPTwoHundred_Amount
  }


  // Get deals per user
  async function GetUserDeals() {
    if (selectedUserId === -1) {
      dispatch(AddAction({ message: "رجاء اختيار مستخدم", status: 'error' }))
    } else {
      let allDeals = await trans_deals(selectedUserId);

      if (allDeals != null) {
        setUserDeals(allDeals)
      }
    }
  }

  // Get Salaries per user
  async function GetUserSalaries() {
    if (selectedUserId === -1) {
      dispatch(AddAction({ message: "رجاء اختيار مستخدم", status: 'error' }))
    } else {
      let allSalaries = await GetUserSalary({ USERID: selectedUserId, WITHOUT_COLLECTED: true });

      if (allSalaries != null) {
        setUserSalaries(allSalaries)
      }
    }
  }


  // GetEvents
  async function GetAllEventsAPI() {
    if (Events.length === 0) {
      let allEvents = await GetAllEvents();
      if (allEvents.length > 0) {
        setEvents(allEvents)
      }
    }
  }

  // get Transportations 
  const getAllTransportation = async () => {
    GetVisitExpensesByUser({ USERID: selectedUserId }).then(res => {
      setTransportationDD(res)
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }

  const GetSelectedUserProfit = () => {
    GetFounderAvailabeBalance({ USERID: selectedUserId }).then(res => {
      setProfit(res)
    }).catch(err => {
      GetErrorAxios(err, dispatch)
    })
  }


  // useEffect Logic
  useEffect(() => {
    GetAllDropDownData()
  }, [])

  useEffect(() => {

  }, [selectedDeals])


  useEffect(() => {
    let totalSum = CalculateTotal(changeData)
    setTotal(totalSum.toString())
  }, [changeData])

  useEffect(() => {
    // if (selectedBoxId === Boxes.ExpensesBox) {
    //   let transReasonsDropDown = transReason.filter(item => item.IS_SALARY)
    //   let TransTypesDropDownMap: DropDownData[] = transReasonsDropDown.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
    //   setTransReasonDropDown(TransTypesDropDownMap)
    // } else {
    //   let TransTypesDropDownMap: DropDownData[] = transReason.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
    //   setTransReasonDropDown(TransTypesDropDownMap)
    // }
    // let TransTypesDropDownMap: DropDownData[] = transReason.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
    // setTransReasonDropDown(TransTypesDropDownMap)
  }, [selectedBoxId])

  // Main Function Request 
  const withdrawMoney = (TransDetails: TRANSACTION_DETALS[]) => {
    let Continue = true

    if (TransDetails.length < 1) {
      dispatch(AddAction({ message: "لم يتم تحديد صرف المال في انهي جهه, برجاء تحديد الجهه مثل صفقه او مرتب او انتقال او فاعليه ..الخ", status: 'error' }))
      return;
    }


    if (selectedUserId === -1) {
      dispatch(AddAction({ message: "برجاء تحديد مستخدم ليتم الصرف ليه", status: 'error' }))
      return;
    }

    if (selectedBoxId === 0) {
      dispatch(AddAction({ message: "برجاء تحديد صندوق للصرف منه زي المصاريف او الشركه او البوكس ... الخ", status: 'error' }))
      return;
    }


    if ((changeData.EGP === "" || changeData.EGP === '0') && (changeData.FiveEGP === "" || changeData.FiveEGP === '0') && (changeData.TenEgp === "" || changeData.TenEgp === '0') && (changeData.twentyEGP === "" || changeData.twentyEGP === '0') && (changeData.fiftyEGP === "" || changeData.fiftyEGP === '0') && (changeData.hundredEGP === "" || changeData.hundredEGP === '0') && (changeData.TwoHundredEGP === "" || changeData.TwoHundredEGP === '0')) {
      Continue = false;
      dispatch(AddAction({ message: "برجاء تحديد فئات المال المصروف", status: 'error' }))
      return;
    }


    if (Continue) {
      let totalConversion = total
      if (total === "") totalConversion = "0"


      let withdrawRequestObject: TRANSACTIONS = {
        AMOUNT: parseFloat(totalConversion),
        COUNT_1: parseInt(changeData.EGP),
        COUNT_10: parseInt(changeData.TenEgp),
        COUNT_5: parseInt(changeData.FiveEGP),
        COUNT_20: parseInt(changeData.twentyEGP),
        COUNT_50: parseInt(changeData.fiftyEGP),
        COUNT_100: parseInt(changeData.hundredEGP),
        COUNT_200: parseInt(changeData.TwoHundredEGP),
        SOURCE_TYPE: MONEY_SOURCE.BOX_SOURCE,
        TRANSACTION_CATG: TRANSACTION_CATG.CREDIT_CATG,
        NOTES: notes,
        TRANSACTION_DETALS: TransDetails,
        FORCE_TRANSACTION: forceWithdraw,
        TRANSACTION_TYPE_ID: selectedTransReason

      }

      // if no deals selected and box type is commission
      // if no change is selected 



      dispatch(setLoadingTrue())
      trans_fin_save(withdrawRequestObject).then(res => {
        dispatch(AddAction({ message: "تم الصرف بنجاح", status: 'success' }))
        window.location.reload()
      }).catch(err => {
        GetErrorAxios(err, dispatch)
      }).finally(() => {
        dispatch(setLoadingFalse())
      })
    }


  }

  return (
    // grid container
    <div className='grid grid-cols-2 grid-rows-2 gap-3'>
      {/* right top side */}
      <div className='bg-white rounded shadow col-start-1 col-end-2 row-start-1 row-end-2'>
        {/* main container */}
        <div className='w-full h-full p-3'>
          <h3 className='mb-5 font-bold'>الفئات</h3>
          {/* فئات الفلوس */}
          <div className='flex justify-between flex-wrap w-full mb-8'>
            {/* الجنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>الجنيه</h6>
              <CustomTextFieldFullHeight height={25} id='EGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, EGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 5 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>5 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='FiveEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, FiveEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 10 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>10 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='TenEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, TenEgp: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 20 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>20 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='twentyEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, twentyEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 50 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>50 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='FiftyEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, fiftyEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 100 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>100 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='hundredEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, hundredEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
            {/* 200 جنيه */}
            <div className='max-w-[75px]'>
              <h6 className='mb-2'>200 جنيه</h6>
              <CustomTextFieldFullHeight height={25} id='TwoHundredEGP' type={''} onChange={(e: any) => {
                let val = e.target.value as string;
                setChangeData(prev => ({ ...prev, TwoHundredEGP: val }))

              }} style={MainInputStyle}
                colors={colors} theme={theme} />
            </div>
          </div>
          {/* الإجمالي */}
          <div className='w-full'>
            <h6 className='mb-2'>الإجمالي</h6>
            <CustomTextFieldFullHeight disabled value={total} height={25} id='PROJECT_NAME' type={''} onChange={(e: any) => {
              let val = e.target.value as string;

            }} style={MainInputStyle}
              colors={colors} theme={theme} />
          </div>
        </div>
      </div>
      {/* left top side*/}
      <div className='bg-white rounded shadow col-start-2 col-end-3 row-start-1 row-end-2' >
        {/* container */}
        <div className='p-3 flex flex-col gap-4'>

          {/* صرف إجباري */}
          <div className='flex gap-3 items-center '>
            <input type="checkbox" id="uniqueLoc" checked={forceWithdraw} name="ProjType" className='w-[1em] h-[1em]' onChange={(e: any) => {
              let isChecked = e.target.checked
              setForceWithdraw(isChecked)
            }} />
            <label htmlFor='uniqueLoc' className='text-xl'>صرف إجباري</label>
          </div>
          {/* الصندوق */}
          <div className='w-full flex flex-row gap-4 items-center'>
            <label className='text-base min-w-[80px]'>الصندوق</label>
            <DropTextField initalValue={0} data={[
              { id: 0, desc: "اختار صندوق" },
              { id: Boxes.CommissionBox, desc: "عمولات" },
              { id: Boxes.CompanyBox, desc: "الشركه" },
              { id: Boxes.ExpensesBox, desc: "المصاريف" },
              { id: Boxes.FoundersBox, desc: "المؤسسين" },
              { id: Boxes.TaxBox, desc: "الضرائب" },
              { id: Boxes.TheTeamBox, desc: "TheTeam" },
              { id: Boxes.ZakahBox, desc: "الزكاه" },

            ]} onchange={e => {
              let val = e.target.value as number

              if (val === Boxes.ExpensesBox) {
                // let TransTypesDropDownMap: DropDownData[] = transReason.filter(item => (item.IS_EVENT === undefined || item.IS_EVENT === false)).map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
                let TransTypesDropDownMap: DropDownData[] = transReason.filter(item => item.IS_EVENT !== true).map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
                setTransReasonDropDown(TransTypesDropDownMap)
              } else {
                let TransTypesDropDownMap: DropDownData[] = transReason.map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
                setTransReasonDropDown(TransTypesDropDownMap)
              }
              setSelectedBoxId(val)

              if (val === Boxes.FoundersBox) {
                resetEveryThing("profit", true, false)
              } else {
                resetEveryThing("Event", false, true)
              }
            }} />
          </div>
          {/* التصنيف */}
          {selectedBoxId === Boxes.ExpensesBox && (
            <div className='w-full flex flex-row gap-4 items-center'>
              <label className='text-base min-w-[80px]'>التصنيف</label>
              <DropTextField initalValue={0} data={[
                { id: 0, desc: "اخرى" },
                { id: 1, desc: "فعاليات" },
              ]} onchange={e => {
                let val = e.target.value as number
                if (val === 0) {
                  setIsEvent(true)

                  let TransTypesDropDownMap: DropDownData[] = transReason.filter(item => (item.IS_EVENT === undefined || item.IS_EVENT === false)).map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
                  setTransReasonDropDown(TransTypesDropDownMap)
                } else {
                  setIsEvent(false)
                  let TransTypesDropDownMap: DropDownData[] = transReason.filter(item => item.IS_EVENT === true).map((item, idx) => ({ id: item.ID, desc: item.DESCRIPTION }))
                  setTransReasonDropDown(TransTypesDropDownMap)
                }
                // setSelectedBoxId(val)
                // resetEveryThing("Event", false, true)
              }} />
            </div>
          )}


          {/* سبب الصرف */}
          <div className='w-full flex flex-row gap-4 items-center'>
            <label className='text-base min-w-[80px]'>سبب الصرف</label>
            <DropTextField initalValue={-1} data={[
              { id: -1, desc: "اختار السبب" },
              ...TransReasonDropDown
            ]} onchange={e => {
              let val = e.target.value as number
              let selectedTransReason = transReason.find(item => item.ID === val)

              if (selectedTransReason !== undefined) {
                resetEveryThing('salary', false, true)
                if (selectedTransReason.IS_SALARY) {
                  resetEveryThing('salary', true, false)
                }
                if (selectedTransReason.IS_EVENT) {
                  resetEveryThing('Event', true, false)
                }
                if (selectedTransReason.IS_TRANSPORTATION) {
                  resetEveryThing('transportation', true, false)
                }
                if (selectedTransReason.IS_DEAL) {
                  resetEveryThing('deal', true, false)
                } else {
                  resetEveryThing('profit', true, false)
                }
              }

              setSelectedReason(val)
            }} />
          </div>
          {/* المستخدمين */}
          <div className='w-full flex flex-row gap-4 items-center'>
            <label className='text-base min-w-[80px]'>المستخدمين</label>
            {/* <DropTextField initalValue={-1} data={[
              { id: -1, desc: "اختار مستخدم" }, ...userDropDown
            ]} onchange={e => {
              let val = e.target.value
              setSelectedUserId(val)
            }} /> */}
          </div>
          {multiDDHolder && (
            <MulitDD OuterDropDownData={userDropDown} IsImage={true} SelectedIds={(ids) => {

              setSelectedUserId(ids[0])
            }} SingleSelection />
          )}
          {/* الصفقات */}
          {isDeal && (
            <>
              <div className='w-1/4 md:w-1/2 flex flex-row gap-4 items-center'>
                <EgButton HandleClick={() => GetUserDeals()}>
                  <h6>عرض الصفقات</h6>
                </EgButton>
              </div>
              <hr />
              <div>
                <div>
                  <h5 className='text-[#e7c498] mb-6'>صفقات تم صرف شيكها</h5>
                  <div>
                    {(userDeals.CollectedDeals !== null && userDeals.CollectedDeals !== undefined && userDeals.CollectedDeals.length > 0) && userDeals.CollectedDeals.map((item, idx) => {
                      let dealName = item.DEAL_ID + "/" + item.CONTACT_NAME + "/" + item.PROJECT_NAME + " بمبلغ " + item.AMOUNT + " جنيه مصري "
                      return <div key={item.DEAL_ID}>
                        <input type="radio" id={item.DEAL_ID!.toString()} name={"AllDeals"} className='w-[1em] h-[1em]' onChange={(e: any) => {
                          // setSelectedSalary([])
                          // setSelectedDeals(prev => [{ AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, DEAL_ACTION_ID: item.DEAL_ID }])
                          ResetTransDetails('deal', [{ AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, DEAL_ACTION_ID: item.DEAL_ID }])
                        }} />
                        <h6>{dealName}</h6>
                      </div>
                    })}
                  </div>
                </div>
                <hr />
                {forceWithdraw && (
                  <div>
                    <h5 className='text-[#e7c498] mb-6'>صفقات لم يتم صرف شيكها</h5>
                    <div>
                      {(userDeals.UnCollectedDeals !== null && userDeals.UnCollectedDeals !== undefined && userDeals.UnCollectedDeals.length > 0) && userDeals.UnCollectedDeals.map((item, idx) => {
                        let dealName = item.DEAL_ID + " / " + item.CONTACT_NAME + " / " + item.PROJECT_NAME + " بمبلغ " + item.AMOUNT + " جنيه مصري "
                        return <div className='flex gap-5 mb-3' key={item.DEAL_ID}>
                          <input type="radio" id={item.DEAL_ID!.toString()} name={"AllUnPaidDeals"} className='w-[1em] h-[1em]' onChange={(e: any) => {
                            // setSelectedSalary([])
                            // setSelectedDeals(prev => [{ AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, DEAL_ACTION_ID: item.DEAL_ID }])
                            ResetTransDetails('deal', [{ AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, DEAL_ACTION_ID: item.DEAL_ID }])
                          }} />
                          <h6>{dealName}</h6>
                        </div>
                      })}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {/* المرتبات */}
          {isSalary && (
            <>
              <div className='w-1/4 md:w-1/2 flex flex-row gap-4 items-center'>
                <EgButton HandleClick={() => GetUserSalaries()}>
                  <h6>عرض المرتبات</h6>
                </EgButton>
              </div>
              <hr />
              <div>
                <div>
                  <h5 className='text-[#e7c498] mb-6'>المرتبات المتاحه</h5>
                  <div>
                    {(userSalaries !== null && userSalaries !== undefined && userSalaries.length > 0) && userSalaries.map((item, idx) => {
                      // let salaryName = item.DEAL_ID + "/" + item.CONTACT_NAME + "/" + item.PROJECT_NAME + " بمبلغ " + item.AMOUNT + " جنيه مصري "
                      return <div key={item.DATE} className='flex flex-col gap-1'>
                        <div className='flex gap-5'>
                          <input type="radio" id={item.DATE} name={"AllSalaries"} className='w-[1em] h-[1em]' onChange={(e: any) => {
                            // setSelectedDeals([])
                            // setSelectedSalary(prev => [{ AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, MONTH: item.MONTH, YEAR: item.YEAR }])
                            ResetTransDetails('salary', [{ AMOUNT: item.AMOUNT, DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, MONTH: item.MONTH, YEAR: item.YEAR }])
                          }} />
                          <div className='flex w-full lg:w-1/2 justify-between'>
                            <div>
                              <h6>التاريخ</h6>
                              <p>{item.DATE}</p>
                            </div>
                            <div>
                              <h6>المرتب</h6>
                              <p>{item.AMOUNT}</p>
                            </div>
                          </div>
                        </div>
                        <hr className='w-full bg-black mb-2' />
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* الفعاليات */}
          {isEvent && (
            <>
              <div className='w-1/4 md:w-1/2 flex flex-row gap-4 items-center'>
                <EgButton HandleClick={() => GetAllEventsAPI()}>
                  <h6>عرض الفعاليات</h6>
                </EgButton>
              </div>
              <hr />
              <div>
                <div>
                  <h5 className='text-[#e7c498] mb-6'>الفاعليات المتاحه</h5>
                  <div>
                    {(Events !== null && Events !== undefined && Events.length > 0) && Events.map((item, idx) => {
                      // let salaryName = item.DEAL_ID + "/" + item.CONTACT_NAME + "/" + item.PROJECT_NAME + " بمبلغ " + item.AMOUNT + " جنيه مصري "
                      return <div key={item.ID} className='flex flex-col gap-1'>
                        <div className='flex gap-5'>
                          <input type="radio" id={item.ID?.toString()} name={"AllEvents"} className='w-[1em] h-[1em]' onChange={(e: any) => {
                            ResetTransDetails('event', [{ DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, EVENT_ID: item.ID, AMOUNT: item.EVENTS_DETAILS.reduce((acc, obj) => acc + obj.AMOUNT, 0), }])
                          }} />
                          <div className='flex w-full lg:w-1/2 justify-between'>
                            <div>
                              <h6>الفاعليه</h6>
                              <p>{item.DESCRIPTION}</p>
                            </div>
                            <div>
                              <h6>المصاريف</h6>
                              <p>{item.EVENTS_DETAILS.reduce((acc, a) => { return acc + a.AMOUNT }, 0)}</p>
                            </div>
                          </div>
                        </div>
                        <hr className='w-full bg-black mb-2' />
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* النقل */}
          {isTransportation && (
            <>
              <div className='w-1/4 md:w-1/2 flex flex-row gap-4 items-center'>
                <EgButton HandleClick={() => getAllTransportation()}>
                  <h6>عرض التنقلات</h6>
                </EgButton>
              </div>
              <hr />
              <div>
                <div>
                  <h5 className='text-[#e7c498] mb-6'>التنقلات المتاحه المتاحه</h5>
                  <div>
                    {(TransportationDD !== null && TransportationDD !== undefined && TransportationDD.length > 0) && TransportationDD.map((item, idx) => {
                      // let salaryName = item.DEAL_ID + "/" + item.CONTACT_NAME + "/" + item.PROJECT_NAME + " بمبلغ " + item.AMOUNT + " جنيه مصري "
                      return <div key={item.ACTION_ID} className='flex flex-col gap-1'>
                        <div className='flex gap-5'>
                          <input type="radio" id={item.ACTION_ID!.toString()} name={"AllTrans"} className='w-[1em] h-[1em]' onChange={(e: any) => {
                            ResetTransDetails('transportation', [{ DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, TRANSPORTATION_ACTION_ID: item.ACTION_ID, AMOUNT: item.AMOUNT }])
                          }} />
                          <div className='flex w-full lg:w-1/2 justify-between'>
                            <div>
                              <h6>الى</h6>
                              <p>{item.SITE?.SITE_NAME_AR}</p>
                            </div>
                            <div>
                              <h6>المبلغ</h6>
                              <p>{item.AMOUNT}</p>
                            </div>
                          </div>
                        </div>
                        <hr className='w-full bg-black mb-2' />
                      </div>
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* الأرباح */}
          {isProfit && (
            <>
              <div className='w-1/4 md:w-1/2 flex flex-row gap-4 items-center'>
                <EgButton HandleClick={() => {
                  if (selectedUserId === undefined || selectedUserId === -1) {
                    dispatch(AddAction({ message: "برجاء اختيار مستخدم", status: 'error' }))
                    return;
                  }
                  GetSelectedUserProfit()
                }}>
                  <h6>عرض الأرباح</h6>
                </EgButton>
              </div>
              <hr />
              <div>
                <div>
                  <div>
                    {(Profits.USER_ID !== undefined) && (
                      <div key={Profits.USER_ID} className='flex flex-col gap-1'>
                        <h5 className='text-[#e7c498] mb-6'>الأرباح</h5>

                        <div className='flex gap-5'>
                          <input type="radio" name={"AllProfits"} className='w-[1em] h-[1em]' onChange={(e: any) => {
                            ResetTransDetails('profit', [{ DESTINATION_ID: selectedBoxId, BENEFICIARY_USER_ID: selectedUserId, AMOUNT: Profits.AMOUNT }])
                          }} />
                          <div className='flex w-full lg:w-1/2 justify-between'>
                            <div>
                              <h6>المبلغ</h6>
                              <p>{Profits.AMOUNT}</p>
                            </div>
                          </div>
                        </div>
                        <hr className='w-full bg-black mb-2' />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* bottom full width side of grid*/}
      <div className='bg-white rounded shadow col-span-full h-60'>
        {/* container */}
        <div className='flex flex-col gap-5  p-3'>
          {/* ملاحظات */}
          <textarea id="Notes" name="otherNotes" className="col-span-2 resize-none w-full h-[10em] shadow-[0px] rounded-md border-black border focus:outline-none focus:shadow-none " placeholder='ملاحظات' onChange={(e: any) => {
            let val = e.target.value
            setNotes(val)
          }} >
          </textarea>
          <div className='self-end w-28'>
            <EgButton HandleClick={() => {
              withdrawMoney(finalTrans)
            }}>
              <h6>صرف</h6>
            </EgButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Withdraw
