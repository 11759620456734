export const getNotificationElement = (mouseEnter, MouseLeave)=> {

    //Notification Items
    var item = document.getElementById("Nofification");
    var itemContainer = document.getElementById("NotifiContainer");

    itemContainer.addEventListener('mouseenter',function (){
        item.style.fill = mouseEnter;
    });

    itemContainer.addEventListener('mouseleave',function (){
        item.style.fill = MouseLeave;
    });

};

export const getSetupElement = (mouseEnter, MouseLeave)=> {

    //Notification Items
    var items = document.getElementsByClassName("setupPath");
    var itemContainer = document.getElementById("Setup");


    itemContainer.addEventListener('mouseenter',function (){
        for (var i = 0; i < items.length; i++) {
            var item = items.item(i)
            item.style.fill = mouseEnter
            item.classList.add('RotateBy360');
         }  
    });

    itemContainer.addEventListener('mouseleave',function (){
        for (var i = 0; i < items.length; i++) {
            var item = items.item(i)
            item.style.fill = MouseLeave;
            item.classList.remove('RotateBy360')
         }  
    });

};



export const menuItemJS = (mouseEnter, MouseLeave)=> {

    var items = document.getElementsByClassName("MenuPath");
    var itemContainer = document.getElementById("MenuContainer");



    itemContainer.addEventListener('mouseenter',function (){
        for (var i = 0; i < items.length; i++) {
            items.item(i).style.fill = mouseEnter;
         }   
    });

    itemContainer.addEventListener('mouseleave',function (){
        for (var i = 0; i < items.length; i++) {
            items.item(i).style.fill = MouseLeave;
         }   
    });

};