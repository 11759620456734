// import React, { useEffect, useRef, useState } from 'react'
// import styles from './ProgressBar.module.css'

// interface ProgressBarProps {
//     percentage: number;
//     label?: string;
//     showCompletedPercentage?: boolean;
//     duration?: number; // Duration in milliseconds
// }

// const ProgressBar: React.FC<ProgressBarProps> = ({ 
//     percentage, 
//     label, 
//     showCompletedPercentage = true,
//     duration = 1000 // Default duration of 1 second
// }) => {
//     const [animatedPercentage, setAnimatedPercentage] = useState(0);
//     const previousPercentage = useRef(percentage);

//     useEffect(() => {
//         // Only animate if the percentage has changed
//         if (previousPercentage.current !== percentage) {
//             const startTime = Date.now();
//             const startValue = previousPercentage.current;
//             const endValue = percentage;

//             const animateProgress = () => {
//                 const now = Date.now();
//                 const elapsedTime = now - startTime;
//                 const progress = Math.min(elapsedTime / duration, 1);

//                 // Easing function: ease-out cubic
//                 const easedProgress = 1 - Math.pow(1 - progress, 3);

//                 const currentValue = startValue + (endValue - startValue) * easedProgress;
//                 setAnimatedPercentage(currentValue);

//                 if (progress < 1) {
//                     requestAnimationFrame(animateProgress);
//                 }
//             };

//             requestAnimationFrame(animateProgress);
//             previousPercentage.current = percentage;
//         }
//     }, [percentage, duration]);

//     return (
//         <div className={styles.progressBar}>
//             <div className={styles.labelContainer}>
//                 {label && <p className={`font-bold text-xl`}>{label}</p>}
//                 {showCompletedPercentage && (
//                     <div className={styles.completedLabel}>
//                         {Math.floor(animatedPercentage)}%
//                     </div>
//                 )}
//             </div>
//             <div className={styles.barContainer}>
//                 <div
//                     className={styles.bar}
//                     style={{ width: `${animatedPercentage}%` }}
//                 />
//             </div>
//         </div>
//     );
// };

// export default ProgressBar;



import React, { useEffect, useRef, useState } from 'react'
import styles from './ProgressBar.module.css'

interface ProgressBarProps {
    percentage: number;
    label?: string;
    showCompletedPercentage?: boolean;
    duration?: number; 
    isSubItem? : boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ 
    percentage, 
    label, 
    showCompletedPercentage = true,
    duration = 1000 ,
    isSubItem = false
}) => {
    const [animatedPercentage, setAnimatedPercentage] = useState(0);
    const animationRef = useRef<number | null>(null);
    const startTimeRef = useRef<number | null>(null);

    useEffect(() => {
        // Start the animation
        startAnimation();

        // Cleanup function to cancel animation if component unmounts
        return () => {
            if (animationRef.current !== null) {
                cancelAnimationFrame(animationRef.current);
            }
        };
    }, [percentage, duration]);

    const startAnimation = () => {
        // Cancel any ongoing animation
        if (animationRef.current !== null) {
            cancelAnimationFrame(animationRef.current);
        }

        // Reset start time and animated percentage
        startTimeRef.current = null;
        setAnimatedPercentage(0);

        // Start a new animation
        animationRef.current = requestAnimationFrame(animateProgress);
    };

    const animateProgress = (timestamp: number) => {
        if (startTimeRef.current === null) {
            startTimeRef.current = timestamp;
        }

        const elapsed = timestamp - startTimeRef.current;
        const progress = Math.min(elapsed / duration, 1);

        // Easing function: ease-out cubic
        const easedProgress = 1 - Math.pow(1 - progress, 3);

        const currentValue = percentage * easedProgress;
        setAnimatedPercentage(currentValue);

        if (progress < 1) {
            animationRef.current = requestAnimationFrame(animateProgress);
        }
    };

    return (
        <div className={styles.progressBar}>
            <div className={styles.labelContainer}>
                {label && <p className={`${isSubItem ? "font-thin text-sm" : " font-bold text-xl"}`}>{label}</p>}
                {showCompletedPercentage && (
                    <div className={styles.completedLabel}>
                        {Math.round(animatedPercentage)}%
                    </div>
                )}
            </div>
            <div className={styles.barContainer}>
                <div
                    className={styles.bar}
                    style={{ width: `${animatedPercentage}%` }}
                />
            </div>
        </div>
    );
};

export default ProgressBar;