import { Box, InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import { CheckedIcon, THETEAM, Unchecked } from "../../components/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { useRef, useState } from "react";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { UserModel } from "../../Service/models/UserModel/UserModel";
import { RegisterUserThunk } from "../../redux/slices/UserSlice";
import { useGoogleLogin } from "@react-oauth/google";
import { CustomTextField } from "../../CustomComps/Customs";
import KeyIcon from '@mui/icons-material/Key';
import { EgButton } from "../Shared/Shared";
import "./index.css"
import { SubmitHandler, useForm } from "react-hook-form";




export const textFiedlFlag = (Switch: 0 | 1 | -1, initialIcon: JSX.Element) => {
    return {
        endAdornment: Switch === -1 ? (
            <InputAdornment position="start">
                <Box marginX={1} width={25} height={25}>
                    {initialIcon}
                </Box>
            </InputAdornment>
        ) : Switch === 1 ? <InputAdornment position="start">
            <Box width={25} height={25} marginX={1}>
                <CheckedIcon />
            </Box>
        </InputAdornment> : <InputAdornment position="start">
            <Box width={25} height={25} marginX={1}>
                <Unchecked />
            </Box>
        </InputAdornment>,
    }
};

function Changepassword() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const MainInputStyle = { marginBottom: 20, width: '100%', backgroundColor: 'white' };
    const [pswMatch, setPswMatch] = useState<boolean>(false);
    const [iconFLag, setIconFLag] = useState<1 | 0 | -1>(-1);
    const pswConfirmString = useRef<string>('');

    const confirmPSW = useRef<string>();

    const dispatch = useDispatch<AppDispatch>();



    const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<UserModel>({
        reValidateMode: 'onBlur',
        mode: 'onBlur'
    });

    // let passData = watch('PASSWORD');


    const {
        ref: PasswordInputRef, ...PasswordInputProps } = register("PASSWORD", {
            required: "رمز المرور لايمكن ان يكون فارغ",
            pattern: {
                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
                message: "رمز الدخول لايقل عن 8 احرف على ان يحتوي حرف كبير و حرف صغير و رقم ورمز خاص مثل @ "
            }
            // pattern: {
            //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //   message: "نمط البريد غير صحيح"
            // }
        });
    // const [passwordConfirm, setPasswordConfirm] = useState('');
    // const [password, setPassword] = useState('');
    const passwordRef = useRef('');
    const passwordConfirmRef = useRef('');

    const onSubmit: SubmitHandler<UserModel> = async (data: UserModel) => {
        if (pswMatch && iconFLag !== 0) {

            dispatch(RegisterUserThunk(data)).then(res => {

                if (res.payload === "Done") {
                    navigate('/');
                }

            }).catch(err => {

            });
        }
    };






    const login = useGoogleLogin({
        onSuccess: tokenResponse => { }
    });

    return (
        <>  <div className='registerBG h-screen w-full flex justify-center items-center'>
            <div className='bg-[#EDF3F4] rounded-3xl w-4/6 md:w-2/6 h-[70%] flex flex-col items-center'>
                <div className='h-1/3 p-2 mb-10 mt-5'>
                    <THETEAM />
                </div>
                <form className='w-4/5 h-2/3 flex flex-col  justify-start items-center' onSubmit={handleSubmit(onSubmit)}>

                    <div className='w-full flex flex-row gap-4'>
                        <CustomTextField placeholder='الرقم السري' inputRef={PasswordInputRef} {...PasswordInputProps} type={'password'}
                            onChange={e => {
                                passwordRef.current = e.target.value;
                                // setPassword(e.target.value);
                                if (e.target.value === passwordConfirmRef.current && e.target.value.length > 1) {
                                    setPswMatch(true);
                                    setIconFLag(1);
                                } else {
                                    setIconFLag(0);
                                    setPswMatch(false);
                                }
                            }}
                            InputProps={textFiedlFlag(iconFLag, <KeyIcon />)}
                            style={MainInputStyle}
                            error={!!errors.PASSWORD}
                            helperText={errors?.PASSWORD?.message}
                            colors={colors}
                            theme={theme} />
                    </div>
                    <div className='w-full flex flex-row gap-4'>
                        <CustomTextField placeholder='تأكيد الرقم السري' onChange={e => {
                            passwordConfirmRef.current = e.target.value;
                            if (e.target.value === passwordRef.current && e.target.value.length > 1) {
                                setPswMatch(true);
                                setIconFLag(1);
                            } else {
                                setIconFLag(0);
                                setPswMatch(false);
                            }
                        }}
                            type={'password'} InputProps={textFiedlFlag(iconFLag, <KeyIcon />)} style={MainInputStyle} helperText={iconFLag === 1 ? "" : iconFLag === 0 ? "الباسورد غير متطابق" : ""}
                            colors={colors} theme={theme} />
                    </div>
                    <div className=' w-full flex flex-row gap-4 justify-around 	'>

                        <div >
                            <EgButton Action={'submit'} HandleClick={() => {
                            }}>
                                <Stack direction={'row'} justifyContent='center'>
                                    <Typography sx={{ marginLeft: '10px' }} variant="h5">تأكيد</Typography>
                                </Stack>
                            </EgButton>
                        </div>
                        <div >
                            <EgButton HandleClick={() => {
                            }}>
                                <Stack direction={'row'} justifyContent='center'>
                                    <Typography sx={{ marginLeft: '10px' }} variant="h5">إلغاء</Typography>
                                </Stack>
                            </EgButton>
                        </div>

                    </div>
                </form>
            </div>
        </div></>
    );
}

export default Changepassword;
