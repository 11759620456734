import React, { useEffect } from 'react'
import { DEAL_MODEL } from '../../../Service/models/DealApplication'
import { EgButton } from '../../Shared/Shared'

import './style.css'
import { useLocation } from 'react-router-dom'
import { getNameOfUnitType, getYearMonthDayFromDate } from '../../../Globals'
const DealPrint = () => {
  const location = useLocation();
  const { dealOBJ: deal } = location.state as { dealOBJ: DEAL_MODEL } || {};

  useEffect(() => {
    setTimeout(() => {
      window.print()
    }, 1000)
    
  }, [deal])

  return (
    <div className="bg-white w-full h-full  rounded-2xl p-10">
      <div className='gridContainer overflow-y-auto relative' id='print-section'>
        <div className='header mb-3'>
          <h3>إستماره مبيعات</h3>
        </div>
        <div className='box mb-3'>
          <div className='flex flex-wrap h-52'>
            <div className='flex flex-col w-[80%] gap-3 justify-between'>
              <div className=' flex justify-between'>
                <div className='w-1/2 '>
                  <div>
                    <h5>إسم مقدم الطلب</h5>
                  </div>
                  <div className='w-full text-right h-[5em]'>
                    <h2>{deal.APPLICANT_NAME ?? ""}</h2>
                  </div>
                </div>
                <div className='w-1/3 flex flex-col'>
                  <label htmlFor="ProjectID">المشروع</label>
                  <h2> {deal.COMPANY_PROJECT_NAME ?? ""}</h2>
                </div>
              </div>
              <div className='flex justify-between items-center w-1/2 md:w-2/4'>
                <h5>التاريخ</h5>
                <h4>{getYearMonthDayFromDate(deal.APPLICATION_DATE, true)}</h4>
              </div>
            </div>
            <div className='w-[20%] h-[100%]'>
              <img className=' w-full h-full object-contain' src='https://the-team.co/api/images/img?id=6' alt='logo' />
            </div>
          </div>
        </div>
        <div className='header mb-2'>
          <h3>بيانات شركه التطوير</h3>
        </div>
        <div className='box'>
          {/* اسم الشركه */}
          <div className='flex flex-col w-full items-start gap-2'>
            <h5 >إسم الشركه</h5>
            <div className='w-full text-right h-[5em]'>
              <h2>{deal.COMPANY_NAME ?? ""}</h2>
            </div>
          </div>
          {/* اسم المشروع */}
          <div className='flex flex-col w-full items-start gap-2'>
            <h5 >إسم المشروع</h5>
            <div className='w-full text-right h-[5em]'>
              <h2>{deal.COMPANY_PROJECT_NAME ?? ""}</h2>
            </div>
          </div>
          {/* اسم السيلز */}
          <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >إسم السيلز</h5>
              <div className='w-full text-right h-[5em]'>
                <h2> {deal.COMPANY_SALES_NAME ?? ""}</h2>
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >رقم الهاتف</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.COMPANY_SALES_PHONE ?? ""}</h2>
              </div>
            </div>
          </div>
          {/* مسئول الحسابات */}
          <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >مسئول الحسابات</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.COMPANY_ACCOUNTANT_NAME ?? ""}</h2>
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >رقم الهاتف</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.COMPANY_ACCOUNTANT_PHONE ?? ""}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="Break"></div>
        <div className='header my-3'>
          <h3>بيانات العميل و الوحده</h3>
        </div>
        <div className='box'>
          {/* اسم العميل و رقم الهاتف */}
          <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >إسم العميل</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.CLIENT_NAME ?? ""}</h2>
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >رقم الهاتف</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.CLIENT_PHONE ?? ""}</h2>
              </div>
            </div>
          </div>
          {/* نوع الوحده و كود الوحده */}
          <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >نوع الوحده</h5>
              <div className='w-full text-right h-[5em] flex justify-start'>
                <h2>{getNameOfUnitType(deal.CLIENT_UNIT_TYPE)}</h2>
                {/* <EGInput HTMLFor={'UnitType'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                      UpdateModalRefBy('CLIENT_UNIT_TYPE', data);
                    }}
                      validated={(data) => {
                        ValidateModalRefBy('CLIENT_UNIT_TYPE', data);
                      }}
                    /> */}
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5>كود الوحده</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.CLIENT_UNIT_CODE ?? ""}</h2>
              </div>
            </div>
          </div>
          {/* سعر الوحده  */}
          <div className='flex flex-col w-full items-start gap-2'>
            <h5 >سعر الوحده</h5>
            <div className='w-full text-right h-[5em]'>
              <h2>{deal.CLIENT_UNIT_PRICE ?? ""}</h2>
            </div>
          </div>
          {/* عموله الشركه و الحافز */}
          <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >عموله الشركه</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.COMPANY_COMMISSION ?? ""}</h2>
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5>الحافز</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.COMPANY_INCENTIVE ?? ""}</h2>
              </div>
            </div>
            
          </div>
          {/* تاريخ التعاقد */}
          <div className='flex justify-between items-right w-full md:w-2/4'>
            <h5>تاريخ التعاقد</h5>
            <h4>{getYearMonthDayFromDate(deal.CLIENT_CONTRACT_DATE,true)}</h4>
          </div>
        </div>
        <div className='header my-3'>
          <h3>بيانات حساب الـ SALES</h3>
        </div>
        <div className='box'>
          {/* IPAN & الإسم  */}
          <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5>إسم البنك</h5>
              <div className='w-full text-right h-[5em]'>
                <h2> {deal.SALES_BANK_NAME ?? ""}</h2>
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >IBAN</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.SALES_IBAN ?? ""}</h2>
              </div>
            </div>
          </div>
          {/* رقم الحساب */}
          <div className='flex flex-col w-full items-start gap-2'>
            <h5 >رقم الحساب</h5>
            <div className='w-full text-right h-[5em]'>
              <h2>{deal.SALES_ACCOUNT_NUMBER ?? ""}</h2>
            </div>
          </div>
        </div>
        <div className="Break"></div>
        <div className='header my-3'>
          <h3>بيانات حساب الـ Leader</h3>
        </div>
        <div className='box'>
          {/* IPAN & الإسم  */}
          <div className='flex'>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5>الإسم</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.LEADER_NAME ?? ""}</h2>
              </div>
            </div>
            <div className='flex flex-col w-full items-start gap-2'>
              <h5 >IBAN</h5>
              <div className='w-full text-right h-[5em]'>
                <h2>{deal.LEADER_IBAN ?? ""}</h2>
              </div>
            </div>
          </div>
          {/* رقم الحساب */}
          <div className='flex flex-col w-full items-start gap-2'>
            <h5 >رقم الحساب</h5>
            <div className='w-full text-right h-[5em]'>
              <h2>{deal.LEADER_NAME ?? ""}</h2>
            </div>
          </div>
        </div>
        <div className=''>
          {/* توقيع المستلم & توقيع مقدم الإستماره */}
          {/* <div className='flex mb-20'>
                <div className='flex flex-col w-full items-start gap-2'>
                  <h3>مقدم الإستماره</h3>
                  <div className='w-full text-center h-[5em]'>
                    <EGInput HTMLFor={'APPLICANTpROVIDER'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                      UpdateModalRefBy('APPLICANTS_SIGNATURE', data);
                    }}
                      validated={(data) => {
                        ValidateModalRefBy('APPLICANTS_SIGNATURE', data);
                      }}
                    />
                  </div>
                  <h3>التوقيع</h3>
                </div>
                <div className='flex flex-col w-full items-start gap-2'>
                  <h3 >المستلم</h3>
                  <div className='w-full text-center h-[5em]'>
                    <EGInput HTMLFor={'RECIPIENTS_SIGNATURE'} errorText={'هذا الحقل اجباري'} valueChange={(data) => {
                      UpdateModalRefBy('RECIPIENTS_SIGNATURE', data);
                    }}
                      validated={(data) => {
                        ValidateModalRefBy('RECIPIENTS_SIGNATURE', data);
                      }}
                    />
                  </div>
                  <h3>التوقيع</h3>
                </div>
              </div> */}
          {/* حفظ و طباعه & اضافه خسم & إلغاء */}
        </div>
        <div className='fullRow mt-3'>
        </div>
      </div>
      <div>

      </div>
    </div>
  )
}

export default DealPrint