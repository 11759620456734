import React, { useEffect, useState } from 'react'
import { getYearMonthDayFromDate } from '../../../../Globals'
import { FINISHING_PAYMENT_PLAN, FINISHING_REQUEST, FINISHING_TRANSACTIONS } from '../../../../Service/models/Finishing'


type ClientFinanceProps = {
    FinishRequest: FINISHING_REQUEST
}

const ClientFinance = (Props: ClientFinanceProps) => {

    const { FinishRequest } = Props

    const [finish, setFinish] = useState<FINISHING_REQUEST>({} as FINISHING_REQUEST)


    useEffect(() => {
        setFinish(FinishRequest)
    }, [])


    return (
        <div>
            <div className='mb-5'>
                <p>الرصيد</p>
                <h3>{finish.BALANCE ?? 0} جنيه</h3>

            </div>
            {finish?.FINISHING_TRANSACTIONS && finish.FINISHING_TRANSACTIONS?.length > 0 ?
                <div>
                    <p>المعاملات</p>
                    <FinishInstalmentsTable Installments={finish.FINISHING_TRANSACTIONS ?? []} />
                </div>
                : <p></p>
            }

        </div>
    )
}

export default ClientFinance

type FinishingAppInstalments = {
    Installments: FINISHING_TRANSACTIONS[]
}
const FinishInstalmentsTable = (Props: FinishingAppInstalments) => {
    const { Installments } = Props


    // useEffect(() => {
    // }, [])
    console.log(Installments)


    return <div className="mt-5">
        <table className="min-w-full overflow-hidden rounded-lg shadow-md border-none">
            <thead className="bg-slate-100 rounded overflow-hidden border-0">
                <tr className="border-0 shadow">
                    <th className=" font-semibold border-none">#</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">القيمه</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">تاريخ العمليه</th>
                    <th className=" font-semibold border-y-0  border-x-2 border-gray-200">نوع العمليه</th>
                </tr>
            </thead>
            {Installments.length > 0 && (
                <tbody>
                    {Installments.map((install, idx) => {
                        return <>
                            <tr className="border-t border-gray-200">
                                <td className=" border-x-2 border-gray-200">{idx + 1}</td>
                                <td className=" border-x-2 border-gray-200"><span className="bg-blue-100 text-blue-800 px-2 py-1 rounded"> {install.AMOUNT} جنيه </span></td>
                                <td className=" border-x-2 border-gray-200">{getYearMonthDayFromDate(install.STAMP_DATE!, true)}</td>
                                <td className=" border-x-2 border-gray-200">
                                    {install.TRANSACTION_CATG === 2 ? <span className="bg-green-100 text-green-800 px-2 py-1 rounded"> إيداع</span> : <span className="bg-red-100 text-red-800 px-2 py-1 rounded"> سحب</span>
                                    }
                                </td>
                            </tr>
                        </>
                    })}

                </tbody>
            )}
        </table>
    </div >
}