import React, { useEffect, useState } from 'react'
import { CustomTextFieldFullHeight } from '../../../CustomComps/Customs';
import { EgButton } from '../../Shared/Shared';
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import EGDate from '../../../components/shared/EGDate';
import { EVENTS, EVENTS_DETAILS, EXPENSES_TYPE } from '../../../Service/models/EventsData';
import MulitDD from '../../../components/MultiDD';
import { DropDownData } from '../Actions/ActionsTypesScreen/AppointMents';
import { DigitValidate, GetErrorAxios } from '../../../Globals';
import { SaveEvent } from '../../../Service/Apis';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { AddAction } from '../../../redux/slices/ErrorsSlice';

type CompType = {
    ExpenseTypes: EXPENSES_TYPE[],
    clearData: boolean
}

const NewEvent = ({ ExpenseTypes, clearData }: CompType) => {


    //constants 
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };
    const dispatch = useDispatch<AppDispatch>()


    //states 
    const [expensesReq, setExpensesReq] = useState<EVENTS_DETAILS[]>([])
    const [maxIDnumber, setMaxIdNumber] = useState<number>(0)
    const [RequestForSaveEvent, setRequestForSaveEvent] = useState<EVENTS>({} as EVENTS)

    //functions 
    const changeEventInArray = (Event: EVENTS_DETAILS) => {
        //remove old element but save its id 
        let Oldid = Event.EVENT_ID
        // remove from array
        let OtherElements = expensesReq.filter(event => event.EVENT_ID !== Event.EVENT_ID)
        // create new element but with old id to keep order as it is 
        let newEventData: EVENTS_DETAILS = { ...Event, EVENT_ID: Oldid }
        OtherElements.push(newEventData)

        //order by Id
        let orderededElements = OtherElements.sort((a, b) => a.EVENT_ID - b.EVENT_ID)
        setExpensesReq(orderededElements)
    }
    const clearRequestData = () => {
        setRequestForSaveEvent({} as EVENTS)
        setExpensesReq([])
    }


    // Requests 

    // save Request 
    const SaveEventAPI = () => {

        let allowSendData = true;
        if (RequestForSaveEvent.DESCRIPTION === '') {
            dispatch(AddAction({ message: `برجاء ادخال وصف للفاعليه`, status: 'error' }))
            allowSendData = false
            return;
        }

        if (RequestForSaveEvent.DESCRIPTION_EN === '') {
            dispatch(AddAction({ message: `برجاء ادخال وصف بالإنجليزيه للفاعليه`, status: 'error' }))
            allowSendData = false
            return;
        }

        if (!RequestForSaveEvent.START_DATE) {
            dispatch(AddAction({ message: `برجاء ادخال تاريخ بدايه للفاعليه`, status: 'error' }))
            allowSendData = false
            return;
        }

        if (!RequestForSaveEvent.END_DATE) {
            dispatch(AddAction({ message: `برجاء ادخال تاريخ النهايه للفاعليه`, status: 'error' }))
            allowSendData = false
            return;
        }

        if (RequestForSaveEvent.EVENTS_DETAILS.length < 1) {
            dispatch(AddAction({ message: `برجاء ادخال مصاريف للفاعليه`, status: 'error' }))
            allowSendData = false
            return;
        }

        if (expensesReq.length > 0) {
            for (let item of expensesReq) {
                if (item.EXP_TYPE === undefined || item.EXP_TYPE === -1) {
                    dispatch(AddAction({ message: `برجاء عدم ترك اي خانه لنوع المصروف فارغه`, status: 'error' }))
                    allowSendData = false
                    break;
                }
                if (item.AMOUNT < 1 || isNaN(item.AMOUNT)) {
                    dispatch(AddAction({ message: `برجاء ادخال قيمه للمصروف و عد تركه فارغ`, status: 'error' }))
                    allowSendData = false
                    break;
                }
            }
        }

        if (allowSendData) {
            let updatedRequest: EVENTS = { ...RequestForSaveEvent, ID: 0, EVENTS_DETAILS: RequestForSaveEvent.EVENTS_DETAILS.map(item => ({ ...item, EVENT_ID: -1, ID: -1 })) }
            SaveEvent(updatedRequest).then(res => {
                dispatch(AddAction({ message: "تم إضافه الفاعليه بنجاح", status: 'success' }))
            }).catch(err => {
                GetErrorAxios(err, dispatch)
            })
        }

    }



    // useEffects

    useEffect(() => {
        setRequestForSaveEvent(prev => ({ ...prev, EVENTS_DETAILS: expensesReq }))
    }, [expensesReq])

    useEffect(() => {
        if (clearData === true) {
            clearRequestData()
        }
    }, [clearData])

    return (
        <>
            <div className='w-full h-full pb-10 p-5 flex flex-col justify-start overflow-y-auto'>
                {/* اسم الشركه عربي و انجليزي  */}
                <div className='flex flex-wrap gap-5'>
                    <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <label htmlFor="EventDesc">وصف الفاعليه</label>

                        <div>
                            <CustomTextFieldFullHeight height={25} id='EventDesc' value={RequestForSaveEvent.DESCRIPTION ?? ""} type={''} onChange={(e: any) => {
                                let val = e.target.value as string;
                                setRequestForSaveEvent(prev => ({ ...prev, DESCRIPTION: val }))
                            }} style={MainInputStyle}
                                colors={colors} theme={theme} />
                        </div>
                    </div>
                    <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                        <label htmlFor="DescEng">وصف الفاعليه بالغه الإنجليزيه</label>
                        <div>
                            <CustomTextFieldFullHeight height={25} id='DescEng' value={RequestForSaveEvent.DESCRIPTION_EN ?? ""} type={''} onChange={(e: any) => {
                                let val = e.target.value as string;
                                setRequestForSaveEvent(prev => ({ ...prev, DESCRIPTION_EN: val }))
                            }} style={MainInputStyle}
                                colors={colors} theme={theme} />
                        </div>
                    </div>
                </div>
                <div className='flex gap-5'>
                    {/* تاريخ البدايه  */}
                    <div className='justify-between flex flex-col gap-2 flex-wrap mt-5' >
                        <h6 className='font-bold'>تاريخ البدايه</h6>
                        <EGDate DateValue={(dateVal) => {
                            setRequestForSaveEvent(prev => ({ ...prev, START_DATE: new Date(dateVal) }))
                        }}
                        />
                    </div>
                    {/* تاريخ النهايه  */}
                    <div className='justify-between flex flex-col gap-2 flex-wrap mt-5' >
                        <h6 className='font-bold'>تاريخ النهايه</h6>
                        <EGDate DateValue={(dateVal) => {
                            setRequestForSaveEvent(prev => ({ ...prev, END_DATE: new Date(dateVal) }))
                        }}
                        />
                    </div>
                </div>

                {/* expenses */}
                <div className='flex flex-col gap-2 mt-2'>
                    {expensesReq && expensesReq.length > 0 && expensesReq.map((item, idx) => {
                        return <div key={item.EVENT_ID}>
                            <ExpensesComp RemoveFromMainExpenses={(id) => {
                                let removedItems = expensesReq.filter(item => item.EVENT_ID !== id).sort((a, b) => a.EVENT_ID - b.EVENT_ID)
                                setExpensesReq(removedItems)
                            }} data={item} Expenses={ExpenseTypes} onItemDataChange={(EventDetail) => {
                                changeEventInArray(EventDetail)
                            }} />
                        </div>
                    })}
                </div>

                <div className='flex gap-1 items-center' onClick={() => {
                    setMaxIdNumber(prev => prev + 1)

                    if (maxIDnumber !== undefined) {
                        setExpensesReq(prev => [...prev, { AMOUNT: 0, EVENT_ID: maxIDnumber, EXP_TYPE: -1, EXP_TYPE_DESC: '', NOTES: '', ID: -1 }])
                    }
                }}>
                    <div className='i-material-symbols-add-box-outline-rounded text-2xl text-gray-500 hover:cursor-pointer my-3'>
                    </div>
                    <h4 className='text-xl font-bold'>مصروف جديد</h4>
                </div>


                <div className="h-[3em] w-[10em] self-start mt-5">
                    <EgButton HandleClick={() => {
                        SaveEventAPI()
                    }}>
                        <h5>إضافه الفاعليه</h5>
                    </EgButton>
                </div>
            </div>
        </>
    )
}

export default NewEvent

type TExpensesComp = {
    Expenses: EXPENSES_TYPE[],
    data: EVENTS_DETAILS,
    RemoveFromMainExpenses: (id: number) => void,
    onItemDataChange: (value: EVENTS_DETAILS) => void
}

const ExpensesComp = ({ Expenses, data, RemoveFromMainExpenses, onItemDataChange }: TExpensesComp) => {
    //constants 
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const MainInputStyle = { marginBottom: 2, width: '100%', backgroundColor: 'white' };

    //states 
    const [ExpensesTypeDD, setExpensesTypeDD] = useState<DropDownData[]>([])
    const [expenseValues, setExpenseValues] = useState<EVENTS_DETAILS>(data)
    const [AmountString, setAmountString] = useState<string>("");


    useEffect(() => {
        setExpensesTypeDD(Expenses.map((exp) => ({ desc: exp.DESCRIPTION, id: exp.ID })))
    }, [Expenses])


    useEffect(() => {
        onItemDataChange(expenseValues)
    }, [expenseValues])

    useEffect(() => {
        setExpenseValues(prev => ({ ...prev, AMOUNT: parseFloat(AmountString) }))
    }, [AmountString])


    return <>
        <div className='w-full  bg-white rounded-xl shadow-md p-2 flex flex-wrap gap-3 items-baseline relative transition-all duration-1000'>
            <div className='absolute top-2 left-2 i-material-symbols-close-rounded text-3xl text-red-500 hover:cursor-pointer' onClick={() => {
                
                RemoveFromMainExpenses(data.EVENT_ID)
            }}>
            </div>
            {/* Expense Type */}
            <div className='w-1/4'>
                <h6 className='mb-1'>نوع المصروف</h6>
                <div className='w-full'>
                    {/* {ExpensesTypeDD.length > 0 && ()} */}
                    <MulitDD OuterDropDownData={ExpensesTypeDD} SelectedIds={(ids) => {
                        setExpenseValues(prev => ({ ...prev, EXP_TYPE: ids[0] }))
                    }} SingleSelection />
                </div>
            </div>
            {/* Expense Desc */}
            <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                <label htmlFor="ExpDesc">وصف للمصروف</label>

                <div>
                    <CustomTextFieldFullHeight height={25} id='ExpDesc' type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        setExpenseValues(prev => ({ ...prev, EXP_TYPE_DESC: val }))
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
            </div>
            {/* Amount */}
            <div className='w-full md:w-[30%] flex flex-col gap-1 my-2'>
                <label htmlFor="ExpAmount">قيمه المصروف</label>

                <div>
                    <CustomTextFieldFullHeight height={25} id='ExpAmount' value={AmountString} type={''} onChange={(e: any) => {
                        let val = e.target.value as string;
                        if (val.match(DigitValidate)) {
                            setAmountString(val)
                        }
                    }} style={MainInputStyle}
                        colors={colors} theme={theme} />
                </div>
            </div>
            {/* Notes */}
            {/* ملاحظات */}
            <textarea id="Notes" name="otherNotes" className="col-span-2 resize-none w-full h-[5em] shadow-[0px] rounded-md border-black border focus:outline-none focus:shadow-none " placeholder='ملاحظات' onChange={(e: any) => {
                let val = e.target.value
                setExpenseValues(prev => ({ ...prev, NOTES: val }))
            }} >
            </textarea>
        </div>
    </>
}