import React, { useCallback, useEffect, useReducer, useState } from 'react'


import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import {
    ActionStatisticsRetunType,
    BGCOLORs,
    Border_Color,
    getStatisticsByAction, getStatisticsByAction_Optimized,
    getStatisticsByBranch,
    STA_DRAW_TYPE, Station_DATA_Optimized, USER_ACTIONS_DATA, USER_DATA_Optimized
} from "./logic";
import { initialStatisticsState, STATISTIC_FROM_API, StatisticsReducer } from "./reducers/statisticsReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { setLoadingFalse, setLoadingTrue } from "../../redux/slices/LoadingSlice";
import { GET_ALL_STATIONS, GetAllUsers, GetAllUsers_online, getTeamStatistics } from "../../Service/Apis";
import { ActionTypes, GetActionTypeString, GetErrorAxios, IsObjectEmpty } from "../../Globals";
import { STATISTICS } from "../../Service/models/statistics/indext";
import { Attendance, DropDownData, DropTextField } from "../DashBoard/Actions/ActionsTypesScreen/AppointMents";
import DOComp from './reducers/components/PieComp';
import { USER_Detail, UserModel } from '../../Service/models/UserModel/UserModel';
import EGDate from "../../components/shared/EGDate";
import { EgButton } from "../Shared/Shared";
import MulitDD from '../../components/MultiDD';

ChartJS.register(ArcElement, Tooltip, Legend);


type Chart_and_users_dataType = {
    chartData: STA_DRAW_TYPE
    user_data: USER_DATA_Optimized
}

type Chart_and_station_dataType = {
    chartData: STA_DRAW_TYPE
    station_data: Station_DATA_Optimized
}

const TeamStatistics = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [statisticsReducer, dispatchStatistics] = useReducer(StatisticsReducer, initialStatisticsState);
    const [statistics_holder, setStatisticsHolder] = useState<STATISTICS>({} as STATISTICS)
    const [Actions_DD, setActions_DD] = useState<DropDownData[]>([]);
    const [branchesDropDown, setBranchesDropDown] = useState<DropDownData[]>([]);
    const [usersDropDown, setUsersDropDown] = useState<DropDownData[]>([]);
    const [parentDropDown, setparentDropDown] = useState<DropDownData[]>([]);
    const [users, setUsers] = useState<USER_Detail[]>([]);
    const [stationID, setStationID] = useState<number>(-1)
    const [selectedUser, setSelectedUser] = useState<number>(-1)
    const [parentUser, setparentUser] = useState<number>(-2)

    const now = new Date(); // Make sure to initialize 'now'
    const currentMonth = now.getMonth(); // getMonth() returns a value between 0 (January) and 11 (December)
    let per;
    if (currentMonth >= 0 && currentMonth <= 2) {
        per = 1; // January, February, March
    } else if (currentMonth >= 3 && currentMonth <= 5) {
        per = 2; // April, May, June
    } else {
        per = 3; // July to December
    }
    const [duration_Time, set_duration_time] = useState<number>(per)
    const [start_date, set_start_date] = useState<Date>(new Date())
    const [end_date, set_end_date] = useState<Date>(new Date())
    const [all_users_stat_data, set_all_users_stat_data] = useState<Chart_and_users_dataType[]>([])
    const [all_stations_stat_data, set_all_stations_stat_data] = useState<Chart_and_station_dataType[]>([])
    const [selectedOptionDD, setSelectedOptionDD] = useState<number[]>([-2])

    useEffect(() => {
        if (parentUser > 0) {
        dispatch(setLoadingTrue());
        getTeamStatistics({
            WITH_ACTIONS: false,
            USER_ID:parentUser,
            PERIOD_ENUM: duration_Time,
        }).then(res => {
            dispatchStatistics(STATISTIC_FROM_API(res))
            setStatisticsHolder(res);
            dispatch(setLoadingFalse())
        }).catch(err => {
            dispatch(setLoadingFalse())
            GetErrorAxios(err, dispatch)
        })
    }
    }, [])

    useEffect(() => {
        GET_ALL_STATIONS().then(res => {
            setBranchesDropDown(res.map(station => ({ id: station.STATION_ID, desc: station.STATION_NAME })))
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })

        GetAllUsers_online().then(res => {
            setparentDropDown([...res.map(user => ({ id: user.ID, desc: user.FULL_NAME }))])
            setUsersDropDown([
                { id: -1, desc: 'الكل' },
                { id: -2, desc: "قادة الفريق" },
                //  ...res.map(user => ({ id: user.ID, desc: user.FULL_NAME }))
                ])
            setUsers(res);
            setSelectedOptionDD([-2])
        }).catch(err => {
            GetErrorAxios(err, dispatch)
        })
    }, [])

    useEffect(() => {
        // أحصائيات المستخدم
        let data: USER_DATA_Optimized[] = getStatisticsByAction_Optimized(statistics_holder, selectedUser === -2 ? 'TEAM' : 'USER', stationID, selectedUser);
        let all_users_data_and_chart_data_arr: Chart_and_users_dataType[] = [];

        data.map(users => {
            let DrawData: STA_DRAW_TYPE = {
                labels: ["بيانات عميل", "مكالمه", "موعد", "زياره موقع", "تقفيل الديل (الإستماره)",],
                datasets: [{
                    label: 'عدد المرات',
                    data: [users.CUSTOMER_DETAILS, users.CALL_ACTION, users.Appointment, users.SITE_VISIT_REQ, users.DEALS],
                    backgroundColor: [BGCOLORs()[0], BGCOLORs()[1], BGCOLORs()[2], BGCOLORs()[3], BGCOLORs()[4]],
                    borderColor: [Border_Color()[0], Border_Color()[1], Border_Color()[2], Border_Color()[3], Border_Color()[4]],
                    borderWidth: 1
                }]
            }
            all_users_data_and_chart_data_arr.push({ user_data: users, chartData: DrawData })
        })

        set_all_users_stat_data(all_users_data_and_chart_data_arr)

        //احصائيات الفرع كامل
        let branchs_data: Station_DATA_Optimized[] = getStatisticsByBranch(statistics_holder, stationID)
        let all_stations_data_and_chart_data_arr: Chart_and_station_dataType[] = [];


        branchs_data.map(branch => {
            let DrawData: STA_DRAW_TYPE = {
                labels: ["بيانات عميل", "مكالمه", "موعد", "زياره موقع", "تقفيل الديل (الإستماره)",],
                datasets: [{
                    label: 'عدد المرات',
                    data: [branch.CUSTOMER_DETAILS, branch.CALL_ACTION, branch.Appointment, branch.SITE_VISIT_REQ, branch.DEALS],
                    backgroundColor: [BGCOLORs()[0], BGCOLORs()[1], BGCOLORs()[2], BGCOLORs()[3], BGCOLORs()[4]],
                    borderColor: [Border_Color()[0], Border_Color()[1], Border_Color()[2], Border_Color()[3], Border_Color()[4]],
                    borderWidth: 1
                }]
            }
            all_stations_data_and_chart_data_arr.push({ station_data: branch, chartData: DrawData })
        })
        set_all_stations_stat_data(all_stations_data_and_chart_data_arr)

    }, [Actions_DD, statistics_holder, stationID, selectedUser])




    return (
        <>
            {/*  header & filter Data*/}
            <div>
                <div
                    className={'w-5/6  bg-slate-100 rounded-xl shadow-md mx-auto mb-2 p-3 flex justify-center items-center '}>
                    <div className={'w-full'}>
                        <div className='w-full flex flex-wrap gap-5'>
                            <div className="flex items-center gap-1">
                                <label className='text-base w-32'>فرع الشركه</label>
                                {/* <DropTextField initalValue={stationID} data={[
                                    { id: -1, desc: 'الكل' }, ...branchesDropDown
                                ]} onchange={e => {
                                    let val = e.target.value as number
                                    setStationID(val)
                                }} /> */}
                                <MulitDD
                                    OuterDropDownData={[
                                        { id: -1, desc: 'الكل' }, ...branchesDropDown
                                    ]}
                                    SelectedIds={(ids) => {
                                        setStationID(ids[0])
                                    }}
                                    SingleSelection
                                    InitialSelectedIds={[stationID]}
                                />
                            </div>
                            <div className="flex  items-center gap-1">
                                <label className='text-base'>المدير</label>
                                {/* <DropTextField initalValue={selectedOptionDD[0]} data={[
                                    { id: -2, desc: "فريقي" }, { id: -1, desc: 'الكل' }, ...usersDropDown
                                ]} onchange={e => {
                                    let val = e.target.value as number
                                    setSelectedUser(val)
                                }} /> */}
                                <MulitDD
                                    OuterDropDownData={parentDropDown}
                                    SelectedIds={(ids) => {
                                        setparentUser(ids[0])
                                    }}
                                    SingleSelection
                                    InitialSelectedIds={selectedOptionDD}
                                />
                            </div>
                            <div className="flex  items-center gap-1">
                                <label className='text-base'>المستخدم</label>
                                {/* <DropTextField initalValue={selectedOptionDD[0]} data={[
                                    { id: -2, desc: "فريقي" }, { id: -1, desc: 'الكل' }, ...usersDropDown
                                ]} onchange={e => {
                                    let val = e.target.value as number
                                    setSelectedUser(val)
                                }} /> */}
                                <MulitDD
                                    OuterDropDownData={usersDropDown}
                                    SelectedIds={(ids) => {
                                        setSelectedUser(ids[0])
                                    }}
                                    SingleSelection
                                    InitialSelectedIds={selectedOptionDD}
                                />
                            </div>
                            <div className="flex items-center gap-1">
                                <label className='text-base'>المده</label>
                                {/* <DropTextField initalValue={duration_Time} data={[
                                    { id: 24, desc: 'اخر 24 ساعه' },
                                    { id: 24 * 7, desc: 'اخر 7 ايام' },
                                    { id: 24 * 30, desc: 'اخر 30 يوم' },
                                    { id: 1, desc: 'الربع الأول' },
                                    { id: 2, desc: 'الربع الثاني' },
                                    { id: 3, desc: 'الربع الثالث' },
                                    { id: 4, desc: 'الربع الرابع' },
                                    { id: 0, desc: 'مده محدده' },
                                ]} onchange={e => {
                                    let val = e.target.value as number
                                    set_duration_time(val)

                                }} /> */}
                                <MulitDD
                                    OuterDropDownData={[
                                        { id: 24, desc: 'اخر 24 ساعه' },
                                        { id: 24 * 7, desc: 'اخر 7 ايام' },
                                        { id: 24 * 30, desc: 'اخر 30 يوم' },
                                        { id: 1, desc: 'الربع الأول' },
                                        { id: 2, desc: 'الربع الثاني' },
                                        { id: 3, desc: 'الربع الثالث' },
                                        { id: 4, desc: 'الربع الرابع' },
                                        { id: 0, desc: 'مده محدده' },
                                    ]}
                                    SelectedIds={(ids) => {
                                        set_duration_time(ids[0])
                                    }}
                                    SingleSelection
                                    InitialSelectedIds={[duration_Time]}
                                />
                            </div>
                            {duration_Time === 0 && (
                                <div className={'flex flex-row gap-5'}>
                                    <p>من</p>
                                    <EGDate DateValue={(date) => {
                                        set_start_date(date)
                                    }} />
                                    <p>الى</p>
                                    <EGDate DateValue={(date) => {
                                        set_end_date(date)
                                    }} />

                                </div>
                            )}
                            <div>
                                <EgButton HandleClick={() => {
                                    dispatch(setLoadingTrue())
                                    if (duration_Time === 0) {
                                        getTeamStatistics({
                                            WITH_ACTIONS: false,
                                            START_DATE: start_date,
                                            END_DATE: end_date,
                                            PERIOD_ENUM: duration_Time,
                                            USER_ID: parentUser,
                                        }).then(res => {
                                            dispatchStatistics(STATISTIC_FROM_API(res))
                                            setStatisticsHolder(res);
                                            dispatch(setLoadingFalse())
                                        }).catch(err => {
                                            dispatch(setLoadingFalse())
                                            GetErrorAxios(err, dispatch)
                                        })
                                    } else {
                                        getTeamStatistics({
                                            WITH_ACTIONS: false,
                                            PERIOD_ENUM: duration_Time,
                                            USER_ID: parentUser,
                                        }).then(res => {
                                            dispatchStatistics(STATISTIC_FROM_API(res))
                                            setStatisticsHolder(res);
                                            dispatch(setLoadingFalse())
                                        }).catch(err => {
                                            dispatch(setLoadingFalse())
                                            GetErrorAxios(err, dispatch)
                                        })
                                    }

                                }}>
                                    <h6>عرض الإحصائيات</h6>
                                </EgButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* data itself with test */}
            <div>
                {/*the all width chart if exist*/}
                <div>

                </div>
                {statisticsReducer.ALL_STATISTICS !== undefined && statisticsReducer.ALL_STATISTICS.length > 0 && (
                    <div
                        className={'w-full bg-slate-200 rounded-xl shadow-3xl grid gap-10 grid-cols-[repeat(auto-fill,minmax(250px,250px))] p-5'}>

                        {statistics_holder !== undefined && (
                            <>
                                {all_stations_stat_data.map((branch) => {
                                    let drawData = branch.chartData
                                    let branchData = branch.station_data
                                    return (
                                        <>
                                            <div className='w-80 rounded-3xl shadow bg-white p-4 relative'>
                                                {/*<div*/}
                                                {/*    className={'absolute top-[23.5%] left-[36.1%] rounded-full w-20 h-20 overflow-hidden'}>*/}
                                                {/*    <img alt='Personal' src={user.user_data.user.PHOTO_URL}*/}
                                                {/*         style={{objectFit: 'contain'}}*/}
                                                {/*         className='w-full h-full'/>*/}
                                                {/*</div>*/}
                                                <DOComp data={drawData} />
                                                <div>
                                                    <p className={'text-center truncate'}>إحصائيات فرع</p>
                                                    <h5 className={'text-center truncate'} dir={'ltr'}>{branchData.station.STATION_NAME}</h5>
                                                    <div className={'mt-10'}>
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد بيانات العميل</h6>
                                                            <p>{(branchData?.CUSTOMER_DETAILS !== undefined && branchData?.CUSTOMER_DETAILS !== 0) ? branchData.CUSTOMER_DETAILS : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد المكالمات</h6>
                                                            <p>{(branchData?.CALL_ACTION !== undefined && branchData?.CALL_ACTION !== 0) ? branchData.CALL_ACTION : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد المقابلات</h6>
                                                            <p>{(branchData?.Appointment !== undefined && branchData?.Appointment !== 0) ? branchData.Appointment : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد مرات زياره الموقع</h6>
                                                            <p>{(branchData?.SITE_VISIT_REQ !== undefined && branchData?.SITE_VISIT_REQ !== 0) ? branchData.SITE_VISIT_REQ : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد الإستمارات ( تقفيل الديل)</h6>
                                                            <p>{(branchData?.DEALS !== undefined && branchData?.DEALS !== 0) ? branchData.DEALS : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>اجمالي المبيعات</h6>
                                                            <p>{branchData.Total_price_text !== "" ? branchData.Total_price_text : "مفيش"}</p>
                                                        </div>
                                                        <div className={"flex gap-5"}>
                                                            <p>فقظ</p>
                                                            <p className={'text-wrap'}>{branchData.Total_price_Tafqeet !== "" ? branchData.Total_price_Tafqeet : "مفيش"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                                {all_users_stat_data.map((user) => {
                                    let drawData = user.chartData
                                    let userData = user.user_data
                                    return (
                                        <>
                                            <div className='w-80 rounded-3xl shadow bg-white p-4 relative'>
                                                <div
                                                    className={'absolute top-[143px] left-[105px] rounded-full w-20 h-20 overflow-hidden'}>
                                                    <img alt='Personal' src={user.user_data.user.PHOTO_URL}
                                                        style={{ objectFit: 'contain' }}
                                                        className='w-full h-full' />
                                                </div>
                                                <DOComp data={drawData} />
                                                <div>
                                                    <p className={'text-center truncate'}>إحصائيات</p>
                                                    <h5 className={'text-center truncate'}
                                                        dir={'ltr'}>{user.user_data.user.FULL_NAME}</h5>
                                                    <p className={'text-center truncate'}>فرع</p>
                                                    <h5 className={'text-center truncate'}>({user.user_data.station.STATION_NAME})</h5>
                                                    <div className={'mt-10'}>
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد بيانات العميل</h6>
                                                            <p>{(userData?.CUSTOMER_DETAILS !== undefined && userData?.CUSTOMER_DETAILS !== 0) ? userData.CUSTOMER_DETAILS : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد المكالمات</h6>
                                                            <p>{(userData?.CALL_ACTION !== undefined && userData?.CALL_ACTION !== 0) ? userData.CALL_ACTION : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد المقابلات</h6>
                                                            <p>{(userData?.Appointment !== undefined && userData?.Appointment !== 0) ? userData.Appointment : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد مرات زياره الموقع</h6>
                                                            <p>{(userData?.SITE_VISIT_REQ !== undefined && userData?.SITE_VISIT_REQ !== 0) ? userData.SITE_VISIT_REQ : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>عدد الإستمارات ( تقفيل الديل)</h6>
                                                            <p>{(userData?.DEALS !== undefined && userData?.DEALS !== 0) ? userData.DEALS : "لايوجد"}</p>
                                                        </div>
                                                        <hr className={'my-3'} />
                                                        <div className={'flex gap-5'}>
                                                            <h6>اجمالي المبيعات</h6>
                                                            <p>{userData.Total_price_text !== "" ? userData.Total_price_text : "مفيش"}</p>
                                                        </div>
                                                        <div className={"flex gap-5"}>
                                                            <p>فقظ</p>
                                                            <p className={'text-wrap'}>{userData.Total_price_Tafqeet !== "" ? userData.Total_price_Tafqeet : "مفيش"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}


                                {/*<div className='w-72 rounded-3xl shadow bg-white p-4'>*/}
                                {/*    <DOComp data={drawDataTeam}/>*/}
                                {/*    <div>*/}
                                {/*        <h5 className={'text-center'}>احصائيات الفريق</h5>*/}
                                {/*        <div className={'mt-10'}>*/}
                                {/*            <div className={'flex gap-5'}>*/}
                                {/*                <h6>عدد بيانات العميل</h6>*/}
                                {/*                <p>{teamSta.find(data => data.ActionTypeName === GetActionTypeString(ActionTypes.ClientData))?.Actions.length ?? "لايوجد"}</p>*/}
                                {/*            </div>*/}
                                {/*            <hr className={'my-3'}/>*/}
                                {/*            <div className={'flex gap-5'}>*/}
                                {/*                <h6>عدد المكالمات</h6>*/}
                                {/*                <p>{teamSta.find(data => data.ActionTypeName === GetActionTypeString(ActionTypes.Call))?.Actions.length ?? "لايوجد"}</p>*/}
                                {/*            </div>*/}
                                {/*            <hr className={'my-3'}/>*/}
                                {/*            <div className={'flex gap-5'}>*/}
                                {/*                <h6>عدد المقابلات</h6>*/}
                                {/*                <p>{teamSta.find(data => data.ActionTypeName === GetActionTypeString(ActionTypes.Appointment))?.Actions.length ?? "لايوجد"}</p>*/}
                                {/*            </div>*/}
                                {/*            <hr className={'my-3'}/>*/}
                                {/*            <div className={'flex gap-5'}>*/}
                                {/*                <h6>عدد مرات زياره الموقع</h6>*/}
                                {/*                <p>{teamSta.find(data => data.ActionTypeName === GetActionTypeString(ActionTypes.SiteVisit))?.Actions.length ?? "لايوجد"}</p>*/}
                                {/*            </div>*/}
                                {/*            <hr className={'my-3'}/>*/}
                                {/*            <div className={'flex gap-5'}>*/}
                                {/*                <h6>عدد الإستمارات ( تقفيل الديل)</h6>*/}
                                {/*                <p>{teamSta.find(data => data.ActionTypeName === GetActionTypeString(ActionTypes.Deal))?.Actions.length ?? "لايوجد"}</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

export default TeamStatistics
