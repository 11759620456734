import React from 'react'
import { HeaderH2Text, HeaderH3Text } from '../../../CustomComps/Customs'
import { ProjectModel } from '../../../Service/models/projects/ProjectModel'
import nileView from './../../../Assets/images/Hero/NileViewImage.png'

const Project = ({ projectData }: { projectData: ProjectModel }) => {
    return (
        <div className='flex flex-col overflow-hidden justify-end rounded-lg shadow-md  bg-white w-full h-full'>
            <div className='w-full h-full' style={{ backgroundImage: "url(" + projectData.PROJECT_IMAGES[0].IMAGE_URL + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', objectFit: 'cover' }}>
            </div>
            {/* <div className=' flex flex-col my-5 px-5 w-[25rem] h-1/3'>
                <HeaderH2Text>{projectData.PROJECT_NAME}</HeaderH2Text>
                <HeaderH2Text>{projectData.DESCRIPTION}</HeaderH2Text>
                <div className='flex flex-row gap-4'>
                    <HeaderH3Text >عدد الوحدات المتاحه </HeaderH3Text>
                    <HeaderH3Text style={{ color: '#FF590B' }}>{projectData.NO_UNITS ?? 17}</HeaderH3Text>
                </div>
            </div> */}
        </div>
    )
}

export default Project