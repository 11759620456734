import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import axios, { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CheckedIcon, THETEAM, Unchecked } from '../../components/Icons/Icons';
import { CustomTextField } from '../../CustomComps/Customs';
import { AddAction } from '../../redux/slices/ErrorsSlice';
import { AppDispatch } from '../../redux/store';
import { FEEDBACK_Model } from '../../Service/models/FeedBackModel';
import { UserModel } from '../../Service/models/UserModel/UserModel';
import { tokens } from '../../theme';
import { EgButton } from '../Shared/Shared';

export type Cities = {
    ID: number,
    GOV_ID: number,
    CITY_NAME: string,
    CITY_NAME_EN: string
}

export type GovAndCities = {
    ID: number,
    GOVERNORATE_NAME: string,
    GOVERNORATE_NAME_EN: string,
    ALL_CITIES: Cities[]
}




export const textFiedlFlag = (Switch: 0 | 1 | -1, initialIcon: JSX.Element) => {
    return {
        endAdornment: Switch === -1 ? (
            <InputAdornment position="start">
                <Box marginX={1} width={25} height={25}>
                    {initialIcon}
                </Box>
            </InputAdornment>
        ) : Switch === 1 ? <InputAdornment position="start">
            <Box width={25} height={25} marginX={1}>
                <CheckedIcon />
            </Box>
        </InputAdornment> : <InputAdornment position="start">
            <Box width={25} height={25} marginX={1}>
                <Unchecked />
            </Box>
        </InputAdornment>,
    }
};


const Terms = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const MainInputStyle = { marginBottom: 20, width: '100%', backgroundColor: 'white' };

    const [feedBackRQ, setFeedBackRQ] = useState<FEEDBACK_Model>({ PROJECT_RATE: 2 } as FEEDBACK_Model);
    const [Govs, setGovs] = useState<GovAndCities[]>();
    const [cities, setCities] = useState<Cities[]>();

    const dispatch = useDispatch<AppDispatch>();




    const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<UserModel>({
        reValidateMode: 'onBlur',
        mode: 'onBlur'
    });

    // let passData = watch('PASSWORD');

    const {
        ref: EmailInputRef, ...EmailInputProps } = register("EMAIL", {
            required: "البريد الإلكتروني لايمكن ان يكون فارغ",
            // pattern: {
            //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //   message: "نمط البريد غير صحيح"
            // }
        });
    const {
        ref: FullNameInputRef, ...FullNameInputProps } = register("FULL_NAME", {
            required: "الإسم لايمكن ان يكون فارغ",
            // pattern: {
            //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //   message: "نمط البريد غير صحيح"
            // }
        });
    const {
        ref: MobileNumberInputRef, ...MobileNumberInputProps } = register("MOBILE_NO", {
            required: "رقم الموبايل لايمكن ان يكون فارغ",
            pattern: {
                value: /^(?:\+?[0-9]+)$/i,
                message: "رقم الموبايل غير صحيح"
            }
        });
    const {
        ref: PasswordInputRef, ...PasswordInputProps } = register("PASSWORD", {
            required: "رمز المرور لايمكن ان يكون فارغ",
            pattern: {
                value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g,
                message: "رمز الدخول لايقل عن 8 احرف على ان يحتوي حرف كبير و حرف صغير و رقم ورمز خاص مثل @ "
            }
            // pattern: {
            //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //   message: "نمط البريد غير صحيح"
            // }
        });
    // const [passwordConfirm, setPasswordConfirm] = useState('');
    // const [password, setPassword] = useState('');
    const passwordRef = useRef('');
    const passwordConfirmRef = useRef('');

    const onSubmit: SubmitHandler<UserModel> = async (data: UserModel) => {

    };



    const ValidateData = () => {
        
    }

    const sendDatatoServer = (req: FEEDBACK_Model) => {

        


        var reqBodySerialized = JSON.stringify(req);


        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'X-Host': window.location.hostname,
            },
            withCredentials: true,
            credentials: 'include',
        }

        axios.post('/api/feedback/postFeedback', reqBodySerialized, axiosConfig).then(res => {
            dispatch(AddAction({ message: "تم ارسال التقيم", status: "success" }))
        }
        ).catch(err => {
            let ERR = err as AxiosError
            
            
            let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;
            dispatch(AddAction({ message: ErrMsg, status: "error" }))
        })

    }



    const login = useGoogleLogin({
        onSuccess: tokenResponse => {}
    });





    useEffect(() => {
        axios.get('/api/govs/getAllGovernorates').then(res =>
            setGovs(res.data)
        ).catch(err => {
            let ERR = err as AxiosError
            
            
            let ErrMsg = err.response.data.Error as string || err.response.data as string || ERR.message + " At " + ERR.config?.url as string;
            dispatch(AddAction({ message: ErrMsg, status: "error" }))
        })
    }, [])



    useEffect(() => {
        let newCitiesArr = Govs?.filter(gov => gov.ID === feedBackRQ.GOV)[0];
        setCities(newCitiesArr?.ALL_CITIES)
    }, [Govs, feedBackRQ.GOV])



    return (
        <div className='bgscreen w-full flex py-5 justify-center items-center'>
            <div className='bg-[#EDF3F4] rounded-3xl w-4/6  lg:w-3/6  flex flex-col items-center'>
                <div className='h-1/3 p-2 mb-10'>
                    <THETEAM />
                </div>
                <div>
                    {/* <p className='text-2xl'>نرحب بكم , يسعدنا معرفه رأي حضرتك</p> */}
                </div>
                <div className='p-5 flex flex-col gap-5'>
                    <h1 className='text-2xl font-bold text-black'> الشروط والأحكام</h1>
                    <h2 className='text-lg font-bold text-black'>
                        مرحبًا بك في The-Team. باستخدام مواقع The-Team (والتي تشمل جميع العقارات والتطبيقات المحمولة والمنصات الإلكترونية أو أي وسيلة أخرى) والخدمات ذات الصلة (التي سيشار إليها جماعيًا باسم "الخدمات")، فإنك توافق على الامتثال لبنود الاستخدام التالية، والتي قد تم تحديثها بين الحين والآخر ("بنود الاستخدام").
                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        1. الاستخدام المسموح به:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        ما لم تكن محترفًا في مجال العقارات أو القروض في سياق مهني، فإنك توافق على استخدام الخدمات بشكل صارم للاستخدام الشخصي فقط. يقتصر استخدامك التجاري على المعاملات التي يتم إجراؤها نيابةً عنك. يجوز للمحترفين في مجال العقارات أو القروض استخدام الخدمات لتقديم المعلومات للمستهلكين أو القيام بإجراءات نيابة عن العملاء. يُحظر على المحترفين في مؤسسات القروض استخدام المعلومات المقدمة من قبل The-Team عبر الخدمات في اتخاذ أي قرارات متعلقة بالقروض. يجوز لك نسخ المعلومات من الخدمات للاستعراض الشخصي، والحفظ، والطباعة، والفاكس، وإرسال البريد الإلكتروني. يُحظر استنساخ، وتعديل، وتوزيع، وعرض، وتوفير الوصول إلى الخدمات بأي طريقة. لا يجوز لك إنشاء أعمال مشتقة، أو فك الشيفرة المصدرية، أو عكس هندسة أي جزء من الخدمات. توافق أيضًا على عدم تفويض المواقع من طرف ثالث غير متعلق بـ The-Team بتوفير روابط إلى الخدمات.
                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        2. الاستخدام المقبول:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        توافق على عدم استخدام الخدمات لأغراض غير مشروعة أو لإلحاق الضرر بـ The-Team، مقدمي الخدمات، الموردين، أو المستخدمين. يُحظر توزيع البريد المزعج، ورسائل السلسلة، وأنماط الهرم، والاتصالات المماثلة من خلال الخدمات المقدمة من قبل The-Team. يُحظر التنكر كشخص آخر أو تضليل الانتماء. باستثناء ما هو معلن فيما يلي، قد لا تستخدم أجزاء من الخدمات على مواقع طرف ثالث.

                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        3. الاستعلامات التلقائية:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        تُحظر بشكل صارم الاستعلامات التلقائية (مثل الخدمات السحابية، العناكب، والروبوتات)، ما لم يتم التصريح بها بكتابة من قبل The-Team. تُسمح باستثناء محدود لمحركات البحث العامة وأدوات التصفح على الإنترنت المماثلة بالاستعلام عن الخدمات وتوفير فهرس يحتوي على روابط إلى صفحات الويب، فقط بقدر ما يُسمح بذلك بموجب قانون حقوق النشر المعمول به. لا يُسمح لمحركات البحث بالاستعلام عن معلومات محمية بنظام التحقق من الأمان ("كابتشا") الذي يحد من وصول المستخدمين البشريين.
                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        4. المواد التي تقدمها؛ استخدام الحساب؛ الخصوصية؛ مواقع الويب لجهات ثالثة:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        تمنح The-Team ترخيصًا غير قابل للعكس، دائم، ومجاني عالميًا للمواد التي تقدمها (المواد التي تنشرها أو تقدمها بأي طريقة أخرى). قد تستخدم The-Team المواد الخاصة بك في الخدمات أو في وسائط أخرى، بما في ذلك نسخها وتوزيعها وعرضها وتكييفها وتعديلها وإعداد أعمال مشتقة منها ودمجها في أعمال أخرى وترجمتها. أيضًا، يُحظر استنساخ المواقع أو تعديلها أو توزيعها أو عرضها أو توفير وصول إليها على مواقع طرف ثالث.

                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        5. دور The-Team:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        The-Team هو وسيط عقاري مرخص في جمهورية مصر العربية.
                    </h2>


                    <h2 className='text-lg font-bold text-black'>
                        6. الملكية الفكرية:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>

                        الخدمات هي ملكية لدى The-Team. قد لا تستخدم علامات The-Team كجزء من اسم شاشتك أو عنوان بريدك الإلكتروني. لا تكتسب حقوق النشر أو حقوق الصور أو وصف القوائم العقارية من خلال الخدمات.

                    </h2>
                    <h2 className='text-lg font-bold text-black'>

                        7. الحد من المسؤولية؛ الوسيلة الحصرية للإصلاح:
                    </h2>

                    <h2 className='text-lg font-bold text-gray-500'>
                        لا يتحمل The-Team أو مورديه مسؤولية الأضرار الناجمة عن هذه بنود الاستخدام أو استخدامك للخدمات. يستمر استبعاد الأضرار بموجب هذه الفقرة مستقلاً عن الوسيلة الحصرية للإصلاح، وهي إيقاف استخدام الخدمات.

                    </h2>

                    <h2 className='text-lg font-bold text-black'>

                        8. التغييرات؛ الإيقاف:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        يحتفظ The-Team بالحق في تغيير بنود الاستخدام في أي وقت. التغييرات ستكون سارية فور نشر النسخة المعدلة من بنود الاستخدام على خدمات The-Team. استمرار استخدامك بعد التغييرات يعني الموافقة والاتفاق على هذه التغييرات. قد يقوم The-Team بتعديل، أو تعليق، أو إيقاف الخدمات دون إشعار.
                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        9. اختيار القانون؛ النزاعات:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        تحكم بنود الاستخدام هذه بقوانين جمهورية مصر العربية، دون الإخلال بأحكام قوانين التعارض. تخضع للقضاء الحصري والولاية في المحاكم الجالسة في القاهرة، جمهورية مصر العربية، لأي نزاعات أو مطالبات أو إجراءات تنشأ من أو تتعلق بالخدمات أو بنود الاستخدام.

                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        10. الدفعة التعويضية:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        توافق على الدفاع عن The-Team وشركائها، ومديريها، وضباطها، وموظفيها، ووكلائها، ضد أي وجميع المطالبات التي يمكن أن تثيرها أطراف ثالثة نتيجة لانتهاكك لبنود الاستخدام هذه أو استخدامك للخدمات.
                    </h2>

                    <h2 className='text-lg font-bold text-black'>
                        11. إطلاق سراح:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        تطلق The-Team وشركاؤها، ومديروها، وضباطها، وموظفيها، ووكلائها من مسؤولية أي وجميع المطالبات التي قد تثيرها ضد أي طرف ثالث نتيجة للخدمات.

                    </h2>
                    <h2 className='text-lg font-bold text-black'>
                        12. عام:
                    </h2>
                    <h2 className='text-lg font-bold text-gray-500'>
                        منع تصدير الخدمات دون الامتثال لقوانين التصدير المعمول بها. تشكل بنود الاستخدام هذه اتفاقًا كاملاً بينك وبين The-Team.

                        شكرًا لاستخدامك The-Team. إذا كانت لديك أي استفسارات، يُرجى التواصل معنا عبر البريد الإلكتروني info@the-team.co.
                    </h2>
                </div>
            </div>
        </div>
    )
}
export default Terms