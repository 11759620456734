import React, { useEffect, useRef, useState } from 'react'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import LoginBody from './LoginBody'
import { CustomTextField } from '../../CustomComps/Customs'
import { Box, InputAdornment, Stack, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme'
import KeyIcon from '@mui/icons-material/Key';
import { SubmitHandler, useForm } from 'react-hook-form'
import { EgButton, SnackbarFeedback } from '../Shared/Shared'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../redux/store'
import { fetchTodos } from '../../redux/slices/CheckConnection'
import Loading from '../Loading/Loading'
import { LoadingState } from '../../redux/slices/LoadingSlice';
import { FeedbacFlag } from '../../redux/slices/Feedback';
import { useNavigate } from "react-router-dom";
import './Login.css'
import { EmailLogo, GoogleIcon, Logo, THETEAM } from '../../components/Icons/Icons'
import { LoginReq } from '../../Service/models/LoginRQ'
import { useGoogleLogin } from '@react-oauth/google'
import { getUserById } from '../../Service/Apis'
import { GetCurrentUserThunk } from '../../redux/slices/UserSlice'
import Cookies from 'js-cookie'
import { UserModel } from '../../Service/models/UserModel/UserModel'
import { CookiesData, LOCALSTORAGE_SAVE, localStorageKeys } from '../../Globals'
import { Link } from 'react-router-dom'

export interface ILoginInputs {
  userName: string
  password: string
}

export type inputType = 'text' | 'password'


function Login() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const MainInputStyle = { marginBottom: 20, width: '100%' };
  const LoadingFlag = useSelector(LoadingState);
  const feedbackFlag = useSelector(FeedbacFlag);
  const animationRef = useRef<HTMLDivElement>(null)
  const [pswTypeChange, setPSWTypeChange] = useState<inputType>('password');

  const navigate = useNavigate();


  const [Login, setLogin] = useState<ILoginInputs>({} as ILoginInputs);

  const dispatch = useDispatch<AppDispatch>();

  const { register, setError, control, handleSubmit, reset, watch, formState: { errors } } = useForm<LoginReq>({
    reValidateMode: 'onBlur',
    mode: 'onBlur'
  });

  const { ref: UserNameinputRef, ...UserNameinputProps } = register("UserName", {
    required: "برجاء ادخال البريد الإلكتروني",
  });

  const { ref: PassWordInputRef, ...PassWordinputProps } = register("Password", {
    required: "كلمه المرور لا يمكن ان تكون فارغه",
  });

  const onSubmit: SubmitHandler<LoginReq> = async (data: LoginReq) => {
    dispatch(fetchTodos(data)).then(res => {
      if (res.payload === "Done") {
        dispatch(GetCurrentUserThunk(null)).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            // Cookies.set('User',JSON.stringify(res.payload as UserModel))

            LOCALSTORAGE_SAVE(localStorageKeys.LoggedInUser, res.payload as UserModel)
            navigate('/')
          }
        });
      }
    }).catch(err => {

    });
  };

  const login = useGoogleLogin({
    onSuccess: tokenResponse => { },
  });

  return (
    <div className='registerBG h-screen w-full flex justify-center items-center'>
      <div className='bg-[#EDF3F4] rounded-3xl w-4/6 md:w-2/6 h-[80%] flex flex-col items-center'>
        <div className='h-1/3 p-2 my-5 mb-10'>
          <THETEAM />
        </div>
        <form className='w-4/5 h-2/3 flex flex-col  justify-start items-center' onSubmit={handleSubmit(onSubmit)}>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField inputRef={UserNameinputRef} {...UserNameinputProps} type={'email'} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box marginLeft={2}>
                    <div className='w-6 h-6'>
                      <EmailLogo />
                    </div>
                  </Box>
                </InputAdornment>
              ),
            }} style={MainInputStyle} error={!!errors.UserName}
              helperText={errors?.UserName?.message}
              placeholder='البريد الإلكتروني' colors={colors} theme={theme} />
          </div>
          <div className='w-full flex flex-row gap-4'>
            <CustomTextField placeholder='رمز المرور' inputRef={PassWordInputRef} {...PassWordinputProps} type={pswTypeChange} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box marginLeft={2}>
                    <div className='hover:cursor-pointer' onClick={() => {
                      setPSWTypeChange(prev => {
                        if (prev === 'password') {
                          return 'text'
                        } else {
                          return 'password'
                        }
                      })
                    }} >
                      <KeyIcon />
                    </div>
                  </Box>
                </InputAdornment>
              ),
            }} style={MainInputStyle} error={!!errors.Password}
              helperText={errors?.Password?.message}
              colors={colors} theme={theme} />
          </div>
          <div>
            <div className=' my-3	'	 >
              <p onClick={() => {
                navigate('/Confirmeemail')
              }} className='hover:cursor-pointer   text-[#e7c498]'>هل نسيت كلمة السر ؟</p>
            </div>
          </div>
          <div className='w-2/3 self-center  	my-8	'	>
            <EgButton Action={'submit'} HandleClick={() => {
              //dispatch(fetchTodos({ password: 'ahmed', userName: 'ahmed' }));
            }}>
              <Stack direction={'row'} justifyContent='center'>
                <Typography sx={{ marginLeft: '10px' }} variant="h5">دخول</Typography>
              </Stack>
            </EgButton>
          </div>


        </form>
      </div >
    </div >
  )
}

export default Login