import React, { FC, ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { CookiesData, getCookies, IsObjectEmpty } from '../../../Globals';
import { SetCurrentUserReducer, UsersState } from '../../../redux/slices/UserSlice';
import { AppDispatch } from '../../../redux/store';
import { UserModel } from '../../../Service/models/UserModel/UserModel';


type Props = {
  children: JSX.Element,
  user: UserModel
}

const Protect = ({ children, user }: Props) => {

  let User = getCookies(CookiesData.User);
  // if(User){

  // }
  // let userObj = JSON.parse(User) as UserModel ;
  // let isUserEmpty = IsObjectEmpty(User);
  // if (!objectEmpty) {
  //   return children
  // }

  // return User ? children : <Navigate to={'/'} />
  return User ? children : <Navigate to={'/'} />
}
type PropsForELE = {
  Comp: FC<any>,
  user: UserModel
}

export const ProtectWithProps = ({ Comp, user }: PropsForELE)  => (props : any) => {

  let User = getCookies(CookiesData.User);
  // if(User){

  // }
  // let userObj = JSON.parse(User) as UserModel ;
  // let isUserEmpty = IsObjectEmpty(User);
  // if (!objectEmpty) {
  //   return children
  // }

  // return User ? children : <Navigate to={'/'} />
  return User ? <Comp {...props} user={user} /> : <Navigate to={'/'} />
}

export default Protect