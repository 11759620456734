import { createSlice, PayloadAction, createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit'
import { GetAllPermissions, getAllProjects, GetCompany, GetConnectionFromServer1, Login, SaveCompany, SaveProject } from '../../Service/Apis';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from './LoadingSlice';
import { hideFeedBack, showFeedBack } from './Feedback';
import { saveCompanyRQ } from '../../Service/models/company/SaveCompanyRQ';
import { GetCompanyRS } from '../../Service/models/company/GetCompanyRS';
import { AppDispatch, RootState } from '../store';
import { ProjectModel } from '../../Service/models/projects/ProjectModel';
import { PermissionModel } from '../../Service/models/Permission/Permission';
import { GetError } from '../../Globals';



export interface IPermissionsSlice {
    AllPermissions: PermissionModel[],
    Error: string,
    Loading: boolean,
    Empty: PermissionModel[],

}

const initialState: IPermissionsSlice = {
    AllPermissions: [],
    Error: "",
    Loading: false,
    Empty: []
}

export const PermissionsSlice = createSlice({
    name: 'Permission',
    initialState,
    reducers: {
        // GetAllProjects: (state, action: PayloadAction<ProjectModel[]>) => {
        //     state.status = action.payload as ProjectModel[]
        // },

    },
    extraReducers: (builder) => {

        // When we send a request,
        // `fetchTodos.pending` is being fired:
        builder.addCase(GetAllPermissionThunk.pending, (state) => {
            // At that moment,
            // we change status to `loading` 
            // and clear all the previous errors:
            // state.status = "loading";
            // state.error = null;
        });

        // When a server responses with the data,
        // `fetchTodos.fulfilled` is fired:
        builder.addCase(GetAllPermissionThunk.fulfilled,
            (state, { payload }) => {
                // We add all the new todos into the state
                // and change `status` back to `idle`:
                // state.list.push(...payload);
                // state.status = "idle";
                state.AllPermissions = payload as PermissionModel[];
            });

        // When a server responses with an error:
        builder.addCase(GetAllPermissionThunk.rejected,
            (state, { payload }) => {
                // We show the error message
                // and change `status` back to `idle` again.
                // if (payload) state.error = payload.message;
                state.Error = payload as string

            });
    }
})

type FetchTodosError = {
    message: string;
};

export const GetAllPermissionThunk = createAsyncThunk<PermissionModel[] | string>(
    // The first argument is the action name:
    "Permission/getAll",

    // The second one is a function
    // called payload creator.
    // It contains async logic of a side-effect.
    // We can perform requests here,
    // work with device API, 
    // or any other async APIs we need to.

    // The second argument, `thunkApi`, is an object
    // that contains all those fields
    // and the `rejectWithValue` function:

    // The third type-parameter is an object with:
    // `{dispatch?, state?, extra?, rejectValue?}`` fields.
    //
    // `extra` is useful when we need to pass 
    // some static data to the request function,
    // like jwt-token or HTTP-headers.
    //
    // `rejectValue` is useful when we need to type 
    // possible errors.

    async (project, thunkApi) => {

        thunkApi.dispatch(setLoadingTrue());

        return GetAllPermissions().then(res => {
            thunkApi.dispatch(setLoadingFalse());
           // thunkApi.dispatch(showFeedBack({ show: true, message: "تم الصلاحيات", status: "success" }))
            return res;
        }).catch(err => {
            return  GetError(err,thunkApi) 
        });
    }
);

export const PermissionsState = (state: RootState) => state.PermissionStore;

export const {  } = PermissionsSlice.actions

export default PermissionsSlice.reducer