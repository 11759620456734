import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from './comps/Header'

import { Box } from '@mui/material'
import { googleLogout } from '@react-oauth/google'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { EGNavButton, EGNavButtonRegister, NavBarText } from '../../CustomComps/Customs'
import { CookiesData, getCookies } from '../../Globals'
import { LogOutThunk } from '../../redux/slices/CheckConnection'
import { FeedbacFlag } from '../../redux/slices/Feedback'
import { langDirState } from '../../redux/slices/Lang'
import { LoadingState } from '../../redux/slices/LoadingSlice'
import { GetProjectsAsync } from '../../redux/slices/Projects'
import { SetCurrentUserReducer, UsersState } from '../../redux/slices/UserSlice'
import { AppDispatch } from '../../redux/store'
import { UserModel } from '../../Service/models/UserModel/UserModel'
import Footer from './comps/Footer/Footer'
import Hero from './comps/Hero/Hero'
import MostSelling from './comps/Sections/MostSelling/MostSelling'
import OurServices from './comps/Sections/OurServices/OurServices'
import TopMarketers from './comps/Sections/TopMarketers/TopMarketers'
import './Home.css'
import { GetAllCitiesThunk } from '../../redux/slices/CitySlice'
import SalesCard from './comps/Sales'
import MulitDD from '../../components/MultiDD'




function Home() {
    const { i18n } = useTranslation();
    const langDirectionState = useSelector(langDirState);

    const dispatch = useDispatch<AppDispatch>();
    const UsersData = useSelector(UsersState);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const DrawerRef = useRef<HTMLDivElement>(null);
    const [hideClass, setHideClass] = useState<boolean>(false);

    const LoadingFlag = useSelector(LoadingState);
    const feedbackFlag = useSelector(FeedbacFlag);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        let user = getCookies(CookiesData.User);

        if (user) {
            let userOBJ = JSON.parse(user) as UserModel;
            dispatch(SetCurrentUserReducer(userOBJ));
        } else {
            dispatch(SetCurrentUserReducer({} as UserModel))
        }


        if (langDirectionState === 'ltr') {
            i18n.changeLanguage('en')
        } else {
            i18n.changeLanguage('ar')
        }

        document.body.dir = langDirectionState




    }, [langDirectionState])

    useEffect(() => {


        dispatch(GetAllCitiesThunk()).then(res => {

        })

        dispatch(GetProjectsAsync()).then(res => {

        })

        let isLoggedInd = Cookies.get('LoggedIn');


        // TestAsync()
        return () => {

        };
    }, []);

    const logOut = async () => {
        // 
        // var base64Url = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjY3NmRhOWQzMTJjMzlhNDI5OTMyZjU0M2U2YzFiNmU2NTEyZTQ5ODMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2ODkxNTIyNDQsImF1ZCI6IjEwMDYzMTM3NzczOS04NmJscjUyaG9yYXBybTE3ZGNvMXVlY2h2OTU4cTVvYS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExNDU1NTY0NDEyMTQ4OTMxNzU3MiIsImVtYWlsIjoiZHIuZW5nLmFobWVkQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiIxMDA2MzEzNzc3MzktODZibHI1MmhvcmFwcm0xN2RjbzF1ZWNodjk1OHE1b2EuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJuYW1lIjoiQWhtZWQgRW1hZCIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRjbUR3U3lTLTdtMmV1MjVIM0VrVXBEeXdLY2ZmWFpPYVgtV01LVVR4dFIwUT1zOTYtYyIsImdpdmVuX25hbWUiOiJBaG1lZCIsImZhbWlseV9uYW1lIjoiRW1hZCIsImlhdCI6MTY4OTE1MjU0NCwiZXhwIjoxNjg5MTU2MTQ0LCJqdGkiOiIwMTM5MDM5MDYwNjIxODRmYzIyMjZiZWJjYWE5YTA2NDJhOWM3ZTg3In0.X224LB47Y20scd_AVltdZTX1K4PCanKXqYlFFZ_IWYiZHZGGq9cOlHriLwOt1Mtn4_cxLFEwFsAdso5GGz3Te366OTs7PbmIK7ERn8qzcq7gxAJD7Ys15-a0utRGvdr_YKVGRN6lLuCHxtJQ4aYA8OqA73Zaz1jQhGBJNbx04o6oYT5gLg_LzotYdUTe5kDJyyZhy36Q4eLZd_BxC9F3szO5zCjsDuBGZt8XjkxlBMpJKz-XsGE5zQvcKNhB24uQqmSEociawNNyfZIFPYMORdRBlTOaGxj46D8XLppSIN8lnFk73bSXSaMVN1bJ0tsbxD_wHi9-Ul7LMKRkOdH0IA".split('.')[1];
        // var decoded =  jwt_decode("eyJhbGciOiJSUzI1NiIsImtpZCI6IjY3NmRhOWQzMTJjMzlhNDI5OTMyZjU0M2U2YzFiNmU2NTEyZTQ5ODMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2ODkxNTU2NzYsImF1ZCI6IjEwMDYzMTM3NzczOS04NmJscjUyaG9yYXBybTE3ZGNvMXVlY2h2OTU4cTVvYS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwNTUwNTIyMTYwNzE4MjU2NDM4MiIsImVtYWlsIjoiZWd5LmRldi5tYWhtb3VkQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiIxMDA2MzEzNzc3MzktODZibHI1MmhvcmFwcm0xN2RjbzF1ZWNodjk1OHE1b2EuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJuYW1lIjoiTWFobW91ZCBFbGF0dGFyIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FBY0hUdGRxbVNlcDhXM1J5TlFRMzVhOWFVUndZYm9oSEJYUHRJdjg1RHcxOUVOQUozWT1zOTYtYyIsImdpdmVuX25hbWUiOiJNYWhtb3VkIiwiZmFtaWx5X25hbWUiOiJFbGF0dGFyIiwiaWF0IjoxNjg5MTU1OTc2LCJleHAiOjE2ODkxNTk1NzYsImp0aSI6ImYyMzU4MGQzNWVkNTYzOTU1OTgwMDUyOGY0ZTQ1MTc1MWE3NWQwMzcifQ.PNYgKym5cZHSkJNQyu8gXVsC0vLXR4vby42ThGYzMeNUJ-3PKbz-ZVG4mAhDWG7jp9nxTOshv3bTnRnkkM4SiS62NSYH2Jm_1oKUjo49dY99s5hkt0D4Q4omd-MawPTrVNxMzrHOu4JdGP-MFPbB8UsjaThvPZijGJOUcjAjRw5VI_nQ0bBwwLKP7RKuuc3PBCQAzr-6J36zIzUi4XePD43IV_zFA2gahPnh3HFLG-lN0z7Vb4FIl61AgHhAxEapT2LxLhiE8DF-HHVCfpY5l4vwqoax5McaCD2FaGk2EURZIKOZWEK7L3UK_MJWIuRsF7vghLpEW2F26FeqYz1leQ");


        // 
        googleLogout();
    };

    const responseFacebook = (response: any) => {

    }

    const SIGNOUT = () => {
        dispatch(LogOutThunk()).then(res => {
            dispatch(SetCurrentUserReducer({} as UserModel))
        });
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const DisAbleFunction = () => {
        let usage = "hello every one"

    }

    const nonBlocking = async () => {
        
    }
    const nonBlocking2 = () => {
        return new Promise((res, rej) => {
            res(22)
        })
    }

    // const TestAsync = async () => {
    //     async function getUserData() {
    //         
    //         let response1 = await fetch('https://jsonplaceholder.typicode.com/users');
    //         let response2 = await fetch('https://jsonplaceholder.typicode.com/users');
    //         let response3 = await fetch('https://jsonplaceholder.typicode.com/users');
    //         
    //     }

    //     getUserData();
    //     
    // }

    return (
        <div className='bg-[#f6f6f6] relative'>
            <div ref={DrawerRef} className='hide md:hidden w-2/4 flex flex-col h-[90vh] fixed z-[999] top-[60px] right-0 bg-[#f9f9f9] gap-2'>
                <div className='h-1/3'>
                    <Box>
                        {!UsersData.currentUser?.EMAIL && (
                            <EGNavButtonRegister onClick={() => {
                                navigate('/register')
                                DisAbleFunction()
                            }}>
                                <NavBarText>{t('SignUp')}</NavBarText>
                            </EGNavButtonRegister>
                        )}

                        <EGNavButton onClick={() => {
                            navigate('/Login')
                        }}>
                            {/* <NavBarText className='' >{t('SignIn')}</NavBarText> */}
                            <NavBarText>{t('SignIn')}</NavBarText>
                        </EGNavButton>

                    </Box>

                </div>
            </div>
            {/* {t('language')} */}
            {/* <img
                src="https://the-team.co/api/images/img?id=1"
                alt="new"
            /> */}
            {/* {feedbackFlag && <SnackbarFeedback />}
            {LoadingFlag && <Loading />} */}

            {/* <div>
                <h2>React Google Login</h2>
                <br />
                <br />
                <button onClick={logOut}>Log out</button>
                <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
            </div> */}
            <Header user={UsersData.currentUser} openDrawerFunc={(flag) => {
                if (flag) {
                    DrawerRef.current?.classList.add("hide")
                } else {
                    DrawerRef.current?.classList.remove("hide")
                }
            }} />
            <Hero />
            {/* <div className='w-1/2'>
                <MulitDD SingleSelection={true} DropDownData={[
                    { id: 0, desc: "BMW" },
                    { id: 1, desc: "Mercedes" },
                    { id: 2, desc: "Kia" },
                    { id: 3, desc: "toyota" },
                    { id: 4, desc: "MG" },
                    { id: 5, desc: "Jaguar" },
                ]} SelectedIds={(ids) => {
                    
                }} />
            </div> */}
            <div className='w-full'>
                <div className='mx-5 md:mx-24 flex flex-col gap-10'>
                    {/* <div className='w-52'>
                        <SalesCard totalSales='1000' percentageChange='80%' />
                    </div> */}
                    <MostSelling />

                    <TopMarketers />
                    <OurServices />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Home


