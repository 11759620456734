import React from 'react'
import { HeaderH1Text, HeaderH2Text, HeaderH3Text } from '../../../../../CustomComps/Customs'
import Marketing from './../../../../../Assets/images/Services/Marketing.png'
import Finishing from './../../../../../Assets/images/Services/Finishing.png'
import exec from './../../../../../Assets/images/Services/Execution.png'

const OurServices = () => {
    return (
        <div className='w-full'>
            <HeaderH1Text className='mb-2' weight='bold'>
                خدماتنا
            </HeaderH1Text>
            <div className='w-full h-56 flex flex-row shadow-md mb-24'>
                <div className='w-1/4 h-full ' style={{ backgroundImage: "url(" + Marketing + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', objectFit: 'cover' }}>

                </div>
                <div className='w-3/4 h-full bg-white px-7 shadow'>
                    <div className='w-4/5 h-full flex flex-col justify-center gap-5'>
                        <HeaderH2Text weight='bold'>التسويق العقاري</HeaderH2Text>
                        <p className='font-bold'>لو عندك شقه , بيت , محل او حتى عماره و محتاج حد يبيعهالك , عندنا فريق مدرب على اعلى مستوى هيساعدك في ده علشان تقدر تبيع بالسعر اللي انت عاوزه في أقرب وقت , لحد النهارده قدرنا نحقق اكتر من <span className='secondaryText'>20,000 عمليه بيع ناجحه </span>     على مستوى جمهوريه مصر العربيه</p>
                    </div>
                </div>
            </div>
            <div className='w-full h-56 flex flex-row-reverse shadow-md mb-24'>
                <div className='w-1/4 h-full ' style={{ backgroundImage: "url(" + Finishing + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', objectFit: 'cover' }}>

                </div>
                <div className='w-3/4 h-full bg-white px-7 shadow'>
                    <div className='w-4/5 h-full flex flex-col justify-center gap-5'>
                        <HeaderH2Text weight='bold'>التشطيبات</HeaderH2Text>
                        <p className='font-bold'><span className='secondaryText'>اكثر من 30 سنه خبره </span>في مجال التشطيبات ساعدتنا في تشطيب اكتر من 1500 وحده ما بين وحدات سكنيه , عيادات , مستشفيات  ,معامل او شركات و مراكز تدريب علشان نحقق المعادله الصعبه في تحقيق اعلى جوده لأقل تكلفه </p>
                    </div>
                </div>
            </div>
            <div className='w-full h-56 flex flex-row shadow-md mb-24'>
                <div className='w-1/4 h-full ' style={{ backgroundImage: "url(" + exec + ")", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', objectFit: 'cover' }}>

                </div>
                <div className='w-3/4 h-full bg-white px-7 shadow'>
                    <div className='w-4/5 h-full flex flex-col justify-center gap-5'>
                        <HeaderH2Text weight='bold'>التنفيذ</HeaderH2Text>
                        <p className='font-bold'>مجموعه من خبراء المهندسين هيساعدوك علشان تقدر تنفذ مشروعك في<span className='secondaryText'> أسرع وقت و أقل تكلفه</span> و أعلى خامات .</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurServices